.careers-top-banner {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    .agile-banner-inner {
        .agile-banner-content {
            display: block;
            padding-left: 0px;
            padding-right: 0px;
            background: transparent;
            .row {
                align-items: center;
            }
            .careers-heading-left {
                max-width: 680px;
                .heading {
                    text-align: left;
                    margin-bottom: 0;
                    h1.page-heading {
                        margin-bottom: 16px;
                    }
                    p {
                        padding-bottom: 32px;
                    }
                }
            }
            .careers-heading-right {
                text-align: center;
                img {
                    max-width: 157px;
                    width: 100%;
                }
            }
        }
    }
}
.career-middle {
    .trootech-job-positions {
        padding: 180px 0 160px;
        .section-titel {
            padding-bottom: 34px;
        }
        .job-searching-inner {
            display: flex;
            justify-content: space-between;
            border: 1px solid rgba(0, 134, 230, 0.2);
            padding: 10px 10px 10px 0;
            border-radius: 10px;
            align-items: center;
            margin-bottom: 30px;

            .job-search-bar {
                flex: 1;
                .form-group {
                    margin-bottom: 0 !important;

                    .form-input {
                        position: relative;
                        padding-left: 74px;
                        .form-control {
                            border: 0px !important;
                            height: 52px;
                            border-radius: 10px;
                            padding-left: 0;
                            background-color: transparent;
                            &::placeholder {
                                color: #91959b !important;
                                font-weight: 400;
                                font-size: 16px;
                            }
                        }
                        .search-icon {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 30px;
                        }
                    }
                }
            }
            .job-search-loction {
                border-left: 1px solid rgba(0, 134, 230, 0.2);
                border-right: 1px solid rgba(0, 134, 230, 0.2);
                padding: 0 20px;

                .form-group {
                    margin-bottom: 0 !important;
                }
                
                .budget-select {
                    position: relative;
                    padding-left: 20px;
                    &::after {
                        position: absolute;
                        content: "";
                        left: 0;
                        width: 19px;
                        height: 24px;
                        background-image: url(../assets/images/location.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        top: 6px;
                    }
                    .budget-select__value-container {
                        padding-right: 5px;
                    }
                    .budget-select__control {
                        padding: 0;
                        border: none !important;
                    }
                    .budget-select__indicator {
                        background-image: url(../assets/images/grey-down-arrow.svg);
                        background-size: 16px;
                        background-repeat: no-repeat;
                        padding: 0;
                    }
                    .budget-select__single-value {
                        font-weight: 400;
                    }
                }
            }
            .job-search-btn {
                padding-left: 10px;
            }
        }
        .job-searching-result {
            ul {
                padding: 0;
            }
            .job-description-box {
                border: 1px solid rgba(97, 97, 97, 0.2) !important;
                border-radius: 10px;
                margin: 12px 0;
                padding: 20px;
                height: calc(100% - 24px);
                display: flex;
                flex-flow: column;
                transition: all 0.5s;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
                    opacity: 0;
                    transition: all 0.5s;
                }
                &:hover {
                    border: 1px solid #f4fafe !important;
                    &::after {
                        opacity: 1;
                    }
                    .job-description-top {
                        .job-apply-icon {
                            opacity: 1;
                            transform: translateX(0px) translateY(0px);
                        }
                    }
                }
                .job-description-top {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 20px;
                    .job-title-left {
                        flex: 1;
                        padding-right: 10px;
                        .job-title {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            color: #040405;
                            margin-bottom: 5px;
                        }
                        p {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #91959b !important;
                            margin-bottom: 0;
                        }
                    }
                    .job-apply-icon {
                        opacity: 0;
                        transform: translateX(-10px) translateY(0px);
                        transition: all 0.5s;
                        .btn.btn-secondary {
                            padding: 0;
                            border: none !important;
                            box-shadow: none !important;
                            min-height: auto;
                            height: auto;
                            background-color: transparent !important;
                            &:hover {
                                transform: translateY(0px);
                            }
                            img {
                                max-width: 23px;
                            }
                        }
                    }
                }
                .job-description-bottom {
                    display: flex;
                    margin-top: auto;
                    position: relative;
                    z-index: 1;
                    justify-content: space-between;
                    .job-bottom-experience,
                    .job-bottom-openings {
                        .job-bottom-label {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 5px;
                            color: #91959b !important;
                        }
                        .job-bottom-content {
                            font-weight: 600;
                            font-size: 20px;
                            color: #040405 !important;
                            line-height: 30px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.careers-startups-section {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    overflow: hidden;
    padding: 120px 0 180px;
    .startups-uber-section {
        background: transparent;
        padding: 0px 0px 50px 0px;
    }
    .careers-partner-section {
        background-color: transparent;
        .startups-partner-inner {
            & > .container {
                position: relative;
            }
            .pratner-box {
                top: 0;
                position: relative;
                padding-top: 0;
               	margin: 0 -15px;

                .startups-partner-item {
                    padding: 0 15px;
                }
                .slick-list {
                    .slick-track {
                        display: flex !important;
                    }

                    .slick-slide {
                        height: inherit !important;
                        & > div {
                            height: 100%;
                            & .startups-partner-item {
                                height: 100%;
                                & .startups-partner-card {
                                    height: calc(100% - 4px);
                                    border: 1px solid rgba(0, 134, 230, 0.1);
                                }
                            }
                        }
                    }
                    .startups-partner-card {
                        height: 100%;
                        padding: 0;
                        border: 0;
                        .startups-partner-content {
                            padding: 0 20px 20px;
                        }
                        .startups-partner-img {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            height: 0;
                            position: relative;
                            padding-bottom: 55%;
                            overflow: hidden;
                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center center;
                            }
                        }
                    }
                }
                .slick-arrow {
                    position: absolute;
                    top: auto;
                    transform: translateY(0) translateX(-50%);
                    bottom: -80px;
                    left: calc(50% - 24px);
                    background: transparent;
                    width: 32px;
                    height: 32px;
                    border: 0.75px solid rgba(4, 4, 5, 0.2);
                    border-radius: 10px;
                    transition: all 0.4s;
                    &:hover {
                        border-color: rgb(141, 208, 255);
                        background: transparent;
                        &::before {
                            opacity: 1;
                            filter: none;
                        }
                    }
                    &::before {
                        content: "" !important;
                        background-image: url(../assets/images/update-left-arrows.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        display: block;
                        margin: 0 auto;
                        filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
                        opacity: 0.6;
                        width: 12px;
                        height: 11px;
                        transition: all 0.4s;
                    }
                    &.slick-next {
                        margin-left: 48px;
                        &::before {
                            transform: rotate(180deg);
                        }
                    }
                    &.slick-prev {
                        margin-right: 48px;
                    }
                }
            }
        }
    }
}
.career-culture-main {
    padding: 160px 0;
    background-color: #fff;
    .career-culture-section {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        .our-culture-card {
            background-image: none;
            padding-top: 0 !important;
            background: transparent;
            .card-tite h2 {
                background: none;
                -webkit-background-clip: inherit;
                -webkit-text-fill-color: inherit;
                background-clip: unset;
                color: #040405 !important;
            }
            .card-images {
                display: none;
            }
        }
    }
    .career-gallery-section {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        .gallery-slider {
            margin-top: 0;
            .slick-slide {
                margin: 0 0px;
            }
            .gallery-img-item {
                padding: 0 15px;
                .gallery-img-wrapper {
                    height: auto;
                    max-height: inherit;
                    &.gallery-img-row {
                        display: flex;
                        column-gap: 30px;
                        max-width: 740px;
                        img {
                            width: 100% !important;
                            height: 100% !important;
                            object-fit: cover;
                            object-position: center center;
                            border-radius: 10px !important;
                            min-width: inherit;
                            max-width: 100%;
                            transition: all 0.4s linear;
                            transform: scale(1);
                            transform-origin: center center;
                        }
                        .gallery-col-1 {
                            max-width: 316px;
                            width: 100%;
                            .gallery-col-big {
                                margin-bottom: 30px;
                                height: 320px;
                                overflow: hidden;
                                border-radius: 10px !important;
                                &:hover{
                                    img{
                                        transform: scale(1.03);
                                         transition: all 0.4s linear;
                                    }
                                }
                            }
                            .gallery-col-small {
                                height: 180px;
                                overflow: hidden;
                                border-radius: 10px !important;
                                &:hover{
                                    img{
                                        transform: scale(1.03);
                                         transition: all 0.4s linear;
                                    }
                                }
                            }
                        }
                        .gallery-col-2 {
                            max-width: 394px;
                            width: 100%;
                            display: flex;
                            flex-flow: column-reverse;
                            .gallery-col-big {
                                margin-top: 30px;
                                height: 320px;
                                overflow: hidden;
                                border-radius: 10px !important;
                                &:hover{
                                    img{
                                        transform: scale(1.03);
                                         transition: all 0.4s linear;
                                    }
                                }
                            }
                            .gallery-col-small {
                                height: 180px;
                                overflow: hidden;
                                border-radius: 10px !important;
                                &:hover{
                                    img{
                                        transform: scale(1.03);
                                         transition: all 0.4s linear;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.career-benefits-section {
    background-color: #fff;
    padding-top: 0 !important;
    &::after {
        display: none;
    }

    .section-titel {
        text-align: center;
        padding-bottom: 32px;
    }
    .icon-card {
        .card {
            text-align: center;
            overflow: hidden;
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
                position: absolute;
                transition: all 0.5s;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
            .icon-img {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                z-index: 2;
                &::after {
                    background: none;
                }
            }
            .card-content {
                position: relative;
                z-index: 2;
            }
        }
    }
}

.employee-review-section {
    padding: 120px 0 160px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    .section-titel {
        padding-bottom: 42px;
        h2 {
            font-size: 42px;
            line-height: 60px;
            color: #040405;
            text-align: center;
            font-weight: 700;
        }
    }
    .employee-review-slider {
        .slick-slide:not(.slick-center) {
            .review-slide {
                transform: translateY(0px);
            }
        }
        .review-slide {
            padding: 50px 15px 0;
            transform: translateY(-50px);
            transition: all 0.5s;
            .review-card {
                background-color: #fff;
                box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                margin-bottom: 30px;
                overflow: hidden;
                .review-body {
                    padding: 70px 20px 32px;
                    position: relative;
                     min-height: 400px;
                    &::after {
                        content: "";
                        top: 20px;
                        left: 20px;
                        position: absolute;
                        width: 40px;
                        height: 30px;
                        background-size: contain;
                        background-position: center center;
                        background-image: url(../public/images/review-quotes.svg);
                        background-repeat: no-repeat;
                    }
                    p {
                        font-weight: 400;
                        font-size: 18px;
                        color: #040405 !important;
                        line-height: 27px;
                       
                    }
                }
                .review-footer {
                    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
                    padding: 20px;
                    p {
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 18px;
                        color: #040405;
                    }
                }
            }
        }
        .slick-arrow {
            position: absolute;
            top: auto;
            transform: translateY(0) translateX(-50%);
            bottom: -40px;
            left: calc(50% - 24px);
            background: transparent;
            width: 32px;
            height: 32px;
            border: 0.75px solid rgba(4, 4, 5, 0.2);
            border-radius: 10px;
            transition: all 0.4s;
            &:hover {
                border-color: rgb(141, 208, 255);
                background: transparent;
                &::before {
                    opacity: 1;
                    filter: none;
                }
            }
            &::before {
                content: "" !important;
                background-image: url(../assets/images/update-left-arrows.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                height: 15px;
                width: 15px;
                display: block;
                margin: 0 auto;
                filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
                opacity: 0.6;
                width: 12px;
                height: 11px;
                transition: all 0.4s;
            }
            &.slick-next {
                margin-left: 48px;
                &::before {
                    transform: rotate(180deg);
                }
            }
            &.slick-prev {
                margin-right: 48px;
            }
        }
    }
}
.theme-dark .employee-review-section .employee-review-slider .slick-arrow,
.theme-dark .careers-startups-section .careers-partner-section .startups-partner-inner .pratner-box .slick-arrow {
    border: 0.75px solid rgb(255 255 255 / 30%);
}
.theme-dark .employee-review-section .employee-review-slider .slick-arrow:hover,
.theme-dark.careers-startups-section .careers-partner-section .startups-partner-inner .pratner-box .slick-arrow:hover {
    border-color: rgb(141, 208, 255);
}
.theme-dark .career-middle .trootech-job-positions .job-searching-result .job-description-box:hover {
    border: 1px solid #f4fafe33 !important;
}
.theme-dark .employee-review-section .employee-review-slider .review-slide .review-card .review-footer {
    background: #1a1a1a;
}

.careersabout-slider-inner {
    position: relative;
    background-image: url(../public/images/careersabout-bg.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    outline: none !important;
    padding: 80px 40px;
    .careersaboutslider {
        width: 347px;
        max-width: 100%;
        margin: 0 auto;
        outline: none !important;
        border-radius: 12px;
        border: 7px solid #fff;
        .slick-list {
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0px 52.96113586425781px 122.94547271728516px 0px rgba(0, 0, 0, 0.12);
        }
        .slick-initialized .slick-slide {
            outline: none !important;
        }
        .careersaboutslider-item {
            text-align: center;
            outline: none !important;
            vertical-align: top;
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
            }
        }
        .slick-arrow {
            position: absolute;
            top: auto;
            transform: translateY(0) translateX(-50%);
            bottom: -60px;
            left: calc(50% - 24px);
            background: transparent;
            width: 32px;
            height: 32px;
            border: 0.75px solid rgba(4, 4, 5, 0.2);
            border-radius: 10px;
            transition: all 0.4s;
            &:hover {
                border-color: rgb(141, 208, 255);
                background: transparent;
                &::before {
                    opacity: 1;
                    filter: none;
                }
            }
            &::before {
                content: "" !important;
                background-image: url(../assets/images/update-left-arrows.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                display: block;
                margin: 0 auto;
                filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
                opacity: 0.6;
                width: 12px;
                height: 11px;
                transition: all 0.4s;
            }
            &.slick-next {
                margin-left: 48px;
                &::before {
                    transform: rotate(180deg);
                }
            }
            &.slick-prev {
                margin-right: 48px;
            }
        }
    }
    .slick-dots {
        margin-top: -50px;
    }
}
.theme-dark .careersabout-slider-inner .careersaboutslider .slick-arrow {
    border: 0.75px solid rgb(255 255 255 / 30%);
}
.theme-dark .careersabout-slider-inner .careersaboutslider .slick-arrow:hover {
    border-color: rgb(141, 208, 255);
}
.career-middle .careers-about-section {
    padding: 100px 0;
}
/*****responsive********/
@media (max-width: 1900px) {
    .employee-review-section .section-titel h2 {
        font-size: 40px;
        line-height: 60px;
    }
}
@media (max-width: 1400px) {
    .employee-review-section .section-titel h2 {
        font-size: 36px;
        line-height: 56px;
    }
    .careers-top-banner .agile-banner-inner .agile-banner-content .careers-heading-left {
        max-width: 560px;
    }
}
@media (max-width: 1366px) {
    .employee-review-section .section-titel h2 {
        font-size: 32px;
        line-height: 43px;
    }
}
@media (max-width: 1200px) {
    .employee-review-section .section-titel h2 {
        font-size: 28px;
        line-height: 42px;
    }
    .career-culture-main {
        .career-gallery-section {
            .gallery-slider {
                .gallery-img-item {
                    .gallery-img-wrapper {
                        &.gallery-img-row {
                            .gallery-col-2 .gallery-col-small,
                            .gallery-col-1 .gallery-col-small {
                                height: 160px;
                            }
                            .gallery-col-2 .gallery-col-big,
                            .gallery-col-1 .gallery-col-big {
                                height: 240px;
                            }
                        }
                    }
                }
            }
        }
    }
    .careers-top-banner .agile-banner-inner .agile-banner-content .careers-heading-left {
        max-width: 500px;
    }
}
@media (max-width: 991px) {
    .employee-review-section {
        padding: 80px 0 120px;
        overflow: hidden;
        .section-titel {
            padding-bottom: 30px;
            h2 {
                font-size: 26px;
                line-height: 35px;
            }
        }
        .employee-review-slider {
            .slick-list {
                overflow: visible;
            }
        }
    }
    .career-middle {
        .trootech-job-positions {
            padding: 80px 0 80px;
            .job-searching-inner {
                .job-search-bar {
                    .form-group {
                        .form-input {
                            padding-left: 50px;
                            .form-control {
                                height: 35px;
                            }
                            .search-icon {
                                left: 16px;
                                img {
                                    width: 20px;
                                }
                            }
                        }
                    }
                }
                .job-search-btn {
                    .btn.btn-primary {
                        height: auto;
                    }
                }
            }
            .job-searching-result {
                .job-description-box {
                    .job-description-top {
                        .job-title-left {
                            .job-title {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                    .job-description-bottom {
                        .job-bottom-experience,
                        .job-bottom-openings {
                            .job-bottom-content {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }
        }
    }
    .careers-startups-section {
        padding: 80px 0 120px;
        .careers-partner-section {
            .startups-partner-inner {
                .pratner-box {
                    .slick-arrow {
                        bottom: -60px;
                    }
                }
            }
        }
    }
    .career-culture-main {
        padding: 80px 0;
    }
    .career-middle .careers-about-section {
        padding: 80px 0;
    }
}
@media (max-width: 767px) {
    .our-culture-section .our-culture-card .card-tite h2,
    .startups-uber-section .startups-uber-inner .heading h2,
    .career-middle .job-positions-section .section-titel h2,
    .career-middle .careers-about-section .careers-about-left h2,
    .employee-review-section .section-titel h2 {
        font-size: 28px;
        line-height: 40px;
    }
    .employee-review-section {
        padding: 60px 0 80px;
        .employee-review-slider {
            .review-slide {
                .review-card {
                    .review-body {
                        min-height: inherit;
                        p {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 0;
                            min-height: 200px;
                        }
                    }
                }
            }
        }
    }
    .career-middle .careers-about-section {
        padding: 60px 0 60px;
    }
    .career-middle .trootech-job-positions {
        padding: 60px 0 60px;
    }
    .career-middle
        .trootech-job-positions
        .job-searching-result
        .job-description-box
        .job-description-top
        .job-title-left
        .job-title,
    .career-middle
        .trootech-job-positions
        .job-searching-result
        .job-description-box
        .job-description-bottom
        .job-bottom-experience
        .job-bottom-content,
    .career-middle
        .trootech-job-positions
        .job-searching-result
        .job-description-box
        .job-description-bottom
        .job-bottom-openings
        .job-bottom-content {
        font-size: 16px;
        line-height: 24px;
    }
    .career-middle
        .trootech-job-positions
        .job-searching-result
        .job-description-box
        .job-description-bottom
        .job-bottom-experience
        .job-bottom-label,
    .career-middle
        .trootech-job-positions
        .job-searching-result
        .job-description-box
        .job-description-bottom
        .job-bottom-openings
        .job-bottom-label {
        font-size: 14px;
        line-height: 22px;
    }

    .career-middle {
        .trootech-job-positions {
            .job-searching-inner {
                flex-wrap: wrap;
                padding: 10px;
                .job-search-bar {
                    flex: 100%;
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 134, 230, 0.2);
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    .form-group {
                        .form-input {
                            padding-left: 40px;
                            .form-control {
                                height: 35px;
                            }
                            .search-icon {
                                left: 5px;
                            }
                        }
                    }
                }
                .job-search-loction {
                    flex: 1;
                    border-left: 0px;
                    padding-left: 10px;
                }
                .job-search-btn {
                    padding-left: 15px;
                }
            }
        }
    }

    .careers-startups-section {
        padding: 60px 0 100px;
        .startups-uber-section {
            padding: 0px 0px 0px 0px;
        }
        .careers-partner-section {
            .startups-partner-inner {
                .pratner-box {
                    .slick-arrow {
                        bottom: -60px;
                    }
                }
            }
        }
    }
    .career-culture-main {
        padding: 60px 0;
        .our-culture-section {
            .our-culture-card {
                padding: 50px 20px 20px;
            }
        }
        .career-gallery-section {
            .gallery-slider {
                .gallery-img-item {
                    .gallery-img-wrapper {
                        &.gallery-img-row {
                            .gallery-col-2 .gallery-col-small,
                            .gallery-col-1 .gallery-col-small {
                                height: 180px;
                            }
                            .gallery-col-2 .gallery-col-big,
                            .gallery-col-1 .gallery-col-big {
                                height: 250px;
                            }
                        }
                    }
                }
            }
        }
    }
    .employee-review-section {
        .employee-review-slider {
            .review-slide {
                padding-top: 0;
                transform: none;
            }
        }
    }
    .careersabout-slider-inner .careersaboutslider {
        width: 300px;
    }
    .careersabout-slider-inner {
        padding: 60px 30px;
    }
    .career-middle .careers-about-section {
        padding: 60px 0;
    }
    .careers-top-banner .agile-banner-inner .agile-banner-content .careers-heading-left {
        max-width: 100%;
    }
}
@media (max-width: 575px) {
    .careers-top-banner {
        .agile-banner-inner {
            .agile-banner-content {
                display: block;
                padding-left: 0px;
                padding-right: 0px;

                .careers-heading-right {
                    display: none;
                }
            }
        }
    }
    .career-middle {
        .trootech-job-positions {
            .job-searching-inner {
                .job-search-bar {
                    .form-group {
                        .form-input {
                            .form-control {
                                height: 35px;
                                font-size: 14px;
                                &::placeholder {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .career-culture-main {
        padding: 60px 0;
        .our-culture-section {
            .our-culture-card {
                padding: 50px 20px 20px;
            }
        }
        .career-gallery-section {
            .gallery-slider {
                .slick-slide {
                    padding: 0;
                }
                .gallery-img-item {
                    padding: 0 10px;
                    .gallery-img-wrapper {
                        &.gallery-img-row {
                            column-gap: 20px;
                            .gallery-col-2 .gallery-col-small,
                            .gallery-col-1 .gallery-col-small {
                                height: 140px;
                            }
                            .gallery-col-2 .gallery-col-big,
                            .gallery-col-1 .gallery-col-big {
                                height: 200px;
                            }
                            .gallery-col-2 .gallery-col-big {
                                margin-top: 20px;
                            }
                            .gallery-col-1 .gallery-col-big {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .employee-review-section {
        .employee-review-slider {
            .review-slide {
                padding-top: 0;
            }
        }
    }
}
@media (max-width: 460px) {
    .careers-startups-section
        .careers-partner-section
        .startups-partner-inner
        .pratner-box
        .slick-list
        .startups-partner-card
        .startups-partner-img {
        padding-bottom: 40%;
    }
}

@media (max-width: 416px) {
    .career-culture-main {
        .career-gallery-section {
            .gallery-slider {
                .gallery-img-item {
                    .gallery-img-wrapper {
                        &.gallery-img-row {
                            .gallery-col-2 .gallery-col-small,
                            .gallery-col-1 .gallery-col-small {
                                height: 120px;
                            }
                            .gallery-col-2 .gallery-col-big,
                            .gallery-col-1 .gallery-col-big {
                                height: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1024px) and (max-height: 800px) {
    .careers-top-banner .agile-banner-inner .agile-banner-content,
    .career-middle .careers-about-section,
    .career-middle .trootech-job-positions,
    .career-culture-main {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .employee-review-section {
        padding-top: 80px;
        padding-bottom: 120px;
    }
    .career-middle .news-letter-section {
        padding-bottom: 100px;
    }
    .careers-startups-section {
        padding-top: 80px;
        padding-bottom: 120px;
    }
}

/*********/

// banner min height css start
@media only screen and (min-width: 1400px) {
    .careers-top-banner {
        min-height: 610px;
    }
}
// banner min height css end
