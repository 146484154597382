.theme-light {
    --blog-text-link: #0086e6;
}
.theme-dark {
    --blog-text-link: #8dd0ff;
}

.agile-banner-section.agile-full-banner {
    padding: 107px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    min-height: 580px;
    display: flex;
    align-items: center;
    .agile-banner-inner {
        width: 100%;
        .container > .row {
            align-items: center;
        }
        .agile-banner-content {
            padding: 0;
            background: transparent;
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;
            max-width: 660px;
            min-height: inherit !important;
            .heading {
                text-align: left;
                h3 {
                    text-align: left;
                }
                h3:empty {
                    display: none;
                }
                .page-heading {
                    text-align: left;
                }
                p {
                    max-width: 100%;
                    text-align: left;
                }
            }
        }
        .agile-banner-img {
            text-align: center;
            img {
                max-width: 100%;
                height: auto;
                width: auto;
            }
        }
        .agile-banner-notes {
            ul {
                display: flex;
                padding: 40px 0 0;
                li {
                    padding: 0 16px;
                    list-style: none;
                    border-left: 1px solid rgba(4, 4, 5, 0.2);
                    p {
                        font-weight: 500 !important;
                        font-size: 20px !important;
                        line-height: 28px !important;
                        color: #040405 !important;
                        padding: 0;
                    }
                    &:first-child {
                        padding-left: 0;
                        border-left: 0;
                    }
                }
            }
        }
    }
}
.service-middle,
.technologies-middle,
.industry-middle {
    .partner-logo-section {
        padding-top: 0;
        position: relative;
        &::after {
            content: "";
            width: 100%;
            height: 2px;
            background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
            opacity: 0.1;
            top: 0;
            left: 0;
            position: absolute;
        }
    }
}
.service-middle {
    .services-banner-img {
        display: inline-block !important;
    }
}
.technologies-middle {
    .technologies-banner-img {
        display: inline-block !important;
    }
}
.industry-middle {
    .industries-banner-img {
        display: inline-block !important;
    }
}
.theme-dark {
    .agile-banner-section.agile-full-banner .agile-banner-inner .agile-banner-notes ul li {
        border-color: rgba(255, 255, 255, 0.2) !important;
    }
}
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail {
    padding: 0px 0px 40px 40px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .recommend-indu-item:last-child h2 {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%),
        linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail img {
    max-height: inherit !important;
    object-fit: inherit !important;
}
/***/

.contact-other-section
    .findus-section
    .our-location
    .our-location-inner
    .our-location-row
    .our-location-col
    .our-location-card
    .contact-info
    .contact-link {
    position: relative;
    z-index: 4;
}

.blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent {
    .blogcontent-detail {
        h2 {
            font-size: 26px;
            line-height: 39px;
        }
        h3 {
            font-size: 22px;
            line-height: 33px;
        }
        h4 {
            font-size: 20px;
            line-height: 30px;
        }
        h5 {
            font-size: 18px;
            line-height: 26px;
        }
        h6 {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.industry-middle
    .industries-system-section
    .development-box
    .development-bottom
    .development-links
    li
    .development-links-btn::after,
.industry-middle
    .industries-system-section
    .development-box
    .development-bottom
    .development-links
    li
    .development-links-btn:hover::after {
    top: 50% !important;
    transform: translateY(-50%) !important;
}
/***27-9-2023****/
.theme-dark .form-control::placeholder {
    color: var(--text-proposal-color-black);
}
.theme-dark .modal-backdrop.show {
    background-color: rgba(255, 255, 255, 0.4);
}
.theme-dark .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    background-image: url(../assets/images/accordion-plus-dark.svg);
}
.theme-dark .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
    background-image: url(../assets/images/accordion-minus-dark.svg);
}
.faq-section .accordion .accordion-item .accordion-collapse .accordion-body span {
    max-width: 100%;
}
.lets-connect-modal .modal-content .modal-body .card .card-body .btn.btn-secondary {
    background: transparent !important;
    position: relative;

    &::after {
        content: "";
        background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.4s;
        border-radius: 8px !important;
        box-shadow: 0 3px 15px 0 rgba(0, 134, 230, 0.4) !important;
    }
    span {
        position: relative;
        z-index: 2;
    }
}
.lets-connect-modal .modal-content .modal-body .card .card-body .btn.btn-secondary:hover {
    // transform: translateY(-3px) !important;
    border-color: transparent !important;
    &::after {
        opacity: 1;
    }
}
.lets-connect-modal .modal-content .modal-body .card .card-body .btn.btn-secondary {
    transition:
        all 0.4s,
        color 0.4s !important;
}
.career-middle .trootech-job-positions .job-searching-inner .job-search-bar .form-group .form-input .form-control {
    color: #040405;
}
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail a,
.blog-digital-section .blog-digital-inner .blog-digital-heading p a {
    color: #0086e6 !important;
}
.subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom {
    align-items: flex-start;
    li {
        .btn-primary {
            margin-top: 4px;
        }
    }
}
.theme-dark
    .subscribe-modal
    .modal-content
    .modal-body
    .subscribe-modal-content
    .subscribe-modal-content-bottom
    .form-group
    .error-message {
    color: #f36969 !important;
}
.error-text-message {
    margin-top: 5px;
    display: block;
    font-size: 14px;
}
.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content h3 {
    color: #040405 !important;
}
.form-floating > .form-control-plaintext::placeholder,
.form-floating > .form-control::placeholder {
    color: transparent !important;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
    background-color: transparent !important;
}

.industries-relevant-section .steps-feature .feature-content .btn.btn-secondary.feature-btn {
    position: relative;
    transition: all 0.4s;
    &::before {
        content: "";
        background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.6s ease-in-out;
        border-radius: 8px !important;
        z-index: -1;
        box-shadow: 0 3px 15px 0 rgba(0, 134, 230, 0.4) !important;
    }
    span {
        position: relative;
        z-index: 2;
    }
}
.industries-relevant-section .steps-feature .feature-content .btn.btn-secondary.feature-btn:hover {
    background: none;

    &::before {
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }
}
.service-middle .agile-team-section h2 {
    color: #040405;
}
.startups-building-section .startups-building-inner .startups-app-card .startups-app .startups-app-img img {
    transition: all 0.4s;
}
.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app-tittle span {
    font-weight: 500;
    transition: all 0.4s;
}
.startups-building-section .startups-building-inner .startups-app-card .startups-app .startups-app-img p {
    transition: all 0.4s;
    font-size: 16px !important;
    line-height: 24px !important;
}
.case-studies-about-detial-section .case-studies-about-listing .share-icon .icon-list-img li > a > img {
    transition: all 0.2s;
}
.case-studies-about-detial-section .case-studies-about-listing .share-icon .icon-list-img li > a:hover > img {
    transform: scale(1.2);
}
.case-studies-about-detial-section .case-studies-about-listing .share-icon .icon-list-img li button span {
    transition: all 0.2s;
}
.case-studies-about-detial-section .case-studies-about-listing .share-icon .icon-list-img li button:hover span {
    transform: scale(1.2);
}
.startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-img img {
    object-fit: cover;
    object-position: center center;
    border-radius: 12px;
}
.theme-dark .challenges-section .challenges-inner .challenges-content p {
    color: #040405 !important;
}
.technologies-middle
    .faq-section
    .faq-inner
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed:after {
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}
.technologies-middle .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button:after {
    top: 50%;
    transform: translateY(-8%);
    right: 3px;
}
.banner-section .banner-heading h1 u {
    display: inline-block;
}
.middle-sction.technologies-middle {
    background-color: #fff;
}
.faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 18px !important;
    background-position: center center;
}
.faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 18px !important;
}
.modal-form .modal-content .modal-body .modal-banner-notes {
    margin-bottom: 18px;
    p {
        margin-bottom: 0;
    }
}
.modal-form .modal-content .modal-body .modal-banner-notes::after {
    top: 50%;
    transform: translateY(-50%);
}
.blog-topstories-section .blog-topstories-inner .blog-topstories-heading:before {
    top: 50%;
    transform: translateY(-50%);
}
.blog-topstories-section .blog-topstories-inner .blog-topstories-heading h2:after {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}
.blog-page-section .blog-topstories-section .blog-topstories-inner .blog-topstories-heading h2 {
    margin-bottom: 0;
}
p[style="text-align: justify;"] {
    text-align: inherit !important;
}
.blog-page-section .blog-allstories-section .blog-allstories-inner nav {
    margin-bottom: 80px;
}
.service-middle .testimonial-section {
    padding: 80px 0 120px 0;
}
// 29-9-2023
.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app-tittle span::before {
    right: -107px;
    top: 6px;
}
.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app-tittle span::after {
    top: 6px;
}

.service-middle .technologies-section {
    padding: 80px 0 80px;
}
.service-middle .challenges-section {
    padding: 80px 0 80px;
}
.service-middle .agile-team-section {
    padding: 80px 0 120px;
}
.service-middle .approach-section .approach-inner .cto-div .cto-pofile {
    margin-bottom: 150px;
}
.service-middle .approach-section .approach-inner {
    padding: 80px 0;
}
.service-middle .approach-section {
    padding: 0 0;
}
.service-middle .partner-section {
    padding: 80px 0;
}
.service-middle .case-study-section {
    padding-bottom: 0;
}
.service-middle .approach-section .cto-pofile .cto-profile-info {
    top: 76%;
    bottom: auto;
}
.service-feature-section .service-follow .service-follow-content ul li {
    text-align: center;
}
.service-feature-section .service-follow .service-follow-content ul li::after {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}
.industry-middle .industries-tab .slick-track {
    display: flex;
    align-items: flex-end;
}
.contact-banner-form .request-blog-content h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 12px;
    margin: 0;
    color: #040405;
}
.request-proposal-form .request-blog-content h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 12px;
    margin: 0;
    color: #040405;
}
/*********/
@media (min-width: 992px) {
    .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
        margin-bottom: 10px;
    }
}
@media (min-width: 1366px) {
    .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content {
        padding: 10px 15px;
    }
    .service-middle .testimonial-section {
        padding: 80px 0 80px 0;
    }
}

@media (max-width: 1400px) {
    .agile-banner-section.agile-full-banner {
        min-height: 500px;
        .agile-banner-inner {
            .agile-banner-content {
                max-width: 560px;
            }
            .agile-banner-notes {
                ul {
                    padding: 40px 0 0;
                    li {
                        p {
                            font-size: 18px !important;
                            line-height: 26px !important;
                        }
                    }
                }
            }
        }
    }
    .contact-banner-form .request-blog-content h2,
    .request-proposal-form .request-blog-content h2 {
        font-size: 28px;
        line-height: 42px;
    }
}
@media (max-width: 1366px) {
    .career-culture-main
        .career-gallery-section
        .gallery-slider
        .gallery-img-item
        .gallery-img-wrapper.gallery-img-row {
        column-gap: 14px;
    }
    .career-culture-main .career-gallery-section .gallery-slider .gallery-img-item {
        padding: 0 7px;
    }
    .career-culture-main
        .career-gallery-section
        .gallery-slider
        .gallery-img-item
        .gallery-img-wrapper.gallery-img-row
        .gallery-col-2
        .gallery-col-big {
        margin-top: 14px;
    }
    .career-culture-main
        .career-gallery-section
        .gallery-slider
        .gallery-img-item
        .gallery-img-wrapper.gallery-img-row
        .gallery-col-1
        .gallery-col-big {
        margin-bottom: 14px;
    }
    .service-middle .approach-section .cto-pofile .cto-profile-info {
        bottom: auto;
    }
    .contact-banner-form .request-blog-content h2,
    .request-proposal-form .request-blog-content h2 {
        font-size: 28px;
        line-height: 42px;
    }
}
@media (max-width: 1200px) {
    .agile-banner-section.agile-full-banner {
        padding: 100px 0;
        min-height: 450px;
        .agile-banner-inner {
            .agile-banner-content {
                max-width: 500px;
            }
            .agile-banner-notes {
                ul {
                    padding: 40px 0 0;
                    li {
                        p {
                            font-size: 16px !important;
                            line-height: 24px !important;
                        }
                    }
                }
            }
        }
    }
    .career-culture-main
        .career-gallery-section
        .gallery-slider
        .gallery-img-item
        .gallery-img-wrapper.gallery-img-row {
        column-gap: 14px;
    }
    .career-culture-main .career-gallery-section .gallery-slider .gallery-img-item {
        padding: 0 7px;
    }
    .career-culture-main
        .career-gallery-section
        .gallery-slider
        .gallery-img-item
        .gallery-img-wrapper.gallery-img-row
        .gallery-col-2
        .gallery-col-big {
        margin-top: 14px;
    }
    .career-culture-main
        .career-gallery-section
        .gallery-slider
        .gallery-img-item
        .gallery-img-wrapper.gallery-img-row
        .gallery-col-1
        .gallery-col-big {
        margin-bottom: 14px;
    }
    .aboutus-middle .meet-management-section .meet-slider .slick-next {
        right: -40px;
    }
    .aboutus-middle .meet-management-section .meet-slider .slick-prev {
        left: -40px;
    }
    .meet-management-section .meet-slider {
        max-width: 550px;
        padding: 0 40px;
    }
    .industries-tabs-section .industries-tab {
        padding: 0 30px !important;
    }
    .industries-tabs-section .slick-next {
        right: -30px;
    }
    .industries-tabs-section .slick-prev {
        left: -30px;
    }
    .technologies-tabs-section .technologies-feature-heading li a {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .contact-banner-form .request-blog-content h2,
    .request-proposal-form .request-blog-content h2 {
        font-size: 22px;
        line-height: 32px;
    }
}
@media (max-width: 992px) {
    .agile-banner-section.agile-full-banner {
        padding: 80px 0;
        min-height: 350px;
        .agile-banner-inner {
            .container > .row {
                align-items: flex-start;
            }
            .agile-banner-content {
                max-width: 100%;
            }
            .agile-banner-notes {
                ul {
                    padding: 30px 0 0;
                    li {
                        p {
                            font-size: 14px !important;
                            line-height: 21px !important;
                        }
                    }
                }
            }
        }
    }
    .blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent {
        .blogcontent-detail {
            h2 {
                font-size: 24px;
                line-height: 38px;
            }
            h3 {
                font-size: 20px;
                line-height: 30px;
            }
            h4 {
                font-size: 18px;
                line-height: 26px;
            }
            h5 {
                font-size: 16px;
                line-height: 24px;
            }
            h6 {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom {
        align-items: flex-start;
        li {
            .btn-primary {
                margin-top: 0px;
            }
        }
    }
    .banner-section .banner-heading h1 u {
        display: inline-block;
    }
    .homepage-middle .service-section .service-feature h3 {
        padding-right: 30px;
    }
    .work-section .our-project .project .project-user {
        margin-bottom: 0;
    }
    .aboutus-middle .meet-management-section .meet-slider .slick-next {
        right: -50px;
    }
    .aboutus-middle .meet-management-section .meet-slider .slick-prev {
        left: -50px;
    }
    .aboutus-middle .meet-management-section .meet-profile {
        min-height: auto;
    }
    .meet-management-section .meet-profile img {
        position: relative;
    }
    .testimonial-section .testimonial-main-box::before {
        background-size: 100% !important;
        right: 20px !important;
        width: 30px;
    }
    .career-middle .gallery-slider-section .gallery-slider .slick-slide {
        padding: 0;
    }
    .modal-form .modal-content .modal-footer .modal-footer-inner .btn {
        min-width: 150px;
    }
    .modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
        margin-right: 12px;
    }
    .aboutus-middle .vibe-section {
        padding: 40px 0 60px;
    }
    /*****/
    .homepage-middle .client-section {
        padding: 40px 0 40px 0;
    }
    .service-middle .testimonial-section {
        padding: 40px 0 80px 0;
    }
    .hiredeveloper-section {
        padding: 40px 0;
    }
    .homepage-middle .testimonial-section {
        padding: 40px 0 40px;
    }
    .homepage-middle .faq-section {
        padding: 40px 0 80px;
    }
    .contact-other-section .media-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .blog-page-section .blog-allstories-section .blog-allstories-inner nav {
        margin-bottom: 60px;
    }
    .service-middle .testimonial-section {
        padding: 80px 0 80px 0;
    }

    .service-middle .technologies-section {
        padding: 40px 0 40px;
    }
    .service-middle .challenges-section {
        padding: 40px 0 40px;
    }
    .service-middle .agile-team-section {
        padding: 40px 0 80px;
    }
    .service-middle .approach-section .approach-inner .cto-div .cto-pofile {
        margin-bottom: 150px;
        margin-left: 68px;
    }
    .service-middle .approach-section .approach-inner {
        padding: 80px 0;
    }
    .service-middle .approach-section {
        padding: 0 0;
    }
    .service-middle .partner-section {
        padding: 40px 0 40px;
    }
    .service-middle .digital-service-section {
        padding: 80px 0 40px;
    }
    .service-middle .faq-section {
        padding: 40px 0 60px;
    }
    .approach-section .approach-inner .cto-div {
        align-items: flex-end;
    }
    .service-middle .approach-section .approach-inner .cto-div .cto-pofile img {
        max-width: 100%;
        width: 300px;
    }
    .service-middle .approach-section .cto-pofile .cto-profile-info {
        top: 63%;
        bottom: auto;
    }
    .service-middle .book-section {
        padding: 40px 0 120px 0 !important;
    }
    .digital-service-section .heading {
        position: relative;
        top: auto;
    }
    .service-middle .service-tabs-section {
        padding-top: 12px;
    }
    .startups-middle .agile-banner-section {
        padding: 20px 0 40px 0;
    }

    .startups-building-section .startups-building-inner .startups-app-card .startups-app-tittle span::before {
        right: -107px;
        top: 6px;
    }
    .startups-building-section .startups-building-inner .startups-app-card .startups-app-tittle span::after {
        top: 6px;
    }
}
@media (max-width: 767px) {
    .homepage-middle .faq-section .accordion .accordion-item .accordion-header .accordion-button,
    .homepage-middle .faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
        padding: 12px 35px 12px 12px;
    }
    .agile-banner-section.agile-full-banner {
        padding: 50px 0;
        min-height: 350px;
        .agile-banner-inner {
            .agile-banner-content {
                max-width: 100%;
            }
            .agile-banner-notes {
                ul {
                    padding: 30px 0 0;
                    flex-flow: column;
                    li {
                        padding: 5px 8px 5px 12px;
                        position: relative;

                        &:first-child {
                            border-left: 1px solid rgba(4, 4, 5, 0.2);
                            padding-left: 12px;
                        }
                        p {
                            font-size: 14px !important;
                            line-height: 21px !important;
                        }
                    }
                }
            }
        }
    }
    .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail {
        padding: 20px 0;
    }
    .blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent {
        .blogcontent-detail {
            h2 {
                font-size: 22px;
                line-height: 34px;
            }
            h3 {
                font-size: 20px;
                line-height: 30px;
            }
            h4 {
                font-size: 18px;
                line-height: 26px;
            }
            h5 {
                font-size: 16px;
                line-height: 24px;
            }
            h6 {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
    .technologies-middle
        .faq-section
        .faq-inner
        .accordion
        .accordion-item
        .accordion-header
        .accordion-button.collapsed:after {
        right: 8px;
        background-size: 70%;
        width: 22px;
        height: 22px;
    }
    .technologies-middle .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
        background-size: 70%;
        width: 22px;
        height: 22px;
    }
    .technologies-middle .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button,
    .technologies-middle
        .faq-section
        .faq-inner
        .accordion
        .accordion-item
        .accordion-header
        .accordion-button.collapsed {
        font-size: 14px;
        line-height: 21px;
    }
    .homepage-middle .service-section .service-feature h3 {
        padding-right: 0px;
    }
    .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
        right: 6px !important;
        background-size: 70% !important;
        width: 22px !important;
        height: 22px !important;
    }
    .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
        background-size: 70% !important;
        width: 22px !important;
        height: 22px !important;
        right: 6px !important;
    }
    .aboutus-middle .meet-management-section .meet-slider .slick-next {
        right: -30px;
    }
    .aboutus-middle .meet-management-section .meet-slider .slick-prev {
        left: -30px;
    }
    .theme-dark .service-middle .stage-select-dropdown,
    .theme-dark .technologies-middle .stage-select-dropdown {
        background-color: #fff;
    }
    .modal-form .modal-content .modal-footer .modal-footer-inner .modal-recaptcha {
        margin-bottom: 20px !important;
    }
    .modal-form .modal-content .modal-footer .modal-footer-inner {
        justify-content: center !important;
    }
    .request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-content h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .aboutus-middle .difference-section .heading h2 {
        max-width: inherit;
    }

    .vibe-section .vibe-main-box .vibe-text {
        padding: 20px 15px;
    }
    .aboutus-middle .vibe-section {
        padding: 40px 0 60px;
    }
    .believe-section .row:nth-child(2) .col-lg-4:last-child {
        .believe-box {
            margin-bottom: 0;
        }
    }
    .contact-other-section .findus-section {
        padding-bottom: 120px;
    }
    /****/
    .homepage-middle .banner-section {
        padding: 42px 0 40px 0;
    }
    .homepage-middle .service-section .list-fetaure .col-sm-12:last-child .service-feature {
        padding-bottom: 20px;
    }
    .homepage-middle .work-section .btn.btn-secondary {
        padding: 6px 16px;
    }
    .homepage-middle .work-section {
        padding: 60px 0 30px 0;
    }
    .homepage-middle .client-section {
        padding: 30px 0 30px 0;
    }
    .homepage-middle .hiredeveloper-section {
        padding: 30px 0;
    }
    .homepage-middle h3:empty {
        display: none;
    }
    .homepage-middle .testimonial-section {
        padding: 30px 0 30px;
    }
    .aboutus-middle .difference-section {
        padding: 30px 0 30px;
    }
    .aboutus-middle .believe-section {
        padding: 30px 0 30px 0;
    }
    .aboutus-middle .vibe-section {
        padding: 30px 0 60px;
    }
    .contact-other-section .findus-section {
        padding-bottom: 80px;
    }
    .wordwise-section.contact-wordwise-section {
        padding-top: 60px;
        padding-bottom: 30px;
    }
    .contact-other-section .media-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .contact-banner-section {
        padding: 30px 0 20px 0;
    }
    .blog-detail-development .blog-migration-section {
        padding: 0px 0px 60px 0px;
    }

    .service-middle .testimonial-section {
        padding: 60px 0 60px 0;
    }

    .service-middle .technologies-section {
        padding: 30px 0 30px;
    }
    .service-middle .challenges-section {
        padding: 30px 0 30px;
    }
    .service-middle .agile-team-section {
        padding: 30px 0 60px;
    }
    .service-middle .approach-section .approach-inner .cto-div .cto-pofile {
        margin-bottom: 150px;
    }
    .service-middle .approach-section .approach-inner {
        padding: 60px 0;
    }
    .service-middle .approach-section {
        padding: 0 0;
    }
    .service-middle .partner-section {
        padding: 30px 0 30px;
    }
    .service-middle .digital-service-section {
        padding: 60px 0 30px;
    }
    .service-middle .faq-section {
        padding: 30px 0 60px;
    }
    .service-middle .technologies-section .technologies-inner .technologies-cantent .tabs-content {
        padding: 15px 0 0;
    }
    .service-middle .case-study-section .case-study-inner {
        padding: 60px 0;
    }
    .technologies-middle .faq-section {
        padding: 30px 0 40px;
    }
    .technologies-middle .framework-section .framework-inner .framework-card {
        margin-bottom: 52px;
        padding: 34px 28px 40px 28px;
        height: calc(100% - 52px);
    }
    .startups-middle .startups-vacation-section .startups-vacation-inner .heading {
        max-width: 100%;
    }
}
@media (max-width: 575px) {
    .agile-banner-section.agile-full-banner {
        .agile-banner-inner {
            .agile-banner-content {
                .heading {
                    text-align: left;
                    h3 {
                        text-align: left;
                    }
                    h3:empty {
                        display: none;
                    }
                    .page-heading {
                        text-align: left;
                    }
                    p {
                        max-width: 100%;
                        text-align: left;
                    }
                }
            }
            .agile-banner-img {
                text-align: center;
                display: none;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .service-feature-section .service-feature-content {
        padding-top: 20px;
    }
    .service-feature-section .service-follow .service-follow-content ul li {
        padding-right: 15px;
    }
    .contact-other-section .findus-section {
        padding-bottom: 110px;
    }
    .technologies-middle .framework-section .framework-inner .framework-card {
        margin-bottom: 42px;
        padding: 34px 28px 40px 28px;
        height: calc(100% - 42px);
    }
    .industry-middle .demand-education-section .demand-inner .row:nth-child(2) .col-sm-6:last-child .e-demand-box {
        margin-bottom: 0;
    }
}
@media (min-width: 1024px) and (max-width: 1366px) {
    .technologies-middle .book-section {
        padding: 60px 0 120px 0 !important;
    }
}
@media (min-width: 992px) and (max-width: 1368px) {
    .header .navbar .collapse1 .navbar-nav a.nav-link {
        padding: 8px 8px;
    }
}
// @media (min-width: 768px) and (max-width: 1366px){
//     .service-middle .approach-section .approach-inner .cto-div .cto-pofile img {
//         max-width: 340px;
//     }
// }

@media screen and (max-width: 1366px) and (max-height: 768px) and (min-width: 1024px) {
    .technologies-middle .book-section {
        padding: 60px 0 120px 0 !important;
    }
    .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button.collapsed:after,
    .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
        right: 10px !important;
    }
}
@media (min-width: 992px) and (max-width: 1024px) {
    .industries-middel .industries-tabs-section .slick-slide a {
        font-size: 14px;
        line-height: 18px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .industries-middel .services-education-section {
        padding: 20px 0 0 0;
    }
}

/*******************/

.homepage-middle .banner-section .solution-box {
    margin: 0 0px !important;
}

.banner-section {
    .banner-solution-box {
        .solution-btn {
            background-color: rgba(0, 134, 230, 0.1);
            border-radius: 12px;
            padding: 10px 16px;
            min-height: 52px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            outline: none;
            border: none;
            box-shadow: none;
            .solution-btn-text {
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
                color: rgb(108, 121, 131);
                text-align: left;
                transition: all 0.4s;
            }
            .arrow {
                margin-left: auto;
                background-image: url(../assets/images/letest-right-icon.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 11px;
                height: 30px;
                width: 30px;
                display: none;
                transition: all 0.4s;
                transform: translateX(-15px);
                display: block;
                opacity: 0;
            }
            &:hover {
                .solution-btn-text {
                    color: #040405;
                }
                .arrow {
                    transform: translateX(0px);
                    opacity: 1;
                }
            }
        }
        .col-lg-3:nth-child(2),
        .col-lg-3:nth-child(4) {
            .solution-btn {
                background: linear-gradient(317.72deg, rgba(138, 188, 0, 0.1) 5.24%, rgba(204, 250, 78, 0.1) 137.29%);
            }
        }
    }
}
@media (max-width: 991px) {
    .banner-section .banner-solution-box .solution-btn {
        margin: 6px 0;
    }
    .banner-section .banner-solution-box .col-lg-3:nth-child(3) .solution-btn {
        background: linear-gradient(317.72deg, rgba(138, 188, 0, 0.1) 5.24%, rgba(204, 250, 78, 0.1) 137.29%);
    }
    .banner-section .banner-solution-box .col-lg-3:nth-child(4) .solution-btn {
        background: rgba(0, 134, 230, 0.1);
    }
}
@media (max-width: 767px) {
    .banner-section .banner-solution-box .solution-btn {
        padding: 8px 13px;
        height: auto;
        min-height: auto;
        .solution-btn-text {
            font-size: 13px;
        }
    }
}
@media (max-width: 575px) {
    .banner-section .banner-solution-box .col-lg-3:nth-child(3) .solution-btn {
        background: rgba(0, 134, 230, 0.1);
    }
    .banner-section .banner-solution-box .col-lg-3:nth-child(4) .solution-btn {
        background: linear-gradient(317.72deg, rgba(138, 188, 0, 0.1) 5.24%, rgba(204, 250, 78, 0.1) 137.29%);
    }
    .banner-section .banner-solution-box .solution-btn {
        max-width: 265px;
        margin: 6px auto;
    }
}
/******************/

/*******************/
.technologies-middle .technologies-tabs-section .technologies-feature-heading li a {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
}
.startups-tab-section .startups-tab-inner .startups-tab-heading li a {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
    &.active {
        font-weight: 500;
    }
}
.industries-middel .industries-tabs-section .slick-slide a {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500 !important;
}
/***************/

/***********technologies-page-changes*************/

.technologies-full-banner {
    .agile-input-group {
        width: 100%;
        margin-bottom: 32px;
        .input-group {
            max-width: 540px;
            .form-control {
                border-radius: 8px;
                border: 1px solid rgba(4, 4, 5, 0.2);
                color: #040405 !important;
                background-color: #fff;
                font-size: 16px;
                line-height: 20px;
                &:focus {
                    border-color: rgba(0, 134, 230, 0.4);
                }
            }
            .input-group-btn.btn.btn-primary {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                &:hover {
                    transform: translateY(-0px);
                }
            }
        }
    }
    &.agile-full-banner {
        .agile-banner-inner {
            .agile-banner-notes {
                ul.banner-notes-list {
                    padding: 24px;
                    border: 1px solid rgba(4, 4, 5, 0.1);
                    border-radius: 12px;
                    li {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}
.onestop-bottom {
    text-align: center;
    padding-top: 18px;
}

.technologies-middle .milestone-section .heading {
    margin-bottom: 48px;
}
.our-milestones-section {
    padding-bottom: 60px;
    background-color: #fff;
    .our-milestones-slider {
        .our-milestones-image {
            height: calc(100% - 40px);
            border-radius: 12px;
            overflow: hidden;
            margin-bottom: 40px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .milestones-details {
            margin-bottom: 40px;
            .milestones-title {
                padding-bottom: 14px;
                h2 {
                    color: #040405 !important;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 42px;
                }
            }
            .milestones-description {
                padding-bottom: 16px;
                p {
                    color: #91959b !important;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 27px;
                    &.milestones-label {
                        &:empty{
                            display: none;
                        }
                         margin-bottom: 8px;
                           display: inline-block;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 30px;
                            color: transparent;
                            background: linear-gradient(283deg, #0086e6 5.56%, #8dd0ff 113.66%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        
                    }
                }
            }
            .milestones-full-description{
                    color: #91959b !important;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 27px;
                    padding-bottom: 16px;
            }
            .milestones-list {
                padding: 0;
                li {
                    list-style: none;
                    padding-left: 40px;
                    position: relative;
                    color: #040405;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22.4px;
                    margin-bottom: 16px;
                    &::after {
                        content: "";
                        background-image: url(../assets/images/green-right-icon.svg);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 24px;
                        height: 24px;
                        background-size: contain;
                        background-position: center center;
                    }
                }
            }
        }
        .milestones-statistics {
            .milestones-count {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                height: 100%;
                .milestones-number {
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 50px;
                    background: linear-gradient(283deg, #0086e6 5.56%, #8dd0ff 113.66%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 4px;
                    display: inline-block;
                }

                p {
                    color: #202020;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                }
            }
        }
    }
}
.our-milestones-slider {
    .milestones-slider {
        margin-bottom: 48px;
    }
    .arrow-center {
        text-align: center;
    }
    .swiper-arrow {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        display: inline-block;
        background: transparent;
        width: 32px;
        height: 32px;
        border: 0.75px solid rgba(4, 4, 5, 0.2);
        border-radius: 10px;
        transition: all 0.4s;
        border-radius: 6px !important;
        border-width: 2px !important;
        margin: 0 8px;
        &:hover {
            border-color: rgb(141, 208, 255);
            background: transparent;
            &::after {
                opacity: 1;
                filter: none;
            }
        }
        &::after {
            content: "" !important;
            background-image: url(../assets/images/update-left-arrows.svg);
            background-repeat: no-repeat;
            background-size: auto 12px;
            background-position: center center;
            display: block;
            margin: 0 auto;
            filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
            opacity: 0.6;
            width: 12px;
            height: 11px;
            transition: all 0.4s;
            width: 100%;
            height: 100%;
        }
        &.swiper-button-next {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .swiper-arrow-div {
        text-align: center;
    }
}
.hiring-models-section {
    padding: 60px 0;
    background-color: #fff;
    .heading {
        text-align: center;
        margin-bottom: 40px;
        h3 {
            color: #91959b;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
        h2 {
            color: #040405;
            font-weight: 700;
            font-size: 38px;
            line-height: 54px;
        }
    }
    .hiring-card-list {
        padding-bottom: 18px;
    }
    .hiring-card {
        border-radius: 20px;
        border: 1px solid #0086e6;
        padding: 24px;
        background: linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        height: calc(100% - 30px);
        margin-bottom: 30px;
        .hiring-card-icon {
            margin-bottom: 16px;
            width: 80px;
            height: 80px;
            border-radius: 12px;
            background-color: #e7f5ff;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
            }
        }
        .hiring-card-body {
            .hiring-card-title {
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
                color: #040405;
                margin-bottom: 12px;
                img {
                }
            }
            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #91959b;
                margin-bottom: 24px;
            }
            .hiring-card-list {
                padding: 0;
                margin: 0;
                li {
                    list-style-type: none;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    color: #040405;
                    padding-left: 40px;
                    position: relative;
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        background-image: url(../assets/images/blue-star-right.svg);
                        background-size: contain;
                        background-position: center center;
                    }
                }
            }
        }
        &.hiring-card-green {
            background: linear-gradient(318deg, rgba(138, 188, 0, 0.05) 5.24%, rgba(204, 250, 78, 0.05) 137.29%);
            border-color: #8abc00;
            .hiring-card-icon {
                background-color: #f1f8da;
            }
            .hiring-card-body .hiring-card-list {
                li::before {
                    background-image: url(../assets/images/light-green-right.svg);
                }
            }
        }
    }
    .hiring-models-btn {
        text-align: center;
    }
}

.schedule-interview-section {
    padding: 60px 0;
    .heading {
        text-align: center;
        margin-bottom: 40px;
        h3 {
            color: #91959b;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
        h2 {
            color: #040405;
            font-weight: 700;
            font-size: 38px;
            line-height: 54px;
            max-width: 720px;
            margin: 0 auto 8px auto;
        }
    }

    .schedule-interview-form {
        border-radius: 20px;
        background: #fff;
        box-shadow:
            0px 6px 30px 0px rgba(130, 213, 255, 0.2),
            0px 6px 20px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }
    .schedule-interview-left {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background: var(
            //     --7,
            //     linear-gradient(
            //         122deg,
            //         rgba(0, 145, 218, 0.5) -11.62%,
            //         rgba(0, 145, 218, 0.44) 8.53%,
            //         rgba(69, 168, 104, 0) 69.21%,
            //         rgba(132, 189, 0, 0.5) 100%
            //     )
            // );
        }
        .schedule-interview-img {
            height: 100%;

            img {
                width: auto;
                height: 100%;
                max-width: 537px;
                object-fit: cover;
                object-position: center center;
            }
        }
        .schedule-interview-title {
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 100%;
            padding: 24px;
            h3 {
                color: #fff;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 45px;
                max-width: 302px;
            }
        }
    }
    .schedule-interview-right {
        padding: 40px;
        background-image: url(../public/images/form-bg-1.svg), url(../public/images/form-bg-2.svg),
            url(../public/images/form-bg-3.svg), url(../public/images/form-bg-4.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position:
            top left,
            top right,
            bottom left 5%,
            bottom right;
        .schedule-form {
            .form-group {
                margin-bottom: 28px;
                .form-error {
                    font-size: 14px;
                    line-height: 20px;
                    color: red;
                }
                .form-control {
                    height: 56px;
                    padding: 16px;
                    font-weight: 600;
                    font-size: 16px !important;
                    line-height: 24px !important;
                    border: 1px solid rgba(4, 4, 5, 0.2);
                    border-radius: 10px;
                    color: rgba(4, 4, 5, 1);
                    background-color: #fff !important;
                    margin-bottom: 4px;
                }
                textarea.form-control {
                    min-height: 165px;
                }
                &.form-floating label {
                    width: auto;
                    padding: 16px;
                    font-size: 14px !important;
                    line-height: 21px !important;
                    opacity: 1;
                    color: var(--text-proposal-color-black) !important;
                }

                &.form-floating > .form-control:focus ~ label,
                &.form-floating > .form-control:not(:placeholder-shown) ~ label,
                &.form-floating > .form-select ~ label {
                    color: rgb(141, 208, 255) !important;
                }
            }
        }
    }
}
.hire-industry-section {
    padding: 60px 0;
    .heading {
        text-align: center;
        margin-bottom: 40px;
        h3 {
            color: #91959b;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
        h2 {
            color: #040405;
            font-weight: 700;
            font-size: 38px;
            line-height: 54px;
            max-width: 790px;
            margin: 0 auto 8px auto;
        }
    }
    .hire-industry-card {
        padding: 24px;
        border-radius: 12px;
        border: 1px solid #0086e6;
        background: linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        margin-bottom: 30px;
        height: calc(100% - 30px);
        .industry-card-number {
            width: 48px;
            height: 48px;
            margin-bottom: 15px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(283deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(143, 208, 255, 0.15) 113.66%);
            span {
                color: #040405;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }
        }
        .industry-card-content {
            h3 {
                color: #040405;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 16px;
            }
            p {
                color: #91959b;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }
        &.industry-card-green {
            border-color: #8abc00;
            background: linear-gradient(318deg, rgba(138, 188, 0, 0.05) 5.24%, rgba(204, 250, 78, 0.05) 137.29%);
            .industry-card-number {
                background: linear-gradient(318deg, rgba(138, 189, 0, 0.15) 4%, rgb(204 250 78 / 15%) 137.29%);
            }
        }
    }
}
.tech-advantages-section {
    padding: 60px 0;
    .tech-advantages-tabs {
        border: 0;
        position: relative;
        margin-bottom: 80px;
        flex-wrap: nowrap;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(283deg, #0086e6 5.56%, #8dd0ff 113.66%);
            display: block;
            opacity: 0.2;
        }
        .nav-item {
            .nav-link {
                color: #91959b;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                margin: 0;
                border: 0;
                padding-bottom: 22px;
                position: relative;
                transition: all 0.4s;
                background-color: transparent;
                height: 100%;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(283deg, #0086e6 5.56%, #8dd0ff 113.66%);
                    display: block;
                    opacity: 0;
                    z-index: 2;
                    left: 0;
                }
                &.active {
                    color: #040405;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .advantages-tabs-wrapper {
        .advantages-tabs-image {
            img {
                max-width: 100%;
                border-radius: 12px;
            }
        }
        .advantages-tabs-col {
            align-self: center;
        }
        .advantages-tabs-content {
            .advantages-tabs-title {
                padding-bottom: 16px;
                h2 {
                    color: #040405 !important;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 56px;
                }
            }
            .advantages-tabs-description {
                p {
                    color: #91959b;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 27px;
                }
            }
        }
    }
}

.floating-form-wrapper {
    .form-group.form-floating {
        margin-bottom: 24px;
        text-align: left;
        .form-error {
            font-size: 14px;
            line-height: 20px;
            color: red;
        }
        .form-control {
            padding: 16px;
            font-weight: 600;
            font-size: 16px !important;
            line-height: 24px !important;
            border: 1px solid rgba(4, 4, 5, 0.2);
            border-radius: 10px;
            color: rgba(4, 4, 5, 1);
            background-color: #fff !important;
            margin-bottom: 4px;
        }
        textarea.form-control {
            min-height: 120px;
        }
        &.form-floating label {
            width: auto;
            padding: 16px;
            font-size: 14px !important;
            line-height: 21px !important;
            opacity: 1;
            color: var(--text-proposal-color-black) !important;
        }

        &.form-floating > .form-control:focus ~ label,
        &.form-floating > .form-control:not(:placeholder-shown) ~ label,
        &.form-floating > .form-select ~ label {
            color: rgb(141, 208, 255) !important;
        }
    }
    .bottom-book-btn {
        .btn.btn-primary {
            margin: 0 auto;
        }
    }
}
.book-section.tech-book-section {
    .book-text {
        padding-bottom: 32px;
        p {
            margin-bottom: 15px;
        }
    }
}
.tech-advantages-section .tech-advantages-tabs li{
    flex: 1;
}
.theme-dark {
    .technologies-full-banner.agile-full-banner .agile-banner-inner .agile-banner-notes ul.banner-notes-list {
        border-color: rgba(255, 255, 255, 0.2) !important;
    }
    .technologies-full-banner .agile-input-group .input-group .form-control {
        border-color: rgba(255, 255, 255, 0.2) !important;
    }
    .our-milestones-section .our-milestones-slider .milestones-statistics .milestones-count {
        border-color: rgba(255, 255, 255, 0.2) !important;
    }
    .our-milestones-section .our-milestones-slider .milestones-statistics .milestones-count p {
        color: #ffffff8f;
    }
    .our-milestones-slider .swiper-arrow {
        border: 2px solid rgba(255, 255, 255, 0.3);
        &:hover {
            border-color: rgb(141, 208, 255);
        }
    }
    .our-milestones-section .milestones-statistics {
        border-color: rgba(255, 255, 255, 0.2) !important;
    }
    .schedule-interview-section {
        .schedule-interview-right {
            .schedule-form {
                .form-group .form-control:focus {
                    border: 1px solid rgba(0, 134, 230, 0.4) !important;
                    background-color: #fff !important;
                }
                .form-floating > .form-control-plaintext:focus,
                .form-floating > .form-control-plaintext:not(:placeholder-shown),
                .form-floating > .form-control:focus,
                .form-floating > .form-control:not(:placeholder-shown) {
                    background-color: #fff !important;
                }
            }
        }
    }
    .floating-form-wrapper {
        .form-group .form-control {
            &:focus {
                border: 1px solid rgba(0, 134, 230, 0.4) !important;
            }
        }

        .form-floating > .form-control-plaintext:focus,
        .form-floating > .form-control-plaintext:not(:placeholder-shown),
        .form-floating > .form-control:focus,
        .form-floating > .form-control:not(:placeholder-shown) {
            background-color: #fff !important;
        }
    }
    .hiring-models-section .hiring-card .hiring-card-icon {
        background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    }
    .hiring-models-section .hiring-card.hiring-card-green .hiring-card-icon {
        background: linear-gradient(
            283.13deg,
            rgba(138, 188, 0, 0.05) 5.56%,
            rgba(204, 250, 78, 0.05) 113.66%
        ) !important;
    }
    .schedule-interview-section .schedule-interview-form {
        box-shadow:
            0px 27px 51px rgba(130, 213, 255, 0.04),
            0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    }
    .technologies-full-banner .agile-input-group .input-group .form-control {
        background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        &:focus{
            border-color: rgba(0, 134, 230, 0.4) !important;
        }
    }
}
.error-message {
    font-size: 14px;
    line-height: 18px;
}
.technologies-middle .framework-section .framework-inner .framework-card{
    a:hover{
        text-decoration: underline !important;
    }
}
.hire-industry-bottom{
    padding-top: 18px;
}
@media (max-width: 1400px) {
    .agile-full-banner.technologies-full-banner {
        .agile-banner-inner {
            .agile-banner-notes ul.banner-notes-list {
                li {
                    p {
                        font-size: 16px !important;
                        line-height: 24px !important;
                    }
                }
            }
        }
    }
    .our-milestones-section .our-milestones-slider .milestones-statistics .milestones-count p{
        font-size: 16px;
        line-height: 24px;
    }
    .our-milestones-section .our-milestones-slider .milestones-statistics .milestones-count .milestones-number{
        font-size: 32px;
        line-height: 46px;
    }
}
@media (max-width: 1200px) {
    .agile-full-banner.technologies-full-banner {
        .agile-banner-inner {
            .agile-banner-notes ul.banner-notes-list {
                padding: 16px;
                li {
                    padding: 0 10px;
                    p {
                        font-size: 16px !important;
                        line-height: 24px !important;
                    }
                }
            }
        }
    }
    .our-milestones-section .our-milestones-slider {
        .milestones-details {
            .milestones-title h2 {
                font-size: 24px;
                line-height: 34px;
            }
            .milestones-description p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .milestones-statistics .milestones-count .milestones-number {
            font-size: 30px;
            line-height: 42px;
        }
        .milestones-statistics .milestones-count p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .hiring-models-section {
        .heading {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            h2 {
                font-size: 28px;
                line-height: 42px;
            }
        }
        .hiring-card .hiring-card-body .hiring-card-title {
            font-size: 22px;
            line-height: 32px;
        }
    }

    .schedule-interview-section {
        .heading {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            h2 {
                font-size: 28px;
                line-height: 42px;
            }
        }
    }
    .hire-industry-section {
        .heading {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            h2 {
                font-size: 28px;
                line-height: 42px;
            }
        }
    }
    .tech-advantages-section .advantages-tabs-wrapper .advantages-tabs-content .advantages-tabs-title {
        padding-bottom: 6px;
        h2 {
            font-size: 28px;
            line-height: 42px;
        }
    }
}
@media (max-width: 1024px) {
    .hiring-models-section {
        .heading {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            h2 {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .schedule-interview-section {
        .heading {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            h2 {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .hire-industry-section {
        .heading {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            h2 {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .tech-advantages-section .advantages-tabs-wrapper .advantages-tabs-content .advantages-tabs-title {
        h2 {
            font-size: 24px;
            line-height: 36px;
        }
    }
}
@media (max-width: 991px) {
    .agile-full-banner.technologies-full-banner {
        .agile-banner-inner {
            .agile-banner-notes ul.banner-notes-list {
                padding: 10px;
                li {
                    padding: 3px 8px;
                    p {
                        font-size: 15px !important;
                        line-height: 22px !important;
                    }
                }
            }
        }
    }
    .our-milestones-section {
        .our-milestones-slider {
            .milestones-slider {
                margin-bottom: 32px;
            }
            .our-milestones-image {
                height: 350px;
            }
        }
        .milestones-statistics {
            padding-left: 30px;
            border-left: 1px solid rgba(0, 0, 0, 0.2);
        }
        .milestones-statistics .milestones-count {
            margin-bottom: 20px;
        }
    }
    .hiring-card .hiring-card-body .hiring-card-title {
        font-size: 20px;
        line-height: 30px;
    }
    .schedule-interview-section {
        .heading h2 {
            max-width: 450px;
        }
    }
    .schedule-interview-left {
        display: none;
    }
    .hire-industry-section {
        padding: 40px 0;
        .hire-industry-card {
            .industry-card-content {
                h3 {
                    font-size: 18px;
                    line-height: 20px;
                }
                p {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    .tech-advantages-section .advantages-tabs-wrapper .advantages-tabs-content .advantages-tabs-description p {
        font-size: 14px;
        line-height: 20px;
    }
    .tech-advantages-section {
        padding: 40px 0;
        .tech-advantages-tabs {
            margin-bottom: 40px;
        }
    }
    .our-milestones-section {
        padding-bottom: 40px;
    }
    .hiring-models-section {
        padding: 40px 0;
    }
    .schedule-interview-section {
        padding: 40px 0;
    }
    .schedule-form {
        .btn.btn-primary {
            margin: 0 auto;
        }
    }
    .hiring-models-section .hiring-card .hiring-card-icon {
        width: 60px;
        height: 60px;
    }
    .our-milestones-section .our-milestones-slider .milestones-details .milestones-full-description{
        font-size: 14px;
        line-height: 20px;
    }
}
@media (max-width: 767px) {
    .technologies-full-banner.agile-full-banner .agile-banner-inner .agile-banner-notes ul.banner-notes-list {
        padding: 16px;
        li {
            display: block;
        }
    }
    .our-milestones-section .milestones-statistics {
        padding-left: 0px;
        border-left: none;
    }

    .our-milestones-section {
        padding-bottom: 30px;
        .our-milestones-slider {
            .our-milestones-image {
                height: 300px;
                margin-bottom: 20px;
            }
            .milestones-statistics .milestones-count .milestones-number {
                font-size: 24px;
                line-height: 34px;
            }
            .milestones-details {
                margin-bottom: 25px;
            }
            .milestones-details .milestones-list li {
                padding-left: 26px;
                margin-bottom: 14px;
            }
            .milestones-details .milestones-list li::after {
                width: 18px;
                height: 18px;
                top: 3px;
            }
            .milestones-details {
                .milestones-description p {
                    font-size: 14px;
                    line-height: 21px;
                }
                .milestones-list li {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
            .milestones-statistics .milestones-count p {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    .hiring-models-section {
        padding: 30px 0;
        .hiring-card {
            .hiring-card-icon {
                width: 50px;
                height: 50px;            
            }
            .hiring-card-body {
                .hiring-card-title {
                    font-size: 20px;
                    line-height: 30px;
                }
                .hiring-card-list li {
                    padding-left: 26px;
                    &::before {
                        width: 18px;
                        height: 18px;
                        top: 4px;
                    }
                }
            }
        }
    }
    .hire-industry-section {
        padding: 30px 0;
        .hire-industry-card {
            .industry-card-number {
                width: 40px;
                height: 40px;
                span {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
    }
    .tech-advantages-section {
        padding: 30px 0;
        .tech-advantages-tabs .nav-item .nav-link {
            padding: 10px 3px 12px 3px;
            height: 100%;
        }
        .advantages-tabs-wrapper {
            .advantages-tabs-image {
                text-align: center;
                margin-bottom: 30px;
                img {
                    width: 350px;
                }
            }
            .advantages-tabs-content {
                text-align: center;
            }
        }
    }
    .schedule-interview-section .schedule-interview-right .schedule-form .form-group .form-control{
        height:48px;
        min-height: 48px;
    }
    .schedule-interview-section .schedule-interview-right .schedule-form .form-group.form-floating label{
        padding: 12px 16px;
    }
    .schedule-interview-section .schedule-interview-right .schedule-form .form-group textarea.form-control{
        min-height: 115px;
    }
    .floating-form-wrapper .form-group.form-floating .form-control{
        height:48px;
        min-height: 48px;
    }
    .floating-form-wrapper .form-group.form-floating.form-floating label{
        padding: 12px 16px;
    }
    .floating-form-wrapper .form-group.form-floating textarea.form-control{
        min-height: 100px;
    }
    .our-milestones-section .our-milestones-slider .milestones-statistics .milestones-count{
        height:calc(100% - 20px)
    }
}
@media (max-width: 575px) {
    .technologies-full-banner .agile-input-group .input-group {
        display: block;
        .form-control {
            width: 100%;
            height: 50px;
            border-radius: 8px !important;
            margin-bottom: 14px;
        }

        .input-group-btn.btn.btn-primary {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
        }
    }
    .our-milestones-section .our-milestones-slider .our-milestones-image {
        height: 260px;
    }
    .schedule-interview-section .schedule-interview-right {
        padding: 30px 20px;
    }
    .tech-advantages-section .tech-advantages-tabs{
        flex-wrap: wrap;
        justify-content: center;
    }
    .tech-advantages-section .tech-advantages-tabs li{
        width: 100%;
        flex: 100%;
        text-align: center;
    }
    .tech-advantages-section .tech-advantages-tabs::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(283deg, #0086e6 5.56%, #8dd0ff 113.66%);
        display: block;
        opacity: 0.2;
    }
    .tech-advantages-section .tech-advantages-tabs .nav-item .nav-link{
        width: auto;
        position: relative;
        margin: 0 auto;
    }
    .tech-advantages-section .tech-advantages-tabs .nav-item .nav-link::before{
        content: "";
        margin-left: 10px;
        background-image: url(../assets/images/update-right-arrow.svg);
        background-position: right center;
        background-size: auto;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        position: absolute;
        right: 0;
        display: none;
    }
    .tech-advantages-section .tech-advantages-tabs .nav-item .nav-link::after{
        display: none;
    }
}


.milestones-listing {
    padding: 0;
    li {
        list-style: none;
        padding-left: 40px;
        position: relative;
        color: #040405;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
        margin-bottom: 16px;
        &::after {
            content: "";
            background-image: url(../assets/images/green-right-icon.svg);
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-position: center center;
        }
    }
}

@media (max-width: 767px) {
    .milestones-listing li {
        padding-left: 26px;
        margin-bottom: 14px;
    }
    .milestones-listing li::after {
        width: 18px;
        height: 18px;
        top: 3px;
    }
}