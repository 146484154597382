
.middle-section {
    padding-top: 80px;
}
.header .navbar {
    border-bottom: 0 !important;
}

.theme-dark {
    .hero-banner-section {
        &::after {
            opacity: 1;
        }
    }
}
.list-style-primery {
    padding-left: 15px;
    li {
        padding-left: 10px;
        list-style-image: url(../assets/images/primery-list-bg.svg);
    }
}
.list-style-secondary {
    padding-left: 15px;
    li {
        padding-left: 10px;
        list-style-image: url(../assets/images/secondery-list-bg.svg);
    }
}
.gradient-text {
    background: linear-gradient(318deg, #0086e6 0%, rgba(152, 249, 255, 0.77) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
h2.usecase-section-title {
    color: #040405;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 0;
    padding-bottom: 24px;
}
.hero-banner-section {
    padding: 60px 0 0;
    background-color: #fff;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 70%;
        top: 0;
        left: 0;
        opacity: 0.05;
        background: linear-gradient(341deg, #0086e6 0%, #8dd0ff 100%);
        z-index: 1;
    }
    .hero-banner-inner {
        position: relative;
        z-index: 2;
        .hero-banner-img {
            position: relative;
            height: 0;
            padding-bottom: 41.2%;
            overflow: hidden;
            border-radius: 12px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.main-heading {
    background-color: #fff;
    padding-bottom: 60px;
    .main-heading-inner {
        .main-heading-title {
            margin-top: -50px;
            z-index: 9;
            position: relative;
            padding: 40px 58px;
            border-radius: 12px;
            border: 1px solid #dfdfdf;
            background-color: #fff;
            box-shadow:
                0px 15px 20px 10px rgba(0, 1, 28, 0.03),
                0px 27px 51px 0px rgba(130, 213, 255, 0.04);

            .use-case-title {
                color: #040405;
                text-align: center;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 60px;
                text-transform: capitalize;
                span {
                    background: linear-gradient(318deg, #0086e6 0%, rgba(152, 249, 255, 0.77) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}
.theme-dark .main-heading .main-heading-inner .main-heading-title {
    border: 1px solid #dfdfdf40;
}
.introduction-section {
    background-color: #fff;
    .introduction-section-content {
        padding-bottom: 60px;
        p {
            color: #040405;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.actors-section,
.preconditions-section {
    background-color: #fff;
}
.actors-section-content {
    padding-bottom: 60px;
    .actors-list {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            padding-bottom: 16px;
            &:nth-child(odd) {
                .actors-list-item {
                    background: linear-gradient(341deg, rgb(0, 134, 230, 0.05) 0%, rgb(141, 208, 255, 0.05) 100%);
                }
            }
            .actors-list-item {
                display: flex;
                align-items: center;
                padding: 24px;
                background: linear-gradient(318deg, rgb(138, 188, 0, 0.05) 0%, rgb(204, 250, 78, 0.05) 100%);
                border-radius: 12px;
                .actors-item-number {
                    width: 72px;
                    height: 72px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
                    border-radius: 12px;
                    span {
                        background: linear-gradient(341deg, #0086e6 0%, #8dd0ff 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 36px;
                        text-transform: capitalize;
                    }
                }
                .actors-item-content {
                    padding-left: 24px;
                    flex: 1;
                    .row {
                        align-items: center;
                    }
                    h2 {
                        color: #040405;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 36px;
                        margin-bottom: 0;
                    }
                    p {
                        color: #040405;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 27px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.preconditions-section-content {
    padding-bottom: 60px;
    background-color: #fff;
    .preconditions-list {
        li {
            padding-bottom: 32px;
            &:last-child {
                padding-bottom: 0;
            }
            h2 {
                color: #040405;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24.3px;
                margin-bottom: 0;
                padding-bottom: 12px;
            }
            p {
                color: #040405;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.main-flow-section {
    padding: 60px 0 18px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    .mainflow-list {
        padding-top: 10px;
        position: relative;
        &::after {
            content: "";
            border-left: 2px dashed #040405;
            width: 50px;
            height: 50px;
            top: -13px;
            left: 13px;
            border-radius: 0 0 0 15px;
            opacity: 0.1;
            position: absolute;
        }
        .row {
            padding: 0 60px;
            position: relative;
            &:nth-child(odd) {
                &::after {
                    content: "";
                    border-right: 2px dashed #040405;
                    width: 50px;
                    height: 102%;
                    top: 24px;
                    right: 0;
                    border-radius: 15px;
                    opacity: 0.1;
                    position: absolute;
                }
            }
            &:nth-child(even) {
                flex-direction: row-reverse;
                &::after {
                    content: "";
                    border-left: 2px dashed #040405;
                    width: 50px;
                    height: 101%;
                    top: 26px;
                    left: 23px;
                    border-radius: 15px;
                    opacity: 0.1;
                    position: absolute;
                }
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }

            .col-md-6:first-child {
                .mainflow-details {
                    &::after {
                        content: "";
                        width: 60px;
                        height: 1px;
                        border-top: 2px dashed #040405;
                        position: absolute;
                        left: 38px;
                        top: 26px;
                        max-height: 1px;
                        opacity: 0.1;
                    }
                }
            }
            .col-md-6:nth-child(2) {
                .mainflow-details {
                    &::after {
                        content: "";
                        width: calc(100% - 114px);
                        height: 1px;
                        border-top: 2px dashed #040405;
                        position: absolute;
                        left: 103px;
                        top: 26px;
                        max-height: 1px;
                        opacity: 0.1;
                    }
                }
            }
        }
    }
    .mainflow-details {
        padding-bottom: 42px;
        max-width: 365px;
        margin: 0 auto;
        .mainflow-number {
            width: 50px;
            height: 50px;
            border-radius: 12px;
            border: 1px solid linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
            position: relative;
            z-index: 2;
            span {
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px;
                text-transform: capitalize;
            }
        }
        .mainflow-content {
            h3 {
                color: #040405;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px;
                margin-bottom: 0;
                padding-bottom: 12px;
            }
            p {
                color: #040405;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.alternative-flow-section {
    padding: 60px 0;
    background-color: #fff;

    .alternative-flow-left {
        display: flex;
        .alternative-icon {
            width: 50px;
            height: 50px;
        }

        .alternative-content {
            padding-left: 32px;
            flex: 1;
            padding-right: 30px;
            color: #040405;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            p {
                color: #040405;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            ul {
                padding-left: 20px;
                li{
                    color: #040405;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                }
            }
        }
    }
    .alternative-img {
        img {
            border-radius: 12px;
            max-width: 100%;
        }
    }
}

.conclusion-section {
    background-color: #fff;
    padding-bottom: 60px;

    .conclusion-inner {
        padding: 60px 30px;
        background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        border-radius: 12px;
        background-image: url(../assets/images/conclusion-bg-left.png), url(../assets/images/conclusion-bg-right.png);
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: auto;
        background-position:
            bottom left,
            top right;
    }
    .conclusion-content {
        text-align: center;
        max-width: 730px;
        margin: 0 auto;
        h2 {
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 0;
            padding-bottom: 24px;
        }
        p {
            color: #040405;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.suggested-techStack-section {
    background-color: #fff;
    padding-bottom: 60px;
}

.suggested-techStack-accordion {
    .accordion {
        border-radius: 12px;
        overflow: hidden;

        .accordion-item {
            margin-top: -8px;
            border: 0;

            .accordion-header {
                .accordion-button {
                    background: rgb(245, 250, 254);
                    padding: 24px 24px 24px;
                    border: 0px;
                    height: auto;
                    min-height: auto;
                    color: #040405;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24.3px;
                    box-shadow: none;
                    outline: none;
                    &::after {
                        display: none;
                    }
                }
            }

            .accordion-collapse {
                background: rgb(245, 250, 254);
                .accordion-body {
                    padding: 0 24px 60px;
                    .suggested-accordion-content {
                        .suggested-accordion-img {
                            img {
                                border-radius: 12px;
                                max-width: 100%;
                            }
                        }
                        p {
                            color: #040405;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                }
            }
            &:nth-child(even) {
                .accordion-header {
                    .accordion-button {
                        background: rgb(251, 253, 244);
                    }
                }
                .accordion-collapse {
                    background: rgb(251, 253, 244);
                }
            }
        }
    }
}

/****responsive****/
@media (max-width: 1200px) {
    .main-heading {
        .main-heading-inner {
            .main-heading-title {
                .use-case-title {
                    font-size: 36px;
                    line-height: 56px;
                }
            }
        }
    }
}
@media (max-width: 992px) {
    .main-heading .main-heading-inner .main-heading-title .use-case-title {
        font-size: 32px;
        line-height: 52px;
    }
    h2.usecase-section-title {
        font-size: 28px;
        line-height: 44px;
    }
    .middle-section {
        padding-top: 70px;
    }
    .hero-banner-section {
        padding: 40px 0 0;
    }
    .main-heading {
        padding-bottom: 40px;
    }
    .introduction-section .introduction-section-content {
        padding-bottom: 40px;
        p {
            font-size: 16px;
            line-height: 25px;
        }
    }
    .alternative-flow-section .alternative-flow-left .alternative-content p {
        font-size: 16px;
        line-height: 25px;
    }
    .actors-section-content {
        padding-bottom: 40px;
        .actors-list li .actors-list-item {
            padding: 20px;
            .actors-item-number {
                width: 60px;
                height: 60px;
            }
            .actors-item-content h2 {
                font-size: 20px;
                line-height: 32px;
            }
            .actors-item-content p {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    .preconditions-section-content {
        padding-bottom: 40px;
    }
    .main-flow-section {
        padding: 40px 0 0px;
    }
    .alternative-flow-section {
        padding: 40px 0;
    }
    .conclusion-section .conclusion-content {
        h2 {
            font-size: 28px;
            line-height: 44px;
        }
        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
@media (max-width: 767px) {
    .main-heading .main-heading-inner .main-heading-title {
        padding: 26px 26px;
        .use-case-title {
            font-size: 28px;
            line-height: 40px;
        }
    }
    h2.usecase-section-title {
        font-size: 24px;
        line-height: 38px;
        padding-bottom: 16px;
    }
    .actors-section-content .actors-list li .actors-list-item {
        padding: 15px;
    }

    .main-flow-section .mainflow-details {
        max-width: 100%;
    }
    .main-flow-section .mainflow-list {
        .row {
            padding: 0 0;
            &:nth-child(odd)::after {
                display: none;
            }
            &:nth-child(even)::after {
                display: none;
            }
            &:last-child {
                .col-md-6:last-child {
                    // .mainflow-details::after,
                    .mainflow-details::before {
                        display: none !important;
                    }
                }
            }
            .col-md-6 {
                .mainflow-details {
                    position: relative;
                    padding: 0 20px 42px;
                    &::after {
                        width: calc(100% - 75px) !important;
                        left: 25px !important;
                    }
                }
                &:first-child {
                    .mainflow-details {
                        &::before {
                            content: "";
                            border-right: 2px dashed #040405;
                            width: 50px;
                            height: calc(100% + 2px);
                            top: 26px;
                            right: 0px;
                            border-radius: 0px 15px 15px 0;
                            opacity: 0.1;
                            position: absolute;
                            border-top: 2px dashed #040405;
                            border-bottom: 2px dashed #040405;
                        }
                    }
                }
                &:nth-child(2) {
                    .mainflow-details {
                        &::before {
                            content: "";
                            border-left: 2px dashed #040405;
                            width: 50px;
                            height: 102%;
                            top: 24px;
                            left: 0px;
                            border-radius: 15px;
                            opacity: 0.1;
                            position: absolute;
                            border-top: 2px dashed #040405;
                            border-bottom: 2px dashed #040405;
                        }
                    }
                }
            }
        }
    }
    .alternative-flow-section .alternative-flow-left {
        padding-bottom: 30px;
    }
    .alternative-flow-section .alternative-flow-left .alternative-content{
        padding-left: 12px;
        padding-right: 10px;
    }
  
    .alternative-flow-section .alternative-flow-left .alternative-content{
        padding-left: 10px;
        flex: 1 1;
        padding-right: 10px;
    }
}
@media (max-width: 575px) {
    h2.usecase-section-title {
        font-size: 22px;
        line-height: 34px;
    }
    .main-heading .main-heading-inner .main-heading-title {
        padding: 10px 16px;
        margin-top: -20px;
        .use-case-title {
            font-size: 20px;
            line-height: 32px;
        }
    }
    .main-heading {
        padding-bottom: 30px;
    }
    .actors-section-content .actors-list li .actors-list-item {
        align-items: flex-start;
        .actors-item-content {
            padding-left: 12px;
            h2 {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    .suggested-techStack-accordion
        .accordion
        .accordion-item
        .accordion-collapse
        .accordion-body
        .suggested-accordion-content
        .suggested-accordion-img {
        padding-bottom: 20px;
        img {
            width: auto;
            max-width: 100%;
        }
    }
    .conclusion-section .conclusion-content h2 {
        padding-bottom: 16px;
    }
    .conclusion-section .conclusion-inner {
        padding: 40px 30px;
    }
    .alternative-flow-section .alternative-flow-left .alternative-content{
        padding-left: 12px;
        padding-right: 0px;
    }
    .alternative-flow-section .alternative-img img{
        margin: 0 auto;
        display: block;
    }
}
/****end-responsive****/