.global-new-section {
	padding: 0;
	// background: var(--secondary-background-light);
	background: #F4FAFE;

	.global-container {
		margin-top: -260px;
		position: relative;
		z-index: 9;
	}

	.global-left-card {
		border-radius: 16px;
		// border: 2px solid var(--primary-border-light);
		border: 2px solid rgba(0, 134, 230, 0.20);
		
		// background: var(--global-card-bg);
		background: linear-gradient(132deg, #FFF 0%, #FFF 100%);
		box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
		padding: 30px 30px 15px 30px;

		.global-gutter-space {
			--bs-gutter-x: 16px;
		}

		h3 {
			font-size: 24px;
			line-height: 130%;
			font-weight: 600;
			// color: var(--global-text-card);
			color: #00011C;
			margin-bottom: 24px;
		}

		.brand-compeny-card {
			padding: 26px 24px;
			margin-bottom: 16px;
			width: 100%;
			border-radius: 16px;
			border: 2px solid rgba(0, 134, 230, 0.20);
			background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 100%;
				height: 28px;
			}
		}
	}

	.global-right-content {
		border-radius: 16px;
		// border: 2px solid var(--primary-border-light);
		border: 2px solid rgba(0, 134, 230, 0.20);
		// background: var(--global-card-bg);
		background: linear-gradient(132deg, #FFF 0%, #FFF 100%);
		box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
		padding: 30px 30px 30px 30px;

		h2 {
			// color: var(--heading-text);
			color: #040405;
			font-size: 28px;
			font-weight: 700;
			line-height: 42px;
			margin-bottom: 12px;
		}

		p {
			// color: var(--secondary-text-light);
			color: #686869;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			margin-bottom: 24px;
		}

		.form-group {
			margin-bottom: 24px;

			.form-control {
				padding: 16px !important;
				border-radius: 10px;
				font-size: 16px;
				line-height: 150%;
				font-weight: 600;
				// color: var(--heading-text);
				color: #040405;
				// border: 1px solid var(--primary-border-light);
				border: 1px solid rgba(0, 134, 230, 0.20);
				// background-color: var(--form-control-bg);
				background-color: #fff;

				&:focus {
					// border: 1px solid var(--border-blue) !important;
					border: 1px solid #0086e6 !important;
					
				}
			}

			textarea.form-control {
				height: 122px;
				resize: none;
			}

			.budget-select .budget-select__control {
				padding: 10px 12px 10px 4px;
				background-color: #fff;
				box-shadow: none;
				border-radius: 12px;
				border: 1px solid  rgba(0, 134, 230, 0.20)!important;
			}

			.budget-select .budget-select__control.budget-select__control--menu-is-open {
				// border: 1px solid var(--border-blue) !important;
				border: 1px solid #0086e6 !important;
			}

			.budget-select .budget-select__single-value {
				// color: var(--color-submenu-primary);
				color:  #040405;
				font-weight: 600;
			}

			.budget-select .budget-select__indicator-separator {
				display: none;
			}

			.budget-select .budget-select__control--menu-is-open .budget-select__indicator {
				background-image: url(../../../assets/images/bg-up-arrow.svg);
				background-repeat: no-repeat;
			}

			.budget-select .budget-select__indicator svg {
				display: none;
			}

			.budget-select .budget-select__indicator {
				height: 30px;
				width: 30px;
				background-image: url(../../../assets/images/light-down-arrow.svg);
				background-size: cover;
				background-position: center center;
			}

			.budget-select .budget-select__menu {
				border: 1px solid #8dd0ff !important;
				border-top: 1px solid #8dd0ff !important;
				border-radius: 12px;
				overflow: hidden;
				z-index: 9;
				padding: 0;
			}

			.budget-select .budget-select__menu-list {
				padding: 0;
			}

			.budget-select .budget-select__menu .budget-select__option:last-child {
				border-bottom: none !important;
			}

			.budget-select .budget-select__multi-value__remove:hover {
				background-color: transparent;
			}
		}

		.form-floating>label {
			font-size: 14px;
			// color: var(--secondary-text-light);
			color: #686869;
		}

		.form-floating>.form-control:focus~label {
			color: #0086E6;
			font-size: 14px;
		}

		.select-budget {
			height: 100%;
			margin: 0;
			min-height: 135px;

			.form-control {
				height: 100%;
			}

			h3 {
				font-size: 16px;
				line-height: 150%;
				font-weight: 600;
				margin-bottom: 13px;
				// color: var(--heading-text);
				color: #040405;

			}

			.file-input-budget {
				opacity: 0;
				overflow: hidden;
				z-index: -1;
				display: none;
			}

			.budget-upload-file {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.upload-icon {
					width: 64px;
					height: 64px;
					border-radius: 12px;
					// background: var(--upload-bg-light);
					background: linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%,rgba(141, 208, 255, 0.05) 113.66%);
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 14px;
				}

				span {
					font-size: 14px;
					line-height: 150%;
					font-weight: 500;
					// color: var(--secondary-text-light);
					color: #686869;
					margin: 0;
				}
			}
		}

		.sign-nda {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-top: 48px;

			a {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				font-size: 12px;
				line-height: 150%;
				font-weight: 500;
				margin-right: 24px;
				text-decoration: none;
				// color: var(--contact-sign-link);
				color: rgba(4, 4, 5, 0.56);
				transition: 0.3s;

				img {
					margin-right: 6px;
				}

				&:hover {
					// color: var(--primary-green-color);
					color: #84bd00;
				}
			}
		}

		.budget-btn {
			margin-top: 32px;
			text-align: right;

			.btn-primary {
				min-width: 165px;
			}
		}

		.global-group {
			margin-bottom: 0;

			textarea.form-control {
				border-radius: 12px 12px 0 0;
				height: 112px;
			}
		}

		.global-select-upload {
			height: 100%;
			min-height: unset;

			.form-control {
				border-radius: 0 0 0 12px;
				border-top: unset;
			}

			.document-paste {
				border-radius: 0 0 12px 0;
				color: #040405;
				border-bottom: 1px solid  rgba(0, 134, 230, 0.20);
				border-right: 1px solid  rgba(0, 134, 230, 0.20);
				background-color: #fff;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				line-height: 150%;
				font-weight: 500;
				// color: var(--secondary-text-light);
				color: #686869;
				
				position: relative;

				&:before {
					content: "or";
					width: 24px;
					height: 24px;
					border-radius: 40px;
					border: 1px solid rgba(0, 134, 230, 0.20);
					background:#fff;
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					left: -12px;
					top: -12px;
					font-size: 14px;
					line-height: 20px;
					font-weight: 500;
					color:  #686869;
				}
			}
		}
	}
}

.global-new-section .global-right-content .form-group .budget-select .budget-select__control .budget-placeholder {
	text-overflow: ellipsis;
	width: 83%;
	white-space: nowrap;
	overflow: hidden;
}

@media (min-width: 1200px) {
	.global-new-section .global-right-content .form-group .budget-select .budget-select__control {
		font-size: 16px !important;
		line-height: 110% !important;
		// max-height: 58px;
		min-height: 58px;
	}
}

@media (max-width: 1400px) {
	.global-new-section .global-container {
		margin-top: -200px;
	}
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
	.global-container {
		max-width: 1000px !important;
	}

	.global-new-section {
		padding-bottom: 0 !important;
	}

	.global-new-section .global-container {
		margin-top: -360px;
	}

	.global-new-section .global-left-card {
		padding: 24px 24px 12px 24px;
	}

	.global-new-section .global-left-card h3 {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 16px;
	}

	.global-new-section .global-left-card .brand-compeny-card {
		padding: 19px 24px;
		border-radius: 8px;
	}

	.global-new-section .global-left-card .brand-compeny-card img {
		height: 24px;
	}

	.global-new-section .global-left-card .global-gutter-space {
		--bs-gutter-x: 16px;
	}

	.global-new-section .global-right-content {
		padding: 24px;
	}

	.global-new-section .global-right-content h2 {
		font-size: 20px !important;
		line-height: 32px !important;
		margin-bottom: 6px !important;
	}

	.global-new-section .global-right-content p {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 20px;
	}

	.global-new-section .global-right-content .form-group .form-control {
		padding: 9px 12px !important;
		font-size: 14px;
		border-radius: 8px;
	}

	.global-new-section .global-right-content .form-floating>label {
		padding: 9px;
	}

	.global-new-section .global-right-content .form-group {
		margin-bottom: 16px;
	}

	.error-message {
		font-size: 12px !important;
	}

	.global-new-section .global-right-content .form-group .budget-select .budget-select__control {
		font-size: 14px;

	}

	.global-new-section .global-right-content .global-group {
		margin-bottom: 0;
	}

	.global-new-section .global-right-content .global-select-upload .form-control {
		border-radius: 0 0 0 12px;
		border-top: unset;
	}

	.global-new-section .global-right-content .global-select-upload {
		margin: 0;
	}

	.global-new-section .global-right-content .form-group .budget-select .budget-select__control .budget-placeholder {
		max-width: 60%;
		width: 100%;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon {
		width: 38px;
		height: 38px;
		border-radius: 8px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon img {
		width: 24px;
	}

	.global-new-section .global-right-content .global-select-upload .document-paste {
		border-radius: 0 0 8px 0;
	}

	.global-new-section .global-right-content .sign-nda a img {
		width: 18px;
		height: 18px;
	}

	.global-new-section .global-right-content .budget-btn .btn-primary {
		font-size: 12px;
		padding: 16px 8px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file span {
		font-size: 12px;
	}

	.global-new-section .global-right-content .sign-nda a {
		margin-right: 12px;
	}

	.swiper-wrapper {
		align-items: center;
	}

}

@media (max-width: 1024px) {

	.global-new-section {
		padding-bottom: 0 !important;
	}

	.global-new-section .global-container {
		margin-top: -280px;
	}

	.global-new-section .global-left-card {
		padding: 24px 24px 12px 24px;
	}

	.global-new-section .global-left-card h3 {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 16px;
	}

	.global-new-section .global-left-card .brand-compeny-card {
		padding: 19px 24px;
		border-radius: 8px;
	}

	.global-new-section .global-left-card .brand-compeny-card img {
		height: 24px;
	}

	.global-new-section .global-left-card .global-gutter-space {
		--bs-gutter-x: 16px;
	}

	.global-new-section .global-right-content {
		padding: 24px;
	}

	.global-new-section .global-right-content h2 {
		font-size: 20px !important;
		line-height: 32px !important;
		margin-bottom: 6px !important;
	}

	.global-new-section .global-right-content p {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 20px;
	}

	.global-new-section .global-right-content .form-group .form-control {
		padding: 9px 12px !important;
		font-size: 14px;
		border-radius: 8px;
	}

	.global-new-section .global-right-content .form-floating>label {
		padding: 9px;
	}

	.global-new-section .global-right-content .form-group {
		margin-bottom: 16px;
	}

	.error-message {
		font-size: 12px !important;
	}

	.global-new-section .global-right-content .form-group .budget-select .budget-select__control {
		font-size: 14px;

	}

	.global-new-section .global-right-content .global-group {
		margin-bottom: 0;
	}

	.global-new-section .global-right-content .global-select-upload .form-control {
		border-radius: 0 0 0 12px;
		border-top: unset;
	}

	.global-new-section .global-right-content .global-select-upload {
		margin: 0;
	}

	.global-new-section .global-right-content .form-group .budget-select .budget-select__control .budget-placeholder {
		max-width: 60%;
		width: 100%;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon {
		width: 38px;
		height: 38px;
		border-radius: 8px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon img {
		width: 24px;
	}

	.global-new-section .global-right-content .global-select-upload .document-paste {
		border-radius: 0 0 8px 0;
	}

	.global-new-section .global-right-content .sign-nda a img {
		width: 18px;
		height: 18px;
	}

	.global-new-section .global-right-content .budget-btn .btn-primary {
		font-size: 12px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file span {
		font-size: 12px;
	}

	.global-new-section .global-right-content .sign-nda a {
		margin-right: 12px;
	}

}

@media(max-width: 992px) {
	.global-new-section .global-container {
		margin-top: -300px;
	}

	.global-new-section .global-left-card {
		padding: 20px;
	}

	.global-new-section .global-left-card .global-gutter-space {
		--bs-gutter-x: 15px;
	}

	.global-new-section .global-left-card h3 {
		font-size: 20px;
		margin-bottom: 16px;
	}

	.global-new-section .global-left-card .brand-compeny-card {
		padding: 20px 24px;
		margin-bottom: 15px;
	}

	.global-new-section .global-right-content {
		padding: 20px;
		margin-bottom: 28px;
	}

	.global-new-section .global-right-content h2 {
		font-size: 22px;
		line-height: 30px;
		margin-bottom: 6px;
	}

	.global-new-section .global-right-content p {
		margin-bottom: 16px;
		font-size: 14px;
	}

	.global-new-section .global-right-content .form-group .form-control {
		padding: 10px 16px !important;
		height: unset;
		min-height: unset;
		border-radius: 6px;
		font-size: 14px;
	}

	.global-new-section .global-right-content .form-floating>label {
		padding: 10px;
	}

	.global-new-section .global-right-content .form-group .budget-select .budget-select__control {
		border-radius: 6px;
		font-size: 14px;
		padding: 6px 12px 6px 4px;
	}

	.global-new-section .global-right-content .form-group .budget-select .budget-select__indicator {
		width: 24px;
		height: 24px;
	}

	.global-new-section .global-right-content .global-select-upload .form-control {
		border-radius: 0 0 0 12px;
		padding: 14px 16px !important;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon {
		width: 40px;
		height: 40px;
		border-radius: 8px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon img {
		width: 24px;
	}

	.global-reverse {
		flex-direction: column-reverse;
	}

}

@media (max-width: 480px) {

	.global-new-section .global-container {
		margin-top: -240px;
	}
}

@media (max-width: 320px) {

	.global-new-section .global-container {
		margin-top: -150px;
	}
}

@media (max-width: 767px) {
	.global-new-section .global-right-content h2 {
		text-align: center;
	}

	.global-new-section .global-right-content p {
		text-align: center;
	}

	.global-new-section .global-right-content .form-group {
		margin-bottom: 16px;
	}

	.global-new-section .global-right-content .global-group {
		margin: 0;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon {
		width: 30px;
		height: 30px;
		border-radius: 4px;
		min-width: 30px;
		margin-right: 6px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon img {
		width: 16px;
	}

	.global-new-section .global-right-content .global-select-upload .document-paste {
		padding: 13px;
	}

	.global-new-section .global-right-content .select-budget {
		margin: 0;
	}

	.global-new-section .global-right-content .sign-nda {
		margin-top: 18px;
	}

	.global-new-section .global-right-content .budget-btn {
		margin-top: 20px;
	}

	.global-new-section .global-right-content .select-budget .budget-upload-file span {
		font-size: 13px;
	}

	.global-new-section .global-left-card h3 {
		text-align: center;
		margin-bottom: 12px;
	}

	.global-new-section .global-right-content .global-select-upload .document-paste {
		border-radius: 0 0 8px 0;
	}
}




.document-paste {
	margin: 0 auto;
	width: 100%;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.form-control {
		border-radius: 0 0 12px 12px !important;
	}

	&:before {
		content: "or";
		width: 24px;
		height: 24px;
		border-radius: 40px;
		border: 1px solid  rgba(0, 134, 230, 0.20);
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: -12px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color:  #686869;
	}
}


.range-slider {
	padding: 15px 20px 25px 20px;
	// border: 1px solid var(--form-border);
	border: 1px solid rgba(0, 134, 230, 0.20);
	border-radius: 10px;

	.slider-labels {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.caption {
			p {
				margin: 0;

				em {
					font-size: 12px;
					line-height: 18px;
					font-weight: 500;
					color:#91959b;
					opacity: 0.56;
					margin-bottom: 5px;
					display: block;
					font-style: normal;
				}

				span {
					font-size: 18px;
					line-height: 27px;
					font-weight: 600;
					color:#040405 !important;
				}

			}
		}
	}
}



.global-new-section .global-right-content .global-select-upload .form-control {
	border-radius: 12px !important;
	// border: 1px solid var(--primary-border-light) !important;
	border: 1px solid rgba(0, 134, 230, 0.20) !important;
	min-height: 131px !important;

}


.uploadbox {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	text-align: left;


	h3 {
		text-align: left;
		width: 100%;
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;

		p {

			font-size: 12px !important;
			font-weight: 500 !important;
			line-height: 18px !important;
			text-align: left;
			color: #555C60;
			margin-bottom: 0 !important;



			span {
				font-size: 10px !important;
				font-weight: 400 !important;
				line-height: 15px !important;
				text-align: left;
				display: block !important;

				strong {
					font-size: 10px;
					font-weight: 600 !important;
					line-height: 15px;
					text-align: left;
					color: #040405 !important;
				}
			}

			span.newline {
				font-size: 12px !important;
				font-weight: 500 !important;
				line-height: 18px !important;
				text-align: left;
				color: #555C60;
				margin-bottom: 0 !important;

				strong {
					font-size: 12px !important;
					font-weight: 600 !important;
					line-height: 18px !important;
					text-align: left;
					color: #040405 !important;
				}
			}
		}
	}


}

.global-new-section .global-right-content .select-budget .budget-upload-file .upload-icon {
	height: 56px;
	height: 56px;
	min-width: 56px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.remove-pad {
	>div {
		margin: 0 10px !important;
	}
}

.range-slider .slider-labels .caption p {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	text-align: left;
	margin: 0 !important;
}

.range-slider .slider-labels .caption p em {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	text-align: right;
	margin: 0 !important;
	margin-right: 10px !important;
}

.range-slider .slider-labels .caption p span {
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
	text-align: left;
	margin-bottom: 0px;
}

.range-slider label {
	font-size: 16px !important;
	font-weight: 600 !important;
	margin-bottom: 10px !important;
	line-height: 19px !important;
}

.range-slider .slider-labels {
	margin: 0 0 10px 0 !important;
}

.range-slider {
	// border: 1px solid var(--primary-border-light) !important;
	border: 1px solid rgba(0, 134, 230, 0.20);
	padding-bottom: 10px !important;
	min-height: 140px !important;
	padding: 16px 16px 10px 16px !important;
}


.filelist-item {
	border: 1px solid  rgba(0, 134, 230, 0.20) !important;
	border-radius: 12px !important;

	>div {
		font-size: 12px;
		font-weight: 500;
		line-height: 18px;
		text-align: left;
	}

	.btn-remove {
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
	}
}

.theme-dark {
	.range-slider .slider-labels .caption p em {
		color: #fff !important;
	}

	.range-slider .slider-labels .caption p span {
		color: #fff !important;
	}

	.uploadbox>div p span strong {
		color: #fff !important;
	}

	.range-slider label {
		color: #fff !important;
	}
}

.global-new-section .global-left-card {
	height: 100% !important;
}

@media (max-width: 767px) {
	.modal-upload-file {
		margin-bottom: 15px !important;
	}
}

.global-new-section .global-left-card .brand-compeny-card img {
	width: auto !important;
}

.budget-select .budget-select__control {
	padding: 10px 10px;

	.budget-select__multi-value {
		// border: 1px solid var(--primary-border-light);
		border: 1px solid rgba(0, 134, 230, 0.20);
		// background: var(--resource-card-bg);
		background: #EBF6FE;
		border-radius: 10px !important;
		padding: 7px 12px;
		font-size: 14px !important;
		font-weight: 500 !important;
		line-height: 21px !important;
		text-align: left;
	}

	button {
		color: #D9D9D9 !important;
		font-size: 18px !important;
		font-weight: 500 !important;

		&:hover {
			color: #D9D9D9 !important;
			font-size: 18px !important;
			font-weight: 500 !important;
		}
	}
}

.budget-select__clear-indicator {
	display: none !important;
}

.global-new-section {
.timeline {
	margin-top: 30px;
	list-style: none;
	padding: 0;
	position: relative;
}

.timeline li:after {
	content: '';
	position: absolute;
    left: 10px;
    top: 20px;
    width: 1px;
    height: calc(111% + 0px);
    border-radius: 0%;
    background-color: transparent;
    border-right: 1px solid rgb(204, 231, 250);
    border-width: 3px;
    border-style: dashed;
    color: #fff;
    text-align: center;
    z-index: 0;
}

.timeline li {
	list-style: none;
	position: relative;
	padding-left: 50px;
	margin-bottom: 20px;
	height: 100%;

	&:nth-last-child(1) {
		&::after {
			display: none !important;
		}
	}
}

.timeline li::before {
	content: attr(data-count);
	position: absolute;
	left: 0;
	top: 0;
	width: 28px;
	height: 28px;
	line-height: 30px;
	border-radius: 50%;
	background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
	color: #fff;
	text-align: center;
	z-index: 1;
}

.timeline h3 {
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	text-align: left;
	margin-bottom: 8px;
}

.timeline p {
	font-size: 16px;
	font-weight: 400;
	line-height: 22.4px;
	text-align: left;
	margin-bottom: 32px;
	// color: var(--secondary-text-light);
	color: #686869;
}

.global-left-card p {
	// color: var(--secondary-text-light);
	color: #686869;
}
}

@media(min-width:1200px){
	.global-new-section {

		.timeline li:after {
			height: calc(140% + 0px);
		}

		.timeline li {
			margin-bottom: 60px;
			display: block;
		}
	}

	.contact-right-form .form-group .form-control {
		height: 56px;
	}
}

.service-new .technology-cobntact-section, .service-new .faq-section, .service-new .digital-service-section {
  background-color:#F4FAFE;
}

.service-new section .steps-feature .inner-step {
  background-color: #F4FAFE;
}

//
.technology-new-section.home-banner-section {

  .contact-right-form .budget-btn {
    margin-top: 0 !important;
  }
  .contact-right-form .sign-nda {
    margin-top: 16px !important;
  }
}

// new technology
.contact-new-section.new-tech .contact-right-form .sign-nda {
  margin-top: 16px !important;
}

.contact-new-section.new-tech .contact-right-form .budget-btn {
  margin-top: 0 !important;
}

.theme-light .service-new .partner-logo-inner{
  background-color: #eaf5fd !important;
}


.nav-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  margin-bottom: 40px;
  border: 0;

  .nav-item {
    border-radius: 4px 4px 0 0;
      a {
          background-color: transparent;
          font-size: 20px;
          font-weight: 600;
          line-height: 22px;
          padding: 13px 33px;
          color: #fff;
          border: 2px solid #fff;


          &.active {
            background-color: #fff;
            color: rgba(0, 134, 230, 1);
            border: 2px solid #fff !important;
          }

      }

      &:nth-child(2) a {
        border-radius: 0 8px 8px 0;
      }

      &:nth-child(1) a {
        border-radius: 8px 0 0 8px;
      }
  }
}

.author {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin-top: 20px;

  span {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: center;
      display: block;
  }

}

@media (max-width: 767px) {
  .author {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    margin-top: 20px;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: center;
        display: block;
    }

  }

  .nome-new .nav-tabs .nav-item a {
    font-size: 14px;
    line-height: 16px;
	padding: 8px 10px !important;
}
}


// .industries-middel div:nth-child(7) section {
// 	background: rgba(255, 255, 255, 1) !important;
// }


// budget section design

.pricing-list {
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap:6px;
	margin-bottom: 2px;
  }

    .pricing-list li {
	margin-bottom: 0 !important;
	list-style: none;
  }

    .pricing-list .pricing-radio {
	display: none; /* Hide the radio button */
  }

    .pricing-list .pricing-label {
	font-size: 12px !important;
	font-weight: 400 !important;
	line-height: 15px;
	text-align: center;
	border: 1px solid #DCECF8;
	border-radius: 8px;
	height: 32px;
	padding: 6px 7px;
	color: #000;
	text-decoration: none;
	display: block;
	transition: background-color 0.2s ease;
	white-space: nowrap;
	cursor: pointer;
	margin-bottom: 0 !important;
  }

    .pricing-list .pricing-radio:checked + .pricing-label, .pricing-list .pricing-radio.selected + .pricing-label p, .selected-label{
	background: linear-gradient(96.18deg, #78C4FB 0%, #0086E6 100%);
	color: #fff !important;
	font-weight: 600 !important;
  }

    .pricing-list .pricing-label:hover {
	background-color: #F0F8FF;
  }

  @media (min-width: 1400px) {
  .rfp-modal .global-right-content .global-select-upload .form-control {
	min-height: 140px !important;
  }

  .rfp-modal .contact-right-form .form-group {
	margin-bottom: 24px;
  }

  .rfp-modal .modal-content .modal-body {
	padding-top: 0 !important;
  }
}

@media (max-width: 1400px) {
  
	.rfp-modal .contact-right-form .form-group {
	  margin-bottom: 24px;
	}
  }
    /* Adjust grid layout for smaller screens */
  @media (max-width: 1400px) {
	.pricing-list {
	  grid-template-columns: repeat(2, 1fr);
	}
  }

    @media (max-width: 992px) {
	.pricing-list {
	  grid-template-columns: repeat(3, 1fr);
	}
  }

    @media (max-width: 400px) {
	.pricing-list {
	  grid-template-columns: repeat(2, 1fr);
	}
  }




  body .rfp_new {
    .section-pad-top-120 {
      padding-top: 120px !important;
    }
  
    .section-pad-bottom-120 {
      padding-bottom: 120px !important;
    }

	.section-pad-top-60 {
		padding-top: 60px !important;
	  }
	
	  .section-pad-bottom-60 {
		padding-bottom: 60px !important;
	  }


	  .section-pad-bottom-60-brand {
		padding-bottom: 30px !important;
	  }

    .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
  

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    

  
      .section-pad-top-120 {
        padding-top: 80px !important;
      }
  
      .section-pad-bottom-120 {
        padding-bottom: 80px !important;
      }


	  .section-pad-top-60 {
        padding-top: 40px !important;
      }
  
      .section-pad-bottom-60 {
        padding-bottom: 40px !important;
      }

	  .section-pad-bottom-60-brand {
		padding-bottom: 20px !important;
	  }

      .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
    }
  
    @media (max-width: 992px) {
      
  
      .section-pad-top-120 {
        padding-top: 80px !important;
      }
  
      .section-pad-bottom-120 {
        padding-bottom: 80px !important;
      }

	  .section-pad-top-60 {
        padding-top: 40px !important;
      }
  
      .section-pad-bottom-60 {
        padding-bottom: 40px !important;
      }

	  .section-pad-bottom-60-brand {
		padding-bottom: 20px !important;
	  }

      .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
    }
  
    @media (max-width: 767px) {
  
      .section-pad-top-120 {
        padding-top: 60px !important;
      }
  
      .section-pad-bottom-120 {
        padding-bottom: 60px !important;
      }

	  .section-pad-top-60 {
        padding-top: 30px !important;
      }
  
      .section-pad-bottom-60 {
        padding-bottom: 30px !important;
      }

	  .section-pad-bottom-60-brand {
		padding-bottom: 20px !important;
		min-height: 400px;
	  }

      .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
    }
  }

  @media (max-width: 480px) {
	.brand-row {
		padding: 0 5px;
		> div {
			padding: 0 5px !important;
		}
	  }

	  .nome-new {
		.swiper-pagination-bullet {
			margin: 0 5px !important;
		}
	  }
  }
