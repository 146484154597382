.faq-new-section {
    padding: 120px 0 100px 0;
    padding-bottom: 100px !important;
    background: #F4FAFE;
    min-height: 745px;

    // background: var(--secondary-background-light);
    .faq-new-content {
        padding-bottom: 40px;

        h2 {
            font-size: 40px;
            line-height: 150%;
            margin: 0;
            font-weight: 700;
            color: #040405;
            text-align: center;
        }
    }

    .accordion {
        .accordion-item {
            background-color: transparent;
            border-radius: 12px;
            margin-bottom: 20px;
            overflow: hidden;
            border-radius: 16px;
            border: 2px solid rgba(0, 134, 230, 0.20);
            background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);

            .accordion-header {
                margin: 0;

                .accordion-button {
                    padding: 24px;

                    background-color: transparent;
                    border: none !important;
                    box-shadow: none;

                    span {
                        font-size: 20px;
                        line-height: 150%;
                        font-weight: 500;
                        color: #040405;
                        padding-right: 12px;
                        display: block;
                    }

                    &:after {
                        background-image: url("../../../public/images/home/dark-minus.svg");
                        background-size: 14px;
                        transform: unset;
                        background-position: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 16px;
                        height: 16px;
                        filter: brightness(0.1);
                    }

                    &.collapsed {
                        &:after {
                            background-image: url("../../../public/images/home/dark-plus.svg");
                            background-size: 14px;
                            transform: unset;
                            background-position: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            .accordion-body {
                padding-top: 0;
                padding: 0 24px 28px 24px;

                p {
                    color: #000;
                    font-size: 16px;
                    line-height: 150%;
                }
            }

            &.active,
            &.show {
                border: 2px solid var(--primary-light-active);
                background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);

                .accordion-header {
                    .accordion-button {
                        span {
                            color: #fff;
                        }

                        &:after {
                            filter: brightness(100);
                        }

                        &.collapsed {
                            &:after {
                                filter: brightness(100);
                            }
                        }
                    }
                }

                .accordion-body {
                    padding-top: 0;
                    padding: 0 24px 28px 24px;

                    p {
                        color: #fff;
                        font-size: 16px;
                        line-height: 150%;
                    }

                    ul {
                        color: #fff;
                    }

                    ul li {
                        color: #fff;
                        font-size: 16px;
                        line-height: 150%; 

                        
                        &::marker {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

// technologies faq
.technology-faq-section {
    padding-bottom: 0;
}

// technology-main acordion css
.blog-faq-section {
    padding: 0px 0 60px 40px;
    min-height: unset !important;
    background-color: transparent;
}

.blog-faq-section .faq-new-content {
    padding-bottom: 20px;
}

.blog-faq-section .faq-new-content h2 {
    text-align: left;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}

.blog-faq-section .accordion .accordion-item .accordion-header .accordion-button {
    padding: 20px 24px;
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {

  .blog-faq-section {
        padding: 60px 40px !important;
    }

    .faq-new-section {
        min-height: 500px !important;
        padding: 90px 0;
    }

    .blog-faq-section {
        min-height: unset !important;
    }

    .technology-faq-section {
        padding-bottom: 0 !important;
    }

    .faq-new-section .container {
        max-width: 1000px !important;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button span {
        font-size: 16px;
        line-height: 22px;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button {
        padding: 18px;
    }

    .faq-new-section .accordion .accordion-item.active .accordion-body p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }

    // .technology-faq-section{
    //     padding-bottom: 0 !important;
    // }
}

@media (max-width: 1024px) {
    .faq-new-section {
        padding: 80px 0 60px 0;
        min-height: auto;
    }

    .faq-new-section .faq-new-content {
        padding-bottom: 32px;
    }

    .faq-new-section .faq-new-content h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .technology-faq-section {
        padding-bottom: 0;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button span {
        font-size: 15px;
        line-height: 22px;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button {
        padding: 18px;
    }

    .faq-new-section .accordion .accordion-item.active .accordion-body p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button span {
        font-size: 16px;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button {
        padding: 15px;
    }
}

@media (max-width: 992px) {
    .faq-new-section {
        padding: 60px 0;
    }

    .blog-faq-section {
        padding: 0px 0 40px 30px;
    }

    .faq-new-section .faq-new-content h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 26.4px;
    }

    .faq-new-section .faq-new-content {
        padding-bottom: 20px;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button span {
        font-size: 15px;
    }

    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button {
        padding: 16px;
    }

    .faq-new-section .accordion .accordion-item .accordion-body span {
        font-size: 14px;
    }

    .faq-new-section .accordion .accordion-item .accordion-body {
        padding: 0 16px 20px 16px;
    }

    .faq-new-section .accordion .accordion-item {
        margin-bottom: 16px;
        border-radius: 12px;
    }

    .technology-faq-section {
        padding-bottom: 0;
    }
}

@media (max-width: 767px) {
    .faq-new-section .accordion .accordion-item .accordion-header .accordion-button span {
        font-size: 14px;
    }

    // .faq-new-section .accordion .accordion-item .accordion-header .accordion-button{
    //     padding-bottom: 8px;
    // }
    .faq-new-section .accordion .accordion-item.active .accordion-body p {
        font-size: 14px;
        margin: 0;
    }

    .faq-new-section .accordion .accordion-item.active .accordion-body {
        padding: 0 16px 20px 16px;
    }

    .blog-faq-section {
        padding: 0px 0px 20px 0px;
    }
}

// theme dark
.theme-dark .faq-new-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    filter: brightness(100);
}

.theme-dark .faq-new-section .accordion .accordion-item .accordion-header .accordion-button:after {
    filter: unset;
}