@import '../../styles/App.scss';

.middle-section {
	padding-top: 80px;
}

// Request-banner-section-start
.request-banner-section {
	padding: 20px 0 40px 0;
	background-color: var(--section-primary);

	.request-banner-inner {
		.request-banner-content {
			background: var(--section-secondary);
			text-align: center;
			border-radius: 24px;
			padding: 80px 0px;

			.heading {
				@include heading();
				// @include heading2();
				max-width: 856px;
				margin: 0 auto 0px auto;

			}

		}
	}
}

// Request-banner-section-end

.theme-light {
	--form-bg: #fff;
	--form-border: rgba(4, 4, 5, 0.2);
	--form-color: rgba(4, 4, 5, 1);
}

.theme-dark {
	--form-bg: #111111;
	--form-border: rgba(255, 255, 255, 0.2);
	--form-color: #fff !important;
}

// Request-form-section-start
.request-form-section {
	padding: 20px 0 40px 0;
	background-color: var(--section-primary);

	.request-form-inner {
		.request-form-heading {
			margin-bottom: 40px;


			h6 {
				font-size: 16px;
				line-height: 20px;
				font-weight: 500;
				color: #616161;
				margin: 0;
				text-align: center;
				position: relative;


				&::before {
					content: "";
					background: #fff;
					opacity: 0.4;
					height: 1px;
					display: block;
					top: 0;
					left: 0;
					bottom: 0;
					margin: auto;
					position: absolute;
					width: 100%;
					z-index: -1;
				}

				span {
					display: inline-block;
					background-color: var(--section-primary);
					padding: 0 24px;
				}

			}
		}

		.request-form {
			.form-group {
				margin-bottom: 30px;

				.form-label {
					font-weight: 500;
					font-size: 16px;
					line-height: 150%;
					color: var(--color-sub-text) !important;
					opacity: 0.56;
				}

				.form-control {
					padding: 18px 20px;
					height: 64px;
					border: 1px solid var(--form-border);
					border-radius: 10px;
					font-size: 18px;
					line-height: 27px;
					font-weight: 600;
					color: var(--form-color);
					background-color: var(--form-bg) !important;
				}

				.form-label-check {
					padding-bottom: 20px;
				}

				.form-group-checkbox {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;

					.form-check {
						padding-right: 40px;
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;

						.form-check-label {
							color: rgba(97, 97, 97, 0.56) !important;
							font-size: 18px;
							line-height: 27px;
							font-weight: 500;
						}

					}


				}
			}
		}
	}
}
// Request-form-section-end

// about-project-section-start
.about-project-section {
	padding-top: 50px;
	background-color: var(--section-primary);

	.about-project-inner {
		.about-project-heading {
			margin-bottom: 40px;


			h6 {
				font-size: 16px;
				line-height: 20px;
				font-weight: 500;
				color: #616161;
				margin: 0;
				text-align: center;
				position: relative;


				&::before {
					content: "";
					background: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
					opacity: 0.4;
					height: 1px;
					display: block;
					top: 0;
					left: 0;
					bottom: 0;
					margin: auto;
					position: absolute;
					width: 100%;
					z-index: -1;
				}

				span {
					display: inline-block;
					background-color: var(--section-primary);
					padding: 0 24px;
				}

			}
		}

		.select-type-content {
			.select-text {
				margin-bottom: 20px !important;
			}

			.form-group {
				margin-bottom: 30px;

				.form-label {
					font-weight: 500;
					font-size: 16px;
					line-height: 150%;
					color: var(--color-sub-text);
					margin-bottom: 20px;
				}



				.form-control {
					padding: 18px 20px;
					height: 64px;
					border: 1px solid var(--form-border);
					border-radius: 10px;
					font-size: 18px;
					line-height: 27px;
					font-weight: 600;
					color: var(--form-color);
				}

				.form-lable-check {
					padding-bottom: 20px;
				}

				.form-group-checkbox {
					// display: flex;
					// align-items: center;
					// justify-content: start;
					// flex-wrap: wrap;

					.form-check {
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;

						.form-check-label {
							color: var(--color-sub-text) !important;
							font-size: 18px;
							line-height: 27px;
							font-weight: 500;
							margin-bottom: 0;
							padding-bottom: 20px;
						}

					}


				}
			}

			.text-area {
				border: 1px solid var(--form-border);
				border-radius: 10px;
				padding: 20px;
				font-size: 18px;
				line-height: 27px;
				font-weight: 500;
				color: var(--form-color);
				width: 100%;
				resize: none;
				min-height: 330px;
				background-color: var(--section-primary);
			}

			.upload-file {

				.file-upload-data {
					cursor: pointer;
					padding: 40px 20px 33px 20px;
					border: 1px solid var(--form-border);
					border-radius: 10px;
					text-align: center;
					min-height: 233px;
				}

				.file-input__input {
					opacity: 0;
					overflow: hidden;
					z-index: -1;
					display: none;
				}

				.upload-file-img {
					background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
					border-radius: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 72px;
					height: 72px;
					margin: auto;
					margin-bottom: 39px;

					img {
						width: auto;
					}
				}

				.upload-text {
					span {
						color: #808188;
						font-size: 12px;
						line-height: 18px;
						font-weight: 400;
						display: block;
					}
				}
			}

			.budget-content {
				margin-top: 12px;

				.range-slider {
					padding: 15px 20px 25px 20px;
					border: 1px solid var(--form-border);
					border-radius: 10px;

					.slider-labels {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 15px;

						.caption {
							p {
								margin: 0;

								em {
									font-size: 12px;
									line-height: 18px;
									font-weight: 500;
									color: var(--color-sub-text);
									opacity: 0.56;
									margin-bottom: 5px;
									display: block;
									font-style: normal;
								}

								span {
									font-size: 18px;
									line-height: 27px;
									font-weight: 600;
									color: var(--color-sub-heading) !important;
								}

							}
						}
					}
				}
			}
		}
	}
}
// about-project-section-end

// agreement-section-start
.agreement-section {
	padding-top: 80px;
	padding-bottom: 88px;
	background-color: var(--section-primary);

	.agreement-inner {
		.agreement-list {
			padding: 20px;
			border-top: 1px solid rgba(0, 134, 230, 0.2);
			border-bottom: 1px solid rgba(0, 134, 230, 0.2);
			margin-bottom: 60px;

			.agreement-box {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.form-group {
					margin-bottom: 0;

					.form-lable {
						font-weight: 500;
						font-size: 16px;
						line-height: 150%;
						color: #616161;
						opacity: 0.56;

					}



					.form-control {
						padding: 18px 20px;
						height: 64px;
						border: 1px solid rgba(4, 4, 5, 0.2);
						border-radius: 10px;
						font-size: 18px;
						line-height: 27px;
						font-weight: 600;
						color: #040405;
					}

					.form-group-checkbox {
						.form-check {
							display: flex;
							align-items: flex-start;
							justify-content: flex-start;

							.form-check-label {
								color: var(--color-sub-text) !important;
								font-size: 18px;
								line-height: 27px;
								font-weight: 500;
								margin-bottom: 0;
							}

						}


					}
				}

				// .agreement-btn {
				// 	.btn-primary {
				// 		font-size: 18px;
				// 		line-height: 22px;
				// 	}
				// }
			}


		}

		.conect-box {
			background-image: url(../../assets/images/agreement.png);
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			padding: 60px 40px;
			border-radius: 20px;
			background-color: var(--form-bg);
			box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);

			.conect-box-content {
				h3 {
					margin: 0;
					font-size: 30px;
					line-height: 45px;
					font-weight: 700;
					color: var(--color-sub-heading);
				}
			}

			.skype-contact {
				a {
					border-right: unset !important;
				}
			}

			.skype-conect {
				a {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					border-right: 1px solid #000000;
					color: var(--color-sub-heading);

					p {
						font-size: 30px;
						line-height: 45px;
						font-weight: 500;
						margin: 0;
						padding-right: 18px;
						color: var(--color-sub-heading);
					}

					img {
						width: auto;
					}
				}
			}
		}

	}
}

.file-input__label {
	width: 100%;
}
// agreement-section-end

// form group input style
.form-check {
	display: block;
	min-height: 1.6rem;
	padding-left: 1.5em;
	margin-bottom: .125rem
}

.form-check .form-check-input {
	float: left;
	// margin-left: -40px;
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: .3em;
	vertical-align: top;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, .25);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	// -webkit-print-color-adjust: exact;
	color-adjust: exact
}

.form-check-input[type=checkbox] {
	border-radius: .25em
}

.form-check-input:active {
	-webkit-filter: brightness(90%);
	filter: brightness(90%)
}

.form-check-input:focus {
	border-color: #1266f1;
	outline: 0;
	-webkit-box-shadow: 0 0 0 .25rem rgba(18, 102, 241, .25);
	box-shadow: 0 0 0 .25rem rgba(18, 102, 241, .25)
}

.form-check-input:checked {
	background-color: #1266f1;
}

.form-check-input:checked[type=checkbox] {
	background-image: url(../../assets/images/update-done-arrow.svg);
}

.form-check-input:checked[type=radio] {
	background-image: url(../../assets/images/update-circle-icon.svg);
}

.form-check-input[type=checkbox]:indeterminate {
	background-color: #1266f1;
	background-image: url(../../assets/images/update-slash-icon.svg);
}

.form-check-input:disabled {
	pointer-events: none;
	-webkit-filter: none;
	filter: none;
	opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
	opacity: .5
}

@media(prefers-reduced-motion:reduce) {
	.form-switch .form-check-input {
		-webkit-transition: none;
		transition: none
	}
}

.form-switch .form-check-input:focus {
	background-image: url(../../assets/images/update-blue-circle.svg);
}

.form-switch .form-check-input:checked {
	background-position: 100%;
	background-image: url(../../assets/images/white-circle-icon.svg);
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem
}

.form-check-input {
	position: relative;
	width: 22px;
	min-width: 22px;
	height: 22px;
	background-color: #fff;
	border: 3px solid;
	border-color: rgb(23 146 234 / 60%) !important;
}
.form-check-input:hover {
	cursor: pointer
}

.form-check-input:hover:before {
	opacity: .04;
	-webkit-box-shadow: 0 0 0 13px rgba(0, 0, 0, .6);
	box-shadow: 0 0 0 13px rgba(0, 0, 0, .6)
}

.form-check-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: none;
	-webkit-transition: border-color .2s;
	transition: border-color .2s
}

.form-check-input:focus:before {
	opacity: .12;
	-webkit-box-shadow: 0 0 0 13px rgba(0, 0, 0, .6);
	box-shadow: 0 0 0 13px rgba(0, 0, 0, .6);
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-box-shadow .2s, -webkit-transform .2s;
	transition: -webkit-box-shadow .2s, -webkit-transform .2s;
	transition: box-shadow .2s, transform .2s;
	transition: box-shadow .2s, transform .2s, -webkit-box-shadow .2s, -webkit-transform .2s
}

.form-check-input:checked {
	border-color: #1266f1
}

.form-check-input:checked:before {
	opacity: .16
}

.form-check-input:checked:after {
	content: "";
	position: absolute
}

.form-check-input:checked:focus {
	border-color: #1266f1
}

.form-check-input[type=checkbox] {
	border-radius: .125rem;
	margin-top: .19em;
	margin-right: 20px !important;
}

.form-check-input[type=checkbox]:focus:after {
	content: "";
	position: absolute;
	width: 15px;
	height: 15px;
	z-index: 1;
	display: block;
	border-radius: 0;
	background-color: #fff
}

.form-check-input[type=checkbox]:checked {
	background-image: none;
	background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
	border: transparent !important;
}

.form-check-input[type=checkbox]:checked:after {
	display: block;
	-webkit-transform: rotate(45deg)
		/*!rtl:ignore*/
	;
	transform: rotate(45deg)
		/*!rtl:ignore*/
	;
	width: 8px;
	height: 15px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	margin-left: 7px;
	margin-top: 1px;
	background-color: transparent
}

.form-check-input[type=checkbox]:checked:focus {
	background-color: #1266f1
}

.form-check-input[type=checkbox]:indeterminate {
	background-image: none;
	background-color: transparent;
	border-color: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%) !important;
}

.form-check-input[type=checkbox]:indeterminate:after {
	display: block;
	-webkit-transform: rotate(90deg)
		/*!rtl:ignore*/
	;
	transform: rotate(90deg)
		/*!rtl:ignore*/
	;
	width: .125rem;
	height: .875rem;
	border: .125rem solid #fff;
	border-top: 0;
	border-left: 0
		/*!rtl:ignore*/
	;
	margin-left: .375rem;
	margin-top: 0
}

.form-check-input[type=checkbox]:indeterminate:focus {
	background-color: #1266f1;
	border-color: #1266f1
}

.form-check-input[type=radio] {
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin-top: .125em;
	margin-right: 6px
}

.form-check-input[type=radio]:before {
	width: 24px;
	height: 24px;
}

.form-check-input[type=radio]:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	z-index: 1;
	display: block;
	border-radius: 50%;
	background-color: #fff
}

.form-check-input[type=radio]:checked {
	background-image: none;
	background-color: #fff
}

.form-check-input[type=radio]:checked:after {
	border-radius: 50%;
	width: 12px;
	height: 12px;
	border-color: #1266f1;
	background-color: #1266f1;
	-webkit-transition: border-color;
	transition: border-color;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%
}

.form-check-input[type=radio]:checked:focus {
	background-color: #fff
}

.form-check-label:hover {
	cursor: pointer
}

.input-group-text {
	background-color: transparent;
	padding-top: .26rem;
	padding-bottom: .26rem
}

.input-group-text>.form-check-input[type=checkbox] {
	margin-left: 1px;
	margin-right: 1px
}

.input-group-text>.form-check-input[type=radio] {
	margin-right: 0
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
	border-color: #00b74a
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
	background-color: #00b74a
}

.form-check-input.is-valid:checked:focus:before,
.was-validated .form-check-input:valid:checked:focus:before {
	-webkit-box-shadow: 0 0 0 13px #00b74a;
	box-shadow: 0 0 0 13px #00b74a
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
	-webkit-box-shadow: none;
	box-shadow: none
}

.form-check-input.is-valid:focus:before,
.was-validated .form-check-input:valid:focus:before {
	-webkit-box-shadow: 0 0 0 13px #00b74a;
	box-shadow: 0 0 0 13px #00b74a
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
	color: #00b74a;
	margin-bottom: 1rem
}

.form-check-input.is-valid[type=checkbox]:checked:focus,
.was-validated .form-check-input:valid[type=checkbox]:checked:focus {
	background-color: #00b74a;
	border-color: #00b74a
}

.form-check-input.is-valid[type=radio]:checked,
.was-validated .form-check-input:valid[type=radio]:checked {
	border-color: #00b74a;
	background-color: #fff
}

.form-check-input.is-valid[type=radio]:checked:focus:before,
.was-validated .form-check-input:valid[type=radio]:checked:focus:before {
	-webkit-box-shadow: 0 0 0 13px #00b74a;
	box-shadow: 0 0 0 13px #00b74a
}

.form-check-input.is-valid[type=radio]:checked:after,
.was-validated .form-check-input:valid[type=radio]:checked:after {
	border-color: #00b74a;
	background-color: #00b74a
}

.form-check-inline .form-check-input~.valid-feedback {
	margin-left: .5em
}


.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
	border-color: #f93154
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
	background-color: #f93154
}

.form-check-input.is-invalid:checked:focus:before,
.was-validated .form-check-input:invalid:checked:focus:before {
	-webkit-box-shadow: 0 0 0 13px #f93154;
	box-shadow: 0 0 0 13px #f93154
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
	-webkit-box-shadow: none;
	box-shadow: none
}

.form-check-input.is-invalid:focus:before,
.was-validated .form-check-input:invalid:focus:before {
	-webkit-box-shadow: 0 0 0 13px #f93154;
	box-shadow: 0 0 0 13px #f93154
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
	color: #f93154;
	margin-bottom: 1rem
}

.form-check-input.is-invalid[type=checkbox]:checked:focus,
.was-validated .form-check-input:invalid[type=checkbox]:checked:focus {
	background-color: #f93154;
	border-color: #f93154
}

.form-check-input.is-invalid[type=radio]:checked,
.was-validated .form-check-input:invalid[type=radio]:checked {
	border-color: #f93154;
	background-color: #fff
}

.form-check-input.is-invalid[type=radio]:checked:focus:before,
.was-validated .form-check-input:invalid[type=radio]:checked:focus:before {
	-webkit-box-shadow: 0 0 0 13px #f93154;
	box-shadow: 0 0 0 13px #f93154
}

.form-check-input.is-invalid[type=radio]:checked:after,
.was-validated .form-check-input:invalid[type=radio]:checked:after {
	border-color: #f93154;
	background-color: #f93154
}

.form-check-inline .form-check-input~.invalid-feedback {
	margin-left: .5em
}

.request-form-section .request-form-inner .request-form .form-group .form-group-checkbox .form-check-input:checked+.form-check-label {
	font-weight: 600 !important;
	color: var(--color-sub-heading) !important;
}

.about-project-section .about-project-inner .select-type-content .form-group .form-group-checkbox .form-check-input:checked+.form-check-label {
	font-weight: 600 !important;
	color: var(--color-sub-heading) !important;
}

.agreement-section .agreement-inner .agreement-list .form-group .form-group-checkbox .form-check-input:checked+.form-check-label {
	font-weight: 600 !important;
	color: var(--color-sub-heading) !important;
}

// slider 
.slider-labels {
	margin-top: 10px;
}

/* Functional styling;
   * These styles are required for noUiSlider to function.
   * You don't need to change these rules to apply your design.
   */
.noUi-target,
.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.noUi-target {
	position: relative;
	direction: ltr;
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
	/* Fix 401 */
}

.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}

.noUi-handle {
	position: relative;
	z-index: 1;
}

.noUi-stacking .noUi-handle {
	/* This class is applied to the lower origin when
     its values is > 50%. */
	z-index: 10;
}

.noUi-state-tap .noUi-origin {
	-webkit-transition: left 0.3s, top .3s;
	transition: left 0.3s, top .3s;
}

.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
   * Browsers can paint handles in their own layer.
   */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
   */
.noUi-horizontal {
	height: 4px;
}

.noUi-horizontal .noUi-handle {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	left: -7px;
	top: -7px;
	background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}

/* Styling;
   */
.noUi-background {
	background: #EDEEF1;
	border-radius: 12px;
}

.noUi-connect {
	background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
	border-radius: 12px;
	-webkit-transition: background 450ms;
	transition: background 450ms;
}

.noUi-origin {
	border-radius: 2px;
}

.noUi-target {
	border-radius: 2px;
}

.noUi-target.noUi-connect {}

/* Handles and cursors;
   */
.noUi-draggable {
	cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}

.noUi-handle {
	cursor: default;
	-webkit-box-sizing: content-box !important;
	-moz-box-sizing: content-box !important;
	box-sizing: content-box !important;
}

.noUi-handle:active {
	border: 8px solid #345DBB;
	border: 8px solid rgba(53, 93, 187, 0.38);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	left: -14px;
	top: -14px;
}

/* Disabled state;
   */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}


// Responsive Design

@media(max-width: 992px) {
	.agreement-section .agreement-inner .conect-box .conect-box-content h3 {
		margin-bottom: 10px;
	}

	.about-project-section .about-project-inner .select-type-content .form-group {
		margin-bottom: 0 !important;
	}

	.about-project-section .about-project-inner .select-type-content .form-group {
		padding-top: 10px;
	}
}

@media(max-width: 767px) {
	.request-banner-section {
		.request-banner-inner {
			.request-banner-content {
				padding: 30px 20px;
			}
		}
	}

	.request-form-section {
		.request-form-inner {
			.request-form-heading {
				margin-bottom: 20px;
			}

			.request-form {
				.form-group {
					margin-bottom: 15px;

					.form-lable {
						font-size: 14px;
						line-height: 20px;

					}

					.form-lable-check {
						padding-bottom: 0;
					}

					.form-group-checkbox {
						display: block;

						.form-check {
							align-items: center;
							padding-bottom: 10px;

							.form-check-label {
								font-size: 14px;
								line-height: 20px;
								margin: 0;
								padding-bottom: 0px;
							}
						}

					}

					.form-control {
						height: 45px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	.about-project-section {
		padding-top: 30px;

		.about-project-inner {
			.about-project-heading {
				margin-bottom: 20px;

				h6 {
					font-size: 14px;
				}
			}

			.select-type-content {


				.form-group {
					margin-bottom: 15px;
					padding: 0px;

					.form-lable {
						font-size: 14px;
						line-height: 20px;
						padding-top: 10px;
						padding-bottom: 5px;

					}

					.form-group-checkbox {
						display: block;

						.form-check {
							align-items: center;
							padding-bottom: 10px;

							.form-check-label {
								font-size: 14px;
								line-height: 20px;
								margin: 0;
								padding-bottom: 0px;
							}
						}
					}
				}

				.text-area {
					min-height: 150px;
					padding: 20px;
				}

				.upload-file {
					padding: 0px;
					min-height: 150px;

					.upload-file-img {
						margin-bottom: 15px;
					}
				}
			}
		}
	}

	.agreement-section {
		padding-top: 50px;
		padding-bottom: 50px;


		.agreement-inner {
			.agreement-list {
				margin-bottom: 40px;

				.agreement-box {
					display: flex;

					.agreement-btn {
						padding-top: 0px;

						.btn-primary {
							font-size: 14px;
							line-height: 30px;
							width: auto;
							display: inline-block;
						}
					}

					.form-group {
						.form-group-checkbox {
							.form-check {
								align-items: center;

								.form-check-label {
									font-size: 14px;
									line-height: 20px;
								}
							}
						}
					}
				}
			}

			.conect-box {
				padding: 30px 10px;

				.conect-box-content {
					h3 {
						font-size: 20px;
						line-height: 28px;
					}
				}

				.skype-conect {
					a {
						p {
							font-size: 18px;
							line-height: 30px;
						}
					}
				}
			}
		}

	}
}

@media (max-width: 420px){
    .request-proposal-form  .agreement-section .agreement-inner .agreement-list .agreement-box {
        display: block !important;
    }
    .request-proposal-form  .agreement-section .agreement-inner .agreement-list .agreement-box .agreement-btn{
        padding-top: 25px !important;
    } 
}

.filelist-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
	border: 1px solid var(--form-border);
	color: var(--form-color);
	margin-top: 5px;
	border-radius: 5px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 24px;
}

.filelist-item .btn-remove {
	cursor: pointer;
	font-weight: 600;
}