.theme-light {
    --list-border-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
    --list-hover-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
    --accordion-bg-color: linear-gradient(283.13deg, rgba(0, 145, 218, 0.05) 5.56%, rgba(130, 213, 255, 0.05) 113.66%);
    --count-color: #d8dadc;
    --card-bg-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    --light-bg: #f3f9fe;
    --badge-bg: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    --banner-gradient-bg: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    --bg-header-light: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    --bg-header-light-fixed: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%);
    --color-primary: #f3f9fe;
    --color-text-primary: #00011c;
    --header-text-hover: #040405;
    --color-toggle: #fff;
    --color-submenu-primary: #040405;
    --color-submenu-secondary: rgba(4, 4, 5, 0.5);
    --header-text: #00011c;
    --header-text-hover: #040405;
    --header-bg: #f3f9fe;
    --bg-navigation: #fff;
    --header-border-gray: rgba(0, 1, 28, 0.01);
    --header-border-gray1: rgba(0, 1, 28, 0.02);
    --section-primary: #fff;
    --heading-priamry: #00011c;
    --line: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    --bg-line: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    --line-active: #0086e6;
    --section-secondary: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    --heading-secondary: #91959b;
    --sub-heading-primary: #040405;
    --hire-circle: linear-gradient(283.13deg, rgba(0, 134, 230, 0.03) 5.56%, rgba(141, 208, 255, 0.03) 113.66%);
    --marquee-color: #cbd3d8;
    --dot: #c4c4c4;
    --acc-icon-color: #616161;
    --acc-bg-color: #f6faff;
    --acc-color: rgba(97, 97, 97, 1);
    --acc-active-color: #040405;
    --test-border-color: #dfdfdf;
    --test-bg-color: #ffffff;
    --test-color: #00011c;
    --section-three: rgba(37, 37, 38, 0.12);
    --sectiontree-color: #00011c;
    --footer-title: rgba(0, 1, 28, 1);
    --footer-links: rgba(0, 1, 28, 0.56);
    --footer-links-hover: rgba(0, 1, 28, 1);
    --footer-title-second: rgba(0, 1, 28, 0.56);
    --color-sub-heading: #040405;
    --color-sub-text: #616161;
    --color-feature: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    --color-feature-border: #f3f9fe;
    --color-feature2: #fff;
    --color-sub-light: rgba(97, 97, 97, 0.2);
    --border: rgba(0, 1, 26, 0.2);
    --border3: rgba(0, 1, 26, 0.1);
    --border2: rgba(0, 1, 26, 1);
    --new-color-text: rgba(97, 97, 97, 1);
}

.theme-dark {
    --list-border-color: linear-gradient(283.13deg, rgba(138, 188, 0, 0.15) 5.56%, rgba(204, 250, 78, 0.15) 113.66%);
    --list-hover-color: linear-gradient(283.13deg, rgba(138, 188, 0, 0.15) 5.56%, rgba(204, 250, 78, 0.15) 113.66%);
    --accordion-bg-color: #040405;
    --count-color: #fff;
    --card-bg-color: #111;
    --light-bg: #111111;
    --badge-bg: linear-gradient(317.72deg, rgba(138, 188, 0, 0.1) 5.24%, rgba(204, 250, 78, 0.1) 137.29%);
    --banner-gradient-bg: #000;
    --bg-header-light: #111111;
    --bg-header-light-fixed: #111111;
    --color-primary: #040405;
    --color-text-primary: #fff;
    --color-toggle: #040405;
    --color-submenu-primary: #fff;
    --color-submenu-secondary: #616161;
    --header-text: #a0a0a0;
    --header-text-hover: #fff;
    --header-bg: #040405;
    --bg-navigation: #040405;
    --header-border-gray: rgba(255, 255, 255, 0.02);
    --header-border-gray1: rgba(255, 255, 255, 0.03);
    --section-primary: #040405;
    --heading-priamry: rgb(2, 2, 2);
    --line: linear-gradient(317.72deg, rgba(138, 188, 0, 0.2) 5.24%, rgba(204, 250, 78, 0.2) 137.29%);
    --line-active: rgba(138, 188, 0, 1);
    --bg-line: linear-gradient(317.72deg, rgba(138, 188, 0, 0.05) 5.24%, rgba(204, 250, 78, 0.05) 137.29%);
    --section-secondary: #111111;
    --heading-secondary: rgba(255, 255, 255, 0.56);
    --sub-heading-primary: #fff;
    --hire-circle: #0c0c0c;
    --marquee-color: #3b3b3b;
    --dot: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    --acc-icon-color: rgba(255, 255, 255, 0.3);
    --acc-bg-color: #111111;
    --acc-color: rgba(255, 255, 255, 0.56);
    --acc-active-color: #fff;
    --test-border-color: rgba(255, 255, 255, 0.05);
    --test-bg-color: #111111;
    --test-color: #fff;
    --section-three: #252526;
    --sectiontree-color: #fff;
    --footer-title: rgba(255, 255, 255, 1);
    --footer-links: rgba(255, 255, 255, 0.56);
    --footer-links-hover: rgba(255, 255, 255, 1);
    --color-sub-heading: #fff;
    --color-sub-text: #616161;
    --color-feature: #111111;
    --color-feature-border: #111111;
    --color-feature2: #040405;
    --color-sub-light: rgba(97, 97, 97, 0.2);
    --border: rgba(255, 255, 255, 0.2);
    --border3: rgba(255, 255, 255, 0.1);
    --border2: rgba(255, 255, 255, 1);
    --new-color-text: rgba(255, 255, 255, 0.56);
}


.about-section {
    padding: 80px 0 80px 0;
    position: relative;
    background-color: #fff;
}

.about-section::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 65%;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    z-index: 1;
}

.about-section .about-inner {
    position: relative;
    z-index: 2;
}

.about-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 80px;
}

.about-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.about-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.about-section .heading h2 {
    line-height: 60px;
}

.about-section .heading h2 {
    max-width: 730px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.about-section .heading p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #616161;
    max-width: 920px;
    margin: 0 auto;
}

.about-section .work-image {
    border-radius: 12px;
    overflow: hidden;
}

.about-section .work-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: all 0.4s linear;
}

.about-section .work-image:hover img {
    transform: scale(1.03);
    transition: all 0.4s linear;
}

.vibe-section {
    padding: 80px 0;
    background-color: #fff;
}

.vibe-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 80px;
}

.vibe-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.vibe-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 700;
}

.vibe-section .vibe-main-box {
    border-radius: 12px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.1) 5.56%, rgba(141, 208, 255, 0.1) 113.66%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 570px;
}

.vibe-section .vibe-main-box .vibe-img {
    height: 380px;
    width: 100%;
}

.vibe-section .vibe-main-box .vibe-img img {
    width: 100%;
    height: auto;
}

.vibe-section .vibe-main-box .vibe-text {
    padding: 20px 24px;
    width: 100%;
}

.vibe-section .vibe-main-box .vibe-text p {
    max-width: 160px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0;
    color: #040405;
    color: #040405;
}

.vibe-section .vibe-green-box {
    background: linear-gradient(317.72deg, rgba(138, 188, 0, 0.1) 5.24%, rgba(204, 250, 78, 0.1) 137.29%) !important;
}

.meet-management-section {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.meet-management-section .heading {
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
}

.meet-management-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.meet-management-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 700;
}

.meet-management-section .heading p {
    font-weight: 400;
    font-size: 18px;
    color: #91959b;
    max-width: 440px;
}

.meet-management-section .meet-slider {
    max-width: 450px;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    height: 100%;
}

.meet-management-section .meet-slider>div {
    display: block;
    width: 100%;
}

.meet-management-section .meet-slider .slick-arrow {
    position: absolute;
    top: calc(50% - 5px);
    height: 32px;
    width: 32px;
    border-radius: 9px;
    border: 1px solid #0086e6;
    background-color: transparent;
    color: #0086e6;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
}

.meet-management-section .meet-slider .slick-arrow::before {
    display: none;
}

.meet-management-section .meet-slider .slick-next {
    right: -50px;
    background-image: url(../../assets/images/slick-left-arrows.svg);
    transform: rotate(180deg);
    margin-top: -12px;
}

.meet-management-section .meet-slider .slick-prev {
    left: -50px;
    background-image: url(../../assets/images/slick-left-arrows.svg);
}

.meet-management-section .meet-slider .meet-thumb {
    width: 93px;
    height: 117px;
    position: relative;
    margin-top: 5px;
}

.meet-management-section .meet-slider .meet-thumb::after {
    content: "";
    background-color: #fff;
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 1;
    position: absolute;
    border-radius: 12px;
    top: -5px;
    right: -5px;
}

.meet-management-section .meet-slider .meet-thumb img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    position: relative;
    z-index: 2;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}

.meet-management-section .profile-info {
    margin-top: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.meet-management-section .profile-info h3 {
    font-size: 27px;
    line-height: 33px;
    font-weight: 700;
    color: #040405;
    color: #040405;
}

.meet-management-section .profile-info p {
    color: #616161;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.meet-management-section .profile-info ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.meet-management-section .profile-info ul li {
    margin-left: 15px;
    list-style: none;
}

.meet-management-section .profile-info ul li a {
    height: 23px;
    width: 23px;
    border-radius: 100px;
    padding: 4px;
    background-color: #0086e6;
    font-size: 11px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meet-management-section .profile-info ul li a i {
    font-size: 12px;
}

.meet-management-section .meet-profile {
    width: 340px;
    min-height: 530px;
    position: relative;
    padding: 0 5px;
}

.meet-management-section .meet-profile img {
    position: absolute;
    z-index: 2;
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    bottom: 0;
    top: auto;
    left: 0;
}

.meet-management-section .meet-profile::after {
    content: "";
    background-image: url("../../assets/images/bg-meet.png");
    background-position: bottom center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.difference-section {
    padding: 80px 0;
    background-color: #fff;
}

.difference-section .heading {
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
}

.difference-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.difference-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.difference-section .heading h2 {
    max-width: 350px;
    font-size: 50px;
    line-height: 75px;
    font-weight: 700;
    text-transform: capitalize;
}

.difference-section .make-text h4 {
    font-size: 20px;
    line-height: 30px;
    color: #040405;
    color: #040405;
    font-weight: 700;
    margin-bottom: 16px;
}

.difference-section .make-text p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #616161;
    margin-bottom: 0;
}

.difference-section .help-text {
    margin-bottom: 40px;
}

.difference-section .differrence-content {
    margin-bottom: 40px;
}

.difference-section .deference-feature>div {
    margin: 0;
}

.difference-section .deference-feature>div>div {
    padding: 0;
}

.difference-section .deference-feature .feature-box {
    padding: 20px 24px 22px 20px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 2px solid #f3f9fe;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 2px solid #f3f9fe;
    height: 100%;
}

.difference-section .deference-feature .feature-box p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #616161;
    margin-bottom: 40px;
}

.difference-section .deference-feature .feature-box h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 0;
    text-align: right;
}

.difference-section .deference-feature .feature-white {
    background: #fff !important;
}

.believe-section {
    padding: 80px 0 0 0;
    background-color: #fff;
}

.believe-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 80px;
}

.believe-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.believe-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.believe-section .believe-box {
    margin-bottom: 80px;
    border-top: 3px solid rgba(0, 134, 230, 0.0784313725);
    position: relative;
}

.believe-section .believe-box::after {
    content: "";
    height: 3px;
    top: -3px;
    left: 0;
    position: absolute;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    z-index: 2;
    width: 100%;
    display: none;
}

.believe-section .believe-box:hover {
    border-top: 3px solid rgba(0, 134, 230, 0.0784313725);
}

.believe-section .believe-box:hover::after {
    display: block;
}

.believe-section .believe-box:hover .believe-image img {
    transform: scale(1.05);
    transition: all 0.45s linear;
}

.believe-section .believe-box .believe-content {
    padding-top: 17px;
}

.believe-section .believe-box .believe-content h3 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    color: #040405;
    color: #040405;
    margin-bottom: 10px;
}

.believe-section .believe-box .believe-content p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #616161;
    margin-bottom: 20px;
}

.believe-section .believe-box .believe-image {
    border-radius: 15px;
    overflow: hidden;
}

.believe-section .believe-box .believe-image img {
    transform: scale(1);
    transition: all 0.45s linear;
    width: 100%;
}

.blog-allstories-section {
    background-color: #fff;
    background-color: #fff;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading {
    position: relative;
    margin-bottom: 20px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading:before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 86%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name {
    padding-bottom: 20px;
    border-bottom: 2px solid #dbeffd;
    margin-bottom: 40px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul {
    padding: 0;
    margin: 0;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li {
    display: inline-block;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li a {
    font-size: 18px;
    color: #040405;
    color: #040405;
    font-weight: 400;
    line-height: 27px;
    display: inline-block;
    border-radius: 12px;
    padding: 8px 10px;
    margin-bottom: 15px;
    margin-right: 10px;
    border: 1px solid #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li a.active {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%) !important;
    color: #fff !important;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(odd) a {
    border-color: #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(odd) a.active {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%) !important;
    color: #fff !important;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(even) a {
    border: 1px solid #8abc00;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(even) a.active {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
    color: #fff !important;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group {
    justify-content: end;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .form-control {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 0px;
    padding: 15px 15px;
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    max-width: 350px;
    color: #040405;
    color: #040405;
    box-shadow: none !important;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .form-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .input-group-text {
    border: 0px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 15px 15px;
    color: #0086e6;
    font-size: 18px;
}

.blog-allstories-section .blog-allstories-inner .blog-card-div {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 1px;
    margin-bottom: 40px;
}

.blog-allstories-section .blog-allstories-inner .blog-card {
    background-color: #fff;
    background-color: #fff;
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-img {
    height: 218px;
    border-radius: 12px;
    overflow: hidden;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    min-height: 92px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #040405;
    color: #040405;
    // min-height: 68px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 span {
    font-weight: 400;
    color: #040405;
    color: #040405;
    margin-right: 8px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul li {
    width: auto;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul li span {
    font-size: 12px;
    line-height: 18px;
    color: #616161;
}

.blog-allstories-section .blog-allstories-inner nav {
    margin-top: 40px;
}

.blog-allstories-section .blog-allstories-inner nav .pagination {
    justify-content: center;
}

.blog-allstories-section .blog-allstories-inner nav .pagination li {
    background-color: #fff !important;
    background-color: #fff !important;
}

.blog-allstories-section .blog-allstories-inner nav .pagination li .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    transition: none !important;
}

.blog-allstories-section .blog-allstories-inner nav .pagination li .page-link:hover .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item {
    background-color: #fff;
    background-color: #fff;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    transition: none !important;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item.selected .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item:hover .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.blog-banner-section {
    padding: 20px 0 20px 0;
    background-color: #fff;
    background-color: #fff;
}

.blog-banner-section .blog-banner-inner .blog-banner-content {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    text-align: center;
    border-radius: 24px;
    padding: 50px 0px 70px;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading {
    max-width: 700px;
    margin: 0 auto 20px auto;
    text-align: center;
    margin-bottom: 20px;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading h2 {
    line-height: 60px;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading h2 span {
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 800;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .btn-primary {
    font-size: 16px;
    font-weight: 600;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.blog-topstories-section {
    background-color: #fff;
    background-color: #fff;
    padding: 50px 0px;
}

.blog-topstories-section .blog-topstories-inner .blog-topstories-heading {
    position: relative;
    margin-bottom: 20px;
}

.blog-topstories-section .blog-topstories-inner .blog-topstories-heading:before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 86%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.blog-topstories-section .blog-topstories-inner .blog-topstories-heading h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.blog-topstories-section .blog-topstories-inner .blog-topstories-heading h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

.blog-topstories-section .blog-topstories-inner .blog-card-div {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 1px;
    margin-bottom: 20px;
}

.blog-topstories-section .blog-topstories-inner .blog-card {
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    background-color: #fff;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-img {
    height: 218px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    height: 550px;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content {
    padding: 15px 140px 20px 15px;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 15px;
    min-height: 75px;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content h3:hover {
    cursor: pointer;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content ul {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content ul li {
    display: flex;
    position: relative;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content ul li span {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    padding-right: 30px;
}

.blog-topstories-section .blog-topstories-inner .blog-card .blog-content ul li:first-child::after {
    background: #c4c4c4;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(45deg);
}

.blog-topstories-section .blog-topstories-inner .blog-card-small {
    margin-bottom: 0px;
}

.blog-topstories-section .blog-topstories-inner .blog-card-small .blog-img {
    height: 218px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.blog-topstories-section .blog-topstories-inner .blog-card-small .blog-img img {
    width: 100%;
    height: 218px;
}

.blog-topstories-section .blog-topstories-inner .blog-card-small .blog-content {
    padding: 15px 15px 15px 15px;
}

.blog-topstories-section .blog-topstories-inner .blog-card-small .blog-content h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    min-height: unset;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.blog-articles-section {
    background-color: #fff;
    background-color: #fff;
}

.blog-articles-section .blog-articles-inner .blog-articles-heading {
    margin-bottom: 40px;
}

.blog-articles-section .blog-articles-inner .blog-articles-heading h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    color: #040405;
    color: #040405;
}

.blog-articles-section .blog-articles-inner .blog-card-div {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 1px;
    margin-bottom: 40px;
}

.blog-articles-section .blog-articles-inner .blog-card {
    background-color: #fff;
    background-color: #fff;
    border-radius: 12px;
    height: 100%;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-img {
    height: 218px;
    border-radius: 12px;
    overflow: hidden;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-img img {
    height: 100%;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    min-height: 92px;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #040405;
    color: #040405;
    min-height: 68px;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content h5 span {
    font-weight: 400;
    color: #91959b;
    margin-right: 8px;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content ul li {
    width: auto;
}

.blog-articles-section .blog-articles-inner .blog-card .blog-content ul li span {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
}

.blog-articles-section .blog-articles-inner nav {
    margin-top: 40px;
}

.blog-articles-section .blog-articles-inner nav .pagination {
    justify-content: center;
}

.blog-articles-section .blog-articles-inner nav .pagination .page-item .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
}

.blog-articles-section .blog-articles-inner nav .pagination .page-item.active .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.blog-digital-section {
    padding-top: 88px;
    background-color: #fff;
    background-color: #fff;
}

.blog-digital-section .blog-digital-inner .blog-digital-heading {
    margin-bottom: 20px;
}

.blog-digital-section .blog-digital-inner .blog-digital-heading h2 {
    font-size: 40px;
    color: #040405;
    color: #040405;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.blog-digital-section .blog-digital-inner .blog-digital-heading p {
    font-size: 16px;
    color: #616161;
    font-weight: 500;
    line-height: 24px;
    // max-width: 800px;
}

.blog-digital-section .blog-digital-inner .blog-digital-heading p a {
    color: #0187e6 !important;
    text-decoration: underline;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-date {
    padding: 0;
    margin-bottom: 30px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-date li {
    display: inline-block;
    position: relative;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-date li:after {
    background: #c4c4c4;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(45deg);
}

.blog-digital-section .blog-digital-inner ul.blog-digital-date li:last-child:after {
    width: 0px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-date li a {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    padding-right: 30px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social {
    text-align: right;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social li {
    display: inline-block;
    margin-left: 15px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social li:first-child {
    margin-right: 10px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social li a span {
    background: #616161;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    text-align: center;
    line-height: 21px;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social li a span img {
    width: auto;
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social li a:hover span {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.blog-digital-section .blog-digital-inner ul.blog-digital-social li span {
    font-size: 12px;
    line-height: 16px;
    color: #040405;
    color: #040405;
}

.blog-digital-section .blog-digital-inner .blog-digital-img img {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    max-height: 340px;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.blog-migration-section {
    padding: 60px 0px 45px 0px;
    background-color: #fff;
    background-color: #fff;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent {
    border-bottom: 1px solid #8dd0ff;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name {
    border-right: 1px solid #8dd0ff;
    height: 100%;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name .nav-tabs .nav-link {
    border: 0 !important;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name>div {
    position: sticky;
    top: 100px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs {
    position: sticky;
    top: 100px;
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border: 0;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    border: 0;
    border: 0 !important;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a {
    padding: 10px 20px 10px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #616161;
    background-color: transparent;
    position: relative;
    width: 100%;
    text-align: left;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a .arrow {
    margin-left: 10px;
    background-image: url(../../assets/images/update-right-arrow.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 16px;
    min-width: 16px;
    height: 20px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a:hover .arrow {
    opacity: 1;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a.active {
    color: #040405;
    color: #040405;
    font-weight: 600;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a.active .arrow {
    opacity: 1;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail {
    padding: 0px 0px 100px 40px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h2 {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    color: #040405;
    color: #040405;
    margin-bottom: 15px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h2 span {
    color: #040405;
    color: #040405;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h2 strong {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #040405;
    color: #040405;
    margin-bottom: 15px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h3 span {
    color: #040405 !important;
    color: #040405 !important;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h3 strong {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table th,
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table td {
    padding: 10px 10px;
    border: 1px solid;
    color: #040405;
    background: #fff !important;
    border-color: #616161;
    font-size: 16px;
    line-height: 24px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table,
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail th {
    font-weight: 600;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table strong,
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail th strong {
    font-weight: 600;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table p,
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail th p {
    margin-bottom: 0;
    font-weight: 600;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table td {
    font-weight: 400;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table td p {
    margin-bottom: 0;
    font-weight: 400;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail a {
    color: #0187e6 !important;
    text-decoration: underline;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .btn-primary {
    color: #fff !important;
    text-decoration: none !important;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul {
    padding: 0;
    margin: 0;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul li {
    font-size: 16px;
    line-height: 24px;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul li a {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p {
    font-size: 16px;
    line-height: 24px;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p a {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p strong {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail img {
    margin: 20px 0px 40px 0px;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul {
    margin: 0;
    padding: 0;
}

.blog-migration-section .blog-migration-inner .blog-about-box {
    border-bottom: 1px solid #8dd0ff;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading {
    max-width: 195px;
    height: 100%;
    display: flex;
    align-items: center;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading h2 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about {
    padding: 25px 0px;
    align-items: center;
    position: relative;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about:after {
    content: "";
    border-left: 1px solid #8dd0ff;
    position: absolute;
    height: 100%;
    left: 5px;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img {
    display: flex;
    justify-content: center;
    padding-left: 20px;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img img {
    width: auto;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content h3 {
    font-size: 20px;
    line-height: 30px;
    color: #040405;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content p {
    font-size: 16px;
    color: #040405;
    color: #040405;
    line-height: 24px;
}

.theme-dark .blogcontent-detail p {
    color: #040405 !important;
    color: #040405 !important;
}

.theme-dark .blogcontent-detail p span {
    color: #040405 !important;
    color: #040405 !important;
}

.theme-dark .blogcontent-detail p strong {
    color: #040405 !important;
    color: #040405 !important;
}

.theme-dark .blogcontent-detail li {
    color: #040405 !important;
    color: #040405 !important;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.careers-about-section {
    padding: 50px 0 100px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: #fff;
    background-color: #fff;
}

.careers-about-section .careers-about-left {
    margin-top: 65px;
}

.careers-about-section .careers-about-left h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 30px;
    color: #040405;
    color: #040405;
}

.careers-about-section .careers-about-left p {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #616161;
}

.careers-about-section .careers-about-right {
    position: relative;
    margin-left: 30px;
}

.careers-about-section .careers-about-right::after {
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    transform: rotate(45deg) translateX(-50%);
    width: 160px;
    height: 80%;
    position: absolute;
    top: 30%;
    opacity: 0.05;
    left: 60%;
    z-index: -1;
}

.careers-about-section .careers-about-right::before {
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    transform: rotate(45deg) translateX(-50%);
    width: 160px;
    height: 74%;
    position: absolute;
    top: 2%;
    opacity: 0.05;
    left: 43%;
    z-index: -1;
}

.careers-about-section .careers-about-right .top-img {
    position: absolute;
    top: -30px;
    right: 0;
}

.careers-about-section .careers-about-right .top-img img {
    border-radius: 11px;
}

.careers-about-section .careers-about-right .top-center {
    border-radius: 11px;
}

.careers-about-section .careers-about-right .top-center img {
    border-radius: 11px;
}

.careers-about-section .careers-about-right .top-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 11px;
}

.careers-about-section .careers-about-right .top-bottom img {
    border-radius: 11px;
}

.careers-banner-section {
    padding: 100px 0 100px 0;
    position: relative;
    background-color: #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.careers-banner-section:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 80vh;
    background-image: url(../../assets/images/carrer-bg.svg);
    background-position: top center;
    background-size: 100% 100%;
    z-index: 1;
    background-repeat: no-repeat;
}

.careers-banner-section .careers-banner-inner {
    position: relative;
    z-index: 10;
}

.careers-banner-section .careers-banner-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    max-width: 650px;
    margin: -100px auto 80px;
}

.careers-banner-section .careers-banner-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.careers-banner-section .careers-banner-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.careers-banner-section .careers-banner-inner .heading h2 {
    line-height: 60px;
}

.careers-banner-section .careers-banner-inner .heading h2 {
    max-width: 716px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.careers-banner-section .careers-banner-inner .heading .btn {
    margin-top: 22px;
    font-weight: 500;
}

.careers-banner-section .careers-banner-inner .top-baner-images {
    position: relative;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.careers-banner-section .careers-banner-inner .top-baner-images .img-box {
    box-shadow: 0px 4.7566137314px 6.2104377747px 0px rgba(0, 134, 230, 0.0072);
    box-shadow: 0px 20.9291000366px 12.8592586517px 0px rgba(0, 134, 230, 0.0118);
    box-shadow: 0px 51.3714256287px 25.6454544067px 0px rgba(0, 134, 230, 0.015);
    box-shadow: 0px 98.9375686646px 50.2680130005px 0px rgba(0, 134, 230, 0.0182);
    box-shadow: 0px 166.4814758301px 92.4259262085px 0px rgba(0, 134, 230, 0.0228);
    box-shadow: 0px 256.8571472168px 157.8181762695px 0px rgba(0, 134, 230, 0.03);
}

.careers-banner-section .careers-banner-inner .top-baner-images .img-box img {
    width: auto;
    backdrop-filter: blur(13.6883px);
    background: linear-gradient(284.09deg, #e4faff 1.74%, rgba(238, 248, 255, 0.6) 31.03%, #ffffff 107.07%);
    background-blend-mode: overlay, normal;
    border-radius: 20px;
}

.careers-banner-section .careers-banner-inner .top-baner-images .image-one img {
    border-radius: 12px;
}

.careers-banner-section .careers-banner-inner .bottom-baner-images {
    position: relative;
    bottom: -20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.careers-banner-section .careers-banner-inner .bottom-baner-images .img-box {
    box-shadow: 0px 4.7566137314px 6.2104377747px 0px rgba(0, 134, 230, 0.0072);
    box-shadow: 0px 20.9291000366px 12.8592586517px 0px rgba(0, 134, 230, 0.0118);
    box-shadow: 0px 51.3714256287px 25.6454544067px 0px rgba(0, 134, 230, 0.015);
    box-shadow: 0px 98.9375686646px 50.2680130005px 0px rgba(0, 134, 230, 0.0182);
    box-shadow: 0px 166.4814758301px 92.4259262085px 0px rgba(0, 134, 230, 0.0228);
    box-shadow: 0px 256.8571472168px 157.8181762695px 0px rgba(0, 134, 230, 0.03);
}

.careers-banner-section .careers-banner-inner .bottom-baner-images .img-box img {
    width: auto;
    backdrop-filter: blur(13.6883px);
    background: linear-gradient(284.09deg, #e4faff 1.74%, rgba(238, 248, 255, 0.6) 31.03%, #ffffff 107.07%);
    background-blend-mode: overlay, normal;
    border-radius: 20px;
}

.careers-banner-section .careers-banner-inner .bottom-baner-images .image-four {
    margin-top: 50px;
    margin-right: 70px;
}

.careers-banner-section .careers-banner-inner .bounce-animate {
    animation-name: float-bob;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.careers-banner-section .careers-banner-inner .bounce-animate2 {
    animation-name: float-bob2;
    animation-duration: 3.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob2;
    -moz-animation-duration: 3.6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob2;
    -ms-animation-duration: 3.6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob2;
    -o-animation-duration: 3.6s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes float-bob {
    0% {
        -webkit-transform: translateY(-22px);
        transform: translateY(-22px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(-22px);
        transform: translateY(-22px);
    }
}

@-webkit-keyframes float-bob2 {
    0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

.careers-contributer-section {
    padding: 100px 0 150px;
    min-height: 450px;
    display: flex;
    align-items: center;
    background-color: #fff;
    background-color: #fff;
    position: relative;
}

.careers-contributer-section .carrer-contributer-inner {
    position: relative;
    z-index: -1;
}

.careers-contributer-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/careers-contributer-bg.svg);
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    z-index: 1;
}

.careers-contributer-section .secton-titel h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-shadow: 0px 21px 72px #ffffff;
    text-align: center;
    text-transform: capitalize;
    max-width: 450px;
    margin: 0 auto 10px;
    color: #040405;
    color: #040405;
}

.careers-contributer-section .secton-titel h2 {
    font-weight: 900;
    font-size: 60px;
    line-height: 150%;
    text-transform: uppercase;
    text-align: center;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 30px;
}

.careers-contributer-section .contributer-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.careers-contributer-section .contributer-list>div {
    border: 1px solid #8dd0ff;
    padding: 8px 8px 8px 35px;
    position: relative;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #040405;
    color: #040405;
}

.careers-contributer-section .contributer-list>div::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: 15px;
    top: 50%;
    margin-top: -4px;
    border-radius: 2.19118px;
    transform: rotate(45deg);
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.careers-contributer-section .contributer-list>div:nth-child(even) {
    border: 1px solid #ccfa4e;
}

.careers-contributer-section .contributer-list>div:nth-child(even)::after {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
}

.careers-contributer-section .contributer-list .develop-contributer.d0 {
    top: 20%;
    left: 26%;
    right: auto;
    bottom: auto;
}

.careers-contributer-section .contributer-list .develop-contributer.d2 {
    left: 23%;
    top: 65%;
    right: auto;
    bottom: auto;
}

.careers-contributer-section .contributer-list .develop-contributer.d3 {
    bottom: 10%;
    left: 33%;
    top: auto;
    right: auto;
}

.careers-contributer-section .contributer-list .develop-contributer.d1 {
    top: 20%;
    right: 22%;
    bottom: auto;
    left: auto;
}

.careers-contributer-section .contributer-list .develop-contributer.d4 {
    bottom: 20%;
    right: 26%;
    top: auto;
    left: auto;
}

.news-letter-section {
    padding: 0 0 80px;
    background-color: #fff;
    background-color: #fff;
}

.news-letter-section .news-letter-content {
    background-color: #fff;
    background-color: #fff;
    padding: 40px;
    background-image: url(../../assets/images/news-letter.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    border-radius: 20px;
}

.news-letter-section .news-letter-content h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    color: #040405 !important;
    color: #040405 !important;
}

.news-letter-section .news-letter-content .news-letter-link {
    text-align: right;
}

.news-letter-section .news-letter-content .news-letter-link a {
    font-weight: 500;
    font-size: 30px;
    color: #040405 !important;
    color: #040405 !important;
    padding: 5px 50px;
    display: inline-block;
    vertical-align: middle;
}

.news-letter-section .news-letter-content .news-letter-link a span {
    width: 21px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}

.news-letter-section .news-letter-content .news-letter-link a:first-child {
    border-right: 1px solid #000000;
    padding-left: 0;
}

.news-letter-section .news-letter-content .news-letter-link a:last-child {
    padding-right: 0;
}

.job-positions-section {
    padding: 50px 0 50px;
    background-color: #fff;
    background-color: #fff;
}

.job-positions-section .section-titel h2 {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 40px;
    color: #040405;
    color: #040405;
}

.job-positions-section .section-titel p {
    font-size: 18px;
    text-align: center;
    line-height: 150%;
    color: #616161;
}

.job-positions-section .tro-close-icon {
    right: 0 !important;
}

.job-positions-section .job-searching-section {
    margin-top: 50px;
}

.job-positions-section .job-searching-section form .form-group .form-input {
    position: relative;
}

.job-positions-section .job-searching-section form .form-group .form-input .form-control {
    border-right: 0;
    border-top: 0;
    border-left: 0;
    padding-left: 0;
    height: 60px;
    background-color: #fff;
    color: #040405;
    background-color: #fff;
    color: #040405;
    font-size: 14px;
    line-height: 21px;
}

.job-positions-section .job-searching-section form .form-group .form-input .form-control:focus {
    border-color: #8dd0ff !important;
}

.job-positions-section .job-searching-section form .form-group .form-input .search-icon {
    width: 24px;
    height: 24px;
    top: 50%;
    right: 30px;
    position: absolute;
    transform: translateY(-50%);
    font-weight: 400;
    color: #040405;
    color: #040405;
}

.job-positions-section .job-searching-section form .form-group .form-input .form-select {
    background-size: 19px 19px;
    background-color: #fff;
    background-color: #fff;
    background-image: url(../../assets/images/down-arow.svg);
    color: #040405;
    color: #040405;
    font-size: 14px;
    line-height: 21px;
}

.job-positions-section .job-searching-section form .job-searching-result ul {
    padding: 0;
}

.job-positions-section .job-searching-section form .job-searching-result ul li {
    padding: 20px 0;
    position: relative;
}

.job-positions-section .job-searching-section form .job-searching-result ul li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    opacity: 0.3;
}

.job-positions-section .job-searching-section form .job-searching-result ul li h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #040405;
    color: #040405;
}

.job-positions-section .job-searching-section form .job-searching-result ul li p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #91959b !important;
}

.job-positions-section .job-searching-section form .job-searching-result ul li p span {
    color: #000;
}

.job-positions-section .job-searching-section form .job-searching-result ul li .job-apply-btn {
    text-align: right;
}

.job-positions-section .job-searching-section form .job-searching-result ul li .job-apply-btn .btn {
    transform: none !important;
    transition: none !important;
    min-width: 120px;
}

.job-positions-section .job-searching-section form .job-searching-result ul li .job-apply-btn .btn:hover,
.job-positions-section .job-searching-section form .job-searching-result ul li .job-apply-btn .btn:active,
.job-positions-section .job-searching-section form .job-searching-result ul li .job-apply-btn .btn:focus {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    color: #fff !important;
    border-color: transparent !important;
}

.form-control {
    box-shadow: none !important;
}

.our-culture-section {
    padding: 100px 0 60px;
    background-color: #fff;
    background-color: #fff;
}

.our-culture-section .our-culture-card {
    position: relative;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background-image: url(../../assets/images/our-culture-bg.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 50px;
    position: relative;
    text-align: center;
    border-radius: 24px;
}

.our-culture-section .our-culture-card::after {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    border-radius: 24px;
    z-index: -1;
}

.our-culture-section .our-culture-card .card-tite h2 {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    text-transform: capitalize;
}

.our-culture-section .our-culture-card .card-tite p {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: rgba(97, 97, 97, 1);
}

.our-culture-section .our-culture-card .card-images div {
    width: 100px;
    height: 100px;
    position: absolute;
}

.our-culture-section .our-culture-card .card-images div.img-top-left {
    top: -15%;
    left: 0%;
}

.our-culture-section .our-culture-card .card-images div.img-top-right {
    top: -12%;
    right: 4%;
}

.our-culture-section .our-culture-card .card-images div.img-bottum-right {
    right: 1%;
    bottom: -10%;
}

.our-culture-section .our-culture-card .card-images div.img-bottum-left {
    bottom: -12%;
    left: 2%;
}

.gallery-slider-section {
    padding: 0 0 150px;
    background-color: #fff;
    background-color: #fff;
    overflow: hidden;
}

.gallery-slider-section .gallery-slider {
    margin: 20px 0;
}

.gallery-slider-section .gallery-slider .slick-slide {
    margin: 0 20px;
}

.gallery-slider-section .gallery-slider .slick-slide img {
    border-radius: 24px;
    overflow: hidden;
}

.benefits-section {
    background-color: #fff;
    background-color: #fff;
    padding: 80px 0;
    position: relative;
}

.benefits-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    z-index: -1;
}

.benefits-section .section-titel p {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #91959b !important;
    margin-bottom: 10px;
}

.benefits-section .section-titel h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    text-transform: capitalize;
    color: #040405 !important;
}

.benefits-section .icon-card {
    margin-top: 10px;
}

.benefits-section .icon-card .card {
    padding: 20px;
    margin: 10px 0;
    border: 1px solid rgba(218, 237, 252, 0.6);
    border-radius: 12px;
    background-color: #fff;
    background-color: #fff;
    min-height: calc(100% - 20px);
}

.benefits-section .icon-card .card .icon-img {
    opacity: 0.05;
    border-radius: 12px;
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.benefits-section .icon-card .card .icon-img::after {
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    border-radius: 12px;
    z-index: 1;
}

.benefits-section .icon-card .card .icon-img img {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.benefits-section .icon-card .card .card-content h3 {
    font-weight: 700;
    font-size: 20px;
    color: #040405 !important;
}

.benefits-section .icon-card .card .card-content p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #91959b;
}

.casestudies-allstories-section {
    background-color: #fff;
    background-color: #fff;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading {
    position: relative;
    margin-bottom: 20px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading:before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 86%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name {
    padding-bottom: 20px;
    border-bottom: 2px solid #dbeffd;
    margin-bottom: 40px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul {
    padding: 0;
    margin: 0;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li {
    display: inline-block;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li a {
    font-size: 18px;
    color: #040405;
    color: #040405;
    font-weight: 400;
    line-height: 27px;
    display: inline-block;
    border-radius: 12px;
    padding: 8px 10px;
    margin-bottom: 15px;
    margin-right: 10px;
    border: 1px solid #8dd0ff;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li:nth-child(odd) a {
    border-color: #8dd0ff;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li:nth-child(even) a {
    border: 1px solid #8abc00;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group {
    justify-content: end;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group .form-control {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 0px;
    padding: 15px 15px;
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    max-width: 350px;
    color: #040405;
    color: #040405;
    box-shadow: none !important;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group .form-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group .input-group-text {
    border: 0px;
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 15px 15px;
    color: #0086e6;
    font-size: 18px;
}

.casestudies-allstories-section .casestudies-allstories-inner nav {
    margin-top: 40px;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination {
    justify-content: center;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination li {
    background-color: #fff !important;
    background-color: #fff !important;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination li .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    transition: none !important;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination li .page-link:hover .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination .page-item {
    background-color: #fff;
    background-color: #fff;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination .page-item .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    transition: none !important;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination .page-item.selected .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.casestudies-allstories-section .casestudies-allstories-inner nav .pagination .page-item:hover .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-div {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 0px;
    margin-bottom: 20px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card {
    border-radius: 12px;
    background-color: #fff;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-img {
    height: 218px;
    border-radius: 12px;
    overflow: hidden;
    height: 718px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content {
    padding: 15px 140px 20px 15px;
    position: absolute;
    bottom: 0px;
    margin: 0;
    border-radius: 12px;
    width: 100%;
    background-color: #fff;
    background-color: #fff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content .tag {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
    border-radius: 6px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #fff;
    margin-bottom: 15px;
    display: inline-block;
    font-weight: 500;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 14px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content img.csimage {
    width: auto;
    height: auto;
    max-height: 20px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small {
    margin-bottom: 0px;
    width: 100%;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small .casestudies-img {
    height: 345px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small .casestudies-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small .casestudies-content {
    padding: 15px 15px 15px 15px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small .casestudies-content h3 {
    font-size: 18px;
    line-height: 27px;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.div-grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    grid-template-columns: repeat(2, 4fr);
    column-gap: 20px;
}

.div-grid-row .div-grid-col:nth-of-type(1) {
    grid-column: 1/2;
    grid-row: 1/3;
}

.div-grid-row .div-grid-col:nth-of-type(1) .casestudies-card-small .casestudies-img {
    height: 718px !important;
}

.div-grid-row .div-grid-col:nth-of-type(8n) {
    grid-column: 1/2;
    grid-row: 7/5;
}

.div-grid-row .div-grid-col:nth-of-type(8n) .casestudies-card-small .casestudies-img {
    height: 718px !important;
}

.casestudies-section {
    padding: 20px 0 40px 0;
    background-color: #fff;
    background-color: #fff;
}

.casestudies-section .casestudies-inner .casestudies-content {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    text-align: center;
    border-radius: 24px;
    padding: 50px 0px 50px 0;
}

.casestudies-section .casestudies-inner .casestudies-content .heading {
    max-width: 920px;
    margin: 0 auto 20px auto;
    text-align: center;
    margin-bottom: 20px;
}

.casestudies-section .casestudies-inner .casestudies-content .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.casestudies-section .casestudies-inner .casestudies-content .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.casestudies-section .casestudies-inner .casestudies-content .heading h2 {
    line-height: 60px;
}

.casestudies-section .casestudies-inner .casestudies-content .heading h2 span {
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 800;
}

.casestudies-section .casestudies-inner .casestudies-content .heading h3 {
    max-width: 730px;
    margin: 0 auto;
}

.casestudies-section .casestudies-inner .casestudies-content .btn-primary {
    font-size: 16px;
    font-weight: 600;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.casestudies-stories-section {
    background-color: #fff;
    background-color: #fff;
    padding: 40px 0px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading {
    position: relative;
    margin-bottom: 20px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading:before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 86%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card-div {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 0px;
    margin-bottom: 20px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card {
    border-radius: 12px;
    background-color: #fff;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img {
    height: 218px;
    border-radius: 12px;
    overflow: hidden;
    height: 718px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    padding: 1px !important;
    border-radius: 12px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 30px);
    margin: 0 15px 15px 15px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 15px 140px 15px 15px;
    border-radius: 12px;
    width: 100%;
    background-color: #fff;
    background-color: #fff;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .tag {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
    border-radius: 6px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #040405;
    color: #040405;
    margin-bottom: 16px;
    display: inline-block;
    font-weight: 500;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 14px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content img.csimage {
    width: auto;
    height: auto;
    max-height: 20px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card-small {
    margin-bottom: 0px;
    width: 100%;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card-small .casestudies-img {
    height: 345px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card-small .casestudies-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card-small .casestudies-content {
    padding: 15px 15px 15px 15px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-card-small .casestudies-content h3 {
    font-size: 18px;
    line-height: 27px;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.testimonial-section {
    padding: 80px 0 30px 0;
    background-color: #fff;
    background-color: #fff;
    overflow: hidden;
}

.testimonial-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 50px;
}

.testimonial-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.testimonial-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.testimonial-section .testimonial-main-box {
    padding: 44px 38px 26px 32px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    border-radius: 12px;
    position: relative;
    margin-bottom: 35px;
}

.testimonial-section .testimonial-main-box::before {
    content: "";
    background-image: url(../../assets/images/testimonial-bg-shape.png);
    width: 50px;
    height: 37px;
    position: absolute;
    right: 31px;
    top: 36px;
}

.testimonial-section .testimonial-main-box .star-list {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: -2px 0 0 0;
    list-style: none;
    margin-bottom: 22px;
}

.testimonial-section .testimonial-main-box .star-list li {
    margin-right: 13px;
}

.testimonial-section .testimonial-main-box .star-list li:last-child {
    margin: 0;
}

.testimonial-section .testimonial-main-box .testimonial-text {
    margin-bottom: 22px;
}

.testimonial-section .testimonial-main-box .testimonial-text p {
    font-size: 30px;
    line-height: 45px;
    font-weight: 600;
    color: #00011c;
}

.testimonial-section .testimonial-main-box .coordinator {
    padding-top: 10px;
    margin-bottom: 18px;
}

.testimonial-section .testimonial-main-box .coordinator span {
    font-size: 20px;
    line-height: 30px;
    color: #00011c;
}

.testimonial-section .swiper-slide {
    opacity: 0.06;
    opacity: 0.06;
    transition: opacity 0.3s ease-in-out;
}

.testimonial-section .swiper-slide .testimonial-main-box {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
}

.testimonial-section .swiper-slide-active {
    opacity: 1;
}

.testimonial-section .swiper-slide-active .testimonial-main-box {
    transform: scale(0.99);
    transition: transform 0.3s ease-in-out;
}

.testimonial-section .gallery-thumbs {
    padding: 10px 0 50px 0;
}

.testimonial-section .gallery-thumbs img {
    width: 100%;
    object-fit: contain;
}

.testimonial-section .gallerythumbs {
    padding-top: 20px;
}

.testimonial-section .gallerythumbs .img-div {
    height: 32px;
    min-width: 32px;
    border-radius: 5px;
    margin: 0 20px;
    max-width: 32px;
    transition: all 0.3s;
}

.testimonial-section .gallerythumbs .img-div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.testimonial-section .slick-active {
    opacity: 1;
}

.testimonial-section .slick-active .testimonial-main-box {
    transform: scale(1);
}

.testimonial-section .slick-slide .testimonial-main-box {
    transform: scale(0.9);
}

.testimonial-section .slick-active {
    transition: all 0.5s;
}

.testimonial-section .slick-active .img-div {
    height: 52px !important;
    min-height: 50px;
    width: 52px;
    min-width: 52px;
    margin-top: -15px;
    max-width: 52px;
    transition: all 0.5s;
}

.testimonial-section .slick-active .img-div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.testimonial-section .gallery-thumbs .swiper-slide {
    width: 34px;
    height: 34px;
    margin: 0 15px;
    overflow: hidden;
    opacity: 0.55;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s linear;
    border: 1px solid rgb(227, 227, 227);
}

.testimonial-section .gallery-thumbs .swiper-slide img {
    width: 32px;
    height: 32px;
    transition: all 0.3s linear;
}

.testimonial-section .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
    width: 52px;
    height: 52px;
    margin-top: -10px;
    border-radius: 10px;
    border: 2px solid #0086e6;
    transition: all 0.3s linear;
}

.testimonial-section .gallery-thumbs .swiper-slide-thumb-active img {
    width: 48px;
    height: 48px;
    transition: all 0.3s linear;
}

.gallery-thumbs .swiper-wrapper {
    transform: none !important;
    justify-content: center;
    min-height: 50px;
}

.case-studies-blog-section {
    padding-top: 80px;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-blog-section .case-studies-blog-inner .case-studies-heading h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
}

.case-studies-blog-section .case-studies-blog-inner .left-blog-content .case-studies-heading h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #040405;
    color: #040405;
}

.case-studies-blog-section .case-studies-blog-inner .left-blog-content .case-studies-heading p {
    color: #616161;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 20px;
}

.case-studies-blog-section .case-studies-blog-inner .right-blog-img {
    border-radius: 12px;
    overflow: hidden;
}

.case-studies-about-section {
    padding-top: 35px;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-about-section .case-studies-wrapper {
    padding-bottom: 40px;
}

.case-studies-about-section .case-studies-wrapper .case-studies-heading h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
}

.case-studies-about-section .case-studies-wrapper .case-studies-content p {
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin: 0;
}

.case-studies-about-section .case-studies-listing {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(141, 208, 255, 0.4);
}

.case-studies-about-section .case-studies-listing .share-icon {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img li {
    margin-right: 16px;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img li p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
    margin-right: 18px;
    color: #616161;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img li a span {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid #616161;
    display: flex;
    align-items: center;
    justify-content: center;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img li a span img {
    width: auto;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img li:nth-child(2) a span {
    border: none;
}

.case-studies-about-section .case-studies-listing .client-list h5 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin-bottom: 10px;
}

.case-studies-about-section .case-studies-listing .client-list p {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin: 0;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.case-studies-about-section .case-studies-listing .client-list p span {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%), linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.case-studies-section {
    padding: 50px 0 35px 0;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-section .case-studies-inner .case-studies-heading {
    padding-bottom: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.case-studies-section .case-studies-inner .case-studies-heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.case-studies-section .case-studies-inner .case-studies-heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.case-studies-section .case-studies-inner .case-studies-heading h2 {
    line-height: 60px;
}

.case-studies-section .case-studies-inner .case-studies-heading h2 {
    text-align: left;
}

.case-studies-section .case-studies-inner .case-studies-content {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    text-align: center;
    border-radius: 24px;
    padding: 50px 0px 70px;
}

.case-studies-section .case-studies-inner .case-studies-content .heading {
    max-width: 580px;
    margin: 0 auto 20px auto;
    text-align: center;
    margin-bottom: 20px;
}

.case-studies-section .case-studies-inner .case-studies-content .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.case-studies-section .case-studies-inner .case-studies-content .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.case-studies-section .case-studies-inner .case-studies-content .heading h2 {
    line-height: 60px;
}

.case-studies-section .case-studies-inner .case-studies-content .heading h3 span {
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 800;
}

.case-studies-section .case-studies-inner .case-studies-content .btn-primary {
    font-size: 16px;
    font-weight: 600;
}

.case-studies-section .case-studies-inner .case-studies-img {
    border-radius: 12px;
    overflow: hidden;
}

.case-studies-overview-section {
    padding-top: 80px;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-overview-section .case-studies-overview-inner .left-content-overview .overview-heading h3 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.case-studies-overview-section .case-studies-overview-inner .right-content-overview p {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin: 0;
}

.case-studies-process-section {
    padding-top: 80px;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper {
    padding-bottom: 55px;
    border-bottom: 1px solid rgba(141, 208, 255, 0.4);
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-wrapper {
    padding-bottom: 40px;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-wrapper .process-banner-image {
    border-radius: 12px;
    overflow: hidden;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading h3 {
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading p {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    color: #616161;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .process-listing li {
    color: #616161;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    position: relative;
    padding-left: 15px;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .process-listing li::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #616161;
    display: block;
    left: 0;
    top: 10px;
    border-radius: 50px;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .plateform-content {
    padding-top: 20px;
}

.case-studies-country-section {
    padding-top: 80px;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-country-section .case-studies-country-inner .case-studies-heading {
    padding-bottom: 40px;
}

.case-studies-country-section .case-studies-country-inner .case-studies-heading .case-studies-heading h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
}

.case-studies-country-section .case-studies-country-inner .cloud-content {
    padding-bottom: 40px;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-btn .btn-outline-primary {
    padding: 8px 8px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #040405;
    color: #040405;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 138px;
    width: 138px;
    border: 1px solid #0086e6;
    margin-bottom: 18px;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-btn .btn-outline-primary:hover {
    background-color: transparent;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-heading h2 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 15px;
    max-width: 85%;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing {
    padding-bottom: 20px;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 11px 10px;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul li {
    padding-right: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul li:last-child {
    padding-right: 0;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul li h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin: 0;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul li span {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    display: block;
    transform: rotate(45deg);
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-text p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #616161;
    margin-bottom: 20px;
}

.case-studies-country-section .case-studies-country-inner .cloud-content .developer-right-image {
    border-radius: 12px;
    overflow: hidden;
}

.case-studies-team-section {
    padding-top: 80px;
    background-color: #fff;
    background-color: #fff;
}

.case-studies-team-section .case-studies-team-inner .case-studies-heading {
    margin-bottom: 60px;
}

.case-studies-team-section .case-studies-team-inner .case-studies-heading h3 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
}

.case-studies-team-section .case-studies-team-inner .startup-main-content {
    padding-bottom: 70px;
    border-bottom: 1px solid rgba(141, 208, 255, 0.4);
}

.case-studies-team-section .case-studies-team-inner .startups-app-card {
    text-align: center;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app-tittle {
    margin-bottom: 44px;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app-tittle span {
    font-size: 18px;
    color: #616161;
    font-weight: 500;
    line-height: 27px;
    position: relative;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app-tittle span::after {
    content: "";
    background: #616161;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 7px;
    left: -24px;
    transform: rotate(45deg);
    border-radius: 2px;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app-tittle span::before {
    content: "";
    background: #616161;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 7px;
    right: -24px;
    transform: rotate(45deg);
    border-radius: 2px;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app .startups-app-img img {
    width: auto;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app .startups-app-img p {
    font-size: 15px;
    color: #616161;
    font-weight: 500;
    line-height: 22px;
    margin-top: 20px;
    opacity: 0;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card:hover .startups-app-tittle span {
    color: #040405;
    color: #040405;
    font-weight: 700;
    font-size: 20px;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card:hover .startups-app-tittle span::after {
    content: "";
    width: 100px;
    height: 12px;
    position: absolute;
    top: 7px;
    left: -100px;
    background: transparent;
    background-image: url(../../assets/images/line2.png);
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-size: auto;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card:hover .startups-app-tittle span::before {
    content: "";
    width: 100px;
    height: 12px;
    position: absolute;
    top: 7px;
    right: 0px;
    background: transparent;
    background-image: url(../../assets/images/line1.png);
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-size: auto;
    right: -100px;
}

.case-studies-team-section .case-studies-team-inner .startups-app-card:hover .startups-app .startups-app-img p {
    font-size: 15px;
    color: #616161;
    font-weight: 500;
    line-height: 22px;
    opacity: 1;
}

.case-studies-team-section .case-studies-team-inner .active .startups-app-tittle span {
    color: #040405;
    color: #040405;
    font-weight: 700;
    font-size: 20px;
}

.case-studies-team-section .case-studies-team-inner .active .startups-app-tittle span::after {
    content: "";
    width: 100px;
    height: 12px;
    position: absolute;
    top: 7px;
    left: -100px;
    background: transparent;
    background-image: url(../../assets/images/line2.png);
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-size: auto;
}

.case-studies-team-section .case-studies-team-inner .active .startups-app-tittle span::before {
    content: "";
    width: 100px;
    height: 12px;
    position: absolute;
    top: 7px;
    right: 0px;
    background: transparent;
    background-image: url(../../assets/images/line1.png);
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-size: auto;
    right: -100px;
}

.case-studies-team-section .case-studies-team-inner .active .startups-app .startups-app-img p {
    font-size: 15px;
    color: #616161;
    font-weight: 500;
    line-height: 22px;
    opacity: 1;
}

.blog-allstories-section {
    background-color: #fff;
    background-color: #fff;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading {
    position: relative;
    margin-bottom: 20px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading:before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 86%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-heading h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name {
    padding-bottom: 20px;
    border-bottom: 2px solid #dbeffd;
    margin-bottom: 40px;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul {
    padding: 0;
    margin: 0;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li {
    display: inline-block;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li a {
    font-size: 14px;
    color: #040405;
    color: #040405;
    font-weight: 500;
    line-height: 20px;
    display: inline-block;
    border-radius: 8px;
    padding: 6px 10px;
    margin-bottom: 6px;
    margin-right: 6px;
    border: 1px solid #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(odd) a {
    border-color: #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(even) a {
    border: 1px solid #8abc00;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group {
    justify-content: end;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .form-control {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 0px;
    padding: 15px 15px;
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    max-width: 350px;
    color: #040405;
    color: #040405;
    box-shadow: none !important;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .form-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .input-group-text {
    border: 0px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 15px 15px;
    color: #0086e6;
    font-size: 18px;
}

.blog-allstories-section .blog-allstories-inner .blog-card-div {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 1px;
    margin-bottom: 40px;
}

.blog-allstories-section .blog-allstories-inner .blog-card {
    background-color: #fff;
    background-color: #fff;
    border-radius: 12px;
    height: 100%;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-img {
    height: 218px;
    border-radius: 12px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    min-height: 92px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #040405;
    color: #040405;
    // min-height: 68px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 span {
    font-weight: 400;
    color: #040405;
    color: #040405;
    margin-right: 8px;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul li {
    width: auto;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul li span {
    font-size: 12px;
    line-height: 18px;
    color: #616161;
}

.blog-allstories-section .blog-allstories-inner nav {
    margin-top: 40px;
}

.blog-allstories-section .blog-allstories-inner nav .pagination {
    justify-content: center;
}

.blog-allstories-section .blog-allstories-inner nav .pagination li {
    background-color: #fff !important;
    background-color: #fff !important;
}

.blog-allstories-section .blog-allstories-inner nav .pagination li .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    transition: none !important;
}

.blog-allstories-section .blog-allstories-inner nav .pagination li .page-link:hover .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item {
    background-color: #fff;
    background-color: #fff;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item .page-link {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    border: 1px solid rgba(97, 97, 97, 0.2);
    border-radius: 4px;
    margin: 0px 4px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    transition: none !important;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item.selected .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.blog-allstories-section .blog-allstories-inner nav .pagination .page-item:hover .page-link {
    background: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, #8dd0ff 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: #8dd0ff;
}

.theme-dark .blog-card-div {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
}

.findus-section {
    padding: 80px 0;
    position: relative;
    background-color: #fff;
    background-color: #fff;
}

.findus-section:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 65%;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    z-index: 1;
}

.findus-section .findus-inner {
    position: relative;
    z-index: 9;
}

.findus-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 80px;
}

.findus-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.findus-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 700;
}

.findus-section .heading h2 {
    line-height: 60px;
}

.findus-section .heading p {
    max-width: 737px;
    margin: 0 auto;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #616161;
}

.findus-section .find-card {
    border: 1px solid;
    border-color: rgba(0, 134, 230, 0.2) rgba(141, 208, 255, 0.2);
    position: relative;
    height: 100%;
    background-color: #fff;
    background-color: #fff;
    transition: all 0.3s;
    border-radius: 12px;
}

.findus-section .find-card:hover {
    border-color: rgb(0, 134, 230) rgb(141, 208, 255);
    transition: all 0.3s;
}

.findus-section .find-card:before {
    background-color: #fff;
    content: "";
    border: 5px solid;
    border-color: rgb(0, 134, 230);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    transition: all 0.3s;
}

.findus-section .find-card:hover::before {
    opacity: 1;
    transition: all 0.3s;
}

.findus-section .find-card .find-image {
    position: relative;
    height: 280px;
    max-height: 280px;
}

.findus-section .find-card .find-image .find-content {
    position: absolute;
    bottom: -28px;
    left: 0;
    right: 0;
    text-align: center;
}

.findus-section .find-card .find-image .find-content p {
    font-size: 28px;
    line-height: 36px;
    color: #fff;
    letter-spacing: 0.41em;
    font-weight: 700;
    margin-bottom: 47px;
}

.findus-section .find-card .find-image .find-content span {
    font-size: 64px;
    color: #fff;
    font-weight: 700;
    opacity: 0.4;
}

.findus-section .find-card .find-image img {
    width: 100%;
}

.findus-section .find-card .find-text {
    padding: 46px 40px 60px 40px;
    min-height: 204px;
}

.findus-section .find-card .find-text p {
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin: 0;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0;
}

.findus-section .find-card:hover p {
    color: #040405;
    color: #040405;
}

.findus-section .black-card {
    border: 1px solid;
    border-color: rgba(0, 1, 26, 0.2) !important;
    position: relative;
    height: 100%;
    transition: all 0.3s;
}

.findus-section .black-card:hover {
    border-color: #040405 !important;
    border-color: #040405 !important;
    transition: all 0.3s;
}

.findus-section .black-card:before {
    content: "";
    background-color: #fff;
    background-color: #fff;
    border: 5px solid;
    border-color: rgba(0, 1, 26, 1) !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    transition: all 0.3s;
}

.findus-section .black-card:hover::before {
    opacity: 1;
    transition: all 0.3s;
}

.findus-section .green-card {
    border: 1px solid;
    border-color: rgba(138, 188, 0, 0.2) rgba(204, 250, 78, 0.2);
    position: relative;
    height: 100%;
    transition: all 0.3s;
}

.findus-section .green-card:hover {
    border-color: rgb(138, 188, 0) rgb(204, 250, 78);
    transition: all 0.3s;
}

.findus-section .green-card:before {
    content: "";
    background-color: #fff;
    border: 5px solid;
    border-color: rgb(138, 188, 0);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    transition: all 0.3s;
}

.findus-section .green-card:hover::before {
    opacity: 1;
    transition: all 0.3s;
}

.findus-section .card-1 .find-card {
    border: 1px solid;
    border-color: rgba(0, 134, 230, 0.2) rgba(141, 208, 255, 0.2) !important;
    position: relative;
    height: 100%;
    transition: all 0.3s;
}

.findus-section .card-1 .find-card:hover {
    border-color: rgb(0, 134, 230) rgb(141, 208, 255) !important;
    transition: all 0.3s;
}

.findus-section .card-1 .find-card:before {
    content: "";
    background-color: #fff;
    background-color: #fff;
    border: 5px solid;
    border-color: rgb(0, 134, 230) !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    transition: all 0.3s;
}

.findus-section .card-1 .find-card:hover::before {
    opacity: 1;
    transition: all 0.3s;
}

.findus-section .card-0 .find-card {
    border: 1px solid;
    border-color: rgba(0, 1, 26, 0.2) !important;
    position: relative;
    height: 100%;
    transition: all 0.3s;
}

.findus-section .card-0 .find-card:hover {
    border-color: #040405 !important;
    border-color: #040405 !important;
    transition: all 0.3s;
}

.findus-section .card-0 .find-card:before {
    content: "";
    background-color: #fff;
    background-color: #fff;
    border: 5px solid;
    border-color: rgba(0, 1, 26, 1) !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    transition: all 0.3s;
}

.findus-section .card-0 .find-card:hover::before {
    opacity: 1;
    transition: all 0.3s;
}

.findus-section .card-2 .find-card {
    border: 1px solid;
    border-color: rgba(138, 188, 0, 0.2) rgba(204, 250, 78, 0.2);
    position: relative;
    height: 100%;
    transition: all 0.3s;
}

.findus-section .card-2 .find-card:hover {
    border-color: rgb(138, 188, 0) rgb(204, 250, 78);
    transition: all 0.3s;
}

.findus-section .card-2 .find-card:before {
    content: "";
    background-color: #fff;
    border: 5px solid;
    border-color: rgb(138, 188, 0);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    transition: all 0.3s;
}

.findus-section .card-2 .find-card:hover::before {
    opacity: 1;
    transition: all 0.3s;
}

.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}

.jvectormap-container {
    background-color: #fff !important;
    background-color: #fff !important;
}

.wordwise-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.wordwise-section .heading {
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
    margin-bottom: 54px;
}

.wordwise-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.wordwise-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.wordwise-section .map-image {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/bg-map.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.wordwise-section .map-image img {
    max-width: 1300px;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    object-position: top;
    object-fit: cover;
}

.wordwise-section .client-map-content {
    position: relative;
    top: -450px;
    height: 0;
}

.wordwise-section .client-map-content .slider-client {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    list-style: none;
}

.wordwise-section .client-map-content .item {
    padding: 24px 24px 28px 24px;
    background: #fff;
    background: #fff;
    border-radius: 12px;
    border: 1px solid transparent;
    text-align: center;
    margin: 0 15px;
    margin-bottom: 0px;
    max-width: 160px;
    min-width: 160px;
    cursor: pointer;
}

.wordwise-section .client-map-content .item:last-child {
    margin-right: 0;
}

.wordwise-section .client-map-content .item:hover {
    border: 1px solid;
    border-color: rgb(0, 134, 230) rgb(141, 208, 255);
    box-shadow: 0px 60.635px 60.308px rgba(0, 0, 0, 0.07), 0px 35.2964px 23.35px rgba(0, 0, 0, 0.0425185), 0px 7.46655px 5.97324px rgba(0, 0, 0, 0.0274815);
}

.wordwise-section .client-map-content .item .flag-img {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 8px;
    overflow: hidden;
}

.wordwise-section .client-map-content .item .flag-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wordwise-section .client-map-content .item p {
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin-bottom: 5px;
}

.wordwise-section .client-map-content .item h2 {
    font-size: 40px;
    line-height: 48px;
    color: #040405;
    font-weight: 500;
    margin-bottom: 0;
}

.wordwise-section .client-map-content .item:hover h2 {
    font-weight: 700;
}

.jvectormap-tip {
    border: solid 1px #0d8de8 !important;
    background: #0d8de8 !important;
    color: #fff !important;
    z-index: 999 !important;
}

.media-section {
    padding: 80px 0;
    background-color: #fff;
}

.media-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 40px;
}

.media-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.media-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 700;
}

.media-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
}

.media-section .nav-tabs {
    border: unset;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 1, 26, 0.2);
    border-bottom: 1px solid rgba(0, 1, 26, 0.2);
    max-width: 480px;
    margin: auto;
    margin-bottom: 40px;
}

.media-section .nav-tabs .nav-item {
    position: relative;
    top: 1px;
    padding-right: 88px;
}

.media-section .nav-tabs .nav-item:last-child {
    padding-right: 0px;
}

.media-section .nav-tabs .nav-item .nav-link {
    color: #616161;
    padding: 0 0 13px 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    border: none;
}

.media-section .nav-tabs .nav-item .active {
    color: #040405 !important;
    background-color: transparent;
    border: none;
    border-bottom: 4px solid;
    border-color: rgb(0, 134, 230) rgb(141, 208, 255);
    font-weight: 700;
}

.media-section .contact-tab {
    min-height: 500px;
}

.media-section .media-tab-card .tabing-card-image img {
    width: 100%;
}

.media-section .media-tab-card .seocnd-tan-img {
    margin-bottom: 28px;
}

.media-section .media-tab-card .linked {
    margin-top: 40px;
    text-align: center;
}

.media-section .media-tab-card .linked .btn {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.media-section .media-tab-card .linked .btn img {
    padding-left: 13px;
    width: auto;
}

.timeline-TweetList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-flow: row dense;
    grid-gap: 1.9vw;
    padding-bottom: 50px;
}

.timeline-Tweet-text {
    font-size: 16px;
    line-height: 24px;
}

.linkedIn iframe {
    min-height: 850px;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

@keyframes ldio-9uukc0wm668 {
    0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
    }
}

.loader-div {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff !important;
}

.theme-dark .loader-div {
    background: #000 !important;
}

.ldio-9uukc0wm668 div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-9uukc0wm668 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-9uukc0wm668 div:nth-child(1) {
    border-color: #0091da;
    animation-delay: 0s;
}

.ldio-9uukc0wm668 div:nth-child(2) {
    border-color: #84bd00;
    animation-delay: -0.5s;
}

.loadingio-spinner-ripple-5xrpd0k7ecq {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ldio-9uukc0wm668 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-9uukc0wm668 div {
    box-sizing: content-box;
}

/***********************home***********************/
/**banner**/
.banner-section {
    padding: 40px 0 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.banner-section .banner-heading {
    padding: 140px 0 210px 0;
}

.banner-section .banner-heading h1 {
    font-weight: 800;
    font-size: 58px;
    line-height: 78px;
    color: #040405;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
}

.banner-section .banner-heading h1 u {
    position: relative;
    z-index: 0;
    text-decoration: none;
}

.banner-section .banner-heading h1 u::after {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    opacity: 1;
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    z-index: -2;
    display: block !important;
    height: 10px;
    // animation: fadeIn 0.35s cubic-bezier(0.4, 0, 0.4, 0) both;
    // animation-delay: 1.5s;
}

.banner-section .banner-heading h1 .text-block {
    display: block;
}

.banner-section .banner-heading .btn {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    z-index: 9;
    position: relative;
}

.banner-section .solution-box {
    background-color: rgba(0, 134, 230, 0.1);
    border-radius: 12px;
    padding: 10px 16px;
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.banner-section .solution-box .icon {
    height: 32px;
    width: 32px;
    border-radius: 10px;
    background-color: rgba(0, 134, 230, 0.04);
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 36px 80px rgba(0, 134, 230, 0.1), 0px 13.8667px 25.4815px rgba(0, 134, 230, 0.0607407), 0px 2.93333px 6.51852px rgba(0, 134, 230, 0.0392593);
}

.banner-section .solution-box .icon img {
    height: 23px;
    width: 23px;
    object-fit: contain;
}

.banner-section .solution-box .text p {
    color: rgb(108, 121, 131);
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 0;
}

.banner-section .solution-box .arrow {
    margin-left: auto;
    background-image: url(../../assets/images/letest-right-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;
    height: 30px;
    width: 30px;
    display: none;
}

.banner-section .solution-box.active .icon {
    background-color: #fff;
}

.banner-section .solution-box.active .arrow {
    display: block;
}

.banner-section .solution-box.active .text p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #040405;
}

.banner-section .solution-box {
    margin: 0 15px;
}

.banner-section .slick-slide:nth-child(2n) .solution-box {
    background: linear-gradient(317.72deg, rgba(138, 188, 0, 0.1) 5.24%, rgba(204, 250, 78, 0.1) 137.29%);
}

.banner-section .slick-slide:nth-last-child(1) .solution-box {
    margin-right: 0 !important;
}

.banner-section .slick-slide:nth-child(1) .solution-box {
    margin-left: 0 !important;
}

.banner-section .banner-img img {
    width: 100%;
}

.banner-section .banner-img .img-banner-image {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
}

.slick-current .solution-box .icon {
    background-color: #fff;
}

.slick-current .solution-box .arrow {
    display: block;
}

.slick-current .solution-box .text p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #040405;
}

.banner-section .banner-heading h1 span.line::after {
    // animation: fadeIn 0.35s cubic-bezier(0.4, 0, 0.4, 0) both;
    // animation-delay: 1.5s;
    z-index: 0;
    opacity: 1;
}

// .splitting .char {
//     display: inline-block;
//     animation: slide-in 0.6s cubic-bezier(0.4, 0, 0.4, 0) both;
//     animation-delay: calc(40ms * var(--char-index));
//     z-index: 1;
// }

@keyframes slide-in {
    from {
        transform: translateY(1.5vh);
        opacity: 0;
    }
}

.slick-slider {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
}

.slick-slider.slick-initialized {
    visibility: visible;
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

/**End banner**/
/**faq**/
.faq-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.industry-middle .faq-section {
    padding: 80px 0 0 0;
}

.faq-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 50px;
}

.faq-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.faq-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.faq-section .accordion {
    min-height: 400px;
}

.industry-middle .faq-section .accordion {
    min-height: unset;
}

.faq-section .accordion .accordion-item {
    background-color: transparent;
    border: 1.51847px solid rgba(97, 97, 97, 0.2);
    border-radius: 12px;
    margin-bottom: 20px;
    overflow: hidden;
}

.faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
    padding-top: 0 !important;
}

.faq-section .accordion .accordion-item .show {
    background-color: #f6faff;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    border-radius: 12px 12px 0 0;
    display: flex;
    align-items: center;
    color: #040405;
    position: relative;
    padding: 24px 50px 20px 24px;
    background-color: #f6faff;
    box-shadow: none !important;
    transition: all 0.3s ease-in-out;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button span {
    max-width: 450px;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
    content: "";
    background-image: url(../../assets/images/accordion-minus.svg);
    font-size: 60px;
    position: absolute;
    right: 25px;
    top: 15px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    align-items: center;
    color: #616161;
    background-size: 16px;
    transition: none;
    z-index: 99;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    position: relative;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: rgba(97, 97, 97, 1);
    border-radius: 12px;
    background-color: transparent;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    content: "";
    background-image: url(../../assets/images/accordion-plus.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    top: 30px;
    right: 18px;
    font-weight: 400;
    font-size: 40px;
}

.faq-section .accordion .accordion-item .accordion-collapse {
    background-color: #f6faff;
    transition: all 0.3s linear !important;
}

.faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    border-radius: 0 0 12px 12px;
    text-align: left;
    color: rgba(97, 97, 97, 1);
}

.faq-section .accordion .accordion-item .accordion-collapse .accordion-body span {
    display: block;
    max-width: 460px;
}

.theme-dark .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    transition: all 0.3s ease-in-out;
}

.theme-dark .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    background-image: url(../../assets/images/accordion-plus-dark.svg);
}

.theme-dark .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
    background-image: url(../../assets/images/accordion-minus-dark.svg);
}

.faq-section .accordion .accordion-item.active {
    border-color: #f6faff !important;
    background-color: #f6faff !important;
}

/**End faq**/
/**hiredeveloper**/
.hiredeveloper-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.hiredeveloper-section .developer-circle {
    border-radius: 100%;
    height: 870px;
    width: 870px;
    margin: 0 auto;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.03) 5.56%, rgba(141, 208, 255, 0.03) 113.66%);
    position: relative;
}

.hiredeveloper-section .developer-circle .developer-circle-inner {
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
    border-radius: 100%;
    height: 730px;
    width: 730px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.hiredeveloper-section .developer-circle .developer-circle-inner .content {
    padding: 20px 70px;
    text-align: center;
    margin: 20px 0;
}

.hiredeveloper-section .developer-circle .developer-circle-inner .content h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    color: #00011c;
    margin-bottom: 30px;
}

.hiredeveloper-section .developer-circle .developer-circle-inner marquee p {
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #cbd3d8;
    margin: 0 auto 15px auto;
}

.hiredeveloper-section .developer-circle .developer-circle-inner marquee p span {
    margin: 0 25px;
    position: relative;
}

.hiredeveloper-section .developer-circle .developer-circle-inner marquee p span:hover {
    text-decoration: underline;
}

.hiredeveloper-section .developer-circle .developer-circle-inner marquee p span::after {
    content: "";
    height: 8px;
    width: 8px;
    background: #c4c4c4;
    display: block;
    border-radius: 100px;
    position: absolute;
    right: -25px;
    top: 25px;
    opacity: 0.2;
}

.hiredeveloper-section .developer-circle .developer-circle-inner .hire-developer {
    margin-bottom: 10px;
    position: relative;
    z-index: 10;
}

.hiredeveloper-section .developer-circle .developer-circle-inner .hire-developer a,
.hiredeveloper-section .developer-circle .developer-circle-inner .hire-developer span {
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #cbd3d8;
    padding: 0 22px;
    position: relative;
    z-index: 999;
}

.hiredeveloper-section .developer-circle .developer-circle-inner .marquee-top {
    margin-top: 15px;
}

/**End hiredeveloper**/
/**process**/
.process-section {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.process-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 120px;
}

.process-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.process-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.process-section .process-list {
    padding-top: 0;
    padding-bottom: 85px;
    max-width: 1085px;
    margin: 0 auto;
}

.process-section .process-list ul {
    padding: 0;
    margin: 0;
}

.process-section .process-list ul li {
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    color: rgba(97, 97, 97, 1);
    text-align: center;
    width: 49%;
    display: inline-block;
    margin-bottom: 38px;
    position: relative;
}

.process-section .process-list ul li:first-child {
    width: 100%;
    padding-left: 20px;
}

.process-section .process-list ul li:first-child::after {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/list-line1.svg");
    background-repeat: no-repeat;
    background-size: auto;
    width: 200px;
    height: 111px;
    right: 60px;
    top: 25px;
}

.process-section .process-list ul li:nth-child(2) {
    text-align: right;
    padding-right: 30px;
}

.process-section .process-list ul li:nth-child(2)::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/list-line2.svg");
    background-repeat: no-repeat;
    width: 148px;
    height: 107px;
    background-size: contain;
    left: 40px;
    top: 25px;
}

.process-section .process-list ul li:nth-child(3) {
    text-align: center;
    padding-right: 80px !important;
}

.process-section .process-list ul li:nth-child(3)::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/fourth-list-line-white.svg");
    width: 70px;
    height: 2px;
    left: -5px;
    top: 30px;
}

.process-section .process-list ul li:nth-child(4) {
    text-align: right;
    padding-right: 30px;
}

.process-section .process-list ul li:nth-child(5) {
    text-align: center;
    padding-right: 100px !important;
}

.process-section .process-list ul li:nth-child(5)::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/fourth-list-line-white.svg");
    width: 70px;
    height: 2px;
    left: -5px;
    top: 30px;
}

.process-section .process-list ul li:nth-child(5)::after {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/list-line3.svg");
    width: 203px;
    background-size: contain;
    height: 111px;
    right: 25px;
    top: 25px;
}

.process-section .process-list ul li:last-child {
    width: 100%;
}

.process-section .process-list ul .active {
    color: #040405 !important;
    font-weight: 600;
}

.process-section .process-list ul .active:first-child::after {
    background-image: url("../../assets/images/third-list-line-blue.svg");
}

.process-section .process-text p {
    text-align: center;
    max-width: 762px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #040405 !important;
    margin: auto;
}

.process-section .list-fetaure div:nth-child(2n) .Service-feature {
    padding-left: 40px !important;
}

.reveal-none {
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.reveal-none.reveal-0 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important;
}

.reveal-none.reveal-0::after {
    background-image: url(../../assets/images/list-line-blue1.svg);
}

.reveal-none.reveal-1 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important;
}

.reveal-none.reveal-1::before {
    background-image: url(../../assets/images/list-line-blue2.svg);
}

.reveal-none.reveal-2 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important;
}

.reveal-none.reveal-2::before {
    background-image: url(../../assets/images/list-line-blue3.svg);
}

.reveal-none.reveal-3 {
    opacity: 1;
    transform: translateY(0);
    font-weight: 600 !important;
    color: #040405 !important;
}

.reveal-none.reveal-4 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important;
}

.reveal-none.reveal-4::before {
    background-image: url(../../assets/images/list-line-blue3.svg);
}

.reveal-none.reveal-4:after {
    background-image: url(../../assets/images/list-line-blue3.svg);
}

.reveal-none.reveal-5 {
    opacity: 1;
    color: #040405 !important;
    font-weight: 600 !important;
}

/**End process**/
/**services**/
.theme-dark {
    --service-layer: rgba(0, 0, 0, 0.15);
}

.theme-light {
    --service-layer: rgba(255, 255, 255, 0.15);
}

.service-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.service-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 40px;
}

.service-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.service-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.service-section .service-bg-image {
    padding-top: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px !important;
    z-index: 0;
    overflow: hidden;
    transition: background-image 0.45s ease-in-out;
}

.service-section .block-img {
    background-image: url("../../assets/images/development-img.png");
    position: relative;
}

.service-section .block-img::before {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.15);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
}

.service-section .block-img:hover::before {
    opacity: 1;
}

.service-section .service-feature {
    padding: 15px;
    margin-bottom: 55px;
    min-height: 330px;
    display: block;
    width: 100%;
    position: relative;
    text-decoration: none;
}

.service-section .service-feature:before {
    content: "";
    background-image: url(../../assets/images/icons/custom-arrow.svg);
    position: absolute;
    background-size: 14px;
    right: 40px;
    top: 30px;
    height: 20px;
    width: 25px;
    display: block;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 9;
}

.service-section .service-feature h3 {
    max-width: 310px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    color: #040405;
    margin-bottom: 12px;
}

.service-section .service-feature .max-280 {
    max-width: 280px;
}

.service-section .service-feature p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: rgba(97, 97, 97, 1);
    max-width: 445px;
}

.service-section .service-feature .service-tools {
    padding: 0;
    margin: 0;
    display: none;
    transition: all 0.3s ease-in-out;
}

.service-section .service-feature .service-tools li {
    position: relative;
    background: #fff;
    background: #fff;
    background-clip: padding-box;
    padding: 11px 16px;
    border: solid 1px transparent;
    border-radius: 8px;
    color: #00011c;
    display: inline-block;
    margin-right: 6px;
    height: 36px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.service-section .service-feature .service-tools li:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    /* same as border width */
    border-radius: 8px;
    /* inherit container box's radius */
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.service-section .service-feature:nth-last-child(0) {
    margin-bottom: 0;
}

.service-section .service-feature:nth-last-child(1) {
    margin-bottom: 0;
}

.service-section .list-fetaure div .service-feature {
    position: relative;
}

.service-section .list-fetaure div .service-feature .service-feature-inner {
    position: relative;
    z-index: 9;
}

.service-section .list-fetaure div .service-feature:after {
    content: "";
    display: none;
    position: absolute;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    background: #fff;
    background: #fff;
    box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04);
    z-index: 0;
}

.service-section .list-fetaure div .service-feature:before {
    opacity: 0;
}

.service-section .list-fetaure div .service-feature:hover .service-tools {
    display: block;
    transition: all 0.6s ease-in-out;
}

.service-section .list-fetaure div .service-feature:hover:after {
    display: block;
    transition: all 0.6s ease-in-out;
}

.service-section .list-fetaure div .service-feature:hover:before {
    opacity: 1;
}

.service-section .list-fetaure div:nth-child(2n) .service-feature {
    padding-left: 40px;
}

.service-section .list-fetaure div:nth-child(1) .service-feature:after {
    border-radius: 0 0 200px 0 !important;
}

.service-section .list-fetaure div:nth-child(2):hover .service-feature:after {
    left: auto !important;
    border-radius: 0 0 0 200px !important;
}

.service-section .list-fetaure div:nth-child(odd):hover .service-feature:after {
    top: -60px;
    right: -46px;
    left: auto !important;
    border-radius: 0 200px 200px 0;
}

.service-section .list-fetaure div:nth-child(even):hover .service-feature:after {
    top: -60px;
    right: -46px;
    left: auto !important;
    border-radius: 200px 0 0 200px;
}

.service-section .list-fetaure div:nth-last-child(1):hover .service-feature:after {
    border-radius: 200px 0 0 0 !important;
}

.service-section .list-fetaure div:nth-last-child(2):hover .service-feature:after {
    left: auto !important;
    border-radius: 0 200px 0 0 !important;
}

.service-feature.nohover {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.service-feature.hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.service-feature:hover h3 {
    font-weight: 600;
    transition: all 0.2s linear;
}

.service-feature:hover p {
    font-weight: 500;
    color: #00011c;
}

/**End services**/
/**startup**/
.client-section {
    padding: 80px 0 60px 0;
    background-color: #fff;
    background-color: #fff;
}

.client-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 40px;
}

.client-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.client-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.client-section .client-section-video {
    position: relative;
}

.client-section .client-section-video .video-container {
    width: 100%;
    // max-height: 519px;
    display: block;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 0;
}

.client-section .client-section-video video {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 0;
}

.client-section .client-section-video .play-div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(0, 1, 28, 0.6), rgba(0, 1, 28, 0.6));
}

.client-section .client-section-video .play-btn {
    width: 82px;
    height: 82px;
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.client-section .client-section-video img {
    width: auto;
    margin-left: 5px;
}

.client-section .solution-listing {
    margin-top: 28px;
}

.client-section .solution-listing .solution-box {
    background-color: rgba(0, 134, 230, 0.04);
    border-radius: 12px;
    padding: 18px 20px 18px 42px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.client-section .solution-listing .solution-box .icon {
    height: 44px;
    width: 44px;
    border-radius: 8px;
    background-color: #fff;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 36px 80px rgba(0, 134, 230, 0.1), 0px 13.8667px 25.4815px rgba(0, 134, 230, 0.0607407), 0px 2.93333px 6.51852px rgba(0, 134, 230, 0.0392593);
}

.client-section .solution-listing .solution-box .icon img {
    height: 23px;
    width: 24px;
    object-fit: contain;
}

.client-section .solution-listing .solution-box .text p {
    color: #616161;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    margin-right: 10px;
}

.client-section .solution-listing .solution-box span {
    color: #040405;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.client-section .solution-listing .solution-box.active .icon {
    background-color: #fff;
}

.client-section .solution-listing .solution-box.active .text p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #00011c;
}

.client-section .solution-listing .bg-green {
    background: rgba(204, 250, 78, 0.1);
}

.client-section .solution-listing .bg-blue {
    background: rgba(0, 134, 230, 0.04);
}

.vjs-paused {
    position: relative;
}

.vjs-paused::before {
    content: "";
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(0, 1, 28, 0.6), rgba(0, 1, 28, 0.6));
    transition: all 0.8s;
}

.vjs-paused .vjs-big-play-button {
    display: block !important;
    z-index: 12;
}

.video-container .vjs-big-play-button {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none !important;
    box-shadow: none !important;
    width: 82px !important;
    height: 82px;
    min-height: 82px;
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    border-radius: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.video-container .vjs-big-play-button .vjs-icon-placeholder::before {
    content: "" !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../../assets/images/update-play-btn.svg);
    display: none;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    min-height: 82px;
    margin-left: 4px;
}

.video-container .vjs-big-play-button .vjs-icon-placeholder:hover {
    background-color: transparent;
    opacity: 0.7;
}

.vjs-big-play-button::after {
    animation: pulse-animation 1.5s infinite;
    box-shadow: 0 0 0 20px rgba(204, 250, 78, 0);
    content: "";
    display: block;
    height: 82px;
    width: 82px;
    border-radius: 100px;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(204, 250, 78, 0.3);
    }

    100% {
        box-shadow: 0 0 0 25px rgba(204, 250, 78, 0);
    }
}

/**End startup**/
/**testimonial**/
.testimonial-section {
    padding: 80px 0 30px 0;
    background-color: #fff;
    background-color: #fff;
    overflow: hidden;
}

.testimonial-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 50px;
}

.testimonial-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.testimonial-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.testimonial-section .testimonial-main-box {
    padding: 44px 38px 26px 32px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    border-radius: 12px;
    position: relative;
    margin-bottom: 35px;
}

.testimonial-section .testimonial-main-box::before {
    content: "";
    background-image: url(../../assets/images/testimonial-bg-shape.png);
    width: 50px;
    height: 37px;
    position: absolute;
    right: 31px;
    top: 36px;
}

.testimonial-section .testimonial-main-box .star-list {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: -2px 0 0 0;
    list-style: none;
    margin-bottom: 22px;
}

.testimonial-section .testimonial-main-box .star-list li {
    margin-right: 13px;
}

.testimonial-section .testimonial-main-box .star-list li:last-child {
    margin: 0;
}

.testimonial-section .testimonial-main-box .testimonial-text {
    margin-bottom: 22px;
}

.testimonial-section .testimonial-main-box .testimonial-text p {
    font-size: 30px;
    line-height: 45px;
    font-weight: 600;
    color: #040405;
}

.testimonial-section .testimonial-main-box .coordinator {
    padding-top: 10px;
    margin-bottom: 18px;
}

.testimonial-section .testimonial-main-box .coordinator span {
    font-size: 20px;
    line-height: 30px;
    color: #040405;
}

.testimonial-section .swiper-slide {
    opacity: 0.06;
    opacity: 0.06;
    transition: opacity 0.3s ease-in-out;
}

.testimonial-section .swiper-slide .testimonial-main-box {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
}

.testimonial-section .swiper-slide-active {
    opacity: 1;
}

.testimonial-section .swiper-slide-active .testimonial-main-box {
    transform: scale(0.99);
    transition: transform 0.3s ease-in-out;
}

.testimonial-section .gallery-thumbs {
    padding: 10px 0 50px 0;
}

.testimonial-section .gallery-thumbs img {
    width: 100%;
    object-fit: contain;
}

.testimonial-section .gallerythumbs {
    padding-top: 20px;
}

.testimonial-section .gallerythumbs .img-div {
    height: 32px;
    min-width: 32px;
    border-radius: 5px;
    margin: 0 20px;
    max-width: 32px;
    transition: all 0.3s;
}

.testimonial-section .gallerythumbs .img-div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.testimonial-section .slick-active {
    opacity: 1;
}

.testimonial-section .slick-active .testimonial-main-box {
    transform: scale(1);
}

.testimonial-section .slick-slide .testimonial-main-box {
    transform: scale(0.9);
}

.testimonial-section .slick-active {
    transition: all 0.5s;
}

.testimonial-section .slick-active .img-div {
    height: 52px !important;
    min-height: 50px;
    width: 52px;
    min-width: 52px;
    margin-top: -15px;
    max-width: 52px;
    transition: all 0.5s;
}

.testimonial-section .slick-active .img-div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.testimonial-section .gallery-thumbs .swiper-slide {
    width: 34px;
    height: 34px;
    margin: 0 15px;
    overflow: hidden;
    opacity: 0.55;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s linear;
    border: 1px solid rgb(227, 227, 227);
}

.testimonial-section .gallery-thumbs .swiper-slide img {
    width: 32px;
    height: 32px;
    transition: all 0.3s linear;
}

.testimonial-section .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
    width: 52px;
    height: 52px;
    margin-top: -10px;
    border-radius: 10px;
    border: 2px solid #0086e6;
    transition: all 0.3s linear;
}

.testimonial-section .gallery-thumbs .swiper-slide-thumb-active img {
    width: 48px;
    height: 48px;
    transition: all 0.3s linear;
}

.gallery-thumbs .swiper-wrapper {
    transform: none !important;
    justify-content: center;
    min-height: 50px;
}

/**End testimonial**/
/**work**/
.work-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
    --work-dark-blue-bg: #12123c;
    --work-yello-bg: #fbbc2e;
    --work-blue-bg: #365da2;
    --work-green-bg: #25938c;
    --work-heading: #fff;
}

.work-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 66px;
}

.work-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.work-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.work-section .our-project .project {
    background-color: #12123c;
    padding: 32px 12px 0 22px;
    border-radius: 12px;
    margin-bottom: 30px;
    overflow: hidden;
    min-height: 538px;
    position: relative;
}

.work-section .our-project .project .project-logo {
    margin-bottom: 24px;
}

.work-section .our-project .project .project-logo img {
    width: auto;
    object-fit: contain;
}

.work-section .our-project .project .project-content h3 {
    margin-bottom: 34px;
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    color: #fff;
    max-width: 435px;
}

.work-section .our-project .project .project-user {
    min-height: 295px;
}

.work-section .our-project .project .project-user .retention {
    margin-right: 32px;
}

.work-section .our-project .project .project-user .retention:last-child {
    margin-right: 0;
}

.work-section .our-project .project .project-user .retention p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    opacity: 0.6;
    margin-bottom: 4px;
}

.work-section .our-project .project .project-user .retention span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
}

.work-section .our-project .project .project-user-unset .project-user {
    min-height: unset;
}

.work-section .our-project .project .project-img {
    text-align: center;
    // display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
}

.work-section .our-project .project .project-link {
    margin-bottom: 35px;
    transition: all 0.3s;
    position: absolute;
    bottom: 0px;
}

.work-section .our-project .project .project-link a {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    opacity: 0.6;
    text-decoration: none;
}

.work-section .our-project .project .project-link a img {
    margin-left: 10px;
    width: 12px;
    opacity: 0;
}

.work-section .our-project .project .project-link a:hover {
    opacity: 1;
}

.work-section .our-project .project .project-link a:hover img {
    opacity: 1;
}

.work-section .our-project .project .project-user-unset .project-link {
    position: unset;
}

.work-section .our-project .project-small .project-content h3 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 21px;
}

.work-section .our-project .project-img-right img {
    margin-right: -20px;
}

.work-section .our-project .bg-yellow {
    background-color: #fbbc2e !important;
    height: auto;
}

.work-section .our-project .bg-green {
    background-color: #25938c !important;
    height: auto;
}

.work-section .our-project .bg-blue {
    background-color: #365da2 !important;
    height: auto;
}

.work-section .View-all {
    margin-top: 50px;
    text-align: center;
}

.work-section .our-project .project:hover .project-link a {
    opacity: 1;
    transition: all 0.3s;
}

.work-section .our-project .project:hover .project-link a img {
    opacity: 1;
    transition: all 0.3s;
}

/***********************industry***********************/
/**opt-to-hire**/
.demand-education-section {
    padding: 80px 0 60px 0px;
    background-color: #fff;
    background-color: #fff;
}

.demand-education-section .demand-inner .heading {
    max-width: 1000px;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 90px;
    text-align: left;
}

.demand-education-section .demand-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.demand-education-section .demand-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.demand-education-section .demand-inner .heading h2 {
    line-height: 60px;
}

.demand-education-section .demand-inner .heading h3 {
    margin-bottom: 20px;
}

.demand-education-section .demand-inner .heading h2 {
    max-width: 700px;
}

.demand-education-section .demand-inner .e-demand-box {
    margin-bottom: 30px;
}

.demand-education-section .demand-inner .e-demand-box h3 {
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 36px;
    line-height: 55px;
    font-weight: 700;
    margin-bottom: 17px;
}

.demand-education-section .demand-inner .e-demand-box p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    font-weight: 500;
    max-width: 351px;
    color: #040405;
    color: #040405;
}

.demand-education-section .demand-inner .text-green h3 {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/**End opt-to-hire**/
/**our-spectrums**/
.services-education-section {
    padding: 50px 0 0 0;
    background-color: #fff;
    background-color: #fff;
}

.services-education-section .heading {
    max-width: 736px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 28px;
}

.services-education-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.services-education-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.services-education-section .heading h2 {
    line-height: 60px;
}

.industries-tabs-section {
    background-color: #fff;
    background-color: #fff;
    position: sticky;
    top: 80px;
    z-index: 9999;
    width: 100%;
}

.industries-tabs-section .industries-feature-heading {
    margin: 0;
    padding: 20px 0 0 0 !important;
    display: flex;
    width: 100%;
}

.industries-tabs-section .industries-feature-heading li {
    text-align: center;
    margin-right: 22px;
    width: 100%;
    font-weight: 500;
}

.industries-tabs-section .industries-feature-heading li:last-child {
    margin-right: 0;
}

.industries-tabs-section .industries-feature-heading li ::after {
    content: "";
    background-image: url(../../assets/images/small-right-arrow1.svg);
    background-position: center;
    background-size: contain;
}

.industries-tabs-section .industries-feature-heading li a {
    color: #616161;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    display: block;
    border-bottom: 2px solid transparent;
}

.industries-tabs-section .industries-feature-heading li a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
    border-bottom: 2px solid #0086e6;
}

.industries-tabs-section .industries-feature-heading li a.active {
    color: #040405;
    color: #040405;
    font-weight: 600;
    border-bottom: 2px solid #0086e6;
}

.industries-tabs-section .industries-tab {
    position: relative;
    margin-bottom: 0;
    position: relative;
}

.industries-tabs-section .industries-tab::before {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%) !important;
    height: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
}

.industries-tabs-section .slick-slide a {
    color: #616161;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 2px solid transparent;
    max-width: 190px;
    padding: 10px 12px;
    min-height: 62px;
    position: relative;
}

.industries-tabs-section .slick-slide a:after {
    content: "";
    display: block;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 2px;
    background: #0086e6;
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 99;
}

.industries-tabs-section .slick-slide a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.industries-tabs-section .slick-slide a:hover::after {
    opacity: 1;
}

.industries-tabs-section .slick-slide a.active {
    color: #040405;
    color: #040405;
    font-weight: 600;
}

.industries-tabs-section .slick-slide a.active::after {
    opacity: 1;
}

.industries-tabs-section .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: 0px;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.industries-tabs-section .slick-prev {
    left: -40px;
}

.industries-tabs-section .slick-prev::before {
    content: "";
    background-image: url(../../assets/images/small-left-arrow1.svg);
    background-size: 12px;
    background-position: center;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 12px;
}

.industries-tabs-section .slick-next {
    right: -40px;
}

.industries-tabs-section .slick-next::before {
    content: "";
    background-image: url(../../assets/images/small-left-arrow1.svg);
    background-size: 12px;
    background-position: center center;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 12px;
    transform: rotate(-180deg);
}

.theme-dark .industries-tabs-section .slick-next::before {
    filter: grayscale(1) brightness(2);
}

.theme-dark .industries-tabs-section .slick-prev::before {
    filter: grayscale(1) brightness(2);
}

/**End our-spectrums**/
/**our-spectrums-details**/
.industries-system-section {
    padding: 80px 0px 80px 0px;
    background-color: #fff;
    background-color: #fff;
}

.industries-system-section .industries-img img {
    width: 100%;
    border-radius: 12px;
}

.industries-system-section .industries-card {
    padding-top: 0px;
}

.industries-system-section .industries-card h2 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #040405;
    color: #040405;
}

.industries-system-section .industries-card p {
    color: #616161;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    white-space: pre-wrap;
}

.industries-system-section .development-box {
    border-radius: 12px;
    border: 1px solid #daedfc;
    margin-bottom: 20px;
}

.industries-system-section .development-box .development-top {
    padding: 30px 30px;
    display: flex;
}

.industries-system-section .development-box .development-top .development-img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    border-radius: 100px;
    overflow: hidden;
    margin-bottom: 16px;
    margin-right: 30px;
}

.industries-system-section .development-box .development-top .development-img img {
    width: 100%;
}

.industries-system-section .development-box .development-top h3 {
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #040405;
    color: #040405;
}

.industries-system-section .development-box .development-top p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #616161;
}

.industries-system-section .development-box .development-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #daedfc;
}

.industries-system-section .development-box .development-bottom .title {
    padding: 6px 20px;
    display: block;
    height: 42px;
    margin-left: 140px;
}

.industries-system-section .development-box .development-bottom .title h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
    margin-bottom: 0;
}

.industries-system-section .development-box .development-bottom .development-links {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
}

.industries-system-section .development-box .development-bottom .development-links li a {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
    position: relative;
    padding: 16px 50px 16px 17px;
    display: block;
    display: flex;
}

.industries-system-section .development-box .development-bottom .development-links li a::after {
    content: "";
    position: absolute;
    right: 20px;
    top: 25px;
    display: block;
    opacity: 0.5;
    background-size: contain;
    z-index: 99;
    background-image: url(../../assets/images/small-black-right-arrow.svg);
    background-position: center;
    width: 16px;
    height: 14px;
}

.industries-system-section .development-box .development-bottom .development-links li a:hover {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
}

.industries-system-section .development-box .development-bottom .development-links li a:hover::after {
    background-image: url(../../assets/images/blue-update-right-arrow.svg);
    opacity: 1;
    background-size: auto;
    width: 14px;
    height: 16px;
    top: 23px;
    right: 21px;
}

.industries-system-section .development-box .development-bottom .development-links li .development-links-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
    position: relative;
    padding: 16px 50px 16px 17px;
    display: block;
    display: flex;
    cursor: pointer;
}

.industries-system-section .development-box .development-bottom .development-links li .development-links-btn::after {
    content: "";
    position: absolute;
    right: 20px;
    top: 25px;
    display: block;
    opacity: 0.5;
    background-size: contain;
    z-index: 99;
    background-image: url(../../assets/images/small-black-right-arrow.svg);
    background-position: center;
    width: 16px;
    height: 14px;
}

.industries-system-section .development-box .development-bottom .development-links li .development-links-btn:hover {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
}

.industries-system-section .development-box .development-bottom .development-links li .development-links-btn:hover::after {
    background-image: url(../../assets/images/blue-update-right-arrow.svg);
    opacity: 1;
    background-size: auto;
    width: 14px;
    height: 16px;
    top: 23px;
    right: 21px;
}

.industries-system-section .box-green {
    border-color: #ebf3d7;
    margin-bottom: 0;
}

/**End our-spectrums-details**/
/**strategies**/
.industries-relevant-section {
    padding: 123px 0 70px 0px;
    background-color: #fff;
    background-color: #fff;
}

.industries-relevant-section .heading {
    text-align: center;
    margin-bottom: 20px;
}

.industries-relevant-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.industries-relevant-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.industries-relevant-section .heading h3 {
    text-align: left;
}

.industries-relevant-section .heading h2 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 40px;
    text-transform: capitalize;
    text-align: left;
}

.industries-relevant-section .rel-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin-bottom: 40px;
}

.industries-relevant-section .step-feature-text h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #040405;
    color: #040405;
}

.industries-relevant-section .steps-feature {
    padding: 20px 20px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to left, #0086e6, #8dd0ff) 1;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    margin-bottom: 20px;
}

.industries-relevant-section .steps-feature .feature-img {
    margin-right: 23px;
    height: 52px;
    min-width: 52px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.industries-relevant-section .steps-feature .feature-img img {
    object-fit: contain;
}

.industries-relevant-section .steps-feature .feature-content {
    padding-right: 30px;
}

.industries-relevant-section .steps-feature .feature-content h3 {
    font-size: 18px;
    line-height: 25.2px;
    color: #040405;
    color: #040405;
    margin-bottom: 5px;
    font-weight: 600;
}

.industries-relevant-section .steps-feature .feature-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #616161;
    margin-bottom: 0px;
}

.industries-relevant-section .steps-feature:hover .feature-content h3 {
    font-weight: 600;
}

.industries-relevant-section .feature-green {
    border: 1px solid;
    border-image: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) 1;
}

.industries-relevant-section .feature-green .feature-img {
    background: linear-gradient(283.13deg, rgba(138, 188, 0, 0.05) 5.56%, rgba(204, 250, 78, 0.05) 113.66%) !important;
}

/**End strategies**/
/********************End industry**************************/
/********************our-latest**************************/
.technologies-feature-latest {
    margin-bottom: 30px;
}

.technologies-feature-latest .letest-technologies-content h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

.technologies-feature-latest .letest-technologies-box {
    padding: 12px 23px 12px 12px;
    border: 1px solid rgba(0, 1, 26, 0.2);
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-img {
    max-width: 156px;
    min-width: 156px;
    height: 115px;
    border-radius: 12px;
    overflow: hidden;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-text {
    padding-left: 16px;
    width: 100%;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-text h3 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    min-height: 82px;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-text .letest-technologies-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-text .letest-technologies-date span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #616161;
}

/********************End our-latest**************************/
/********************PageNotFound**************************/
.middle-section {
    padding-top: 80px;
}

.pagenot-section {
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.pagenot-section .pagenot-inner {
    padding: 100px 0 170px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    min-height: calc(100vh - 80px);
}

.pagenot-section .heading {
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
}

.pagenot-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.pagenot-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.pagenot-section .heading h2 {
    margin-bottom: 40px;
    line-height: 58px;
    font-size: 40px;
}

/********************End PageNotFound**************************/
/********************service-details**************************/
/**digital-product**/
/**design-stage**/
.design-stage-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.design-stage-section .heading {
    text-align: center;
    margin-bottom: 20px;
}

.design-stage-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.design-stage-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.design-stage-section .design-steps {
    background: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%);
    border: 1px solid rgba(0, 1, 26, 0.2);
    padding: 25px 10px;
    margin-bottom: 60px;
}

.design-stage-section .design-steps ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.design-stage-section .design-steps ul li {
    color: rgba(97, 97, 97, 0.2);
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    width: 100%;
}

.design-stage-section .design-steps ul li a {
    color: rgba(97, 97, 97, 0.2);
}

.design-stage-section .design-steps ul li:first-child {
    text-align: left;
    justify-content: flex-start;
    width: 80%;
}

.design-stage-section .design-steps ul li:last-child {
    text-align: right;
}

.design-stage-section .design-steps ul .active {
    position: relative;
    color: #040405;
    color: #040405;
}

.design-stage-section .design-steps ul .active a {
    color: #040405;
    color: #040405;
}

.design-stage-section .design-steps ul .active::after {
    content: "";
    position: absolute;
    right: -30px;
    top: 14px;
    background-image: url(../../assets/images/big-right-arrows.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 20px;
    width: 20px;
}

.design-stage-section .design-steps ul .hashlinkactive {
    color: black;
}

.design-stage-section .design-steps ul .hashlinknotactive {
    color: gainsboro;
}

.design-stage-section .steps-detail .heading-steps {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.design-stage-section .steps-detail .heading-steps h2 {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 60px;
    line-height: 84px;
    font-weight: 700;
    opacity: 0.07;
    margin-bottom: 0;
}

.design-stage-section .steps-detail .heading-steps h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #040405;
    color: #040405;
}

.design-stage-section .steps-detail .steps-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #616161;
}

.design-stage-section .steps-detail .line {
    border: 1px solid rgba(0, 1, 26, 0.2);
}

.design-stage-section .steps-detail .step-feature-text h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #040405;
    color: #040405;
}

.design-stage-section .steps-detail .steps-feature {
    padding: 20px 20px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to left, #0086e6, #8dd0ff) 1;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    margin-bottom: 20px;
}

.design-stage-section .steps-detail .steps-feature .feature-img {
    margin-right: 23px;
}

.design-stage-section .steps-detail .steps-feature .feature-img img {
    height: 52px;
    width: 52px;
    border-radius: 12px;
    overflow: hidden;
}

.design-stage-section .steps-detail .steps-feature .feature-content h3 {
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
    margin-bottom: 5px;
    font-weight: 400;
}

.design-stage-section .steps-detail .steps-feature .feature-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #616161;
    margin-bottom: 5px;
}

.design-stage-section .steps-detail .step-row {
    margin-bottom: 60px;
}

.bg-light {
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
}

.sticky-tab {
    position: sticky;
    top: 80px;
    z-index: 99;
}

.design-stage-section .design-steps ul {
    white-space: nowrap;
}

/**end design-stage**/
/**digital-banner**/
.middle-section {
    padding-top: 80px;
}

.digital-banner-section {
    padding: 100px 0 100px 0;
    position: relative;
    background-color: #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.digital-banner-section:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 80vh;
    background-image: url("../../assets/images/digital-banner.svg");
    background-position: top center;
    background-size: contain;
    z-index: 1;
}

.digital-banner-section::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 80vh;
    background: var(--banner-overlay);
}

.digital-banner-section .digital-banner-inner {
    position: relative;
    z-index: 10;
}

.digital-banner-section .digital-banner-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 80px;
}

.digital-banner-section .digital-banner-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.digital-banner-section .digital-banner-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.digital-banner-section .digital-banner-inner .heading h2 {
    line-height: 60px;
}

.digital-banner-section .digital-banner-inner .heading h2 {
    max-width: 716px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.digital-banner-section .digital-banner-inner .heading .btn {
    margin-top: 22px;
    font-weight: 500;
}

.digital-banner-section .digital-banner-inner .top-baner-images {
    position: relative;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.digital-banner-section .digital-banner-inner .top-baner-images .img-box {
    box-shadow: 0px 4.7566137314px 6.2104377747px 0px rgba(0, 134, 230, 0.0072);
    box-shadow: 0px 20.9291000366px 12.8592586517px 0px rgba(0, 134, 230, 0.0118);
    box-shadow: 0px 51.3714256287px 25.6454544067px 0px rgba(0, 134, 230, 0.015);
    box-shadow: 0px 98.9375686646px 50.2680130005px 0px rgba(0, 134, 230, 0.0182);
    box-shadow: 0px 166.4814758301px 92.4259262085px 0px rgba(0, 134, 230, 0.0228);
    box-shadow: 0px 256.8571472168px 157.8181762695px 0px rgba(0, 134, 230, 0.03);
}

.digital-banner-section .digital-banner-inner .top-baner-images .img-box img {
    width: auto;
    backdrop-filter: blur(13.6883px);
    background-blend-mode: overlay, normal;
    border-radius: 20px;
}

.digital-banner-section .digital-banner-inner .top-baner-images .image-one img {
    border-radius: 12px;
}

.digital-banner-section .digital-banner-inner .bottom-baner-images {
    position: relative;
    bottom: -20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.digital-banner-section .digital-banner-inner .bottom-baner-images .img-box {
    box-shadow: 0px 4.7566137314px 6.2104377747px 0px rgba(0, 134, 230, 0.0072);
    box-shadow: 0px 20.9291000366px 12.8592586517px 0px rgba(0, 134, 230, 0.0118);
    box-shadow: 0px 51.3714256287px 25.6454544067px 0px rgba(0, 134, 230, 0.015);
    box-shadow: 0px 98.9375686646px 50.2680130005px 0px rgba(0, 134, 230, 0.0182);
    box-shadow: 0px 166.4814758301px 92.4259262085px 0px rgba(0, 134, 230, 0.0228);
    box-shadow: 0px 256.8571472168px 157.8181762695px 0px rgba(0, 134, 230, 0.03);
}

.digital-banner-section .digital-banner-inner .bottom-baner-images .img-box img {
    width: auto;
    backdrop-filter: blur(13.6883px);
    background-blend-mode: overlay, normal;
    border-radius: 20px;
}

.digital-banner-section .digital-banner-inner .bottom-baner-images .image-four {
    margin-top: 50px;
    margin-right: 70px;
}

.digital-banner-section .digital-banner-inner .bounce-animate {
    animation-name: float-bob;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.digital-banner-section .digital-banner-inner .bounce-animate2 {
    animation-name: float-bob2;
    animation-duration: 3.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob2;
    -moz-animation-duration: 3.6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob2;
    -ms-animation-duration: 3.6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob2;
    -o-animation-duration: 3.6s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@keyframes float-bob {
    0% {
        -webkit-transform: translateY(-22px);
        transform: translateY(-22px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(-22px);
        transform: translateY(-22px);
    }
}

@keyframes float-bob2 {
    0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

/**end digital-banner**/
/**end digital-product**/
/**service-details-layout**/
/**approach-section**/
.approach-section {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    position: relative;
}

.approach-section .approach-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    max-width: 735px;
    text-align: left;
    margin-bottom: 10px;
}

.approach-section .approach-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.approach-section .approach-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.approach-section .approach-inner .heading h2 {
    font-size: 32px;
    line-height: 48px;
}

.approach-section .approach-inner .approach-content {
    padding-top: 20px;
}

.approach-section .approach-inner .approach-content p {
    margin-bottom: 25px;
    line-height: 27px;
    font-size: 18px;
    color: #616161;
}

.approach-section .approach-inner .approach-content p:nth-last-child(1) {
    margin-bottom: 0;
}

.approach-section .approach-inner .cto-div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.approach-section .approach-inner .cto-div .cto-pofile-info {
    margin-right: 30px;
    text-align: right;
}

.approach-section .approach-inner .cto-div .cto-pofile-info h4 {
    font-size: 27px;
    line-height: 33px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 5px;
}

.approach-section .approach-inner .cto-div .cto-pofile-info span {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 20px;
    color: #616161;
    margin-bottom: 15px;
}

.approach-section .approach-inner .cto-div .cto-pofile-info ul {
    margin: 0px;
    padding: 0px;
}

.approach-section .approach-inner .cto-div .cto-pofile-info ul li {
    display: inline-block;
}

.approach-section .approach-inner .cto-div .cto-pofile-info ul li a {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    color: #fff;
    padding: 4px 7px;
    border-radius: 50%;
    font-size: 10px;
    margin-left: 7px;
    display: inline-block;
    margin-top: 14px;
}

.approach-section .approach-inner .cto-div .cto-pofile img {
    max-width: 338px;
    width: 100%;
    margin: 0 auto;
}

/**End approach-section**/
/**book-section**/
.book-section {
    padding: 80px 0 120px 0;
    background-color: #fff;
    background-color: #fff;
}

.book-section .book-box {
    background-image: url(../../assets/images/bg-books.png);
    box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 45px 20px 50px 20px;
    border-radius: 20px;
    background-color: #fff;
    background-color: #fff;
}

.book-section .book-box .book-text {
    text-align: center;
}

.book-section .book-box .book-text h2 {
    font-size: 38px;
    line-height: 57px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 10px;
}

.book-section .book-box .book-text p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #616161;
    margin-bottom: 30px;
}

/**End book-section**/
/**challenges-section**/
.challenges-section {
    background-color: #fff;
    background-color: #fff;
    padding: 40px 0 40px 0;
}

.challenges-section .challenges-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
    margin-bottom: 46px;
    max-width: 750px;
}

.challenges-section .challenges-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.challenges-section .challenges-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.challenges-section .challenges-inner .heading h2 {
    font-size: 32px;
    line-height: 48px;
}

.challenges-section .challenges-inner .challenges-content p {
    color: rgba(4, 4, 5, 0.5);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
}

.challenges-section .challenges-inner .challenges-img {
    text-align: right;
}

.challenges-section .challenges-inner .challenges-img img {
    width: 100%;
    max-width: 558px;
}

/**End challenges-section**/
/**service-banner**/
.middle-section {
    padding-top: 80px;
}

.agile-banner-section {
    padding: 20px 0 40px 0;
    background-color: #fff;
    background-color: #fff;
}

.agile-banner-section .agile-banner-inner .agile-banner-content {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    text-align: center;
    border-radius: 24px;
    padding: 138px 0px;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    text-align: center;
    margin-bottom: 20px;
    max-width: 856px;
    margin: 0 auto 56px auto;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    line-height: 60px;
}

.agile-banner-section .agile-banner-inner .agile-banner-content P {
    color: #616161;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 auto;
    max-width: 760px;
}

/**End service-banner**/
/**service-team**/
.agile-team-section {
    background-color: #fff;
    background-color: #fff;
    padding: 80px 0;
    position: relative;
}

.agile-team-section .col-lg-4.lg1 .team-card {
    margin-top: 0 !important;
}

.agile-team-section .col-lg-4.lg2 .team-card {
    margin-top: 0 !important;
}

.agile-team-section .col-lg-4.lg0 .team-card {
    margin-top: 0 !important;
}

.agile-team-section .agile-team-inner .team-card {
    padding: 65px 30px 60px 30px;
    border: 1px solid #8dd0ff;
    margin-right: -1px;
    margin-top: -1px;
    text-align: center;
    position: relative;
    height: 100%;
}

.agile-team-section .agile-team-inner .team-card span {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    border: 1px solid #0086e6;
    top: -26px;
    background: #fff;
    color: #616161;
    line-height: 46px;
    left: 0px;
    right: 0px;
    font-weight: 700;
    margin: auto;
}

.agile-team-section .agile-team-inner .team-card h5 {
    font-size: 20px;
    line-height: 24px;
    color: #040405;
    color: #040405;
    font-weight: 700;
}

.agile-team-section .agile-team-inner .team-card p {
    font-size: 16px;
    line-height: 24px;
    margin: 14px 0px;
    min-height: 100px;
    color: #616161;
}

/**End service-team**/
/**technologies-section**/
.technologies-section {
    padding: 80px 0px;
    background-color: #fff;
    background-color: #fff;
}

.technologies-section .technologies-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    max-width: 919px;
    text-align: left;
    margin-bottom: 56px;
}

.technologies-section .technologies-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.technologies-section .technologies-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.technologies-section .technologies-inner .technologies-cantent {
    border-top: 1px solid #8dd0ff;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name {
    border-right: 1px solid #8dd0ff;
    height: calc(100% - 50px);
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border: 0;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    border: 0;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    padding: 20px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    width: 100%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #616161;
    background-color: transparent;
    position: relative;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link .arrow {
    margin-left: 10px;
    background-image: url(../../assets/images/update-right-arrow.svg);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    opacity: 0;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link:hover .arrow {
    opacity: 1;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link.active {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link.active .arrow {
    opacity: 1;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content {
    padding: 16px 30px;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top {
    margin-bottom: 40px;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top h3 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 22px;
    color: #040405;
    color: #040405;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 29px;
    color: #616161;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #040405;
    color: #040405;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom hr {
    padding: 0;
    margin: 10px 0;
    border-top: 1px solid rgb(97, 97, 97);
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons {
    padding: 10px 0;
    margin-bottom: 30px;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons a {
    border: 1px solid #8dd0ff;
    padding: 8px 12px;
    border-radius: 10px;
    color: #040405;
    color: #040405;
    font-size: 14px;
    font-weight: 400;
    margin: 0px 10px 11px 0px;
    display: inline-block;
}

/**end technologies-section**/
/**End service-details-layout**/
/********************End service-details**************************/
/********************services****************************/
/**serivce-hire-developer**/
.hire-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.hire-section .hite-content {
    text-align: center;
}

.hire-section .hite-content span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    display: block;
    color: #616161;
}

.hire-section .hite-content h2 {
    font-size: 38px;
    line-height: 57px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 40px;
}

/**End serivce-hire-developer**/
/**service-banner**/
.service-hero-section {
    padding: 80px 0 60px 0;
    position: relative;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.service-hero-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 80px;
}

.service-hero-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.service-hero-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.service-hero-section .heading h2 {
    line-height: 60px;
}

.service-hero-section .heading h2 {
    max-width: 908px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.service-hero-section .heading p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #616161;
    max-width: 740px;
    margin: 0 auto;
}

/**End service-banner**/
/**service-book-consultant**/
.book-section {
    padding: 80px 0 120px 0;
    background-color: #fff;
    background-color: #fff;
}

.book-section .book-box {
    background-image: url(../../assets/images/bg-books.png);
    box-shadow: 0px 27px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 45px 20px 50px 20px;
    border-radius: 20px;
    background-color: #fff;
    background-color: #fff;
}

.book-section .book-box .book-text {
    text-align: center;
}

.book-section .book-box .book-text h2 {
    font-size: 38px;
    line-height: 57px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 10px;
}

.book-section .book-box .book-text p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #616161;
    margin-bottom: 30px;
}

.book-section .book-box .book-text .hire-btn {
    margin: 10px;
}

.book-section .book-box .book-text .hire-btn .btn-outline-secondary {
    padding: 13px 16px;
    background: transparent;
    border-radius: 8px;
    border-color: #616161;
    font-size: 14px;
    margin-left: 12px;
}

.book-section .book-box .book-text .hire-btn .btn-outline-secondary:hover {
    color: #616161;
}

/**End service-book-consultant**/
/**service-company**/
.compeny-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.compeny-section .compeny-main-content {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 40px;
    margin-bottom: 32px;
    border-radius: 24px;
}

.compeny-section .compeny-main-content .compeny-img img {
    max-width: 405px;
    max-height: 520px;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
}

.compeny-section .compeny-main-content .compeny-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.compeny-section .compeny-main-content .compeny-text h2 {
    font-size: 38px;
    line-height: 57px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #040405;
    color: #040405;
}

.compeny-section .compeny-main-content .compeny-text p {
    font-size: 20px;
    line-height: 30px;
    color: #040405;
    color: #040405;
    font-weight: 500;
    margin-bottom: 0px;
}

.compeny-section .compeny-main-content .compeny-text .compeny-logo h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #040405;
    color: #040405;
}

.compeny-section .compeny-main-content .compeny-text .compeny-logo ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.compeny-section .compeny-main-content .compeny-text .compeny-logo ul li {
    padding-right: 18px;
}

.compeny-section .compeny-main-content .compeny-text .compeny-logo ul li img {
    width: 105px;
    height: 105px;
}

.compeny-section .compeny-box {
    padding: 20px;
    border-radius: 12px;
    position: relative;
    z-index: 1;
}

.compeny-section .compeny-box p {
    font-size: 16px;
    line-height: 24px;
    color: #616161;
    font-weight: 500;
    margin-bottom: 24px;
}

.compeny-section .compeny-box h2 {
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin: 0;
}

.compeny-section .compeny-box1 {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.compeny-section .compeny-box2 {
    background: #fff;
    background: #fff;
}

.compeny-section .compeny-box-border {
    position: relative;
}

.compeny-section .compeny-box-border::after {
    content: "";
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: -1px;
    z-index: 0;
    border-radius: 12px;
}

.compeny-section .compeny-box-border::before {
    content: "";
    background: #fff;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 12px;
    position: absolute;
    left: 0;
    top: 0;
}

.compeny-section .compeny-box-border-2 {
    position: relative;
}

.compeny-section .compeny-box-border-2::after {
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: -1px;
    z-index: 0;
    border-radius: 12px;
}

.compeny-section .compeny-box-border-2::before {
    content: "";
    background: #fff;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 12px;
    position: absolute;
    left: 0;
    top: 0;
}

/**End service-company**/
/**service-feature**/
.service-feature-section {
    background-color: #fff;
    background-color: #fff;
}

.service-feature-section .service-feature-card {
    padding: 50px 0 40px 0;
}

.service-feature-section .service-feature-card .service-feature-content h6 {
    font-size: 20px;
    line-height: 30px;
    color: #616161;
    margin-bottom: 23px;
    font-weight: 400;
}

.service-feature-section .service-feature-card .service-feature-content h2 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 42px;
    max-width: 430px;
}

.service-feature-section .service-feature-card .service-feature-content p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #616161;
    margin: 0;
}

.service-feature-section .service-feature-card .service-feature-image {
    border-radius: 12px;
    overflow: hidden;
}

.service-feature-section .service-feature-card .service-feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.service-feature-section .service-feature-latest {
    margin-bottom: 30px;
}

.service-feature-section .service-feature-latest .letest-service-content h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

.service-feature-section .service-feature-latest .letest-service-box {
    padding: 12px 23px 12px 12px;
    border: 1px solid rgba(0, 1, 26, 0.2);
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.service-feature-section .service-feature-latest .letest-service-box .letest-service-img {
    max-width: 156px;
    min-width: 156px;
    height: 115px;
    border-radius: 12px;
    overflow: hidden;
}

.service-feature-section .service-feature-latest .letest-service-box .letest-service-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    transform: scale(1);
    transition: all 0.45s linear;
}

.service-feature-section .service-feature-latest .letest-service-box .letest-service-text {
    padding-left: 16px;
    width: 100%;
}

.service-feature-section .service-feature-latest .letest-service-box .letest-service-text h3 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    min-height: 82px;
}

.service-feature-section .service-feature-latest .letest-service-box .letest-service-text .letest-service-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service-feature-section .service-feature-latest .letest-service-box .letest-service-text .letest-service-date span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #616161;
}

.service-feature-section .service-feature-latest .letest-service-box:hover .letest-service-img img {
    transform: scale(1.05);
    transition: all 0.45s linear;
}

.service-feature-section .service-follow {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 13px;
    min-height: 50px;
}

.service-feature-section .service-follow .service-follow-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service-feature-section .service-follow .service-follow-content h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin: 0;
}

.service-feature-section .service-follow .service-follow-content ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.service-feature-section .service-follow .service-follow-content ul li {
    padding-right: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
}

.service-feature-section .service-follow .service-follow-content ul li:last-child {
    padding-right: 0;
}

.service-feature-section .service-follow .service-follow-content ul li h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin: 0;
}

.service-feature-section .service-follow .service-follow-content ul li span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #0086e6;
    display: block;
}

/**End service-feature**/
/**service-logos**/
.project-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.project-section .project-box .project-logos {
    border: 1px solid rgba(0, 1, 26, 0.2);
    border-radius: 12px;
    width: 240px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.project-section .project-box .project-logos img {
    width: auto;
}

/**End service-logos**/
/**service-tab**/
.service-tabs-section {
    background-color: #fff;
    background-color: #fff;
}

.service-tabs-section .service-feature-heading {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
}

.service-tabs-section .service-feature-heading::before {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    height: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
}

.service-tabs-section .service-feature-heading li {
    text-align: center;
    margin-right: 22px;
}

.service-tabs-section .service-feature-heading li:last-child {
    margin-right: 0;
}

.service-tabs-section .service-feature-heading li a {
    color: #616161;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding: 20px 0;
    display: block;
    border-bottom: 2px solid transparent;
    position: relative;
}

.service-tabs-section .service-feature-heading li a::before {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    height: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 9;
}

.service-tabs-section .service-feature-heading li a.active {
    color: #040405;
    color: #040405;
    font-weight: 600;
    border-bottom: 2px solid #0086e6;
}

.service-tabs-section .service-feature-heading li a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
    border-bottom: 2px solid #0086e6;
}

.service-tabs-section {
    position: sticky;
    height: 80px;
    left: 0;
    top: 80px;
    z-index: 999999;
}

/**End service-tab**/
/********************End services****************************/
/********************startup****************************/
/**business-section-details**/
.startups-vacation-section {
    padding: 50px 0px 20px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-vacation-section .startups-vacation-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    max-width: 350px;
    text-align: left;
}

.startups-vacation-section .startups-vacation-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.startups-vacation-section .startups-vacation-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.startups-vacation-section .startups-vacation-inner .heading h2 {
    line-height: 60px;
}

.startups-vacation-section .startups-vacation-inner .heading h2 {
    margin-bottom: 50px;
}

.startups-vacation-section .startups-vacation-inner .startups-vacation-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #616161;
    margin-bottom: 20px;
}

.startups-technology-section {
    padding: 20px 0px 0 0;
    background-color: #fff;
    background-color: #fff;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent {
    border-top: 1px solid #8dd0ff;
    border-bottom: 1px solid #8dd0ff;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name {
    border-right: 1px solid #8dd0ff;
    height: 100%;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border: 0;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    border: 0;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link {
    padding: 20px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    width: 100%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #616161;
    background-color: transparent;
    position: relative;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link .arrow {
    margin-left: 10px;
    background-image: url(../../assets/images/update-right-arrow.svg);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    opacity: 0;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link:hover .arrow {
    opacity: 1;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link.active {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link.active .arrow {
    opacity: 1;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content {
    padding-bottom: 20px;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card {
    display: flex;
    padding: 30px 30px 10px 30px;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-img {
    margin-right: 23px;
    height: 52px;
    width: 52px;
    min-width: 52px;
    overflow: hidden;
    border-radius: 8px;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-img img {
    width: 100%;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle h4 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
    color: #040405;
    color: #040405;
}

.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle p {
    font-size: 16px;
    line-height: 24px;
    color: #616161;
    margin-bottom: 0;
}

.startups-features-section {
    padding: 0px 0px 40px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-features-section .startups-features-inner .startups-heading {
    text-align: center;
    padding: 20px 0;
}

.startups-features-section .startups-features-inner .startups-heading h2 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 0;
    color: #040405;
    color: #040405;
    font-weight: 700;
}

.startups-features-section .startups-features-inner .startups-features-vacation {
    border-top: 1px solid #8dd0ff;
}

.startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card {
    padding: 20px 10px;
}

.startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card h2 {
    font-size: 33px;
    line-height: 50px;
    color: transparent;
    -webkit-text-stroke: 1px #0086e6;
    font-weight: 700;
}

.startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card h4 {
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
}

.startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card p {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    font-weight: 500;
}

.startups-hireteam-section {
    padding: 60px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card {
    background-image: url(../../assets/images/hireteam.png);
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px 30px 50px 30px;
    border-radius: 12px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: 0px 20px 51px rgba(130, 213, 255, 0.04), 0px 15px 20px 10px rgba(0, 1, 28, 0.03);
    height: 100%;
    overflow: hidden;
}

.startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card .startups-hireteam-content h2 {
    font-size: 40px;
    line-height: 53px;
    color: #040405;
    color: #040405;
    margin-bottom: 145px;
    font-weight: 700;
}

.startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card .startups-hireteam-content h2 span {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
}

.startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card {
    padding: 30px 30px;
    border: 1px solid #8dd0ff;
    border-radius: 12px;
}

.startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card .startups-airbnb-img {
    margin-bottom: 20px;
    max-height: 244px;
    border-radius: 8px;
    overflow: hidden;
}

.startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card .startups-airbnb-img img {
    width: 100%;
}

.startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card .startups-airbnb-content h2 {
    font-size: 30px;
    line-height: 45px;
    color: #040405;
    color: #040405;
    margin-bottom: 50px;
    font-weight: 700;
}

.startups-features-section .slick-next {
    right: 0;
    border-radius: 0px 8px 8px 0px;
}

.startups-features-section .slick-next::before {
    content: "";
    background-image: url(../../assets/images/update-left-arrows.svg);
    width: 20px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}

.startups-features-section .slick-prev {
    left: 0;
    border-radius: 8px 0px 0px 8px;
}

.startups-features-section .slick-prev::before {
    content: "";
    background-image: url(../../assets/images/update-left-arrows.svg);
    width: 20px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
}

.startups-features-section .slick-prev,
.startups-features-section .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: -44px;
    display: block;
    width: 48px;
    height: 48px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

/**End business-section-details**/
/**business-section-tabs**/
.startups-business-section {
    padding: 80px 0px 0 0;
    background-color: #fff;
    background-color: #fff;
}

.startups-business-section .startups-business-inner .heading {
    max-width: 919px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
}

.startups-business-section .startups-business-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.startups-business-section .startups-business-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.startups-business-section .startups-business-inner .heading h2 {
    line-height: 60px;
}

.startups-tabs-section {
    background-color: #fff;
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 9999;
}

.startups-tabs-section .startups-tab-feature-heading {
    margin: 0;
    padding: 0 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 2px solid rgba(0, 134, 230, 0.2);
}

.startups-tabs-section .startups-tab-feature-heading li {
    text-align: center;
    margin-right: 22px;
}

.startups-tabs-section .startups-tab-feature-heading li:last-child {
    margin-right: 0;
}

.startups-tabs-section .startups-tab-feature-heading li a {
    color: #616161;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding: 20px 0;
    min-height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
}

.startups-tabs-section .startups-tab-feature-heading li a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
    border-bottom: 2px solid rgb(0, 134, 230);
}

.startups-tabs-section .startups-tab-feature-heading li.active a {
    color: #040405;
    color: #040405;
    font-weight: 600;
    border-bottom: 2px solid rgb(0, 134, 230);
}

/**End business-section-tabs**/
/**development-process**/
.startups-process-section {
    padding: 80px 0px;
    background: #fff;
    background: #fff;
}

.startups-process-section .startups-process-inner .heading {
    text-align: center;
    max-width: 900px;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 88px;
}

.startups-process-section .startups-process-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.startups-process-section .startups-process-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.startups-process-section .startups-process-inner .heading h2 {
    line-height: 60px;
}

.startups-process-section .startups-process-inner .process-list ul {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.startups-process-section .startups-process-inner .process-list ul li {
    font-weight: 400;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    color: #616161;
    position: relative;
}

.startups-process-section .startups-process-inner .process-list ul .active {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

.startups-process-section .startups-process-inner .process-text p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #040405;
    color: #040405;
    min-height: 80px;
}

/**End development-process**/
/**sharedcard**/
.startups-looking-section {
    background-color: #fff;
    background-color: #fff;
    padding: 20px 0 20px 0;
}

.startups-looking-section .startups-looking-inner .startups-looking-content {
    margin-top: 80px;
}

.startups-looking-section .startups-looking-inner .startups-looking-content h2 {
    font-size: 30px;
    line-height: 45px;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

.startups-looking-section .startups-looking-inner .startups-looking-content p {
    color: #616161;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
}

.startups-looking-section .startups-looking-inner .startups-looking-img {
    position: relative;
    text-align: right;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.startups-looking-section .startups-looking-inner .startups-looking-img img {
    max-width: 470px;
    width: 100%;
}

/**End sharedcard**/
/**startup-strategies**/
startup idea .startups-idea-section {
    padding: 80px 0px;
    background: #fff;
    background: #fff;
    position: relative;
}

startup idea .startups-idea-section::after {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    max-height: 450px;
}

startup idea .startups-idea-section .startups-idea-inner {
    position: relative;
    z-index: 9;
}

startup idea .startups-idea-section .startups-idea-inner .heading {
    text-align: left !important;
    max-width: 750px;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 40px;
}

startup idea .startups-idea-section .startups-idea-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

startup idea .startups-idea-section .startups-idea-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

startup idea .startups-idea-section .startups-idea-inner .heading h2 {
    line-height: 60px;
}

startup idea .startups-idea-section .startups-idea-card {
    border: 1px solid #d9edfb;
    padding: 20px 20px 30px 20px;
    border-radius: 12px;
    background: #fff;
    background: #fff;
    margin-bottom: 30px;
    height: 100%;
}

startup idea .startups-idea-section .startups-idea-card .startups-idea-img {
    margin-bottom: 10px;
}

startup idea .startups-idea-section .startups-idea-card .startups-idea-img img {
    height: 80px;
    width: 80px;
    width: auto;
}

startup idea .startups-idea-section .startups-idea-card .startups-idea-content h4 {
    font-size: 20px;
    line-height: 26px;
    color: #040405;
    color: #040405;
}

startup idea .startups-idea-section .startups-idea-card .startups-idea-content p {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    font-weight: 500;
}

/**End startup-strategies**/
/********************End startup****************************/
/********************startup-details***********************/
/**banner-content**/
.startups-demandtaxi-section {
    padding: 40px 0px 120px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-demandtaxi-section .startups-demandtaxi-inner .startups-demandtaxi-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin-bottom: 0px;
}

hire-marquee .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee {
    background-color: rgba(37, 37, 38, 0.12);
    padding: 10px;
}

hire-marquee .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee ul li {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    display: inline-flex;
    align-items: center;
    color: #00011c;
    margin-right: 60px;
    position: relative;
}

hire-marquee .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee ul li::after {
    content: "";
    height: 8px;
    width: 8px;
    background-color: #00011c;
    display: block;
    border-radius: 3px;
    position: absolute;
    right: -40px;
    top: 21px;
    transform: rotate(45deg);
}

hire-marquee .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee ul li a {
    color: #00011c;
}

.startups-hire-marquee-section .hire-marquee {
    background-color: rgba(37, 37, 38, 0.12);
    padding-top: 10px;
    padding-bottom: 15px;
}

.startups-hire-marquee-section .hire-marquee div span {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: inline-flex;
    align-items: center;
    color: #00011c;
    margin-right: 60px;
}

.startups-hire-marquee-section .hire-marquee div a {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: inline-flex;
    align-items: center;
    color: #00011c;
    margin-right: 60px;
    position: relative;
}

.startups-hire-marquee-section .hire-marquee div a:hover {
    display: block;
    color: #00011c;
    text-decoration: none;
}

.startups-hire-marquee-section .hire-marquee div a::after {
    content: "";
    height: 11px;
    width: 11px;
    background-color: #00011c;
    display: block;
    border-radius: 100px;
    position: absolute;
    left: -40px;
    top: 28px;
}

.startups-hire-marquee-section .hire-marquee ul {
    margin: 0;
    padding: 0;
}

.startups-hire-marquee-section .hire-marquee ul li {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: inline-flex;
    align-items: center;
    color: #00011c;
    margin-right: 60px;
    position: relative;
}

.startups-hire-marquee-section .hire-marquee ul li a {
    display: block;
    color: #00011c;
    text-decoration: none;
}

.startups-hire-marquee-section .hire-marquee ul li::after {
    content: "";
    height: 11px;
    width: 11px;
    background-color: #00011c;
    display: block;
    border-radius: 100px;
    position: absolute;
    right: -40px;
    top: 28px;
}

/**End banner-content**/
/**planning-feature**/
.startups-feature-section {
    padding: 30px 0px 100px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-feature-section .startups-feature-inner .startups-features-card {
    margin-bottom: 40px;
}

.startups-feature-section .startups-feature-inner .startups-features-card h2 {
    font-size: 33px;
    line-height: 50px;
    -webkit-text-stroke: 1px #0086e6;
    color: transparent;
    margin-bottom: 0px;
}

.startups-feature-section .startups-feature-inner .startups-features-card h4 {
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
}

.startups-feature-section .startups-feature-inner .startups-features-card p {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    font-weight: 500;
}

/**End planning-feature**/
/**startup-details-strategies**/
uber .startups-uber-section {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 80px 0px 160px 0px;
}

uber .startups-uber-section .startups-uber-inner .heading {
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
}

uber .startups-uber-section .startups-uber-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

uber .startups-uber-section .startups-uber-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

uber .startups-uber-section .startups-uber-inner .heading h2 {
    line-height: 60px;
}

uber .startups-uber-section .startups-uber-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
}

.startups-partner-section {
    background-color: #fff;
    background-color: #fff;
}

.startups-partner-section .startups-partner-inner .pratner-box {
    top: -140px;
    position: relative;
}

.startups-partner-section .startups-partner-inner .startups-partner-card {
    background-color: #fff;
    background-color: #fff;
    border: 1px solid #d9edfb;
    border-radius: 12px;
    padding: 20px;
    height: 100%;
}

.startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-img {
    border-radius: 12px;
    margin-bottom: 15px;
}

.startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-img img {
    height: 163px;
    width: 100%;
}

.startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-content h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #040405;
    color: #040405;
}

.startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #616161;
}

/**End startup-details-strategies**/
/**startup-planning**/
.startups-planning-section {
    padding: 30px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-planning-section .startups-planning-inner .startups-planning-heading {
    max-width: 491px;
}

.startups-planning-section .startups-planning-inner .startups-planning-heading h2 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 45px;
    font-weight: 700;
    color: #040405;
    color: #040405;
}

.startups-planning-section .startups-planning-inner .startups-planning-heading .btn-primary {
    font-size: 16px;
    font-weight: 600;
}

.startups-planning-section .startups-planning-inner .startups-planning-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
}

.startups-tab-section {
    background-color: #fff;
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 9999;
}

.startups-tab-section .startups-tab-inner .startups-tab-heading {
    margin: 0;
    padding: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 2px solid rgba(0, 134, 230, 0.2);
    border-top: 2px solid rgba(0, 134, 230, 0.2);
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li {
    text-align: center;
    margin-right: 22px;
    width: 25%;
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li:last-child {
    margin-right: 0;
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li a {
    color: #616161;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding-bottom: 20px;
    display: block;
    border-bottom: 2px solid transparent;
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
    border-bottom: 2px solid rgb(0, 134, 230);
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li a.active {
    color: #040405;
    color: #040405;
    font-weight: 600;
    border-bottom: 2px solid rgb(0, 134, 230);
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li.active a {
    color: #040405;
    color: #040405;
    font-weight: 600;
    border-bottom: 2px solid rgb(0, 134, 230);
}

/**End startup-planning**/
/**startup-tech-stack**/
.startups-building-section {
    background-color: #fff;
    background-color: #fff;
    padding: 40px 0px;
}

.startups-building-section .startups-building-inner .startups-building-heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405;
    color: #040405;
}

.startups-building-section .startups-building-inner .startups-building-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
}

.startups-building-section .startups-building-inner .startups-app-card {
    text-align: center;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app-tittle {
    margin-bottom: 44px;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app-tittle span {
    font-size: 18px;
    color: #616161;
    font-weight: 500;
    line-height: 27px;
    position: relative;
    opacity: 0.56;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app-tittle span::after {
    content: "";
    background-color: #616161;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 7px;
    left: -24px;
    transform: rotate(45deg);
    border-radius: 2px;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app-tittle span::before {
    content: "";
    background-color: #616161;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 7px;
    right: -24px;
    transform: rotate(45deg);
    border-radius: 2px;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app .startups-app-img img {
    width: auto;
    height: 95px;
    opacity: 0.5;
}

.startups-building-section .startups-building-inner .startups-app-card .startups-app .startups-app-img p {
    font-size: 15px;
    color: #616161;
    font-weight: 500;
    line-height: 22px;
    margin-top: 20px;
    opacity: 0;
}

.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app-tittle span {
    color: #040405;
    color: #040405;
    font-weight: 700;
    font-size: 20px;
    opacity: 1;
}

.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app-tittle span::after {
    content: "";
    width: 100px;
    height: 12px;
    position: absolute;
    top: 7px;
    left: -100px;
    background: transparent;
    background-image: url(../../assets/images/line2.png);
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-size: auto;
}

.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app-tittle span::before {
    content: "";
    width: 100px;
    height: 12px;
    position: absolute;
    top: 7px;
    right: 0px;
    background: transparent;
    background-image: url(../../assets/images/line1.png);
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-size: auto;
    right: -100px;
}

.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app .startups-app-img img {
    opacity: 1;
}

.startups-building-section .startups-building-inner .startups-app-card:hover .startups-app .startups-app-img p {
    font-size: 15px;
    color: #616161;
    font-weight: 500;
    line-height: 22px;
    opacity: 1;
}

/**End startup-tech-stack**/
/**startups-separate**/
.startups-separate-section {
    padding: 120px 0px 50px 0px;
    background-color: #fff;
    background-color: #fff;
}

.startups-separate-section .startups-separate-inner .startups-separate-content h2 {
    font-size: 30px;
    line-height: 45px;
    color: #040405;
    color: #040405;
    font-weight: 700;
}

.startups-separate-section .startups-separate-inner .startups-separate-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin-bottom: 30px;
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-img {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 24px;
    padding: 30px 20px;
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-img {
    margin-bottom: 20px;
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-img img {
    width: auto;
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-content h2 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 20px;
    color: #040405;
    color: #040405;
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-content .btn-primary {
    font-size: 16px;
    font-weight: 600;
}

/**End startups-separate**/
/********************End startup-details***********************/
/********************technology***********************/
/**framework**/
.framework-section {
    background-color: #fff;
    background-color: #fff;
    padding: 80px 0;
    position: relative;
}

.framework-section .heading {
    text-align: center;
    margin-bottom: 20px;
    max-width: 790px;
    margin: 0 auto;
    margin-bottom: 90px;
}

.framework-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.framework-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.framework-section .heading h2 {
    line-height: 60px;
}

.framework-section .framework-inner .framework-card {
    padding: 30px 30px 54px 30px;
    border: 1px solid #8dd0ff;
    text-align: center;
    position: relative;
    margin-bottom: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: -1px;
}

.framework-section .framework-inner .framework-card span {
    position: absolute;
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: 172px;
    max-width: 172px;
    border-radius: 12px;
    font-weight: 700;
    border: 1px solid #0086e6;
    top: -25px;
    background: #fff;
    color: #040405;
    background: #fff;
    color: #040405;
    line-height: 21px;
    font-size: 14px;
    left: 0px;
    right: 0px;
    margin: auto;
    padding: 14px 5px;
}

.framework-section .framework-inner .framework-card h5 {
    font-size: 20px;
    line-height: 24px;
    color: #040405;
    color: #040405;
    font-weight: 600;
}

.framework-section .framework-inner .framework-card p {
    font-size: 16px;
    line-height: 24px;
    margin: 14px 0px;
    min-height: 120px;
    color: #616161;
}

/**End framework**/
/**opt-to-hire**/
.onestop-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.onestop-section .onestop-inner .heading {
    max-width: 736px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 60px;
}

.onestop-section .onestop-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.onestop-section .onestop-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.onestop-section .onestop-inner .heading h2 {
    font-size: 30px;
    line-height: 40px;
}

.onestop-section .onestop-inner .box-card {
    margin-bottom: 40px;
}

.onestop-section .onestop-inner .onestop-box {
    padding: 2px;
    border-radius: 8px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    height: 100%;
    display: block;
}

.onestop-section .onestop-inner .onestop-box .box-inner {
    background-color: #fff;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.onestop-section .onestop-inner .onestop-box .box-counter {
    padding: 40px 10px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 116px;
    min-width: 116px;
}

.onestop-section .onestop-inner .onestop-box .box-counter h3 {
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
}

.onestop-section .onestop-inner .onestop-box .box-content {
    height: 100%;
    padding: 23px 15px;
}

.onestop-section .onestop-inner .onestop-box .box-content h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    color: #040405;
    color: #040405;
}

.onestop-section .onestop-inner .onestop-box .box-content p {
    color: #616161;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
    font-weight: 500;
}

.onestop-section .onestop-inner .box-green {
    background: linear-gradient(283.13deg, rgba(138, 188, 0, 0.05) 5.56%, rgba(204, 250, 78, 0.05) 113.66%) !important;
}

.onestop-section .onestop-inner .box-green .box-counter {
    background: linear-gradient(283.13deg, rgba(138, 188, 0, 0.05) 5.56%, rgba(204, 250, 78, 0.05) 113.66%) !important;
}

.onestop-section .onestop-inner .box-green .box-counter h3 {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

/**End opt-to-hire**/
/**our-milestones-details**/
.technologies-section {
    background-color: #fff;
    background-color: #fff;
    padding: 60px 0;
}

.technologies-section .heading h2 {
    font-size: 30px;
    line-height: 42px;
    color: #040405;
    color: #040405;
    text-align: center;
    margin-bottom: 20px;
}

.technologies-section .technologies-feature-card {
    padding: 0px 0 40px 0;
}

.technologies-section .technologies-feature-card .technologies-feature-content h2 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
    max-width: 430px;
}

.technologies-section .technologies-feature-card .technologies-feature-content h3 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #040405;
    color: #040405;
}

.technologies-section .technologies-feature-card .technologies-feature-content p {
    font-size: 18px;
    line-height: 27px;
    color: #616161;
    margin-bottom: 30px;
}

.technologies-section .technologies-feature-card .technologies-feature-image {
    border-radius: 12px;
    overflow: hidden;
}

.technologies-section .technologies-feature-card .technologies-feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.technologies-section .technologies-feature-latest {
    margin-bottom: 30px;
}

.technologies-section .technologies-feature-latest .letest-technologies-content h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

.technologies-section .technologies-feature-latest .letest-technologies-box {
    padding: 12px 23px 12px 12px;
    border: 1px solid rgba(0, 1, 26, 0.2);
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-img {
    max-width: 156px;
    min-width: 156px;
    height: 115px;
    border-radius: 12px;
    overflow: hidden;
}

.technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text {
    padding-left: 16px;
    width: 100%;
}

.technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text h3 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    min-height: 82px;
}

.technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text .letest-technologies-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text .letest-technologies-date span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #616161;
}

/**End our-milestones-details**/
/**our-milestones-tabs**/
.milestone-section {
    padding: 80px 0 0 0;
    background-color: #fff;
    background-color: #fff;
}

.milestone-section .heading {
    max-width: 736px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 60px;
}

.milestone-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.milestone-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.milestone-section .heading h2 {
    line-height: 60px;
}

.technologies-tabs-section {
    background-color: #fff;
    background-color: #fff;
    position: sticky;
    top: 79px;
    z-index: 9999;
}

.technologies-tabs-section .technologies-feature-heading {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
}

.technologies-tabs-section .technologies-feature-heading::before {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    height: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
}

.technologies-tabs-section .technologies-feature-heading li {
    text-align: center;
    margin-right: 22px;
    width: 100%;
    font-weight: 500;
}

.technologies-tabs-section .technologies-feature-heading li:last-child {
    margin-right: 0;
}

.technologies-tabs-section .technologies-feature-heading li ::after {
    content: "";
    background-image: url(../../assets/images/small-right-arrow1.svg);
    background-position: center;
    background-size: contain;
}

.technologies-tabs-section .technologies-feature-heading li a {
    color: #616161;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    border-bottom: 2px solid transparent;
}

.technologies-tabs-section .technologies-feature-heading li a:hover {
    color: #040405;
    color: #040405;
    font-weight: 500;
    border-bottom: 2px solid #0086e6;
}

.technologies-tabs-section .technologies-feature-heading li a.active {
    color: #040405;
    color: #040405;
    font-weight: 600;
    border-bottom: 2px solid #0086e6;
}

/**End our-milestones-tabs**/
/**strategies**/
.digital-service-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.digital-service-section .heading {
    text-align: center;
    margin-bottom: 20px;
}

.digital-service-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.digital-service-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700;
}

.digital-service-section .heading h3 {
    text-align: left;
}

.digital-service-section .heading h2 {
    font-size: 30px;
    line-height: 45px;
    text-align: left;
    margin-bottom: 40px;
}

.digital-service-section .step-feature-text h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #040405;
    color: #040405;
}

.digital-service-section .steps-feature {
    padding: 20px 20px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to left, #0086e6, #8dd0ff) 1;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    margin-bottom: 20px;
}

.digital-service-section .steps-feature .feature-img {
    margin-right: 23px;
    height: 52px;
    min-width: 52px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.digital-service-section .steps-feature .feature-img img {
    object-fit: contain;
}

.digital-service-section .steps-feature .feature-content h3 {
    font-size: 18px;
    line-height: 27px;
    color: #040405;
    color: #040405;
    margin-bottom: 5px;
    font-weight: 400;
}

.digital-service-section .steps-feature .feature-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #616161;
}

.digital-service-section .steps-feature:hover .feature-content h3 {
    font-weight: 600;
}

.digital-service-section .feature-green {
    border: 1px solid;
    border-image: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) 1;
}

.digital-service-section .feature-green .feature-img {
    background: linear-gradient(283.13deg, rgba(138, 188, 0, 0.05) 5.56%, rgba(204, 250, 78, 0.05) 113.66%) !important;
}

/**end strategies**/
/********************End technology***********************/
new chages .modal-form .modal-content {
    background-color: #fff;
    background-color: #fff;
}

new chages .modal-form .modal-content .modal-title {
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
}

new chages .modal-form .modal-content .btn-close {
    color: #040405 !important;
    color: #040405 !important;
}

new chages .modal-form .modal-content .modal-header,
new chages .modal-form .modal-content .modal-body,
new chages .modal-form .modal-content .modal-footer {
    padding: 18px 30px;
    border: 0 !important;
}

new chages .modal-form .form-group {
    margin-bottom: 20px;
}

new chages .modal-form .form-group .form-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #616161 !important;
    opacity: 0.56;
}

new chages .modal-form .form-group .form-control,
new chages .modal-form .form-group .form-select {
    padding: 10px 20px;
    height: 50px;
    border: 1px solid rgba(4, 4, 5, 0.2) !important;
    border-radius: 10px;
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    color: rgba(4, 4, 5, 1);
    background-color: #fff !important;
}

new chages .modal-form .form-group .form-label-check {
    padding-bottom: 20px;
}

new chages .modal-form .form-group .form-group-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

new chages .modal-form .form-group .form-group-checkbox .form-check {
    padding-right: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

new chages .modal-form .form-group .form-group-checkbox .form-check .form-check-label {
    color: #91959b !important;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
}

new chages .modal-form .modal-detail h2 {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    color: #040405;
    color: #040405;
    margin-bottom: 15px;
}

new chages .modal-form .modal-detail h2 span {
    color: #040405;
    color: #040405;
}

new chages .modal-form .modal-detail h2 strong {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

new chages .modal-form .modal-detail h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #040405;
    color: #040405;
    margin-bottom: 15px;
}

new chages .modal-form .modal-detail h3 span {
    color: #040405 !important;
    color: #040405 !important;
}

new chages .modal-form .modal-detail h3 strong {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

new chages .modal-form .modal-detail a {
    color: #0187e6 !important;
    text-decoration: underline;
}

new chages .modal-form .modal-detail .btn-primary {
    color: #fff !important;
    text-decoration: none !important;
}

new chages .modal-form .modal-detail ul {
    padding: 0;
    margin: 0;
}

new chages .modal-form .modal-detail ul li {
    font-size: 16px;
    line-height: 24px;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

new chages .modal-form .modal-detail ul li a {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

new chages .modal-form .modal-detail p {
    font-size: 16px;
    line-height: 26px;
    color: #040405;
    color: #040405;
    margin-bottom: 20px;
}

new chages .modal-form .modal-detail p a {
    color: #040405;
    color: #040405;
    font-weight: 500;
}

new chages .modal-form .modal-detail p strong {
    font-weight: 600;
    color: #040405;
    color: #040405;
}

new chages .modal-form .modal-detail ul {
    margin: 0;
    padding: 0;
}

.careers-about-section .careers-about-right img {
    max-width: 100%;
}

.careers-contributer-section .secton-titel {
    position: relative;
    z-index: 2;
}

.job-positions-section .job-searching-section form .job-searching-result ul li h3 {
    margin-bottom: 2px;
}

.job-positions-section .job-searching-section form .job-searching-result ul li p {
    margin-bottom: 2px;
}

.job-positions-section .job-searching-section form .form-group .form-input .form-select,
.job-positions-section .job-searching-section form .form-group .form-input .form-control {
    border-color: #616161;
    border-radius: 0;
}

.service-tabs-section {
    height: auto;
}

.service-tabs-section .service-feature-heading {
    align-items: stretch;
}

.service-tabs-section .service-feature-heading li a {
    height: 100%;
    display: flex;
    align-items: center;
}

.service-feature-section .service-follow .service-follow-content ul li span {
    border-radius: 3px;
    transform: rotate(45deg);
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.work-section {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.agile-team-section .col-lg-4.lg0 .team-card {
    border-top-left-radius: 12px;
}

.agile-team-section .col-lg-4.lg2 .team-card {
    border-top-right-radius: 12px;
}

.agile-team-section .col-lg-4.lg3 .team-card {
    border-bottom-left-radius: 12px;
}

.agile-team-section .col-lg-4.lg5 .team-card {
    border-bottom-right-radius: 12px;
}

.technologies-section .technologies-inner .heading h2 {
    text-align: left;
}

.onestop-section .onestop-inner .onestop-box .box-inner {
    border-radius: 0px;
}

.onestop-section .onestop-inner .onestop-box .box-content h3 {
    font-weight: 600;
}

.technologies-tabs-section .technologies-feature-heading {
    align-items: stretch;
}

.technologies-tabs-section .technologies-feature-heading li a {
    height: 100%;
    display: flex;
    align-items: center;
}

.onestop-section .onestop-inner .onestop-box {
    border-radius: 0;
}

.onestop-section .onestop-inner .onestop-box .box-counter {
    border-radius: 0;
}

.startups-process-section {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.modal-form .form-group .form-control::file-selector-button,
.modal-form .form-group:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: transparent;
    border: 0px;
    background-image: url(../../assets/images/upload-file-icon.png);
    width: 35px;
    height: 35px;
    color: transparent;
    overflow: hidden;
    text-indent: 9999px;
    background-size: 70%;
    background-position: center center;
    margin-top: 0;
    background-repeat: no-repeat;
    position: relative;
    top: 0px;
    margin-left: 0;
    margin-right: 20px;
    background-color: rgba(141, 208, 255, 0.26);
    border-radius: 6px;
}

.modal-form .form-group .form-control[type=file] {
    padding: 6px 20px 6px 10px;
}

.theme-dark .modal-form .modal-content .btn-close {
    opacity: 1;
    filter: invert(1);
}

/*16-1-2023 request scss changes*/
.request-form-section .request-form-inner .request-form-heading h6:before {
    z-index: 1 !important;
    background-color: rgba(0, 1, 26, 0.1) !important;
}

.request-form-section .request-form-inner .request-form-heading h6 span {
    position: relative !important;
    z-index: 2 !important;
}

.approach-section .approach-inner .approach-content p span {
    color: #616161;
}

section .steps-feature {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);

    border-radius: 4px;
    padding: 1px;
    margin-bottom: 20px;
    border-image: none !important;
    border: 0 !important;
    padding: 1px !important;
}

section .steps-feature .inner-step {
    background-color: #fff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding: 20px 20px;
    width: 100%;
    border: 2px solid #8dd0ff
}

section .steps-feature.feature-green {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
    border-radius: 4px;
    padding: 1px;
    margin-bottom: 20px;
}

.industries-system-section .development-box {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    padding: 1px !important;
    border-radius: 12px;
    overflow: hidden;
}

.industries-system-section .development-box .development-box-inner {
    background-color: #fff;
    background-color: #fff;
    border-radius: 10px;
}

.design-stage-section.digital-service-section .steps-detail .step-row {
    margin-bottom: 0px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group .input-group-text {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group label.input-group-text {
    padding: 0 10px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 0px;
    box-shadow: none;
    outline: none;
    margin: 0;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group label.input-group-text img {
    width: 20px;
    filter: invert(72%) sepia(16%) saturate(0%) hue-rotate(184deg) brightness(88%) contrast(96%);
}

.hire-section .hite-content span {
    color: #91959b;
}

.book-section .book-box .book-text p {
    color: #91959b;
}

.service-feature-section .service-feature-card .service-feature-content p {
    color: #91959b;
}

.service-feature-section .service-feature-card .service-feature-content h6 {
    color: #040405;
    color: #040405;
    opacity: 0.4;
}

.testimonial-section .gallery-thumbs .swiper-slide img {
    object-fit: cover;
    object-position: center center;
}

.theme-dark .design-stage-section .design-steps ul li a {
    color: #616161;
}

.theme-dark .design-stage-section .design-steps ul .active {
    color: #040405;
    color: #040405;
}

.meet-management-section .profile-info ul li a {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.startups-features-section .startups-features-inner .startups-heading {
    padding-left: 40px;
    padding-right: 40px;
}

.casesudies-professional-section {
    padding: 80px 0;
    background-color: #fff;
    background-color: #fff;
}

.casesudies-professional-section .casesudies-professional-inner .heading {
    margin-bottom: 60px;
}

.casesudies-professional-section .casesudies-professional-inner .heading h3 {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
    margin-bottom: 50px;
    width: 100%;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills {
    position: relative;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills::before {
    content: "";
    bottom: -75px;
    left: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #8dd0ff !important;
    border-bottom: 1px solid #abd8f9;
    opacity: 1;
    margin: 0;
    margin: 0;
    display: block;
    z-index: 99;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    overflow: hidden;
    border: 1px solid #ABD8F9;
    border-bottom: 0;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul li {
    padding: 26px 50px 0 50px;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul li:nth-child(1) {
    padding-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #ABD8F9;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul li h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 0;
    color: #040405;
    color: #040405;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul:nth-last-child(1) {
    border-radius: 0px 0px 20px 20px;
    border-bottom: 1px solid #ABD8F9;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills .tech-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills .tech-list .tech-item {
    height: 46px;
    width: 46px;
    margin-right: 80px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills .tech-list .tech-item:nth-last-child(1) {
    margin-right: 0;
}

/**19-1-2023(H)**/
.theme-light {
    --text-color-black: #000;
    --number-sub-light: #0003;
}

.theme-dark {
    --text-color-black: #fff;
    --number-sub-light: rgba(255, 255, 255, 0.56);
}

.service-hero-section .heading p,
.about-section .heading p,
.findus-section .heading p,
.agile-banner-section .agile-banner-inner .agile-banner-content p {
    color: #91959b !important;
}

.service-hero-section .heading h2 {
    margin-bottom: 12px;
}

.technologies-feature-latest .letest-technologies-box {
    padding: 10px 23px 12px 10px;
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-text h3 {
    color: #000;
}

.technologies-feature-latest .letest-technologies-box {
    border-color: rgba(4, 4, 5, 0.2);
}

.service-feature-section .service-follow .service-follow-content h5 {
    color: #000;
}

.service-feature-section .service-follow .service-follow-content ul li h6 {
    color: #00011c;
}

.footer-section .footer-inner .hire-marquee {
    padding-top: 9px;
    padding-bottom: 9px;
}

.service-tabs-section .service-feature-heading {
    justify-content: flex-start;
}

.digital-middle .digital-banner-section .digital-banner-inner .heading h2 {
    margin-bottom: 20px;
}

.design-stage-section .steps-detail .steps-feature .feature-content h3 {
    color: #000;
}

.digital-middle .our-project .row>.col-lg-4 {
    order: 2;
}

.digital-middle .our-project .row>.col-lg-8 {
    order: 3;
}

.digital-middle .testimonial-section .testimonial-main-box .testimonial-text p,
.digital-middle .testimonial-section .testimonial-main-box .coordinator span {
    color: #040405;
    color: #040405;
}

.agile-team-section .agile-team-inner .team-card span {
    color: #0003;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons a {
    color: #000;
}

.casesudies-professional-section .casesudies-professional-inner .tech-skills ul {
    padding: 0;
}

.form-group .form-check-input {
    background-color: transparent !important;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    max-width: 975px;
}

.startups-hire-marquee-section {
    overflow: hidden;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading h3 {
    margin-bottom: 20px;
}

.startups-hire-marquee-section .hire-marquee div a::after {
    border-radius: 4px;
    transform: rotate(45deg);
}

.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-content h2 {
    font-weight: 700;
}

.startups-feature-section .startups-feature-inner .startups-features-card h4 {
    font-weight: 600;
}

.startups-building-section .startups-building-inner .startups-building-heading h2 {
    font-weight: 700;
}

.blog-topstories-section .blog-topstories-inner .blog-card-small .blog-content h3 {
    font-weight: 700;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    color: #91959b;
}

.industries-tabs-section .slick-slide a.active {
    color: #000;
}

.industries-system-section .development-box .development-box-inner {
    border-radius: 12px;
}

.industries-system-section .development-box .development-bottom .title h3 {
    color: #00011c;
}

.case-studies-about-section .case-studies-listing .share-icon .icon-list-img {
    padding-left: 0;
}

.sticky-bottom {
    position: sticky;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
    background-color: --section-primary;
}

/**************home page****************/
.process-section .process-inner.mobile-process-inner {
    display: none;
}

.process-section .process-inner .process-list ul li:hover {
    font-weight: 500;
    color: #040405 !important;
}

.homepage-middle .banner-section {
    padding: 82px 0 80px 0;
}

.homepage-middle .banner-section .banner-heading {
    padding: 100px 0 50px 0;
}

.homepage-middle .banner-section .banner-heading h1 {
    font-size: 54px;
    line-height: 65px;
    margin-bottom: 49px;
}

.homepage-middle .banner-section .banner-heading .btn {
    line-height: 19px;
    font-weight: 600;
    font-size: 14px;
}

.homepage-middle .banner-section .banner-inner .banner-img {
    margin-bottom: 82px;
}

.homepage-middle .banner-section .solution-box .text p {
    line-height: 17px;
}

.homepage-middle .service-section {
    padding: 80px 0 185px;
}

.homepage-middle .service-section .heading h3 {
    margin-bottom: 12px;
}

.homepage-middle .service-section .heading h2 {
    margin-bottom: 60px;
}

.homepage-middle .service-section .service-bg-image {
    padding-top: 0;
}

.homepage-middle .process-section .heading h3 {
    margin-bottom: 16px;
}

.homepage-middle .process-section .process-section .process-list ul li {
    font-size: 28px !important;
    line-height: 42px;
}

.homepage-middle .work-section {
    padding: 160px 0 80px 0;
    background: #fff;
    background: #fff;
}

.homepage-middle .work-section .heading {
    margin-bottom: 48px;
}

.homepage-middle .work-section .heading h3 {
    margin-bottom: 16px;
}

.homepage-middle .work-section .our-project .project .project-user .retention p {
    font-weight: 500;
}

.homepage-middle .work-section .our-project .project .project-link {
    margin-bottom: 32px;
}

.homepage-middle .work-section .our-project .project .project-link a {
    font-weight: 600;
}

.homepage-middle .work-section .our-project .project-large .row:nth-child(2) .col-lg-6:first-child {
    width: 33.33%;
}

.homepage-middle .work-section .our-project .project-large .row:nth-child(2) .col-lg-6:nth-child(2) {
    width: 66.66%;
}

.homepage-middle .work-section .our-project .project-large .row:nth-child(2) .col-lg-6:nth-child(2) .project-img {
    justify-content: flex-end;
    margin-right: -12px;
}

.homepage-middle .client-section {
    padding: 80px 0 80px 0;
}

.homepage-middle .client-section .heading {
    margin-bottom: 48px;
}

.homepage-middle .client-section .heading h3 {
    line-height: 35.6px;
    margin-bottom: 16px;
}

.homepage-middle .client-section .solution-listing {
    margin-top: 32px;
}

.homepage-middle .client-section .solution-listing .solution-box {
    justify-content: space-between;
}

.homepage-middle .testimonial-section .heading {
    margin-bottom: 48px;
}

.homepage-middle .testimonial-section .heading h3 {
    margin-bottom: 16px;
}

.homepage-middle .testimonial-section .testimonial-main-box {
    padding: 44px 32px 26px 32px;
}

.homepage-middle .testimonial-section .testimonial-main-box .star-list {
    margin-bottom: 18px;
}

.homepage-middle .testimonial-section .testimonial-main-box .testimonial-text p {
    margin-bottom: 22px;
}

.homepage-middle .testimonial-section .gallery-thumbs {
    padding: 10px 0 80px 0;
}

.homepage-middle .faq-section .heading {
    margin-bottom: 48px;
}

.homepage-middle .faq-section .heading h3 {
    margin-bottom: 16px;
}

.homepage-middle .faq-section .accordion .accordion-item .accordion-header .accordion-button {
    padding: 24px 50px 24px 24px;
}

.homepage-middle .faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 24px;
}

.homepage-middle .service-section .service-feature .service-tools li {
    line-height: 14px;
}

.homepage-middle .process-section .process-list {
    padding-bottom: 82px;
}

.homepage-middle .testimonial-section {
    padding: 80px 0 0 0;
}

.homepage-middle .faq-section {
    padding: 70px 0 80px 0;
}

.service-section .service-feature h3 img {
    display: none;
}

/**************end home page****************/
.footer-section .content-company h3 {
    line-height: 24px;
    margin-bottom: 15px;
}

.footer-section .content-company ul li::after {
    top: 12px;
}

.footer-section .content-company ul li a {
    font-size: 24px;
    line-height: 29px;
}

.footer-section .content-company .content {
    margin-bottom: 34px;
}

.footer-section .footer-content .footerLinks h3 {
    margin-bottom: 22px;
}

.footer-section .footer-content .footerLinks ul li a {
    font-weight: 400;
}

.footer-section .footer-copyright .copyright ul li {
    margin-right: 20px;
}

.footer-section .footer-bottom {
    padding-top: 83px;
}

.header .navbar .submenu .submenu-inner .sub-menu-content a {
    padding: 12px 12px 12px 12px;
}

.header .navbar .submenu .submenu-inner .sub-menu-content a P {
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/****************7-2-2023*******************/
.homepage-middle .banner-section .banner-heading .btn {
    font-size: 16px;
}

.theme-dark .sticky-bottom {
    padding: 0;
}

.theme-dark .sticky-bottom .btn.btn-primary {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.homepage-middle .faq-section {
    padding-bottom: 100px;
}

.client-section .solution-listing .solution-box {
    padding: 18px 20px 18px 20px;
}

.homepage-middle .banner-section .banner-heading h1 {
    margin-bottom: 32px;
}

.service-section .service-feature .service-tools li:before {
    display: none;
}

.service-section .service-feature .service-tools li {
    border: 1px solid #8dd0ff !important;
    border-radius: 8px;
}

/*********9-2-2023********/
/****modal*****/
.modal-form .modal-lg {
    max-width: 1110px;
}

.modal-form .modal-content .btn-close {
    background-image: url(../../assets/images/grey-cross.svg);
    width: 13px;
    height: 13px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0 0 10px;
}

.modal-form .modal-content .modal-header {
    padding: 23px 30px 0px;
}

.modal-form .modal-content .modal-body {
    padding: 18px 30px 30px;
}

.modal-form .modal-content .modal-body .modal-banner-section {
    min-height: 205px;
    position: relative;
    display: flex;
    align-items: center;
    background-image: url(../../assets/images/sq-bg.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px 210px;
    margin-bottom: 30px;
}

.modal-form .modal-content .modal-body .modal-banner-section:after {
    content: "";
    background-position: center left;
    background-size: auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 205px;
}

.modal-form .modal-content .modal-body .modal-banner-section::before {
    content: "";
    background-position: center right;
    background-size: auto;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 205px;
}

.modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content h2 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #040405 !important;
    font-weight: 600;
    max-width: 504px;
    margin: 0 auto;
}

.modal-form .modal-content .modal-body .modal-banner-notes {
    position: relative;
    text-align: center;
}

.modal-form .modal-content .modal-body .modal-banner-notes::after {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 100%;
    height: 1px;
    opacity: 20%;
    right: 0px;
    top: 10px;
}

.modal-form .modal-content .modal-body .modal-banner-notes p {
    color: #91959b !important;
    font-size: 16px;
    font-weight: 500;
    color: #040405;
    color: #040405;
    line-height: 24px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
    position: relative;
    display: inline-block;
    z-index: 2;
}

.modal-form .modal-content .modal-body .form-group {
    margin-bottom: 30px;
}

.modal-form .modal-content .modal-body .form-group .form-control,
.modal-form .modal-content .modal-body .form-group .form-select {
    height: 64px;
}

.modal-form .modal-content .modal-body .form-group label {
    margin-bottom: 10px;
}

.modal-form .modal-content .modal-body .form-group textarea {
    min-height: 150px;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio {
    position: relative;
    padding-bottom: 2px;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio input.form-check-input {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label {
    background: #ffffff;
    border: 1px dashed rgba(4, 4, 5, 0.2);
    border-radius: 10px;
    min-height: 72px;
    padding: 36px 13px 6px 15px;
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #91959b;
    position: relative;
    cursor: pointer;
    margin: 0;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label::after {
    content: "";
    top: 12px;
    left: 15px;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label {
    background-color: #fcfff7;
    border: 1px solid #84bd00;
    color: #84bd00;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label.form-radio-1:after {
    background-image: url(../../assets/images/gren-shape-icon.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label.form-radio-2:after {
    background-image: url(../../assets/images/green-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label.form-radio-3:after {
    background-image: url(../../assets/images/small-green-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio label.form-radio-1:after {
    background-image: url(../../assets/images/blue-shape-icon.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio label.form-radio-2:after {
    background-image: url(../../assets/images/blue-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio label.form-radio-3:after {
    background-image: url(../../assets/images/small-blue-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label {
    background-color: #fcfff7;
    border: 1px solid #84bd00;
    color: #84bd00;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label.form-radio-1:after {
    background-image: url(../../assets/images/green-time.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label.form-radio-2:after {
    background-image: url(../../assets/images/green-time40.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label.form-radio-3:after {
    background-image: url(../../assets/images/green-time20.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio label.form-radio-1:after {
    background-image: url(../../assets/images/blue-time40.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio label.form-radio-2:after {
    background-image: url(../../assets/images/blue-time30.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio label.form-radio-3:after {
    background-image: url(../../assets/images/blue-time20.svg);
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid rgba(4, 4, 5, 0.2);
    border-radius: 10px;
    min-height: 208px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file label {
    cursor: pointer;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 12px;
    width: 72px;
    height: 72px;
    overflow: hidden;
    margin: 0 auto 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #808188;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span:first-child {
    margin-bottom: 8px;
    cursor: pointer;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group {
    width: 184px;
    position: relative;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus .btn,
.modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus .btn {
    height: 44px;
    width: 42px;
    padding: 0 !important;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus .btn:hover,
.modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus .btn:hover {
    box-shadow: none;
    transform: inherit;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus {
    position: absolute;
    top: 11px;
    right: 11px;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus {
    position: absolute;
    top: 11px;
    left: 11px;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-input input {
    height: 64px;
    color: #040405 !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.modal-form .modal-content .modal-body .form-group .modul-document-link .form-control {
    color: #0086e6;
}

.modal-form .modal-content .modal-body .form-group.modal-recaptcha img {
    max-width: 100%;
}

.modal-form .modal-content .modal-footer {
    padding: 0px 30px;
}

.modal-form .modal-content .modal-footer .modal-footer-inner {
    padding: 18px 0;
    width: 100%;
    border-top: 1px solid rgba(0, 134, 230, 0.2) !important;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.modal-form .modal-content .modal-footer .modal-footer-inner .btn {
    margin: 5px 0;
    min-width: 165px;
}

.modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
    margin-right: 32px;
    border: 2px solid #0086e6 !important;
    color: #616161 !important;
}

.modal-form.hire-developer-modal .modal-content .modal-banner-section:after {
    background-image: url(../../assets/images/lap.svg);
}

.modal-form.hire-developer-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/cup.svg);
}

.modal-form.request-quote-modal .modal-content .modal-banner-section::after {
    background-image: url(../../assets/images/request-banner-img-2.svg);
}

.modal-form.request-quote-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/request-banner-img-3.svg);
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file {
    min-height: auto;
    padding: 38px 30px;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label .upload-file-img {
    margin-bottom: 0;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label .upload-text {
    flex: 1;
    padding-left: 30px;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label .upload-text span {
    text-align: left;
}

.modal-form.connect-now-modal .modal-content .modal-banner-section::after {
    background-image: url(../../assets/images/connect-now-banner-1.svg);
}

.modal-form.connect-now-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/connect-now-banner-2.svg);
}

.modal-form.resource-rate-modal .modal-content .modal-banner-section {
    padding: 20px 175px;
}

.modal-form.resource-rate-modal .modal-content .modal-banner-section::after {
    background-image: url(../../assets/images/resource-rate-modal-1.svg);
}

.modal-form.resource-rate-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/resource-rate-modal-2.svg);
}

.modal-form.resource-rate-modal .modal-content .modal-banner-section .modal-banner-content h2 {
    max-width: 660px;
}

.modal-form.business-modal .modal-content .modal-banner-section {
    padding: 20px 175px;
}

.modal-form.business-modal .modal-content .modal-banner-section::after {
    background-image: url(../../assets/images/resource-rate-modal-1.svg);
}

.modal-form.business-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/resource-rate-modal-2.svg);
}

.modal-form.business-modal .modal-content .modal-banner-section .modal-banner-content h2 {
    max-width: 660px;
}

.modal-form.careers-forms-modal .modal-content .modal-banner-section {
    padding: 0 0;
    justify-content: space-between;
    align-items: flex-start;
    background-image: url(../../assets/images/career-top-bg.svg);
}

.modal-form.careers-forms-modal .modal-content .modal-banner-section .modal-banner-content {
    padding: 60px 30px 50px;
    text-align: left;
    max-width: 630px;
    margin-left: 0;
}

.modal-form.careers-forms-modal .modal-content .modal-banner-section .modal-banner-content h2 {
    text-align: left;
    max-width: inherit;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 30px;
    color: #040405 !important;
}

.modal-form.careers-forms-modal .modal-content .modal-banner-section .modal-banner-content p {
    margin-bottom: 0;
    padding-top: 11px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #91959b;
}

.modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item {
    border: none;
    margin-bottom: 20px;
}

.modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-body {
    padding: 30px 0;
}

.modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header {
    position: relative;
    overflow: hidden;
}

.modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header::after {
    content: "";
    width: 8px;
    height: 75%;
    top: 50%;
    transform: translateY(-50%);
    left: -4px;
    background: #0086e6;
    border-radius: 0px 10px 10px 0px;
    z-index: 3;
    position: absolute;
}

.modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header .accordion-button {
    background: #f2f9fe;
    border: 1px solid #dcecf8;
    border-radius: 10px;
    box-shadow: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 18px 20px;
    color: #040405 !important;
}

.modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header .accordion-button::after {
    content: "";
    width: 14px;
    height: 14px;
    background-position: center center;
    background-size: contain;
    background-image: url("../../assets/images/blue-plus.svg");
    background-repeat: no-repeat;
}

.modal-form.careers-forms-modal .modal-content .form-group .modal-upload-file {
    min-height: auto;
    padding: 38px 30px;
}

.modal-form.careers-forms-modal .modal-content .form-group .modal-upload-file label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
}

.modal-form.careers-forms-modal .modal-content .form-group .modal-upload-file label .upload-file-img {
    margin-bottom: 0;
}

.modal-form.careers-forms-modal .modal-content .form-group .modal-upload-file label .upload-text {
    flex: 1;
    padding-left: 30px;
}

.modal-form.careers-forms-modal .modal-content .form-group .modal-upload-file label .upload-text span {
    text-align: left;
}

.theme-dark .modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label {
    background: #fff !important;
}

.theme-dark .modal-form .modal-content .modal-body .form-group .modal-upload-file {
    background: #fff !important;
}

.theme-dark .modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
    background: #fff !important;
}

.theme-dark .modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item {
    background: #f3f9fe !important;
}

.theme-dark .modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header .accordion-button {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    border-color: rgba(4, 4, 5, 0.2);
}

/****end modal*****/
.blog-page-section .blog-banner-section {
    padding: 32px 0 30px 0;
}

.blog-page-section .blog-banner-section .blog-banner-inner .blog-banner-content {
    padding: 120px 0 100px 0;
}

.blog-page-section .blog-banner-section .blog-banner-inner .blog-banner-content .heading h2 {
    margin-bottom: 24px;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 span {
    color: #91959b;
}

.blog-page-section .book-section {
    padding: 110px 0 120px 0;
}

.blog-page-section .blog-allstories-section {
    padding-top: 50px;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
.blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    border-radius: 12px 0 0 12px;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card-div {
    margin-bottom: 32px;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner nav {
    margin-top: 48px;
}

.blog-page-section .blog-topstories-section .blog-topstories-inner .blog-card-small .blog-content h3 {
    min-height: 53px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.theme-light {
    --text-blog-color-black: #91959b;
    --number-sub-light: #0003;
}

.theme-dark {
    --text-blog-color-black: rgba(255, 255, 255, 0.56);
    --number-sub-light: #ffffff80;
}

.blog-detail-development .blog-digital-section {
    padding-top: 32px;
}

.blog-detail-development .blog-digital-section .business-development-btn a {
    font-size: 14px;
    color: #040405;
    color: #040405;
    font-weight: 500;
    line-height: 20px;
    display: inline-block;
    border-radius: 8px;
    padding: 6px 10px;
    margin-bottom: 6px;
    margin-right: 6px;
    border: 1px solid #8dd0ff;
}

.blog-detail-development .blog-digital-section .business-development-btn {
    padding-bottom: 20px;
}

.blog-detail-development .blog-digital-section .blog-digital-inner .blog-digital-heading p {
    color: #91959b;
}

.blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a {
    color: #91959b;
}

.blog-detail-development .blog-articles-section {
    padding-top: 80px;
}

.blog-detail-development .blog-articles-section .blog-articles-inner .blog-card .blog-img {
    border-radius: 12px 0 0 12px;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content h3 {
    font-weight: 600;
}

.theme-light {
    --text-proposal-color-black: #91959b;
    --number-sub-light: #0003;
}

.theme-dark {
    --text-proposal-color-black: rgba(255, 255, 255, 0.56);
    --number-sub-light: #ffffff80;
}

.request-proposal .request-banner-section {
    padding: 32px 0 60px 0;
}

.request-proposal .request-banner-section .request-banner-inner .request-banner-content {
    padding: 120px 0px 110px 0;
}

.request-proposal .request-banner-section .request-banner-inner .request-banner-content h2 {
    margin-bottom: 10px;
}

.request-proposal .request-form-section .request-form-inner .request-form-heading h6::before {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.request-proposal .request-form-section .request-form-inner .request-form-heading h6 span {
    color: #91959b;
}

.request-proposal .about-project-section .about-project-inner .about-project-heading h6::before {
    z-index: 1;
}

.request-proposal .about-project-section .about-project-inner .about-project-heading h6 span {
    position: relative;
    z-index: 2;
    color: #91959b !important;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .select-text {
    margin-bottom: 0px !important;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .form-group .form-group-checkbox .form-check .form-check-label {
    color: #91959b !important;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .form-group .form-label {
    color: #91959b !important;
}

.request-proposal .agreement-section .agreement-inner .agreement-list {
    margin-bottom: 80px;
}

.request-proposal .agreement-section {
    padding-top: 10px;
    padding-bottom: 160px;
}

.form-check-input:active {
    filter: unset !important;
}

.form-check-input[type=checkbox]:focus:after {
    background-color: unset !important;
}

.request-proposal .request-form-section .request-form-inner .request-form .form-group .form-label,
.modal-form .form-group .form-label {
    opacity: 1;
    color: #91959b !important;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .MuiBox-root {
    margin: 0;
    margin-top: 15px;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .slider-labels {
    margin: 0;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider {
    padding: 15px 20px 15px 20px;
}

.request-proposal .agreement-section .agreement-inner .agreement-list {
    padding: 20px 0 20px 20px;
}

.modal-form .form-group {
    margin-bottom: 30px;
}

.case-studies-main-section .casestudies-section {
    padding: 32px 0 30px 0;
}

.case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content {
    padding: 120px 0 100px 0;
}

.case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content .heading h2 {
    margin-bottom: 24px;
}

.case-studies-main-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 span {
    color: #91959b;
}

.book-section {
    padding: 110px 0 120px 0 !important;
}

.service-middle .service-hero-section {
    padding: 80px 0 0 0;
}

.service-middle .service-hero-section .heading h3 {
    margin-bottom: 16px;
}

.service-middle .service-hero-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
}

.technologies-feature-latest .letest-technologies-box {
    padding: 12px 16px 12px 12px;
}

.service-feature-section .service-follow .service-follow-content ul li {
    font-weight: 500;
}

.compeny-section {
    padding: 160px 0 80px;
}

.hire-section .hite-content h2 {
    max-width: 920px;
}

/*****10-2-2023(h)*******/
/******technologies-middle********/
.theme-light {
    --text-normal-color: rgb(97 97 97 / 56%);
    --text-feature-content: rgb(4 4 5 / 56%);
}

.theme-dark {
    --text-normal-color: rgba(255, 255, 255, 0.56);
    --text-feature-content: rgba(255, 255, 255, 0.56);
}

.technologies-middle .agile-banner-section {
    padding: 32px 0 40px 0;
}

.technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content {
    min-height: 557px;
}

.technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    max-width: 858px;
    margin: 0 auto 40px auto;
}

.technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading h3 {
    margin-bottom: 16px;
}

.technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    line-height: 60px;
    margin-bottom: 0;
}

.technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading p {
    margin-top: 15px;
    margin-bottom: 0;
}

.technologies-middle .onestop-section {
    padding: 40px 0;
}

.technologies-middle .onestop-section .onestop-inner .heading {
    margin-bottom: 48px;
}

.technologies-middle .onestop-section .onestop-inner .heading h3 {
    margin-bottom: 16px;
}

.technologies-middle .onestop-section .onestop-inner .heading h2 {
    margin-bottom: 0;
}

.technologies-middle .onestop-section .onestop-inner .onestop-box .box-content p {
    color: #91959b;
}

.technologies-middle .onestop-section .onestop-inner .onestop-box .box-counter h3 {
    font-size: 22px;
}

.technologies-middle .milestone-section {
    padding: 84px 0 0 0;
}

.technologies-middle .milestone-section .heading {
    margin-bottom: 28px;
}

.technologies-middle .milestone-section .heading h3 {
    margin-bottom: 16px;
}

.technologies-middle .milestone-section .heading h2 {
    margin-bottom: 0;
}

.technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-card .technologies-feature-content h3 {
    margin-bottom: 0;
    margin-top: 20px;
}

.technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-card .technologies-feature-content p {
    color: rgb(97 97 97 / 56%);
    margin-bottom: 0;
    padding-top: 10px;
}

.technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-latest {
    margin-bottom: 0;
}

.technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-latest .letest-technologies-box .letest-technologies-text h3 {
    color: #000;
}

.technologies-middle .digital-service-section {
    padding: 120px 0 80px;
}

.technologies-middle .digital-service-section .steps-detail .steps-feature .feature-content h3 {
    color: #040405;
    color: #040405;
}

.technologies-middle .digital-service-section .steps-detail .steps-feature .feature-content p {
    color: rgb(4 4 5 / 56%);
}

.technologies-middle .design-stage-section {
    padding: 120px 0 60px;
}

.technologies-middle .design-stage-section .design-stage-inner .steps-detail .step-row {
    padding-bottom: 0;
}

.technologies-middle .framework-section {
    padding: 80px 0 0;
}

.technologies-middle .framework-section .framework-inner .heading {
    margin-bottom: 84px;
}

.technologies-middle .framework-section .framework-inner .heading h3 {
    margin-bottom: 16px;
}

.technologies-middle .framework-section .framework-inner .heading h2 {
    line-height: 50px;
    margin-bottom: 0;
}

.technologies-middle .framework-section .framework-inner .framework-card {
    margin-bottom: 84px;
}

.technologies-middle .faq-section {
    padding: 76px 0 60px;
}

.technologies-middle .faq-section .faq-inner .heading {
    margin-bottom: 48px;
}

.technologies-middle .faq-section .faq-inner .heading h3 {
    margin-bottom: 16px;
}

.technologies-middle .faq-section .faq-inner .heading h2 {
    margin-bottom: 0;
}

.technologies-middle .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    color: #91959b;
}

.technologies-middle .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    top: 38px;
}

.technologies-middle .faq-section .faq-inner .accordion .accordion-item .accordion-header .accordion-button:after {
    top: 25px;
}

.technologies-middle .book-section {
    padding: 80px 0 160px 0;
}

/****** end technologies********/
/**************about us****************/
.aboutus-middle .heading h3 {
    margin-bottom: 16px;
}

.aboutus-middle .heading h2 {
    margin-bottom: 0px;
}

.aboutus-middle .heading p {
    margin-top: 14px;
}

.aboutus-middle .about-work-slider {
    display: none;
}

.aboutus-middle .difference-section .heading h2 {
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
}

.aboutus-middle .difference-section .heading h3 {
    margin-bottom: 16px;
}

.aboutus-middle .difference-section .make-text p {
    color: rgb(97 97 97 / 56%);
    color: rgb(97 97 97 / 56%);
}

.aboutus-middle .difference-section .deference-feature .feature-box p {
    color: rgb(97 97 97 / 56%);
    margin-bottom: 36px;
}

.aboutus-middle .believe-section .heading {
    margin-bottom: 48px;
}

.aboutus-middle .believe-section .believe-box .believe-content p {
    color: rgb(97 97 97 / 56%);
    color: rgb(97 97 97 / 56%);
}

.aboutus-middle .vibe-section {
    padding: 80px 0 160px;
}

.aboutus-middle .vibe-section .heading {
    margin-bottom: 48px;
}

.aboutus-middle .meet-management-section .heading h2 {
    margin-bottom: 24px;
}

.aboutus-middle .meet-management-section .meet-management-inner .meet-slider-div {
    padding-top: 8px;
}

/**************end about us****************/
/****Industries*****/
.industry-middle .heading h3 {
    margin-bottom: 16px !important;
}

.industry-middle .agile-banner-inner .agile-banner-content {
    min-height: 525px;
}

.industry-middle .agile-banner-inner .agile-banner-content .heading {
    margin: 0 auto 38px auto;
}

.industry-middle .agile-banner-inner .agile-banner-content .heading h3 {
    margin-bottom: 16px;
}

.industry-middle .demand-education-section {
    padding: 40px 0 60px 0px;
}

.industry-middle .demand-education-section .demand-inner .heading {
    margin-bottom: 48px;
}

.industry-middle .services-education-section {
    padding: 70px 0 0 0;
}

.industry-middle .industries-tabs-section .slick-slide a {
    color: #91959b;
}

.industry-middle .industries-system-section .industries-card p {
    color: #91959b;
}

.industry-middle .industries-system-section .development-box .development-top p {
    color: #91959b;
}

.industry-middle .industries-system-section {
    padding: 40px 0px 80px 0px;
    position: relative;
}

/****end Industries*****/
/****modal*****/
.modal-form .modal-lg {
    max-width: 1110px;
}

.modal-form .modal-content .btn-close {
    background-image: url(../../assets/images/grey-cross.svg);
    width: 13px;
    height: 13px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0 0 10px;
}

.modal-form .modal-content .modal-header {
    padding: 23px 30px 0px;
}

.modal-form .modal-content .modal-body {
    padding: 18px 30px 30px;
}

.modal-form .modal-content .modal-body .modal-banner-section {
    min-height: 205px;
    position: relative;
    display: flex;
    align-items: center;
    background-image: url(../../assets/images/sq-bg.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
}

.modal-form .modal-content .modal-body .modal-banner-section:after {
    content: "";
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 205px;
}

.modal-form .modal-content .modal-body .modal-banner-section::before {
    content: "";
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 205px;
}

.modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content h2 {
    font-size: 20px;
    line-height: 30px;
    color: #040405 !important;
    font-weight: 600;
    max-width: 504px;
    margin: 0;
}

.modal-form .modal-content .modal-body .modal-banner-right {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-form .modal-content .modal-body .modal-banner-notes {
    position: relative;
    text-align: center;
}

.modal-form .modal-content .modal-body .modal-banner-notes::after {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 100%;
    height: 1px;
    opacity: 20%;
    right: 0px;
    top: 10px;
}

.modal-form .modal-content .modal-body .modal-banner-notes p {
    color: #91959b !important;
    font-size: 16px;
    font-weight: 500;
    color: #040405;
    color: #040405;
    line-height: 24px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
    position: relative;
    display: inline-block;
    z-index: 2;
}

.modal-form .modal-content .modal-body .form-group {
    margin-bottom: 30px;
}

.modal-form .modal-content .modal-body .form-group .form-control,
.modal-form .modal-content .modal-body .form-group .form-select {
    height: 64px;
}

.modal-form .modal-content .modal-body .form-group label {
    margin-bottom: 10px;
}

.modal-form .modal-content .modal-body .form-group textarea {
    min-height: 150px;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio {
    position: relative;
    padding-bottom: 2px;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio input.form-check-input {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label {
    background: #ffffff;
    border: 1px dashed rgba(4, 4, 5, 0.2);
    border-radius: 10px;
    min-height: 72px;
    padding: 36px 13px 6px 15px;
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #91959b;
    position: relative;
    cursor: pointer;
    margin: 0;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label::after {
    content: "";
    top: 12px;
    left: 15px;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label {
    background-color: #fcfff7;
    border: 1px solid #84bd00;
    color: #84bd00;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label.form-radio-1:after {
    background-image: url(../../assets/images/gren-shape-icon.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label.form-radio-2:after {
    background-image: url(../../assets/images/green-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio input.form-check-input:checked~label.form-radio-3:after {
    background-image: url(../../assets/images/small-green-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio label.form-radio-1:after {
    background-image: url(../../assets/images/blue-shape-icon.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio label.form-radio-2:after {
    background-image: url(../../assets/images/blue-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.need-developers .form-radio label.form-radio-3:after {
    background-image: url(../../assets/images/small-blue-rotate.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label {
    background-color: #fcfff7;
    border: 1px solid #84bd00;
    color: #84bd00;
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label.form-radio-1:after {
    background-image: url(../../assets/images/green-time.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label.form-radio-2:after {
    background-image: url(../../assets/images/green-time40.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio input.form-check-input:checked~label.form-radio-3:after {
    background-image: url(../../assets/images/green-time20.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio label.form-radio-1:after {
    background-image: url(../../assets/images/blue-time40.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio label.form-radio-2:after {
    background-image: url(../../assets/images/blue-time30.svg);
}

.modal-form .modal-content .modal-body .form-group.modal-radio-group.time-commitment .form-radio label.form-radio-3:after {
    background-image: url(../../assets/images/blue-time20.svg);
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid rgba(4, 4, 5, 0.2);
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file label {
    cursor: pointer;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 12px;
    width: 72px;
    height: 72px;
    overflow: hidden;
    margin: 0 auto 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #808188;
}

.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span:first-child {
    margin-bottom: 8px;
    cursor: pointer;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group {
    width: 184px;
    position: relative;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus .btn,
.modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus .btn {
    height: 44px;
    width: 42px;
    padding: 0 !important;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus .btn:hover,
.modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus .btn:hover {
    box-shadow: none;
    transform: inherit;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus {
    position: absolute;
    top: 11px;
    right: 11px;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus {
    position: absolute;
    top: 11px;
    left: 11px;
}

.modal-form .modal-content .modal-body .form-group .modal-number-group .number-input input {
    height: 64px;
    color: #040405 !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.modal-form .modal-content .modal-body .form-group .modul-document-link .form-control {
    color: #0086e6;
}

.modal-form .modal-content .modal-body .form-group.modal-recaptcha img {
    max-width: 100%;
}

.modal-form .modal-content .modal-footer {
    padding: 0px 30px;
}

.modal-form .modal-content .modal-footer .modal-footer-inner {
    padding: 18px 0;
    width: 100%;
    border-top: 1px solid rgba(0, 134, 230, 0.2) !important;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.modal-form .modal-content .modal-footer .modal-footer-inner .btn {
    margin: 5px 0;
    min-width: 165px;
}

.modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
    margin-right: 32px;
    border: 2px solid #0086e6 !important;
    color: #616161 !important;
}

.modal-form.hire-developer-modal .modal-content .modal-banner-section:after {
    background-image: url(../../assets/images/lap.svg);
}

.modal-form.hire-developer-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/cup.svg);
}

.modal-form.request-quote-modal .modal-content .modal-banner-section::after {
    background-image: url(../../assets/images/request-banner-img-2.svg);
}

.modal-form.request-quote-modal .modal-content .modal-banner-section::before {
    background-image: url(../../assets/images/request-banner-img-3.svg);
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file {
    min-height: auto;
    padding: 38px 30px;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label .upload-file-img {
    margin-bottom: 0;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label .upload-text {
    flex: 1;
    padding-left: 30px;
}

.modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file label .upload-text span {
    text-align: left;
}

.theme-dark .modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label {
    background: #fff !important;
}

.theme-dark .modal-form .modal-content .modal-body .form-group .modal-upload-file {
    background: #fff !important;
}

.theme-dark .modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
    background: #fff !important;
}

/****end modal*****/
.select-tab {
    padding: 16px 12px;
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    background-color: #fff;
}

.select-tab:not(:last-child) {
    border-bottom: 1px solid #8dd0ff;
}

.select-tab .select-tab-item {
    font-size: 14px;
    line-height: 21px;
    color: #616161;
    border: 0 !important;
    display: block;
}

.stage-select-dropdown {
    background-color: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%);
    box-shadow: none !important;
    display: none;
    position: sticky !important;
    top: 80px;
    z-index: 999;
}

.list-group-item {
    background-color: #fff !important;
    color: #00011c !important;
}

.digital-middle .digital-banner-section .digital-banner-inner .heading h3 {
    margin-bottom: 16px;
}

.digital-middle .digital-banner-section .digital-banner-inner .heading h2 {
    line-height: 60px;
}

.digital-middle .digital-banner-section .digital-banner-inner .heading .btn {
    margin-top: 20px;
}

.digital-middle .design-stage-section .steps-detail .step-row {
    margin-bottom: 120px;
}

.digital-middle .design-stage-section .steps-detail .steps-feature {
    border-radius: 12px;
}

.digital-middle section .steps-feature .inner-step {
    border-radius: 12px;
}

.digital-middle .design-stage-section .heading h3 {
    margin-bottom: 16px;
}

.digital-middle .design-stage-section .heading h2 {
    line-height: 60px;
}

.digital-middle .design-stage-section .heading {
    margin-bottom: 48px;
}

.digital-middle .design-stage-section .design-steps {
    padding: 20px 10px;
}

.digital-middle .design-stage-section .design-steps ul li {
    font-size: 24px;
    line-height: 45px;
}

.service-middle .challenges-section {
    padding: 40px 0 10px 0;
}

.service-middle .challenges-section .challenges-inner .heading {
    max-width: 727px;
    margin-bottom: 32px;
}

.service-middle .challenges-section .challenges-inner .heading h3 {
    margin-bottom: 16px;
}

.service-middle .agile-team-section .agile-team-inner .team-card {
    padding: 65px 20px 60px 20px;
}

.service-middle .agile-team-section .agile-team-inner .team-card span {
    font-size: 14px;
}

.service-middle .technologies-section {
    padding: 80px 0 154px;
}

.service-middle .technologies-section .technologies-inner .heading {
    margin-bottom: 48px;
}

.service-middle .technologies-section .technologies-inner .heading h3 {
    margin-bottom: 16px;
}

.service-middle .technologies-section .technologies-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
}

.service-middle .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons a {
    line-height: 21px;
}

.service-middle .approach-section .approach-inner .heading h3 {
    margin-bottom: 16px;
}

.service-middle .approach-section .approach-inner .heading h2 {
    font-size: 40px;
    line-height: 60px;
}

.service-middle .approach-section .approach-inner .approach-content {
    padding-top: 10px;
}

.industries-middel .agile-banner-section {
    padding: 32px 0 40px 0;
}

.industries-middel .agile-banner-section .agile-banner-inner .agile-banner-content {
    border-radius: 24px;
}

.industries-middel .agile-banner-section .agile-banner-inner .agile-banner-content .heading h3 {
    margin-bottom: 16px;
}

.industries-middel .agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    line-height: 60px;
}

.industries-middel .agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    margin: 0 auto 40px auto;
}

.industries-middel .agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    margin-bottom: 0;
}

.industries-middel .demand-education-section {
    padding: 40px 0 80px 0px;
}

.industries-middel .demand-education-section .demand-inner .heading {
    margin-bottom: 48px;
}

.industries-middel .demand-education-section .demand-inner .heading h3 {
    margin-bottom: 16px;
}

.industries-middel .demand-education-section .demand-inner .heading h2 {
    max-width: 730px;
    font-size: 40px;
    line-height: 60px;
}

.industries-middel .demand-education-section .demand-inner .e-demand-box {
    margin-bottom: 42px;
}

.industries-middel .services-education-section {
    padding: 80px 0 0 0;
}

.industries-middel .services-education-section .heading h3 {
    margin-bottom: 16px;
}

.industries-middel .services-education-section .heading h2 {
    line-height: 60px;
}

.industries-middel .services-education-section .heading {
    margin-bottom: 48px;
}

.industries-middel .industries-system-section .industries-card h2 {
    margin-bottom: 20px;
}

.industries-middel .industries-system-section .development-box .development-top {
    padding: 30px 30px 40px;
}

.industries-relevant-section {
    padding: 80px 0 70px 0px;
}

/***20-2-2023(y)**/
.digital-middle .design-stage-section .steps-detail .step-row {
    margin: 0;
    padding-bottom: 120px;
}

/***End 20-2-2023(y)****/
/***20-2-2023(h)**/
.startups-middle .agile-banner-section {
    padding: 20px 0 60px 0;
}

.startups-middle .milestone-section .heading {
    margin-bottom: 20px;
}

.startups-middle .startups-looking-section .startups-looking-content h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
}

.startups-middle .startups-looking-section .startups-looking-content p {
    font-size: 18px;
    line-height: 27px;
    color: #91959b !important;
}

.startups-middle .startups-vacation-section .startups-vacation-inner .startups-vacation-content p {
    color: #91959b !important;
}

.startups-middle .startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card {
    min-height: 100%;
}

.startups-middle .technologies-tabs-section .technologies-tabs-inner .technologies-feature-heading {
    padding-left: 0;
}

.startups-middle .technologies-tabs-section .technologies-tabs-inner .technologies-feature-heading li a {
    color: #91959b !important;
}

.startups-middle .technologies-tabs-section .technologies-tabs-inner .technologies-feature-heading li a.active {
    color: #040405 !important;
    color: #040405 !important;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link {
    color: #91959b;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link.active {
    font-weight: 600;
    color: #040405 !important;
    color: #040405 !important;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle h4 {
    font-weight: 600;
}

.startups-middle .startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card h4 {
    font-weight: 600;
}

.startups-middle .startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card p {
    color: #91959b !important;
}

.startups-middle .startups-idea-section .startups-idea-card .startups-idea-content h4 {
    font-weight: 700;
}

.startups-middle .startups-process-section {
    overflow: hidden;
}

.startups-middle .startups-idea-section .startups-idea-card .startups-idea-content p {
    color: #91959b !important;
}

.startups-middle .startups-idea-section .startups-idea-card .startups-idea-content p {
    color: #91959b !important;
}

.startups-middle .design-stage-section .steps-detail .steps-feature .feature-content h3 {
    font-weight: 600 !important;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link {
    font-weight: 600;
}

.startups-middle .startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card .startups-hireteam-content h2 {
    font-weight: 700;
}

.startups-middle .startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card .startups-airbnb-content h2 {
    font-weight: 700;
    margin-bottom: 18px;
}

.startups-middle .startups-process-section.mobile-process-inner {
    display: none;
}

/**21-2-2023(y)**/
.career-middle .careers-banner-section {
    padding: 66px 0;
}

.career-middle .careers-banner-section .careers-banner-inner .heading h3 {
    margin-bottom: 30px;
}

.career-middle .careers-banner-section .careers-banner-inner .heading h2 {
    font-size: 60px;
    line-height: 72px;
}

.career-middle .careers-about-section {
    padding: 30px 0 100px;
}

.career-middle .careers-about-section .careers-about-left h2 {
    line-height: 45px;
    margin-bottom: 20px;
}

.career-middle .careers-about-section .careers-about-left p {
    line-height: 27px;
}

.career-middle .job-positions-section {
    padding: 180px 0 50px;
}

.career-middle .job-positions-section .section-titel h2 {
    margin-bottom: 20px;
    line-height: 60px;
}

.career-middle .job-positions-section .section-titel p {
    line-height: 27px;
}

.career-middle .job-positions-section .job-searching-section {
    margin-top: 40px;
}

.career-middle .job-positions-section .job-searching-section form>.row {
    margin-bottom: 20px;
}

.career-middle .job-positions-section .job-searching-section form .job-searching-result ul li h3 {
    line-height: 30px;
}

.career-middle .startups-uber-section {
    margin-top: 80px;
}

.career-middle .startups-uber-section .startups-uber-inner .heading h2 {
    line-height: 60px !important;
}

.career-middle .startups-partner-section .startups-partner-inner .pratner-box {
    margin-top: 40px;
}

.career-middle .startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-img {
    margin-bottom: 20px;
}

.career-middle .startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-content h3 {
    margin-bottom: 15px;
}

.career-middle .our-culture-section {
    padding: 78px 0 60px;
}

.career-middle .our-culture-section .our-culture-card .card-images div {
    width: 92px;
    height: 92px;
}

.career-middle .gallery-slider-section {
    padding: 20px 0 80px;
}

.career-middle .gallery-slider-section .gallery-slider .slick-slide img {
    max-width: fit-content;
    min-width: fit-content;
}

.career-middle .benefits-section {
    padding: 160px 0;
}

.career-middle .benefits-section .icon-card .card .card-content h3 {
    line-height: 26px;
}

.career-middle .benefits-section .icon-card .card .card-content p {
    line-height: 21px;
}

/**********21-2-2023(h)**********/
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a.active {
    color: #040405 !important;
    color: #040405 !important;
}

.dx-none {
    display: none !important;
}

.blog-digital-inner .btn.btn-primary {
    margin-bottom: 20px;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li a {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: inline-block;
    border-radius: 8px;
    padding: 6px 10px;
    margin-bottom: 6px;
    margin-right: 6px;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    font-weight: 500;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button {
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p {
    padding-bottom: 20px;
    margin-bottom: 0;
}

.career-middle .startups-uber-section {
    margin-top: 0;
}

.career-middle .news-letter-section {
    padding: 0 0 160px;
}

.career-middle .startups-partner-section .startups-partner-inner .pratner-box {
    padding-top: 40px;
    margin-top: 0;
}

.theme-dark .our-culture-section .our-culture-card {
    background-image: none;
}

.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top a.btn {
    min-width: unset;
    height: auto;
    line-height: 19px !important;
}

/**28/2/2023 (Y)**/
/**1/3/2023 (Y)**/
/**2-3-2023**/
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner {
    padding: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../assets/images/blog-cta-pattern.png");
    background-size: cover;
    background-position: center center;
    border: 1px solid #8dd0ff;
    border-radius: 15.5px;
    margin: 30px 0;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner h2 {
    margin-bottom: 19px;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 {
    border: 1px solid #8dd0ff;
    border-radius: 15.5px;
    margin: 30px 0;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .col-bg {
    background-image: url("../../assets/images/pattern.png");
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .col-bg .blog-banner-img {
    width: 60px;
    height: 75px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .col-bg .blog-banner-img img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .blog-banner2-text {
    padding: 21px 0 21px 24px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .blog-banner2-text h2 {
    font-size: 18px;
    line-height: 25px;
    max-width: 412px;
    font-weight: 700;
    margin-bottom: 13px;
}

/********21-3-2023*********/
/************/
.book-section .book-box.bottom-book-box {
    position: relative;
    background-image: none !important;
}

.book-section .book-box.bottom-book-box .book-box-shape-1 {
    background-image: url(../../assets/images/box-shape-1.svg);
    position: absolute;
    width: 123px;
    height: 72px;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center top;
}

.book-section .book-box.bottom-book-box .book-box-shape-3 {
    background-image: url(../../assets/images/box-shape-3.svg);
    position: absolute;
    width: 146px;
    height: 73px;
    top: 0;
    right: 25px;
    background-repeat: no-repeat;
    background-position: center top;
}

.book-section .book-box.bottom-book-box .book-box-shape-2 {
    background-image: url(../../assets/images/box-shape-2.svg);
    position: absolute;
    width: 146px;
    height: 100px;
    bottom: 0;
    left: 30px;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.book-section .book-box.bottom-book-box .book-box-shape-4 {
    background-image: url(../../assets/images/box-shape-4.svg);
    position: absolute;
    width: 185px;
    height: 100px;
    bottom: 0;
    right: 0px;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.book-box.bottom-book-box {
    overflow: hidden;
}

/**********22-3-2023*************/
.startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card {
    background-image: url(../../assets/images/book-consultation-1.svg), url(../../assets/images/book-consultation-2.svg), url(../../assets/images/book-consultation-3.svg), url(../../assets/images/book-consultation-4.svg);
    background-position: top left, top right, bottom left, right bottom 10%;
    background-repeat: no-repeat;
    background-size: auto;
}

.industries-relevant-section .steps-feature .feature-content .btn.btn-secondary.feature-btn {
    margin-top: 16px;
    transition: none !important;
    box-shadow: none !important;
}

.industries-relevant-section .heading {
    text-align: center;
    margin-bottom: 20px;
    position: sticky;
    top: 120px;
}

.industries-relevant-section .steps-feature .feature-content .btn.btn-secondary.feature-btn:hover {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    color: #fff !important;
    border-color: transparent !important;
    transform: translateY(0px);
}

.agile-recommend-section {
    padding: 160px 0px 80px;
    background-color: #fff;
    background-color: #fff;
}

.agile-recommend-section .btn-readmore {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    text-transform: capitalize;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.agile-recommend-section .btn-readmore img {
    width: 10px;
    margin-left: 2px;
}

.agile-recommend-section .agile-recommend-inner .recommend-img {
    position: sticky;
    top: 120px;
}

.agile-recommend-section .agile-recommend-inner .recommend-img img {
    border-radius: 12px;
    width: 100%;
}

.agile-recommend-section .agile-recommend-inner .recommend-content {
    padding-left: 14px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .heading {
    margin-bottom: 20px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .heading h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #616161;
    text-transform: capitalize;
    margin-bottom: 16px;
    color: #91959b;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .heading h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    color: #040405;
    text-transform: capitalize;
    margin-bottom: 16px;
    color: #040405;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user {
    display: flex;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user .recommend-user-img {
    margin-right: 12px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user .recommend-user-img img {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user .recommend-user-name h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #040405;
    text-transform: capitalize;
    margin-bottom: 0px;
    color: #040405;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user .recommend-user-name span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #616161;
    text-transform: capitalize;
    display: block;
    color: #91959b;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu {
    padding: 40px 0px;
    border-top: 1px solid #8dd0ff;
    border-bottom: 1px solid #8dd0ff;
    margin-bottom: 40px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .logistics-card h3 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    color: #040405;
    text-transform: capitalize;
    margin-bottom: 9px;
    color: #040405;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .logistics-card h2 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: #040405;
    text-transform: capitalize;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    margin-bottom: 0px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .logistics-green h2 {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%), linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div {
    padding-bottom: 8px;
    border-bottom: 1px solid #8dd0ff;
    margin-bottom: 40px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-heading {
    margin-bottom: 20px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-heading h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #040405;
    text-transform: capitalize;
    margin-bottom: 0px;
    color: #040405;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card {
    margin-bottom: 32px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card h2 {
    font-size: 33px;
    line-height: 50px;
    font-weight: 700;
    -webkit-text-stroke: 1px #68bcf9;
    color: transparent;
    margin-bottom: 4px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #616161;
    color: #616161;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-heading {
    margin-bottom: 20px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-heading h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #040405;
    text-transform: capitalize;
    margin-bottom: 0px;
    color: #040405;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul {
    padding-left: 0;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul li {
    display: flex;
    margin-bottom: 32px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul li:last-child {
    margin-bottom: 0px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul li img {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 10px;
}

.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul li p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #616161;
    margin-bottom: 0px;
    color: #616161;
}

.maintanence-banner {
    background-color: #fff;
    background-color: #fff;
    padding-top: 80px;
}

.maintanence-banner .mantainence-banner-content {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 12px;
}

.maintanence-banner .mantainence-banner-content .mantainence-banner-text {
    display: flex;
    align-items: center;
    height: 100%;
}

.maintanence-banner .mantainence-banner-content img {
    max-width: 100%;
}

.maintanence-banner .mantainence-banner-content h4 {
    font-size: 40px;
    line-height: 72px;
    font-weight: 500;
    max-width: 506px;
    color: #040405 !important;
}

.maintanence-banner .mantainence-banner-content h4 .heading-subtext {
    display: block;
}

.maintanence-banner .mantainence-banner-content h4 span {
    font-weight: 700;
}

.maintanence-banner .mantainence-banner-content h4 .blue-text {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.maintanence-banner .mantainence-banner-content h4 .green-text {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%), linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.maintanence-banner .mantainence-banner-content .img-wrapper img {
    width: 100%;
}

.compeny-section {
    background-color: #fff;
    background-color: #fff;
}

.compeny-section .compeny-section-inner .agile-box {
    padding: 33px 40px;
    border: 1px solid;
    border-color: rgb(0, 134, 230);
}

.compeny-section .compeny-section-inner .agile-box.compeny-box1 {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05), 113.66%, rgba(141, 208, 255, 0.05));
}

.compeny-section .compeny-section-inner .agile-box.compeny-box2 {
    border-color: rgb(138, 188, 0);
}

.compeny-section .compeny-section-inner .agile-box p {
    margin-bottom: 3px;
}

.compeny-section .compeny-section-inner .agile-box h2 {
    font-size: 48px;
    line-height: 72px;
}

.partner-section {
    background-color: #fff;
    background-color: #fff;
}

.partner-section .partner-section-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 6px;
    font-weight: 500;
}

.partner-section .partner-section-inner .heading h2 {
    color: #040405 !important;
    font-size: 35px;
    line-height: 52px;
    font-weight: 700;
    padding-bottom: 12px;
    max-width: 400px;
}

.partner-section .partner-section-inner p {
    color: #91959b;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    max-width: 500px;
}

.partner-section .partner-section-inner .img-row {
    position: relative;
}

.partner-section .partner-section-inner .img-row::before {
    content: "";
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../../assets/images/partner-border2.svg);
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 1px;
    background-position: center;
}

.partner-section .partner-section-inner .img-col {
    position: relative;
}

.partner-section .partner-section-inner .img-col::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(../../assets/images/partner-border.svg);
    background-repeat: no-repeat;
    display: block;
    width: 1px;
    height: 200%;
}

.partner-section .partner-section-inner .img-col:nth-child(n+3)::after {
    display: none;
}

.partner-section .partner-section-inner .partner-img-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner-section .partner-section-inner .partner-img-wrapper .partner-img {
    width: 130px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner-section .partner-section-inner .partner-img-wrapper .partner-img img {
    width: auto;
    height: auto;
}

.theme-dark .agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card h2 {
    -webkit-text-stroke: 1px #8dd0ff;
}

.theme-dark .maintanence-banner .mantainence-banner-content {
    background-color: rgba(37, 37, 38, 0.12);
}

/********22-3-2023***********/
.book-text {
    position: relative;
    z-index: 2;
}

.startUp-detail-middle .faq-section .accordion {
    min-height: inherit;
}

.book-section .bottom-book-box .book-text .hire-btn .btn {
    margin: 5px 5px 10px;
}

.budget-content .MuiSlider-root .MuiSlider-thumb::before {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%) !important;
}

.budget-content .MuiSlider-root .MuiSlider-track {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%) !important;
    border-color: transparent;
}

.request-proposal .agreement-section .agreement-inner .conect-box {
    background-image: url(../../assets/images/connect-bg-1.svg), url(../../assets/images/connect-bg-2.svg), url(../../assets/images/connect-bg-3.svg), url(../../assets/images/connect-bg-4.svg) !important;
    background-size: auto !important;
    background-position: top left, top right 3%, bottom left 3%, bottom right !important;
}

.casestudy-details-middle .casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2 {
    padding: 0 0 20px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;
    color: #040405;
    color: #040405;
    width: 100%;
    display: block;
    margin: 0;
}

.casestudy-details-middle .casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading:before,
.casestudy-details-middle .casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2:after {
    display: none;
}

.blog-detail-development .blog-articles-section .blog-articles-heading {
    position: relative;
    margin-bottom: 20px;
}

.blog-detail-development .blog-articles-section .blog-articles-heading::before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 86%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.blog-detail-development .blog-articles-section .blog-articles-heading h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.blog-detail-development .blog-articles-section .blog-articles-heading h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

/********23-3-2023**********/
.compeny-section .compeny-main-content .compeny-text p {
    color: #000;
}

.compeny-section .compeny-main-content .compeny-text .compeny-logo h4 {
    color: #000;
}

.project-section .project-box .project-logos {
    border: 1px solid rgba(97, 97, 97, 0.2);
}

.theme-dark .client-section .solution-listing .bg-blue {
    background: rgba(0, 134, 230, 0.1);
}

.theme-dark .client-section .solution-listing .bg-blue .icon {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.12) 5.56%, rgba(141, 208, 255, 0.12) 113.66%);
}

.theme-dark .client-section .solution-listing .bg-green {
    background: rgba(204, 250, 78, 0.1);
}

.theme-dark .client-section .solution-listing .bg-green .icon {
    background: linear-gradient(317.72deg, rgba(138, 188, 0, 0.12) 5.24%, rgba(204, 250, 78, 0.12) 137.29%);
}

.theme-dark .hiredeveloper-section .developer-circle .developer-circle-inner {
    background: rgba(255, 255, 255, 0.05);
}

.theme-dark .project-section .project-box .project-logos {
    border: 1px solid rgba(97, 97, 97, 0.5);
}

.technology-feature-top {
    padding-bottom: 0;
}

.technology-feature-top .startups-vacation-inner .heading-wrapper {
    border-right: 1px solid #8dd0ff;
    padding-bottom: 30px;
    height: 100%;
}

.technology-feature-top .startups-vacation-inner .heading {
    margin-bottom: 0;
}

.technology-feature-top .startups-vacation-inner .heading h2 {
    font-size: 30px;
    line-height: 42px;
    max-width: 272px;
    margin-bottom: 32px;
}

.technology-feature-top .startups-vacation-inner .heading h3 {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
}

.technology-feature-top .startups-vacation-inner .startups-vacation-content p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding-right: 50px;
    color: #91959b;
}

.technology-stack {
    padding-bottom: 20px;
}

.technology-stack .casesudies-professional-inner .tech-skills::before {
    display: none;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list {
    margin-bottom: 18px;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge {
    border: 1px solid #8dd0ff;
    display: inline-block;
    padding: 10px 12px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    margin-bottom: 10px;
    word-break: break-all;
    position: relative;
    background-color: red;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge:not(:last-child) {
    margin-right: 12px;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge::before {
    content: "";
    background: #f2f9fe;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 11px;
    position: absolute;
    left: 0;
    top: 0;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge::after {
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: -1px;
    z-index: 0;
    border-radius: 12px;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge span {
    position: relative;
    z-index: 2;
}

.technology-feature .startups-features-inner .startups-heading {
    border-top: 1px solid #8dd0ff;
    padding: 23px 40px;
}

.technology-feature .startups-features-inner .startups-heading h2 {
    font-size: 24px;
    line-height: 36px;
}

.technology-feature .startups-features-inner .startups-features-vacation {
    border-bottom: 1px solid #8dd0ff;
}

.technology-feature .startups-features-inner .startups-features-vacation .startups-features-card {
    padding-top: 28px;
}

.technology-feature .startups-features-inner .startups-features-vacation .startups-features-card h4 {
    font-weight: 600;
    margin-bottom: 12px;
}

.technology-feature .startups-features-inner .startups-features-vacation .startups-features-card p {
    color: #91959b;
}

.theme-dark .technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge {
    color: #00011c;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge {
    margin-bottom: 12px;
}

.casesudies-professional-section .casesudies-professional-inner .heading h3 {
    text-transform: capitalize;
}

.case-studies-blog-section .case-studies-blog-inner .case-studies-heading h3 {
    text-transform: capitalize;
}

.case-studies-section .case-studies-inner .case-studies-heading h2 {
    font-size: 32px;
    line-height: 48px;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2 {
    color: #00011c;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading h2 {
    color: #00011c;
}

.request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .MuiBox-root .MuiSlider-root {
    color: #91959b;
}

.agreement-section .agreement-inner .conect-box .conect-box-content h3 {
    color: #00011c !important;
}

.agreement-section .agreement-inner .conect-box .skype-conect a p {
    color: #00011c !important;
}

.careers-contributer-section .contributer-list .develop-contributer.d2 {
    border: 1px solid #ccfa4e;
}

.careers-contributer-section .contributer-list .develop-contributer.d3 {
    border: 1px solid #8dd0ff;
}

.careers-contributer-section .contributer-list>.develop-contributer.d2::after {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
}

.careers-contributer-section .contributer-list>.develop-contributer.d3::after {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.about-project-section .about-project-inner .about-project-heading h6 {
    text-transform: capitalize;
}

.news-letter-section .news-letter-content .news-letter-link a span {
    vertical-align: baseline;
}

.our-culture-section .our-culture-card {
    background-image: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%), url(../../assets/images/our-culture-bg.svg);
    background-image: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%), url(../../assets/images/our-culture-bg.svg);
}

.wordwise-section .map-image {
    position: relative;
}

.industry-middle .industries-tabs-section .slick-slide a.active {
    color: #000 !important;
}

.theme-dark .wordwise-section .map-image::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 1;
}

.theme-dark .wordwise-section .heading h3 {
    color: #040405 !important;
}

.theme-dark .wordwise-section .heading {
    position: relative;
    z-index: 2;
}

.theme-dark .wordwise-section .client-map-content .slider-client {
    position: relative;
    z-index: 2;
}

/*****24-3-2023(h)***/
.theme-light .technologies-tabs-section .technologies-feature-heading li a.active {
    color: var(--bs-black);
}

.framework-section .framework-inner .framework-card span {
    border: 1px solid #8dd0ff;
}

.startups-features-section .slick-prev,
.startups-features-section .slick-next {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 8px 8px 8px 8px;
}

.startups-features-section .slick-next::before,
.startups-features-section .slick-next:focus:before,
.startups-features-section .slick-prev::before,
.startups-features-section .slick-prev:focus::before {
    filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
    opacity: 0.6;
}

.startups-features-section .slick-prev:hover,
.startups-features-section .slick-next:hover,
.startups-features-section .slick-next:focus,
.startups-features-section .slick-prev:focus {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.startups-features-section .slick-prev:hover::before,
.startups-features-section .slick-next:hover::before {
    opacity: 1;
    filter: none;
}

.startUp-detail-middle .startups-tab-section .startups-tab-inner .startups-tab-heading {
    position: relative;
    border-bottom: 0px;
}

.startUp-detail-middle .startups-tab-section .startups-tab-inner .startups-tab-heading:after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: rgba(0, 134, 230, 0.2);
}

.startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card h2,
.startups-feature-section .startups-feature-inner .startups-features-card h2 {
    -webkit-text-stroke: 1px #8dd0ff;
}

.book-section .hire-btn .btn.btn-secondary {
    color: #040405 !important;
}

.digital-service-section .heading {
    position: sticky;
    top: 120px;
    text-align: left;
}

.media-tab-card.instagram .rsme-instagram-embed iframe {
    min-width: 280px !important;
    margin: 0 auto;
}

.technology-feature .startups-features-inner .startups-features-vacation .startups-features-card p {
    margin-bottom: 0;
}

.technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge::before {
    background: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%);
}

.maintanence-banner .mantainence-banner-content img {
    width: 100%;
}

/******28-3-2023 h****/
.service-section .list-fetaure div:nth-child(2n):hover .service-feature:after {
    right: -60px;
}

.our-culture-section .our-culture-card {
    background-size: 100%;
}

.news-letter-section .news-letter-content,
.book-section .book-box {
    position: relative;
    background-image: none;
}

.news-letter-section .news-letter-content:after,
.book-section .book-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/desired-position-1.svg), url(../../assets/images/desired-position-2.svg), url(../../assets/images/desired-position-3.svg), url(../../assets/images/desired-position-4.svg);
    background-position: top left 8px, bottom left 67px, top right 45px, bottom right 8px;
    background-size: auto;
    background-repeat: no-repeat;
    opacity: 1;
}

.book-section .book-box:after {
    background-image: url(../../assets/images/book-box-bg-1.svg), url(../../assets/images/book-box-bg-2.svg), url(../../assets/images/book-box-bg-3.svg), url(../../assets/images/book-box-bg-4.svg);
}

.news-letter-section .news-letter-content .row,
.book-section .book-box .row {
    position: relative;
    z-index: 2;
}

.media-tab-card.instagram .rsme-instagram-embed iframe {
    min-height: calc(100% - 12px);
}

/*****28-3-2023 y****/
.budget-select .budget-select__control {
    padding: 17px 20px;
    background-color: #fff;
    background-color: #fff;
    box-shadow: none;
    border-radius: 12px;
    border: 1px solid rgba(4, 4, 5, 0.2) !important;
}

.budget-select .budget-select__control.budget-select__control--menu-is-open {
    border: 1px solid #8dd0ff !important;
}

.budget-select .budget-select__single-value {
    color: #040405;
    font-weight: 600;
}

.budget-select .budget-select__indicator-separator {
    display: none;
}

.budget-select .budget-select__control--menu-is-open .budget-select__indicator {
    background-image: url(../../assets/images/bg-up-arrow.svg);
    background-repeat: no-repeat;
}

.budget-select .budget-select__indicator svg {
    display: none;
}

.budget-select .budget-select__indicator {
    height: 30px;
    width: 30px;
    background-image: url(../../assets/images/light-down-arrow.svg);
    background-size: cover;
    background-position: center center;
}

.budget-select .budget-select__menu {
    border: 1px solid #8dd0ff !important;
    border-top: 1px solid #8dd0ff !important;
    border-radius: 12px;
    overflow: hidden;
    z-index: 9;
    padding: 0;
}

.budget-select .budget-select__menu-list {
    padding: 0;
}

.budget-select .budget-select__menu .budget-select__option {
    border-bottom: 1px solid #8dd0ff !important;
    background: #fff;
    color: #616161;
}

.budget-select .budget-select__menu .budget-select__option--is-selected {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
    color: #212529;
}

.budget-select .budget-select__menu .budget-select__option:last-child {
    border-bottom: none !important;
}

.service-middle .service-feature-section .service-follow {
    position: relative;
}

.budget-select .budget-select__multi-value__remove:hover {
    background-color: transparent;
}

.anchor-before[id]::before {
    content: "";
    display: block;
    height: 50px;
    margin: -50px 0 0;
}

.industries-system-section .anchor-top {
    position: absolute;
    top: -120px;
}

.blog-migration-section .blog-migration-key-points {
    position: relative;
}

.blog-migration-section .blog-faq-key-points {
    position: relative;
}

.blog-migration-section .anchor-top {
    position: absolute;
    top: -90px;
}

.blog-allstories-section .blog-allstories-heading {
    position: relative;
}

.blog-allstories-section .anchor-top {
    position: absolute;
    top: -120px;
}

.casestudies-allstories-section .casestudies-allstories-heading {
    position: relative;
}

.casestudies-allstories-section .anchor-top {
    position: absolute;
    top: -110px;
}

.service-feature-section {
    position: relative;
}

.service-feature-section .anchor-top {
    position: absolute;
    top: -80px;
}

.technologies-section {
    position: relative;
}

.technologies-section .anchor-top {
    position: absolute;
    top: -130px;
}

.digital-products-design .step-row {
    position: relative;
}

.digital-products-design .anchor-top {
    position: absolute;
    top: -160px;
}

.case-studies-v2 .case-studies-sections {
    position: relative;
}

.case-studies-v2 .anchor-top {
    position: absolute;
    top: -100px;
}

/*******29-3-2023 (h)*********/
.work-section .our-project .project .project-link a img {
    transform: translateX(-5px);
}

.work-section .our-project .project:hover .project-link a img {
    transition: all 0.5s;
    transform: translateX(2px);
}

.framework-section .framework-inner .framework-card p {
    min-height: auto;
}

.testimonial-slider .swiper {
    overflow: visible;
}

.news-letter-section .news-letter-content h2 {
    color: #00011c !important;
}

.news-letter-section .news-letter-content .news-letter-link a {
    color: #00011c !important;
}

/***30-3-2023 h****/
.blog-allstories-section .blog-allstories-inner .blog-card .blog-img img {
    transform: scale(1);
    -webkit-transform: scale(1);
    transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group #search img {
    width: 18px;
}

.news-letter-section .news-letter-content .news-letter-link a:hover {
    background: linear-gradient(200.72deg, #0086e6 40.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a {
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a:hover {
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a:hover .arrow {
    opacity: 1;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a:hover {
    color: #040405 !important;
    color: #040405 !important;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a.active:hover .arrow {
    opacity: 1;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li a.active:hover {
    font-weight: 600;
}

.technologies-feature-latest .letest-technologies-box:hover {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-color: rgba(115, 194, 250, 0.3607843137);
}

.theme-dark .technologies-feature-latest .letest-technologies-box:hover {
    border-color: rgba(243, 243, 243, 0.4);
}

.technologies-feature-latest .letest-technologies-box .letest-technologies-img img {
    transform: scale(1);
    transition: all 0.45s linear;
}

.technologies-feature-latest .letest-technologies-box:hover .letest-technologies-img img {
    transform: scale(1.1);
    transition: all 0.45s linear;
}

textarea:focus-visible,
textarea {
    outline: none !important;
}

.form-group .form-control:focus,
.form-group .form-select:focus,
textarea:focus,
textarea:focus-visible,
.about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data:focus {
    border: 1px solid rgba(0, 134, 230, 0.4) !important;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.technologies-feature-latest .letest-technologies-box {
    cursor: pointer;
}

.theme-dark .form-group .form-control:focus,
.theme-dark .form-group .form-select:focus,
.theme-dark textarea:focus,
.theme-dark .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data:focus {
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.agreement-section .agreement-inner .conect-box .skype-conect a:hover p {
    background: linear-gradient(200.72deg, #0086e6 40.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.industries-system-section .development-box .development-bottom .development-links li a::after,
.industries-system-section .development-box .development-bottom .development-links li .development-links-btn::after {
    transition: right 0.4s;
}

.industries-system-section .development-box .development-bottom .development-links li a:hover::after,
.industries-system-section .development-box .development-bottom .development-links li .development-links-btn:hover::after {
    right: 15px;
}

.meet-management-section .profile-info ul li a {
    z-index: 5;
    position: relative;
}

.meet-management-section .profile-info ul li {
    margin-bottom: 5px;
}

.meet-management-section .profile-info ul li a,
.approach-section .approach-inner .cto-div .cto-pofile-info ul li a {
    transition: all 0.4s;
}

.meet-management-section .profile-info ul li a:hover,
.approach-section .approach-inner .cto-div .cto-pofile-info ul li a:hover {
    box-shadow: 1px 1px 5px 0 rgba(0, 134, 230, 0.4);
    transform: scale(1.05);
}

.meet-management-section .meet-slider .slick-arrow {
    filter: invert(83%) sepia(0%) saturate(0%) hue-rotate(169deg) brightness(99%) contrast(84%);
    opacity: 0.4;
    transition: all 0.4s;
}

.meet-management-section .meet-slider .slick-arrow:hover {
    filter: none;
    opacity: 1;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li a {
    transition: all 0.4s;
}

.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li a {
    transition: all 0.4s;
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(odd) a:hover,
.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li:nth-child(odd) a:hover {
    background: rgba(141, 208, 255, 0.1803921569);
}

.blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(even) a:hover,
.casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li:nth-child(even) a:hover {
    background: rgba(138, 188, 0, 0.1);
}

.theme-dark .blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li:nth-child(even) a:hover,
.theme-dark .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li:nth-child(even) a:hover {
    background: rgba(138, 188, 0, 0.15);
}

.service-middle .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons a {
    transition: all 0.4s;
}

.service-middle .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons a:hover {
    background: rgba(141, 208, 255, 0.1803921569);
}

.agile-recommend-section .btn-readmore img {
    transition: all 0.4s;
}

.agile-recommend-section .btn-readmore:hover img {
    transform: translateX(3px);
}

.theme-dark .maintanence-banner .mantainence-banner-content {
    background-color: rgba(116, 195, 250, 0.4901960784);
}

.partner-section .partner-section-inner .partner-img-wrapper .partner-img {
    transform: scale(0.9);
    transition: all 0.3s;
}

.partner-section .partner-section-inner .partner-img-wrapper:hover .partner-img {
    transform: scale(1);
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link.active {
    font-weight: 600;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link {
    font-weight: 500;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link:hover {
    color: #040405 !important;
    color: #040405 !important;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    font-weight: 500;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button:hover {
    color: #040405 !important;
}

.theme-light .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:hover:after {
    background-image: url(../../assets/images/accordion-plus-dark.svg);
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading h2 {
    margin: 0;
    padding: 10px 0;
}

.casestudies-loader {
    grid-column: 1/12;
}

/*** end 30-3-2023 h****/
/*** 30-3-2023 s****/
.budget-select .budget-select__menu .budget-select__option:hover {
    background-color: rgba(162, 207, 239, 0.6);
}

.budget-select .budget-select__menu .budget-select__option {
    cursor: pointer;
}

.budget-select .budget-select__control {
    cursor: pointer;
}

.technologies-middle .anchor-before[id]::before {
    height: 60px;
    margin: -80px 0 0;
}

.findus-section .card-2 .find-card:before {
    background-color: #fff;
    background-color: #fff;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading ul li {
    color: #616161;
}

.budget-select .budget-select__single-value {
    color: #616161 !important;
}

/***31-3-2023 h****/
.form-group .form-select:focus {
    box-shadow: none;
}

.startups-features-section .slick-arrow::before {
    transition: all 0.1s;
}

.service-section .list-fetaure div .service-feature:before {
    -webkit-transition: transform 0.4s ease-in-out;
    -moz-transition: transform 0.4s ease-in-out;
    -ms-transition: transform 0.4s ease-in-out;
    -o-transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition-delay: 0.2s;
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    -o-transform: translateX(-15px);
    transform: translateX(-15px);
}

.service-section .list-fetaure div .service-feature:hover:before {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}

.blog-card-div .blog-card {
    transition: all 0.4s;
}

.blog-card-div .blog-card:hover {
    background-color: #f2f9fe;
}

.theme-dark .blog-card-div .blog-card:hover {
    background-color: #111111;
}

.banner-section .solution-box .arrow {
    transition: all 0.4s;
}

.banner-section .solution-box .arrow {
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    -o-transform: translateX(-15px);
    transform: translateX(-15px);
    display: block;
    opacity: 0;
}

.banner-section .solution-box:hover .arrow {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
}

.banner-section .slick-active.slick-current .solution-box .arrow {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
}

.believe-section .believe-box:hover::after {
    width: 100%;
    transform: scale(1);
}

.believe-section .believe-box::after {
    display: block;
    width: 100%;
    transition: all 0.4s;
    transform: scale(0);
}

.blog-img {
    position: relative;
}

.blog-card:hover .blog-img::after {
    opacity: 0;
}

.blog-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(320deg, rgba(149, 209, 252, 0.2) 5.56%, hsla(0, 0%, 100%, 0) 113.66%);
    opacity: 1;
    transition: all 0.4s;
}

.job-positions-section .job-searching-section form .form-group .form-input .form-control:focus {
    border-width: 0 0 1px 0 !important;
    border-color: #616161 !important;
    background: transparent !important;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail a:hover {
    text-decoration: underline !important;
}

/***end 31-3-2023 h****/
.sticky-bottom {
    width: fit-content;
    margin-left: auto;
    right: 0;
    z-index: 90 !important;
}

.technologies-middle .anchor-before[id]:before {
    margin: -190px 0 0;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-wrapper .process-banner-image img {
    width: 100%;
}

.wordwise-section .client-map-content {
    top: -500px;
}

.wordwise-section .client-map-content .item:hover {
    box-shadow: 0px 24.635px 60.308px rgba(0, 0, 0, 0.07), 0px 35.2964px 23.35px rgba(0, 0, 0, 0.0425185), 0px 7.46655px 5.97324px rgba(0, 0, 0, 0.0274815);
}

.service-feature-section .service-follow .service-follow-content ul li {
    font-weight: 600;
    color: #00011c;
}

/***4-4-2023 (h)***/
.testimonial-slider .swiper-pagination {
    padding-top: 30px;
    position: relative;
    bottom: 0 !important;
    left: 0;
}

.testimonial-slider .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    max-width: 10px;
    height: 10px;
    border-radius: 10px;
    transition: box-shadow 0.4s ease-in-out;
    opacity: 0.4;
    background: #91959b !important;
    margin: 0 8px !important;
}

.testimonial-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%) !important;
    opacity: 1;
    box-shadow: 0px 0px 0 4px rgba(0, 134, 230, 0.2);
}

.theme-dark .testimonial-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    box-shadow: 0px 0px 0 4px rgba(0, 134, 230, 0.4);
}

/***end 4-4-2023 (h)***/
/***4-4-2023 (y)***/
.homepage-middle .process-section .process-list ul li::after {
    background-repeat: no-repeat;
}

.homepage-middle .work-section .our-project .project {
    min-height: calc(100% - 30px);
}

.homepage-middle .work-section .our-project .project img {
    margin-bottom: -1px;
    width: 100%;
}

.homepage-middle .work-section .our-project .col-lg-8 .project .project-user {
    min-height: 310px;
}

.homepage-middle .work-section .our-project .project {
    display: flex;
    flex-flow: column;
}

.homepage-middle .work-section .our-project .project .project-user {
    min-height: 0 !important;
}

.homepage-middle .work-section .our-project .project>.row {
    flex-grow: 1;
}

.meet-management-section .meet-slider .meet-thumb img {
    object-position: top;
}

.believe-section .believe-box .believe-content>p {
    min-height: 135px;
}

.believe-section .believe-box .believe-content p p {
    margin-bottom: 0;
}

.theme-dark .sticky-bottom .btn.btn-primary {
    white-space: nowrap;
}

.findus-section .find-card .find-image {
    position: relative;
    height: auto;
    max-height: unset;
}

/***end 4-4-2023 (y)***/
/*********5-4-2023(h)***********/
h1.page-heading,
.about-section .heading h1,
.findus-section .heading h1.page-heading,
.findus-section .heading h2.page-heading,
.service-middle .service-hero-section .heading h1.page-heading,
.digital-banner-section .digital-banner-inner .heading h1.page-heading,
.agile-banner-section .agile-banner-inner .agile-banner-content .heading h1.page-heading,
.blog-banner-section .blog-banner-inner .blog-banner-content .heading h1,
.blog-digital-section .blog-digital-inner .blog-digital-heading h1,
.case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content .heading h1,
.case-studies-section .case-studies-inner .case-studies-heading h1 {
    font-size: 40px;
    line-height: 56px;
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 700;
    margin: 0 auto 16px;
}

/******/
.case-studies-section .case-studies-inner .case-studies-heading h1 {
    text-align: left;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading h1 span {
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 800;
}

.industries-middel .agile-banner-section .agile-banner-inner .agile-banner-content h1.page-heading {
    margin-bottom: 0;
}

.agile-banner-section .agile-banner-inner .agile-banner-content .heading h1.page-heading {
    margin-bottom: 8px;
}

.service-hero-section .heading h1.page-heading {
    max-width: 908px;
    margin: 0 auto 16px;
}

.careers-banner-section .careers-banner-inner .heading h1.page-heading {
    max-width: 716px;
    margin: 0 auto 16px;
}

.career-middle .careers-banner-section .careers-banner-inner h1.page-heading {
    font-size: 60px;
    line-height: 72px;
}

.digital-banner-section .digital-banner-inner .heading h1.page-heading {
    max-width: 716px;
    margin: 0 auto 16px;
}

.service-middle .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    padding-right: 15px;
    text-align: left;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link .arrow {
    min-width: 20px;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link.active {
    font-weight: 500;
}

/*********end 5-4-2023(h)***********/
.header .navbar .submenu .submenu-inner .sub-menu-content .sub-menu-inner {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    transition: all 0.3s linear;
    color: #91959b;
}

.header .navbar .submenu .submenu-inner .sub-menu-content .sub-menu-inner:hover {
    color: #040405;
}

.mobile-menu .menu-item li a.sub-menu-inner {
    color: #91959b;
}

.mobile-menu .menu-item li a.sub-menu-inner:hover {
    color: #040405;
}

/************/
.form-heading-divider {
    margin-bottom: 40px;
}

.form-heading-divider h6 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #616161;
    margin: 0;
    text-align: center;
    position: relative;
}

.form-heading-divider h6:before {
    content: "";
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    opacity: 0.4;
    height: 1px;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.form-heading-divider h6 span {
    position: relative;
    z-index: 2;
    color: #91959b !important;
    display: inline-block;
    background-color: #fff;
    background-color: #fff;
    padding: 0 24px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    min-height: auto;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .file-upload-data {
    flex-flow: row;
    display: flex;
    padding: 13px;
    margin-bottom: 0;
    align-items: center;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    margin: 0;
    padding: 10px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img img {
    max-width: 100%;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    width: 54px;
    height: 54px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span:first-child {
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #808188;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group.form-group-left {
    margin-bottom: 12px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-separator {
    text-align: center;
    margin-bottom: 12px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-separator span {
    color: #91959b !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group textarea {
    min-height: 160px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .job-description-inner {
    display: flex;
}

.hire-developer-modal.modal-form .modal-content .modal-body .job-description-inner .form-group {
    flex: 1;
}

.hire-developer-modal.modal-form .modal-content .modal-body .job-description-inner .form-separator {
    padding-right: 24px;
    align-self: center;
}

.hire-developer-modal.modal-form .modal-content .modal-footer .modal-footer-inner {
    display: block;
}

.hire-developer-modal.modal-form .modal-content .modal-footer .modal-footer-btn {
    text-align: right;
    padding-top: 10px;
}

.hire-developer-modal.modal-form .modal-content .modal-body {
    padding: 18px 30px 10px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group label {
    margin-bottom: 10px;
    padding: 0;
}

.request-form-section.tr-pb-0 {
    padding-bottom: 0 !important;
}

.about-project-section.tr-pt-0 {
    padding-top: 0 !important;
}

.heading-short-description p {
    color: #91959b !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    display: flex;
    align-items: center;
}

.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-file-img {
    margin: 0;
}

.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data .upload-text {
    flex: 1;
}

.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    min-height: auto;
    padding: 24px 20px 24px 20px;
}

.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-text span {
    padding: 4px 0;
}

.request-proposal-form.request-proposal .agreement-section .agreement-inner .agreement-list {
    padding: 20px 0 20px 0px;
}

.request-proposal-form .about-project-section {
    padding-bottom: 60px;
}

.bottom-section-submenu {
    padding-bottom: 40px;
}

.bottom-section-submenu .submenu-bottom-link a {
    padding: 17px 41px 17px 12px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    display: block;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    color: #040405;
    position: relative;
}

.bottom-section-submenu .submenu-bottom-link a:hover .arrow {
    right: 12px;
    opacity: 1;
}

.bottom-section-submenu .submenu-bottom-link a .arrow {
    margin-left: auto;
    background-image: url(../../assets/images/letest-right-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.5s;
    opacity: 0;
}

.header .navbar .submenu .submenu-inner .right-side-submenu .menu-blog-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #91959b;
    padding-top: 8px;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.header .navbar .submenu .submenu-inner .right-side-submenu .menu-blog-content a {
    color: #91959b;
    transition: all 0.5s;
    position: relative;
    padding-right: 26px;
    opacity: 0.56;
}

.header .navbar .submenu .submenu-inner .right-side-submenu .menu-blog-content a::after {
    content: "";
    background-image: url(../../assets/images/update-right-arrow.svg);
    background-position: center;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
    opacity: 0;
}

.header .navbar .submenu .submenu-inner .right-side-submenu .menu-blog-content a:hover::after {
    opacity: 1;
    right: 0px;
}

.header .navbar .submenu .submenu-inner .right-side-submenu .menu-blog-content a:hover {
    opacity: 1;
    color: #040405;
}

.header .navbar .submenu .submenu-inner .right-side-submenu .submenu-icon-image {
    border-radius: 12px;
    overflow: hidden;
}

/**************/
.blog-migration-section .blog-migration-inner .blog-about-box .blog-about {
    padding-left: 35px;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 0 !important;
    overflow: hidden;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row {
    display: flex;
    align-items: center;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row .blog-about-content {
    padding-left: 20px;
    flex: 1;
}

.blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row .blog-about-content p {
    margin-bottom: 0;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle ul {
    padding-top: 5px;
    padding-left: 20px;
}

.startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle ul li {
    padding-bottom: 12px;
    list-style: disc;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 7rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-banner-section .blog-banner-inner .blog-banner-content .heading {
    max-width: 900px;
}

.sticky-bottom.footerBtn {
    position: fixed;
    right: 0;
    bottom: 0px;
}

.casestudies-section .casestudies-inner .casestudies-content .heading h3 {
    max-width: initial;
}

#fileList .list-group-item {
    margin: 10px 0;
    color: #040405 !important;
}

#fileList .list-group-item .badge.badge-danger {
    color: #040405 !important;
    float: right;
    top: 2px;
    position: relative;
    cursor: pointer;
}

.default-page {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #91959b !important;
    padding: 60px 0;
    background-color: #fff;
    background-color: #fff;
}

.default-page strong {
    font-weight: 700;
}

.default-page h1 {
    font-size: 40px;
    line-height: 56px;
    color: #040405 !important;
    font-weight: 700;
    margin: 0 auto 16px;
}

.default-page h2 {
    font-size: 28px;
    line-height: 40px;
    color: #040405 !important;
    font-weight: 700;
    margin: 25px auto 16px;
}

.default-page h3 {
    font-size: 20px;
    line-height: 30px;
    color: #040405 !important;
    font-weight: 700;
    margin: 0 auto 16px;
}

.default-page ul {
    padding-left: 20px;
}

.default-page ul li {
    list-style: disc;
    margin-bottom: 15px;
}

.default-page p,
.default-page a,
.default-page li {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #91959b !important;
}

.default-page a {
    color: rgba(0, 1, 28, 1) !important;
    transition: 0.5s all;
    word-wrap: break-word;
}

.default-page a:hover {
    font-weight: 500;
    color: #0086e6 !important;
}

.see-more-btn {
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #0091da;
    font-display: swap;
}

/*************font size changes*********************/
/*********/
.page-404-middle {
    background: #fff;
    background: #fff;
}

.page-404-inner {
    padding: 160px 0;
}

.page-404-inner .page-404-content .row {
    align-items: center;
}

.error-page-title {
    padding-bottom: 20px;
}

.error-page-title h1 {
    font-size: 32px;
    line-height: 46px;
    font-weight: 800;
    color: #040405;
}

.error-page-title h1 u {
    position: relative;
    z-index: 0;
    text-decoration: none;
}

.error-page-title h1 u::after {
    content: "";
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    opacity: 0.3;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    z-index: -2;
    display: block;
    height: 10px;
    animation: fadeIn 0.35s cubic-bezier(0.4, 0, 0.4, 0) both;
    animation-delay: 1.5s;
}

.error-page-title h1 span {
    display: block;
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
}

.error-page-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: rgba(97, 97, 97, 1);
    opacity: 0.56;
    padding-bottom: 32px;
}

.error-page-img {
    text-align: center;
}

.error-page-img img {
    max-width: 100%;
}

.process-content ul,
.process-wrapper ul {
    padding-left: 20px;
    list-style: disc;
}

.process-content ul li,
.process-wrapper ul li {
    list-style: disc;
}

.case-studies-section .case-studies-inner .case-studies-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading ul li {
    font-weight: 500;
    margin-bottom: 20px;
}

/*************/
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p {
    font-size: 16px;
    line-height: 24px;
    color: rgb(97 97 97 / 56%);
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul {
    padding-left: 20px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul li {
    list-style: disc;
    font-size: 16px;
    line-height: 24px;
    color: rgb(97 97 97 / 56%);
    font-weight: 500;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h2,
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h1 {
    font-size: 32px;
    line-height: 48px;
    margin: 20px 0 20px;
}

.blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h3 {
    font-size: 22px;
    line-height: 31px;
}

.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h4 {
    font-size: 18px;
    line-height: 26px;
}

p.twotoneline {
    display: none;
}

.compeny-section .compeny-box-border,
.compeny-section .compeny-box-border-2,
.compeny-section .compeny-box {
    height: 100%;
}

b,
strong {
    font-weight: 600 !important;
}

/************/
.careers-banner-section .careers-banner-inner .heading {
    max-width: 600px;
}

.design-stage-section .steps-detail .steps-feature .feature-content h3 {
    font-weight: 600;
}

.case-studies-main-section .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content h3 {
    margin-bottom: 0;
}

.process-section .process-list ul li:nth-child(5)::after {
    height: 100px;
    top: 22px;
}

/**************/
/******************************/
ul li {
    list-style: disc;
}

.menu-items .navItem,
.star-list li,
.footer-section ul li,
.job-searching-result ul li,
.nav-tabs .nav-item,
.blog-card-div ul li,
.pagination li,
.technologies-feature-heading li,
.tech-skills ul li,
.service-feature-heading li,
.service-follow-content ul li,
.compeny-logo ul li,
.development-links li,
.startups-tab-heading li,
.icon-list-img li,
.mobile-menu-inner ul li {
    list-style: none !important;
}

.modal-form .modal-body .request-form .accordion-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #91959b;
}

.hire-developer-modal.modal-form .budget-select .budget-select__control {
    padding: 13px 20px;
}

// .header-menu a[href="/#"] {
//     pointer-events: none;
// }

.startups-tab-section .startups-tab-inner .startups-tab-heading {
    justify-content: center;
    align-items: stretch;
}

.startups-tab-section .startups-tab-inner .startups-tab-heading li a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-sb {
    display: flex !important;
    justify-content: space-between !important;
}

/*********/
.theme-dark .meet-management-section .heading p,
.theme-dark .blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading h2,
.theme-dark .challenges-section .challenges-inner .challenges-content p,
.theme-dark .industries-relevant-section .rel-text,
.theme-dark .digital-service-section .steps-feature .inner-step .feature-content ul li,
.theme-dark .startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle ul li,
.theme-dark .case-studies-blog-section .case-studies-blog-inner .left-blog-content .case-studies-heading p,
.theme-dark .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top ul li {
    color: #91959b !important;
}

.theme-dark .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h4,
.theme-dark .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h3,
.theme-dark .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h5,
.theme-dark .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h6 {
    color: #fff;
}

.theme-dark .startups-separate-section .startups-separate-content h3 {
    color: #040405;
    color: #040405;
}

.theme-dark .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail {
    color: #91959b !important;
}

/***********/
.service-page-link {
    color: #040405;
    color: #040405;
    transition: all 0.5s;
    position: relative;
    margin-top: 30px;
    display: inline-block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 13px 30px;
    border-radius: 12px;
    min-width: 137px;
}

.service-page-link:after {
    content: "";
    background-image: url(../../assets/images/update-right-arrow.svg);
    background-position: 50%;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
    opacity: 0;
}

.service-page-link:hover {
    padding-left: 14px;
    padding-right: 46px;
    color: #040405;
    color: #040405;
}

.service-page-link:hover:after {
    opacity: 1;
    right: 17px;
}

.cursor-pointer {
    pointer-events: none;
}

/************/
.service-feature-section .service-feature-card {
    padding: 150px 0 0px 0;
}

.service-tabs-section .service-feature-heading li a.active {
    font-weight: 500;
}

.service-feature-section .service-feature-card .service-feature-content h2 {
    margin-bottom: 32px;
}

.default-page a {
    color: #0086e6 !important;
}

.default-page a:hover {
    color: rgba(0, 1, 28, 1) !important;
}

.believe-section .believe-box {
    display: flex;
    flex-flow: column;
    height: calc(100% - 80px);
}

.believe-section .believe-box .believe-image {
    margin-top: auto;
}

/***********/
.technologies-tabs-section .technologies-feature-heading {
    padding-left: 0 !important;
}

.technologies-tabs-section .technologies-feature-heading li a.active {
    font-weight: 500;
}

.approach-section .approach-inner .cto-div .cto-pofile img,
.meet-management-section .meet-profile img {
    border-radius: 12px;
}

.aboutus-middle .meet-management-section .meet-profile::after {
    display: none;
}

.thank-you-img img {
    max-width: 100%;
}

.thank-you-middle {
    background: #fff;
    background: #fff;
}

.thank-you-middle .thank-you-content {
    text-align: center;
    padding-top: 120px;
    padding-bottom: 60px;
}

.thank-you-middle .thank-you-content .thank-you-img {
    max-width: 172px;
    margin: 0 auto 5px;
}

.thank-you-middle .thank-you-content .thank-you-title {
    padding-bottom: 12px;
}

.thank-you-middle .thank-you-content .thank-you-title h1 {
    font-size: 48px;
    line-height: 69px;
    color: #040405;
    font-weight: 800;
    margin-bottom: 0;
}

.thank-you-middle .thank-you-content .thank-you-title h1 span {
    font-size: 32px;
    line-height: 46px;
    background: linear-gradient(317.72deg, #0086e6 5.24%, rgba(152, 249, 255, 0.77) 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
}

.thank-you-middle .thank-you-content .thank-page-content {
    max-width: 533px;
    margin: 0 auto;
}

.thank-you-middle .thank-you-content .thank-page-content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: rgba(97, 97, 97, 1);
    opacity: 0.56;
    padding-bottom: 24px;
    margin-bottom: 0;
}

/* thank-you */
.blog-allstories-heading-ty {
    position: relative;
    margin-bottom: 20px;
}

.blog-allstories-heading-ty:before {
    position: absolute;
    content: "";
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 90%;
    height: 2px;
    opacity: 20%;
    right: 0px;
    top: 15px;
}

.blog-allstories-heading-ty h2 {
    font-size: 24px;
    font-weight: 600;
    color: #040405;
    color: #040405;
    line-height: 31px;
    position: relative;
    padding-left: 18px;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    padding-right: 15px;
}

.blog-allstories-heading-ty h2:after {
    height: 8px;
    width: 8px;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    content: "";
    position: absolute;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 0px;
    top: 11px;
}

/************/
.page-blog-list .blog-allstories-heading-ty,
.page-stories-list .casestudies-allstories-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-blog-list .blog-allstories-heading-ty h2,
.page-stories-list .casestudies-allstories-heading h2 {
    margin-bottom: 0;
}

.page-blog-list .blog-allstories-heading-ty:before,
.page-stories-list .casestudies-allstories-heading:before {
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.page-blog-list .view-all-btn,
.page-stories-list .view-all-btn {
    padding-left: 10px;
    background-color: #fff;
    background-color: #fff;
    position: relative;
    z-index: 2;
}

.page-blog-list.blog-articles-section .blog-articles-inner .blog-card .blog-img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.startUp-detail-middle .startups-demandtaxi-section {
    padding: 40px 0 0px;
}

.industries-relevant-section .steps-feature .feature-content .btn.btn-secondary.feature-btn {
    height: auto;
}

.theme-dark .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content {
    background: linear-gradient(180deg, transparent, #101010);
}

.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading ol li {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #616161;
    font-weight: 500;
    margin-bottom: 20px;
}

.mobile-process-list ul li {
    list-style: none;
}

.casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2 {
    position: relative;
    z-index: 2;
}

.startups-process-section .startups-process-inner .process-list ul li {
    list-style: none;
}

.wordwise-section {
    padding-bottom: 0;
}

/*****************/
.startups-looking-section .startups-looking-inner .startups-looking-img img {
    max-width: 595px;
}

/********/
.startups-tab-section {
    position: unset;
}

/***/
.header .navbar .submenu .submenu-inner .right-side-submenu .menu-blog-content a {
    color: #00011c;
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
}

.homepage-middle .process-section .process-list ul li:first-child::after {
    background-size: contain;
    height: 93px;
}

.homepage-middle .process-section .process-list ul li:nth-child(2)::before {
    height: 95px;
}

.careers-banner-section .careers-banner-inner .bottom-baner-images {
    padding-left: 90px;
}

.careers-banner-section .careers-banner-inner .bottom-baner-images .img-box img {
    border-radius: 12px;
}

.careers-banner-section .careers-banner-inner .top-baner-images .img-box.image-tow {
    top: 130px;
    position: relative;
}

.career-middle .careers-banner-section {
    align-items: flex-start;
    min-height: unset;
}

.jvectormap-container {
    background-color: #f6faff !important;
}

.theme-dark .jvectormap-container {
    background-color: transparent !important;
}

.findus-section .card-0 .find-card {
    border-color: rgba(97, 97, 97, 0.5607843137) !important;
}

.findus-section .card-0 .find-card:before {
    opacity: 1;
    border-color: #9e9e9e !important;
}

.findus-section .card-1 .find-card {
    border-color: rgba(97, 97, 97, 0.5607843137) !important;
}

.findus-section .card-1 .find-card:before {
    opacity: 1;
    border-color: #9e9e9e !important;
}

.findus-section .card-2 .find-card {
    border-color: rgba(97, 97, 97, 0.5607843137) !important;
}

.findus-section .card-2 .find-card:before {
    opacity: 1;
    border-color: #9e9e9e !important;
}

.findus-section .card-0 .find-card:hover {
    border-color: #040405 !important;
    border-color: #040405 !important;
}

.findus-section .card-0 .find-card:hover:before {
    border-color: #040405 !important;
    border-color: #040405 !important;
}

.findus-section .card-1 .find-card:hover {
    border-color: rgb(0, 134, 230) rgb(141, 208, 255) !important;
}

.findus-section .card-1 .find-card:hover:before {
    border-color: rgb(0, 134, 230) !important;
}

.findus-section .card-2 .find-card:hover {
    border-color: rgb(138, 188, 0) rgb(204, 250, 78) !important;
}

.findus-section .card-2 .find-card:hover:before {
    border-color: rgb(138, 188, 0) !important;
}

.wordwise-section .client-map-content .slider-client .slick-arrow {
    top: unset;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 8px 8px 8px 8px;
    width: 30px;
    height: 30px;
    bottom: -80px;
}

.wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
    left: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev:before {
    content: "";
    background-image: url(../../assets/images/update-left-arrows.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 15px;
    width: 15px;
    display: block;
    margin: 0 auto;
    filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
    opacity: 0.6;
}

.wordwise-section .client-map-content .slider-client .slick-arrow.slick-next {
    left: 52%;
}

.wordwise-section .client-map-content .slider-client .slick-arrow.slick-next:hover,
.wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev:hover {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev:hover:before,
.wordwise-section .client-map-content .slider-client .slick-arrow.slick-next:hover:before {
    opacity: 1;
    filter: none;
}

.wordwise-section .client-map-content .slider-client .slick-arrow.slick-next:before {
    content: "";
    background-image: url(../../assets/images/update-left-arrows.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 15px;
    width: 15px;
    display: block;
    margin: 0 auto;
    filter: invert(80%) sepia(3%) saturate(16%) hue-rotate(353deg) brightness(98%) contrast(91%);
    opacity: 0.6;
    transform: rotate(180deg);
}

.wordwise-section .client-map-content .item:hover {
    box-shadow: 0px 1.635px 10.308px rgba(0, 0, 0, 0.07), 0px 20.2964px 23.35px rgba(0, 0, 0, 0.0425185), 0px 7.46655px 5.97324px rgba(0, 0, 0, 0.0274815);
}

.wordwise-section .client-map-content .slider-client .slick-slide.slick-active .item {
    box-shadow: 0px 1.635px 10.308px rgba(0, 0, 0, 0.07), 0px 20.2964px 23.35px rgba(0, 0, 0, 0.0425185), 0px 7.46655px 5.97324px rgba(0, 0, 0, 0.0274815);
    border: 1px solid;
    border-color: rgb(0, 134, 230) rgb(141, 208, 255);
    margin-bottom: 50px;
}

.wordwise-section .client-map-content .item {
    padding: 24px;
}

.bottom-section-submenu {
    padding-bottom: 24px;
}

.aboutus-middle .order-slider-2 .meet-slider .slick-slide.slick-current {
    display: none !important;
}

.aboutus-middle .meet-management-section .heading p {
    max-width: 510px;
}

.aboutus-middle .meet-management-section .meet-profile {
    min-height: 345px;
}

.aboutus-middle .meet-management-section .meet-slider .slick-next {
    right: 7px;
}

.work-section .our-project .project .project-link a {
    position: relative;
}

.work-section .our-project .project .project-link a:after {
    content: "";
    background-image: url(../../assets/images/right-arrow.png);
    width: 12px;
    height: 11px;
    position: absolute;
    right: 0;
    top: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    transition: all 0.5s;
}

.work-section .our-project .project .project-link a:hover::after {
    right: -15px;
    opacity: 1;
}

.work-section .our-project .project .project-link a img {
    display: none;
}

/************/
.about-section .work-image {
    height: 100%;
}

.sitemap {
    text-transform: capitalize;
}

.sitemap span {
    text-transform: capitalize;
}

.sitemap a {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.sitemap li {
    margin-bottom: 0px !important;
}

/**********************/
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 1 !important;
    transform: scale(0.85) translateY(-12px) translateX(10px) !important;
    width: auto;
    background: #fff;
    padding: 0 5px !important;
    height: auto;
    background-color: #fff !important;
    background-color: #fff !important;
}

.form-floating>.form-control:focus~label {
    color: #8dd0ff !important;
}

.request-proposal-new.request-proposal.request-proposal-form .request-form-section {
    padding-top: 30px;
}

.request-proposal-new.request-proposal.request-proposal-form .agreement-btn .btn.btn-primary {
    width: 165px !important;
    max-width: 100% !important;
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-select__control {
    padding: 10px 20px 10px 6px;
    border-radius: 10px;
    min-height: 56px;
}

.request-proposal-new.request-proposal.request-proposal-form .agreement-section {
    padding-top: 120px;
}

.request-proposal-new.request-proposal.request-proposal-form .blocks-section {
    padding-top: 120px;
}

.request-proposal-new.request-proposal.request-proposal-form .agreement-section.new-agreement-section {
    padding-top: 10px;
}

.request-proposal-new.request-proposal.request-proposal-form .file-upload-data span.form-label {
    display: block;
    text-align: left;
}

.request-proposal-new.request-proposal.request-proposal-form .request-form-section .request-form-inner .request-form .form-group textarea.form-control {
    height: 112px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .budget-content .range-slider,
.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    padding: 14px 16px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-text span {
    font-size: 12px;
    line-height: 18px;
    padding: 0;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-text span b {
    font-weight: 600;
    color: #040405 !important;
    color: #040405 !important;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data .upload-text {
    text-align: left;
    padding-left: 14px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .form-group .form-label {
    margin-bottom: 16px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .slider-labels .caption p span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .form-group .form-label,
.request-proposal-new.request-proposal.request-proposal-form .request-proposal .about-project-section .about-project-inner .select-type-content .form-group .form-label {
    color: #040405 !important;
    color: #040405 !important;
}

.request-proposal-new.request-proposal.request-proposal-form .upload-file-row {
    display: flex;
    align-items: center;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .budget-content {
    margin-top: 0;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .slider-labels .caption p em {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    display: block;
    align-items: center;
}

.request-proposal-new.request-proposal.request-proposal-form .request-form-section .request-form-inner .request-form .form-group.form-floating label {
    width: auto;
    padding: 16px;
    font-size: 14px !important;
    line-height: 21px !important;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .budget-content .range-slider {
    padding: 14px 16px 3px;
}

.request-proposal-new.request-proposal.request-proposal-form .form-floating>.form-control:focus~label,
.request-proposal-new.request-proposal.request-proposal-form .form-floating>.form-control:not(:placeholder-shown)~label,
.request-proposal-new.request-proposal.request-proposal-form .form-floating>.form-select~label {
    color: rgb(141, 208, 255) !important;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-file-img {
    width: 64px;
    height: 64px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-file-img img {
    width: 38px;
    height: 38px;
}

.request-proposal-new.request-proposal.request-proposal-form .agreement-section .agreement-inner .agreement-list {
    margin-bottom: 0;
}

.request-proposal-new.request-proposal.request-proposal-form .new-agreement-section {
    padding-bottom: 0;
}

.request-proposal-new.request-proposal.request-proposal-form .request-form-section .request-form-inner .request-form .form-group .form-control {
    height: 56px;
    padding: 16px;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px !important;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section {
    padding-bottom: 0;
}

.request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog {
    border-radius: 20px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 24px;
    height: 100%;
}

.request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-image {
    width: 100%;
    height: 231px;
    border-radius: 12px;
    margin-bottom: 16px;
    overflow: hidden;
}

.request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-content h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 12px;
    margin: 0;
    color: #040405;
}

.request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-content p {
    font-weight: 500;
    font-size: 16px;
    color: #91959b !important;
    line-height: 24px;
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-select__multi-value {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 1px solid #dcecf8;
    border-radius: 10px;
    padding: 6px 12px;
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-select__clear-indicator {
    display: none;
}

.budget-select__clear-indicator {
    display: none;
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-select__multi-value__remove svg path {
    fill: rgb(119, 170, 207);
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-select__multi-value__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #040405;
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-placeholder {
    color: #91959b !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

.request-proposal-new.request-proposal.request-proposal-form .request-form-inner .request-form .form-group {
    margin-bottom: 24px;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .form-group .form-label {
    font-weight: 600;
}

.request-proposal-new.request-proposal.request-proposal-form .agreement-section.new-agreement-section .form-group {
    margin-bottom: 0 !important;
}

/**************/
.hire-developer-modal.modal-form .modal-content .modal-body {
    padding: 0;
}

.hire-developer-modal .modal-banner-left {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    height: 100%;
    padding: 24px;
}

.hire-developer-modal.modal-form .modal-content {
    border-radius: 20px;
}

.hire-developer-modal .modal-left-img {
    height: 230px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 16px;
}

.hire-developer-modal .modal-left-img img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center center;
}

.hire-developer-modal.modal-form .modal-content .modal-title {
    font-weight: 700;
    font-size: 32px;
    color: #040405 !important;
    color: #040405 !important;
    line-height: 42px;
    font-weight: 700;
    padding-bottom: 12px;
}

.hire-developer-modal .modal-banner-left p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #91959b !important;
}

.modal-content-right {
    padding: 55px 30px 30px 6px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group label {
    width: auto;
    padding: 16px 20px;
    font-weight: 500;
    font-size: 14px;
    height: auto;
    line-height: 21px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .form-control,
.modal-form .modal-content .modal-body .form-group .form-select {
    height: 56px !important;
}

.modal-form .form-group.form-floating .form-control:focus,
.form-group.form-floating .form-select:focus,
.form-group.form-floating textarea:focus,
.form-group.form-floating textarea:focus-visible,
.form-group.form-floating .file-upload-data:focus {
    border: 1px solid rgba(0, 134, 230, 0.4) !important;
    background: #fff;
    background: #fff;
}

.hire-developer-modal.modal-form .budget-select .budget-select__control {
    padding: 6px 20px;
    height: 56px;
}

.hire-developer-modal .job-description-inner .form-group {
    margin-bottom: 0 !important;
}

.hire-developer-modal .job-description-inner .form-group .form-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.col-pr-0 {
    padding-right: 0 !important;
}

.col-pr-0 .modal-upload-file {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
}

.col-pl-0 {
    padding-left: 0 !important;
}

.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .form-control {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0 !important;
    border-left: 0 !important;
    height: 81px !important;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 0;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.job-description-inner {
    position: relative;
}

.job-description-inner:after {
    content: "or";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 100%;
    border: 1px solid rgba(4, 4, 5, 0.2) !important;
    position: absolute;
    bottom: -12px;
    width: 24px;
    text-align: center;
    height: 24px;
    left: calc(50% - 12px);
    background-color: #fff;
    color: #91959b !important;
    z-index: 4;
}

.hire-developer-modal .modal-footer-inner {
    border-top: 1px solid rgba(4, 4, 5, 0.2);
    padding-top: 24px;
}

.hire-developer-modal .modal-footer-btn {
    text-align: right;
    padding-top: 8px;
}

.hire-developer-modal .modal-footer-btn button.btn-primary {
    width: 165px;
}

.hire-developer-modal .modal-footer-btn .btn.btn-secondary {
    border: 0 !important;
    color: #91959b !important;
    box-shadow: none !important;
}

.btn.cancel-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #91959b !important;
    margin-right: 40px;
    outline: none !important;
    border: 0px;
    box-shadow: none !important;
}

.btn.cancel-btn:hover {
    color: #040405 !important;
    color: #040405 !important;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group textarea {
    min-height: 112px;
}

.job-description-inner .form-group.form-control::placeholder {
    color: #91959b !important;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span b {
    color: #040405 !important;
    color: #040405 !important;
}

.hire-developer-modal .modal-header {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding: 10px 20px !important;
}

.hire-developer-modal.modal-form .form-group .form-control {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.hire-developer-modal.modal-form .col-pl-0 .form-group .form-control::placeholder {
    font-size: 14px;
    line-height: 21px;
    color: #91959b !important;
}

.error-message {
    font-size: 12px;
    line-height: 18px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span {
    padding-left: 20px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span.font-12 {
    font-size: 12px;
    line-height: 18px;
}

.form-floating.select-floating .form-label {
    opacity: 1 !important;
    transform: scale(0.85) translateY(-12px) translateX(10px) !important;
    width: auto;
    background: #fff;
    padding: 0 5px !important;
    height: auto;
    background-color: #fff !important;
    background-color: #fff !important;
    z-index: 9;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group {
    margin-bottom: 24px;
}

.hire-developer-modal.modal-form .budget-select .budget-select__single-value {
    color: rgba(4, 4, 5, 1) !important;
}

.hire-developer-modal.modal-form.modal-form-request-quote .modal-content .modal-body .form-group .modal-upload-file {
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 1px solid rgba(4, 4, 5, 0.2);
}

.modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .form-control {
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-top: 1px solid rgba(4, 4, 5, 0.2) !important;
    border-left: 0 !important;
    height: 82px !important;
}

.modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .modul-document-link {
    position: relative;
}

.modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .modul-document-link:after {
    content: "or";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 100%;
    border: 1px solid rgba(4, 4, 5, 0.2) !important;
    position: absolute;
    top: 50%;
    width: 24px;
    text-align: center;
    height: 24px;
    transform: translateY(-50%);
    left: -12px;
    background-color: #fff;
    color: #91959b !important;
    z-index: 4;
}

.modal-form-request-quote.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    align-items: flex-start;
}

.modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .modul-document-link .form-control {
    padding-left: 30px;
}

.modal-form .form-group .form-control,
.modal-form .form-group .form-select {
    padding: 17px 16px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group label {
    padding: 17px 16px;
}

.modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .form-control:focus {
    border-color: rgba(0, 134, 230, 0.4) !important;
}

.form-group .form-control:focus,
.form-group .form-select:focus,
textarea:focus,
textarea:focus-visible {
    background: transparent !important;
}

.request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .upload-text.form-upload-text span:first-child {
    font-size: 14px;
    line-height: 21px;
}

.hire-developer-modal.modal-form .modal-content .modal-body .form-group .form-control,
.modal-form .modal-content .modal-body .form-group .form-select {
    padding: 15px 16px;
}

.request-proposal-new.request-proposal.request-proposal-form .budget-select .budget-select__control {
    padding-right: 14px;
}

#fileList .list-group-item {
    font-weight: 500;
    font-size: 14px;
    color: #040405 !important;
    color: #040405 !important;
    line-height: 21px;
}

.hire-developer-modal.modal-form .budget-select .budget-select__control {
    padding-right: 14px;
}

.hire-developer-modal.modal-form.modal-form-request-quote .modal-content .modal-body .form-group .modal-upload-file {
    border-color: rgba(4, 4, 5, 0.2) !important;
}

/************/
.theme-dark .request-proposal-new .request-form-section .request-form-inner .request-form .form-group .form-control {
    background-color: #fff !important;
    background-color: #fff !important;
}

.theme-dark .request-proposal-new .request-form-section .request-form-inner .request-form .form-group .form-control:focus {
    border: 1px solid rgba(0, 134, 230, 0.4) !important;
}

.theme-dark .budget-select .budget-select__menu .budget-select__option:hover {
    color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
}

.theme-dark select option:hover {
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
}

.theme-dark .form-group .form-select:focus {
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
}

.framework-section .framework-inner .framework-card span {
    line-height: 18px !important;
}

.lets-connect-middle {
    padding-bottom: 70px;
}

.request-proposal-new.request-proposal.request-proposal-form {
    background-color: #fff;
    background-color: #fff;
}

.lets-connect-middle .modal-content-right {
    padding: 0;
}

.lets-connect-middle.hire-developer-modal .modal-banner-left {
    margin: 0;
    border-radius: 20px;
}

.lets-connect-modal .modal-dialog {
    max-width: 1028px;
}

.lets-connect-modal .modal-content {
    border-radius: 20px;
    padding: 24px 40px 40px 40px;
    background-color: #fff;
    border: 1px solid rgba(4, 4, 5, 0.2) !important;
}

.lets-connect-modal .modal-content .modal-header {
    padding: 0;
    border: 0;
}

.lets-connect-modal .modal-content .modal-header h4 {
    margin-bottom: 0;
    font-size: 26px;
    line-height: 39px;
    font-weight: 700;
    color: rgba(0, 1, 28, 1);
}

.lets-connect-modal .modal-content .modal-header .btn-close {
    width: 12px;
    background-size: cover;
    padding: 0;
}

.lets-connect-modal .modal-content .modal-body {
    padding: 24px 0 0 0;
}

.lets-connect-modal .modal-content .modal-body .card {
    padding: 32px;
    background-color: #fff;
    background-color: #fff;
    border-radius: 12px;
    height: 100%;
}

.lets-connect-modal .modal-content .modal-body .card.card-blue {
    border-color: rgb(0, 134, 230) rgb(141, 208, 255) !important;
}

.lets-connect-modal .modal-content .modal-body .card.card-green {
    border-color: rgb(138, 188, 0) rgb(204, 250, 78) !important;
}

.lets-connect-modal .modal-content .modal-body .card .card-img {
    border-radius: 12px;
    overflow: hidden;
    height: 244px;
}

.lets-connect-modal .modal-content .modal-body .card .card-img img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center center;
}

.lets-connect-modal .modal-content .modal-body .card .card-body {
    padding: 0;
}

.lets-connect-modal .modal-content .modal-body .card .card-body h4 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: rgba(0, 1, 28, 1);
    margin-top: 20px;
    margin-bottom: 24px;
    max-width: 90%;
}

.lets-connect-modal .modal-content .modal-body .card .card-body .btn {
    transform: none !important;
    transition: none !important;
}

.lets-connect-modal .modal-content .modal-body .card .card-body .btn:hover {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    color: #fff !important;
    border-color: #8dd0ff !important;
}

.theme-dark .lets-connect-modal .modal-content .btn-close {
    opacity: 1;
    filter: invert(1);
}

.hire-dedicated-file-list {
    margin-top: 15px;
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card {
    padding: 0;
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card.card-blue .card-img {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    margin: -1px;
    width: calc(100% + 2px);
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card.card-green .card-img {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    margin: -1px;
    width: calc(100% + 2px);
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-img h2 {
    letter-spacing: 0.02em;
    font-weight: 700;
    font-size: 56px;
    line-height: 68px;
    color: #fff;
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-body {
    padding: 0 24px 32px;
}

.lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-body {
    display: flex;
    flex-wrap: wrap;
}

.lets-connect-modal .modal-content .modal-body .card .card-body .btn {
    margin-top: auto;
}

.lets-connect-modal .modal-content .modal-body .card .card-body h4 {
    width: 100%;
}

/*********/
.partner-logo-section {
    background: #fff;
    background: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
}

.partner-logo-inner {
    padding: 33px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background-color: #eaf5fd !important
}

.partner-logo-title p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 27px;
    color: var(--heading-secondary, rgba(97, 97, 97, 1)) !important;

    font-weight: 500;
}

.partner-logo-img {
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner-logo-img img {
    margin: 0 auto;
    max-width: 100%;
    width: auto;
    height: auto;
    filter: grayscale(100%);
    opacity: 0.4;
    transition: all 0.4s;
    max-height: 40px;
}

.partner-logo-img:hover img {
    margin: 0 auto;
    width: auto;
    height: auto;
    filter: grayscale(0);
    opacity: 1;
}

.partner-logo-section .swiper-wrapper {
    display: flex;
    align-items: center;
    min-width: 100%;
}

.partner-logo-section .slick-list {
    overflow: visible;
}

.partner-logo-section .partner-logo {
    clip-path: inset(-100vw -100vw -100vw 0);
    overflow: visible;
}

.partner-logo-item {
    vertical-align: middle;
}

/*********/
.lets-connect-middle {
    padding-top: 30px;
}

.lets-connect-middle.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    align-items: flex-start;
}

.lets-connect-middle.modal-form .hire-dedicated-file-list {
    margin-top: 0;
}

.lets-connect-middle.modal-form .hire-dedicated-file-list li {
    margin-top: 15px;
}

.partner-logo-section .swiper-slide {
    width: auto !important;
}

.slider {
    overflow: hidden !important;
}

.partner-logo-section .swiper-wrapper {
    transition-timing-function: linear !important;
}

/**********/
.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
.blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    aspect-ratio: 4/3;
    height: 189px;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img img,
.blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img img {
    object-position: left;
}

.blog-digital-section .blog-digital-inner .blog-digital-img img {
    max-height: unset;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul li span {
    font-size: 14px;
    line-height: 21px;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3,
.blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-weight: 700;
}

.blog-aspectratio-43 {
    display: flex !important;
}

.blog-aspectratio-43 .blog-img {
    overflow: unset;
}

.blog-aspectratio-image {
    flex: unset !important;
    width: unset !important;
}

.blog-aspectratio-content {
    flex: unset !important;
    width: unset !important;
}

.blog-detail-development .blog-digital-section .blog-digital-inner .blog-digital-img img {
    max-height: unset !important;
}

.topStories-aspectratio-43 {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
}

.topStories-aspectratio-43 .topStories-aspectratio-43-col1 {
    width: 65%;
}

.topStories-aspectratio-43 .topStories-aspectratio-43-col1 .blog-card .blog-img img,
.topStories-aspectratio-43 .topStories-aspectratio-43-col2 .blog-card-small .blog-img img {
    height: unset !important;
    object-position: left;
    aspect-ratio: 4/3;
}

.topStories-aspectratio-43 .topStories-aspectratio-43-col2 .blog-card-small .blog-img img {
    aspect-ratio: 4/2.25;
}

.topStories-aspectratio-43 .topStories-aspectratio-43-col2 {
    width: 35%;
}

.topStories-aspectratio-43 .topStories-aspectratio-43-col1 .blog-card .blog-img,
.topStories-aspectratio-43 .topStories-aspectratio-43-col2 .blog-card-small .blog-img {
    height: unset !important;
}

.talk-to-expert-modal .filelist-name {
    max-width: 275px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/**************/
.approach-section .approach-inner .cto-div {
    position: sticky;
    top: 100px;
}

.approach-section .approach-inner .cto-div .cto-pofile {
    margin-top: 55px;
}

/*******************/
/******about-text-color*********/
.about-section .heading h3,
.aboutus-middle .difference-section .deference-feature .feature-box p,
.aboutus-middle .difference-section .heading h3,
.aboutus-middle .difference-section .deference-feature .feature-box p,
.aboutus-middle .heading h3 {
    color: rgb(145, 149, 155) !important;
}

.meet-management-section .profile-info p {
    color: #91959b !important;
}

.about-section .heading p,
.aboutus-middle .believe-section .believe-box .believe-content p,
.aboutus-middle .meet-management-section .heading p,
.aboutus-middle .difference-section .make-text p,
.footer-section .footer-details.footer-bottom .footer-details-inner .footer-col .footer-location p,
.footer-section .footer-details.footer-bottom .footer-details-inner .footer-col .footer-location a {
    color: #040405 !important;
}

.aboutus-middle .difference-section .make-text p {
    font-weight: 400;
}

.footer-section .footer-details.footer-bottom .footer-details-inner .footer-col .footer-col-link ul li a {
    color: #91959b !important;
    opacity: 1 !important;
}

.footer-section .footer-details.footer-bottom .footer-details-inner .footer-col .footer-col-link ul li a:hover {
    color: #040405 !important;
}

.blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p,
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail ul li {
    font-weight: 400;
    color: #040405 !important;
}

/******home******/
.client-section .solution-listing .solution-box .text p {
    color: #91959b !important;
}

.agile-team-section .agile-team-inner .team-card span {
    color: #040405 !important;
}

.homepage-middle .service-section .service-feature .service-feature-inner p,
.faq-section .accordion .accordion-item .accordion-collapse .accordion-body,
.careers-about-section .careers-about-left p,
.job-positions-section .section-titel p,
.startups-uber-section .startups-uber-content p,
.startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-content p,
.our-culture-section .our-culture-card .card-tite p,
.benefits-section .icon-card .card .card-content p,
.findus-section .heading p,
.blog-detail-development .blog-digital-section .blog-digital-inner .blog-digital-heading p,
.hire-developer-modal .modal-banner-left p,
.request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-content p,
.technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading p,
.technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-card .technologies-feature-content p,
.technologies-middle .onestop-section .onestop-inner .onestop-box .box-content p,
.technology-feature-top .startups-vacation-inner .startups-vacation-content p,
.technology-feature .startups-features-inner .startups-features-vacation .startups-features-card p,
.technologies-middle .digital-service-section .steps-detail .steps-feature .feature-content p,
.framework-section .framework-inner .framework-card p,
.agile-banner-section .agile-banner-inner .agile-banner-content P,
.challenges-section .challenges-inner .challenges-content p,
.agile-team-section .agile-team-inner .team-card p,
.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top p,
.approach-section .approach-inner .approach-content p span,
.approach-section .approach-inner .approach-content p,
.agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card p,
.agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul li p,
.partner-section .partner-section-inner p,
.book-section .book-box .book-text p,
.service-hero-section .heading p,
.service-feature-section .service-feature-card .service-feature-content p,
.service-feature-section .service-feature-card .service-feature-content h6,
.compeny-section .compeny-main-content .compeny-text p,
.demand-education-section .demand-inner .e-demand-box p,
.industries-system-section .industries-card p,
.industry-middle .industries-system-section .development-box .development-top p,
.industries-relevant-section .rel-text,
.industries-relevant-section .steps-feature .feature-content p,
.startups-middle .startups-looking-section .startups-looking-content p,
.startups-middle .startups-vacation-section .startups-vacation-inner .startups-vacation-content p,
.startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle p,
.startups-middle .startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card p,
.startups-middle .startups-idea-section .startups-idea-card .startups-idea-content p,
.startups-demandtaxi-section .startups-demandtaxi-inner .startups-demandtaxi-content p,
.startups-separate-section .startups-separate-inner .startups-separate-content p,
.startups-planning-section .startups-planning-inner .startups-planning-content p,
.startups-feature-section .startups-feature-inner .startups-features-card p,
.design-stage-section .steps-detail .steps-feature .feature-content p,
.startups-building-section .startups-building-inner .startups-building-content p,
.blog-banner-section .blog-banner-inner .blog-banner-content .heading h3,
.casestudies-section .casestudies-inner .casestudies-content .heading h3,
.case-studies-about-section .case-studies-wrapper .case-studies-content p,
.case-studies-blog-section .case-studies-blog-inner .left-blog-content .case-studies-heading p,
.case-studies-overview-section .case-studies-overview-inner .right-content-overview p,
.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading p,
.case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading ul li,
.default-page p,
.default-page a,
.default-page li {
    color: #040405 !important;
    color: #040405 !important;
    font-weight: 400 !important;
    opacity: 1;
}

/******15-5-2023*******/
.partner-logo-img img {
    max-height: inherit;
    max-width: 150px;
}

.request-proposal-page.request-proposal-new.request-proposal.request-proposal-form .blocks-section {
    padding-top: 70px;
}

/******15-5-2023*******/
/***16-5-2023***/
.request-proposal-new.request-proposal.request-proposal-form {
    padding-bottom: 0;
}

.letsconnect-inner-Page .page-stories-list {
    padding-bottom: 60px;
}

.bd-book-section.book-section {
    padding: 30px 0 30px 0 !important;
}

.bd-book-section.book-section .book-box .book-text h2 {
    font-size: 24px !important;
    line-height: 31px !important;
    margin-top: 0;
    font-weight: 700;
}

.bd-book-section.book-section .book-box .book-text {
    padding: 0 100px;
    margin: 0 auto;
}

.bd-book-section.book-section .book-box {
    padding: 30px 20px 30px 20px;
}

.contact-us-page .findus-section,
.contact-us-page .findus-section:after {
    background: #fff;
    background: #fff;
}

.contact-us-page .partner-logo-section {
    padding: 80px 0;
}

.contact-us-page .findus-section {
    padding-top: 40px;
}

.contact-us-page .findus-section .find-card .find-text {
    min-height: inherit;
}

.contact-us-page .request-proposal-new.request-proposal.request-proposal-form .request-form-section {
    padding-top: 80px;
}

/*****23-5-2023*****/
.partner-logo-slider {
    width: 100%;
}

.partner-logo .partner-logo-item {
    margin-right: 80px;
}

/*****end 23-5-2023*****/
.blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .decoration-none:hover {
    text-decoration: none !important;
}

/* Preloader Start */
.preloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

figure.preloader {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 6.25em;
    height: 6.25em;
    animation: rotate 2.4s linear infinite;
}

figure.preloader .white {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    animation: flash 2.4s linear infinite;
    opacity: 0;
}

figure.preloader .dot {
    position: absolute;
    margin: auto;
    width: 2.4em;
    height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease;
}

figure.preloader .dot:nth-child(2) {
    top: 0;
    bottom: 0;
    left: 0;
    background: #088ae7;
    animation: dotsY 2.4s linear infinite;
}

figure.preloader .dot:nth-child(3) {
    left: 0;
    right: 0;
    top: 0;
    background: #01a5bd;
    animation: dotsX 2.4s linear infinite;
}

figure.preloader .dot:nth-child(4) {
    top: 0;
    bottom: 0;
    right: 0;
    background: #86bd01;
    animation: dotsY 2.4s linear infinite;
}

figure.preloader .dot:nth-child(5) {
    left: 0;
    right: 0;
    bottom: 0;
    background: #afe708;
    animation: dotsX 2.4s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    10% {
        width: 6.25em;
        height: 6.25em;
    }

    66% {
        width: 2.4em;
        height: 2.4em;
    }

    100% {
        transform: rotate(360deg);
        width: 6.25em;
        height: 6.25em;
    }
}

@keyframes dotsY {
    66% {
        opacity: 0.1;
        width: 2.4em;
    }

    77% {
        opacity: 1;
        width: 0;
    }
}

@keyframes dotsX {
    66% {
        opacity: 0.1;
        height: 2.4em;
    }

    77% {
        opacity: 1;
        height: 0;
    }
}

@keyframes flash {
    33% {
        opacity: 0;
        border-radius: 0%;
    }

    55% {
        opacity: 0.6;
        border-radius: 100%;
    }

    66% {
        opacity: 0;
    }
}

/***/
.blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card {
    overflow: hidden;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 span {
    color: #91959b !important;
}

.challenges-section .challenges-inner .challenges-img img {
    border-radius: 12px !important;
}

.case-studies-main-section .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small .casestudies-img img,
.case-studies-main-section .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img img {
    object-position: center right;
}

.middle-sction .case-studies-about-detial-section .case-studies-about-listing .client-about-list .client-list-country p {
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

/********5-6-2023*************/
.nav-bottom-btn {
    display: flex;
    flex-flow: row;
}

.nav-bottom-btn a.btn-primary {
    margin: 5px !important;
}

.nav-bottom-btn .nav-mb-link {
    display: none !important;
}

.lets-connect-modal .modal-content .modal-header .btn-close {
    z-index: 10;
    position: relative;
}

/************/
/*******casestudies-allstories-section**********/
.casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    padding: 1px !important;
    border-radius: 12px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 30px);
    margin: 0 15px 15px 15px;
}

.casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 15px 140px 15px 15px;
    border-radius: 12px;
    width: 100%;
    background-color: #fff;
    background-color: #fff;
}

.casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner h3 {
    margin-bottom: 0;
    font-size: 18px !important;
    line-height: 26px !important;
    color: #040405;
    color: #040405;
    font-weight: 700;
}

.case-studies-detail-section .case-studies-details-img {
    height: 0;
    padding-bottom: 38%;
    position: relative;
}

.case-studies-detail-section .case-studies-details-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    object-position: top center !important;
}

/*******end-casestudies-allstories-section**********/
/******12-6-2023********/
.tag-line {
    padding: 3px 6px;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    opacity: 1 !important;
    animation: color-animation 1.5s infinite !important;
    margin-left: 6px;
    display: inline-block;
    vertical-align: middle;
}

@keyframes color-animation {
    0% {
        background-color: #ff7a2f;
    }

    55% {
        background-color: #0086e6;
    }

    100% {
        background-color: #ff7a2f;
    }
}

.submenu-bottom-content.d-none-desktop {
    display: none;
}

.submenu-bottom-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.submenu-bottom-content p {
    padding-bottom: 0;
    margin-bottom: 0;
    color: #91959b;
    flex: 1;
    text-align: right;
}

.service-feature-section .service-follow .service-follow-content ul li {
    position: relative;
    padding-right: 30px;
}

.service-feature-section .service-follow .service-follow-content ul li::after {
    content: "";
    position: absolute;
    right: 10px;
    transform: rotate(45deg);
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    width: 5px;
    height: 5px;
    border-radius: 1px;
    top: 10px;
}

.service-feature-section .service-follow .service-follow-content ul li:last-child::after {
    display: none;
}

/*******************/
.company-banner-section {
    position: relative;
    padding: 110px 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.company-banner-section .company-banner-bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    z-index: -1;
}

.company-banner-section .company-banner-bg-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    opacity: 0.5;
}

.company-banner-section .company-banner-bg-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.company-banner-section .company-banner-inner .banner-content .title {
    font-size: 40px;
    line-height: 52px;
    font-weight: 700;
    color: #040405;
    max-width: 630px;
    margin-bottom: 16px;
}

.company-banner-section .company-banner-inner .banner-content .sub-text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    max-width: 590px;
    color: #040405;
    margin-bottom: 32px;
}

.company-banner-section .company-banner-inner .img-wrapper {
    margin: 0 auto;
}

.company-banner-section .company-banner-inner .img-wrapper img {
    max-width: 100%;
    height: auto;
}

.logo-slider-wrapper {
    padding-bottom: 100px;
    background-color: #fff;
    background-color: #fff;
}

.logo-slider-wrapper .logo-slider-inner {
    padding: 33px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    overflow: hidden;
}

.logo-slider-wrapper .logo-slider-inner .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #91959b;
    margin-bottom: 0;
}

.logo-slider-wrapper .logo-slider-inner .partners-slider-wrapper {
    clip-path: inset(-100vw -100vw -100vw 0);
}

.logo-slider-wrapper .logo-slider-inner .partners-slider-wrapper .slick-list {
    overflow: visible;
}

.logo-slider-wrapper .logo-slider-inner .partners-slider-wrapper .box {
    margin-right: 50px;
}

.logo-slider-wrapper .logo-slider-inner .partners-slider-wrapper .box .img-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-slider-wrapper .logo-slider-inner .partners-slider-wrapper .box .img-wrapper img {
    max-width: 150px;
    max-height: 40px;
}

.count-on-section {
    background-color: #fff;
    background-color: #fff;
    padding-bottom: 100px;
    padding-top: 50px;
}

.count-on-section .count-on-section-inner .count-on-content {
    max-width: 560px;
}

.count-on-section .count-on-section-inner .count-on-content .title {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #040405;
}

.count-on-section .count-on-section-inner .count-on-content .sub-text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #040405;
    margin-bottom: 16px;
}

.count-on-section .count-on-section-inner .count-on-content .text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #040405;
    margin-bottom: 16px;
}

.count-on-section .count-on-section-inner .count-on-content .text:last-child {
    margin-bottom: 0;
}

.count-on-section .count-on-section-inner .img-wrapper {
    width: 100%;
    margin: auto;
    height: 100%;
    border-radius: 10px;
}

.count-on-section .count-on-section-inner .img-wrapper img {
    max-width: 100%;
    height: auto;
}

.hiring-banner-wrapper {
    padding-bottom: 100px;
    background-color: #fff;
    background-color: #fff;
}

.hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box {
    padding: 42px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 18px;
    position: relative;
}

.hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../assets/images/custom-shape-img2.svg), url(../../assets/images/custom-shape-img1.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: top left, center bottom;
    background-size: auto;
    width: 100%;
    height: 100%;
    border-radius: 18px;
}

.hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-banner-box-inner {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-box-left {
    max-width: 400px;
}

.hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-box-left .title {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: #040405;
    margin-bottom: 0;
}

.hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-box-left .title span {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.services-technology-section.technologies-section {
    padding-top: 0;
    padding-bottom: 100px;
}

.services-technology-section.technologies-section .technologies-inner {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.services-technology-section.technologies-section .technologies-inner .heading {
    max-width: 850px;
}

.services-technology-section.technologies-section .technologies-inner .heading h2 {
    margin-bottom: 16px;
}

.services-technology-section.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    text-align: left;
    padding-right: 15px;
}

.latest-technology-wrapper {
    padding-bottom: 100px;
    background-color: #fff;
    background-color: #fff;
}

.latest-technology-wrapper .latest-technology-inner .heading-wrapper {
    max-width: 992px;
    margin: 0 auto 50px;
}

.latest-technology-wrapper .latest-technology-inner .heading-wrapper .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    color: #040405;
    margin-bottom: 16px;
}

.latest-technology-wrapper .latest-technology-inner .heading-wrapper .subtext {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #91959b;
    text-align: center;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card {
    padding: 22px;
    border: 1px solid linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 1px solid linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border-radius: 12px;
    transition: 0.4s linear;
    height: 100%;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card:hover {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card:hover .card-top .icon-box {
    background: #fff;
    background: #fff;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-top .icon-box {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    transition: 0.4s linear;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-top .icon-box .icon-wrapper {
    width: 35px;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-top .icon-box .icon-wrapper img {
    max-width: 100%;
    height: auto;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-top .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #040405;
    margin-bottom: 0;
}

.latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-text p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #040405;
    margin-bottom: 0;
}

.serives-new-middle .testimonial-section {
    padding: 0 0 100px;
}

.serives-new-middle .testimonial-section .testimonial-inner {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 80px 0;
}

.transfroming-industries-wrapper {
    background-color: #fff;
    background-color: #fff;
    padding-bottom: 100px;
}

.transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper {
    max-width: 992px;
    margin: 0 auto 50px;
}

.transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    color: #040405;
    margin-bottom: 16px;
}

.transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper .subtext {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #91959b;
    text-align: center;
}

.transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge {
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 8px;
    transition: 0.4s linear;
}

.transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge .industry-icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 9px;
    background-color: #fff;
    background-color: #fff;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge .industry-icon-wrapper .industry-icon img {
    max-width: 100%;
    height: auto;
    width: auto;
}

.transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge:hover {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
}

.transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge:hover .title {
    color: #040405;
}

.transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #040405;
    margin-bottom: 0;
}

.approach-section-wrapper {
    padding-bottom: 100px;
    background-color: #fff;
    background-color: #fff;
}

.approach-section-wrapper .approach-section-inner {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.approach-section-wrapper .approach-section-inner .heading-wrapper {
    max-width: 992px;
    margin: 0 0 40px;
}

.approach-section-wrapper .approach-section-inner .heading-wrapper .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: left;
    color: #040405;
    margin-bottom: 16px;
}

.approach-section-wrapper .approach-section-inner .heading-wrapper .subtext {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #91959b;
    text-align: left;
}

.approach-section-wrapper .approach-section-inner .approach-section-content {
    border-radius: 12px;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list {
    margin: 0;
    padding: 0;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item {
    padding: 16px;
    border-radius: 12px;
    max-width: 545px;
    width: 100%;
    list-style-type: none;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item:not(:last-child) {
    border-image: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%) 30;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item:hover {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.15) 5.56%, rgba(141, 208, 255, 0.15) 113.66%);
    border-image: none;
    border-color: transparent;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item:hover .approach-list-content .count {
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item:hover .approach-list-content p {
    color: #040405;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item .approach-list-content {
    display: flex;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item .approach-list-content .count {
    margin-right: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #91959b;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item .approach-list-content p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #91959b;
    transition: 0.4s linear;
    margin-bottom: 0;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .img-wrapper {
    border-radius: 10px;
    overflow: hidden;
}

.approach-section-wrapper .approach-section-inner .approach-section-content .img-wrapper img {
    max-width: 100%;
    height: auto;
}

.why-trusted-section {
    padding-bottom: 100px;
    background-color: #fff;
    background-color: #fff;
}

.why-trusted-section .why-trusted-inner .heading-wrapper {
    max-width: 992px;
    margin: 0 auto 50px;
}

.why-trusted-section .why-trusted-inner .heading-wrapper .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    color: #040405;
    margin-bottom: 16px;
}

.why-trusted-section .why-trusted-inner .heading-wrapper .subtext {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #91959b;
    text-align: center;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider {
    background-color: #fff;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: stretch;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform {
    padding: 24px;
    background-color: #fff;
    background-color: #fff;
    border-radius: 12px;
    min-height: 350px;
    min-width: 230px;
    width: 230px;
    position: relative;
    border: 1px solid linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    border: 1px solid linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    display: flex;
    flex-flow: column;
    transition: all 0.4s cubic-bezier(0.61, 1, 0.88, 1);
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform::after {
    content: unset;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform::before {
    content: unset;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform .count {
    font-size: 60px;
    line-height: 90px;
    color: #d8dadc;
    margin-bottom: 16px;
    font-weight: 900;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #91959b;
    display: none;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #040405;
    margin-bottom: 0;
    display: none;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform .title {
    margin-top: auto;
}

.why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform .title h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #91959b;
    display: block;
}

.case-study-section {
    padding-bottom: 100px;
    background-color: #fff;
    background-color: #fff;
}

.case-study-section .case-study-inner {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.case-study-section .case-study-inner .case-study-top {
    margin-bottom: 16px;
}

.case-study-section .case-study-inner .case-study-top .heading-wrapper .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: left;
    color: #040405;
    margin-bottom: 12px;
}

.case-study-section .case-study-inner .case-study-top .heading-wrapper .subtext {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #91959b;
    text-align: left;
    margin-bottom: 0;
}

.case-study-section .case-study-inner .case-study-top .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.case-study-section .case-study-inner .case-study-top .btn-wrapper .btn-secondary {
    background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    color: #040405 !important;
    padding-left: 28px;
    padding-right: 28px;
}

.case-study-section .case-study-inner .case-study-card {
    position: relative;
    border-radius: 11px;
    transition: 0.4s linear;
    display: block;
    overflow: hidden;
}

.case-study-section .case-study-inner .case-study-card:hover {
    display: block;
}

.case-study-section .case-study-inner .case-study-card .img-wrapper {
    width: 100%;
    height: 100%;
}

.case-study-section .case-study-inner .case-study-card .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.case-study-section .case-study-inner .case-study-card .card-content {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 11px 11px;
    padding: 24px 20px;
    background: linear-gradient(180deg, transparent, #000);
    display: flex;
    align-items: flex-end;
}

.case-study-section .case-study-inner .case-study-card .card-content .title {
    max-width: 450px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
}

.theme-dark .case-study-section .case-study-inner .case-study-card:hover {
    box-shadow: 0 3px 15px 0 rgb(4, 4, 5);
}

.services-faq-section.faq-section {
    padding-top: 0;
}

.services-faq-section.faq-section .faq-inner {
    padding: 80px 0;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
}

.services-faq-section.faq-section .faq-inner .accordion {
    min-height: unset;
}

.services-faq-section.faq-section .faq-inner .accordion .accordion-item:hover {
    background: linear-gradient(283.13deg, rgba(0, 145, 218, 0.05) 5.56%, rgba(130, 213, 255, 0.05) 113.66%);
}

.services-faq-section.faq-section .faq-inner .accordion .accordion-item.active {
    background: linear-gradient(283.13deg, rgba(0, 145, 218, 0.05) 5.56%, rgba(130, 213, 255, 0.05) 113.66%) !important;
    border: transparent;
}

.services-faq-section.faq-section .faq-inner .accordion .accordion-item.active .accordion-header .accordion-button {
    background: transparent;
    font-weight: 700;
}

.services-faq-section.faq-section .faq-inner .accordion .accordion-item.active .accordion-collapse {
    background-color: transparent;
}

.services-faq-section.faq-section .faq-inner .accordion .accordion-item.active .accordion-body {
    color: #040405;
    background-color: transparent;
}

.latest-blogs-section {
    background-color: #fff;
    background-color: #fff;
}

.latest-blogs-section .latest-blogs-inner .heading-wrapper {
    max-width: 992px;
    margin: 0 auto 50px;
}

.latest-blogs-section .latest-blogs-inner .heading-wrapper .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    color: #040405;
    margin-bottom: 16px;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card-div {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
    border-radius: 12px;
    padding: 1px;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card {
    display: block;
    border-radius: 12px;
    height: 100%;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card:hover .card-content {
    background-color: #f3f9fe;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card:hover .img-wrapper::after {
    opacity: 0;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .img-wrapper {
    width: 100%;
    height: 220px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    position: relative;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .img-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(320deg, rgba(149, 209, 252, 0.2) 5.56%, hsla(0, 0%, 100%, 0) 113.66%);
    opacity: 1;
    transition: all 0.4s;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content {
    padding: 15px;
    border-radius: 0 0 12px 12px;
    background-color: #fff;
    background-color: #fff;
    transition: 0.4s linear;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #040405;
    margin-bottom: 8px;
    min-height: 72px;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-meta span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #616161;
    display: inline-block;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-meta span:last-child {
    padding-left: 28px;
    position: relative;
}

.latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-meta span:last-child::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #616161;
    left: 8px;
    top: 7px;
    border-radius: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        display: none;
    }

    90% {
        display: block;
    }

    100% {
        opacity: 1;
    }
}

.case-studies-main-section .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img img {
    object-position: 94% !important;
}

.div-grid-row .div-grid-col:nth-of-type(1) .casestudies-card-small .casestudies-img img,
.div-grid-row .div-grid-col:nth-of-type(8n) .casestudies-card-small .casestudies-img img {
    object-position: 94% !important;
}

.developer-slider-list {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.developer-slider-list li {
    list-style: none;
    color: #cbd3d8;
    font-size: 40px;
    line-height: 55px;
    padding: 0 22px;
}

.marquee-container-new {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    margin: 0;
    padding: 8px;
    box-sizing: border-box;
}

.marquee-list {
    display: inline-flex;
    flex-wrap: nowrap;
    animation: marquee 120s linear infinite;
}

.marquee-container-right .marquee-list {
    animation: marqueeone 120s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes marqueeone {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

// lastest
.technologies-middle .salesForce.technologies-contact-box-section {
    border-radius: 12px;
}

.technologies-contact-box-section .hire-left-content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.technologies-contact-box-section .hire-left-content-box ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.technologies-contact-box-section .hire-left-content-box ul li {
    margin-right: 18px;
    list-style: none;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.technologies-contact-box-section .hire-left-content-box ul li img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.technologies-contact-box-section .hire-left-content-box ul li p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: rgba(4, 4, 5, 0.56);
    margin: 0;
}

.hire-now-form-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #00011c;
}

.salesforce-listing {
    margin-bottom: 24px;
}

.salesforce-listing .salesforce-list-chip {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.salesforce-listing .salesforce-list-chip li {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #19191b;
    background-color: #0086E61A;
    padding: 10px 12px;
    list-style: none;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 50px;
}

.technologies-update-banner-section .agile-banner-inner .agile-banner-content .heading {
    margin-bottom: 24px !important;
}

.technologies-update-banner-section.technologies-middle .salesForce {
    padding: 32px 40px !important;
}

.technologies-update-banner-section.agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    max-width: 100%;
    margin: 0 auto 32px auto;
}

.technologies-update-banner-section.agile-banner-section .agile-banner-inner .agile-banner-content .heading h1.page-heading {
    max-width: 100%;
}

.technologies-update-banner-section.agile-banner-section.agile-full-banner {
    padding: 48px 0;
}

// end
.service-middle .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    padding-right: 15px !important;
}

.startups-uber-section {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
    padding: 80px 0 160px;
}

.startups-uber-section .startups-uber-inner .heading h2 {
    font-size: 40px;
    color: #040405 !important;
    font-weight: 700;
}

.startups-uber-section .startups-uber-inner .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
}

.blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    min-height: auto;
}

.menu-heading p {
    margin-bottom: 0 !important;
}


.v-overlay {
    position: relative;
    height: 100% !important;
    z-index: 0;
}

.v-overlay::before {
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(0, 1, 28, 0.6), rgba(0, 1, 28, 0.6));
    transition: all 0.8s;
    display: none !important;
}


.v-paused .v-overlay::before {
    display: flex !important;
}

.v-bigPlay svg {
    display: none;
}

.v-bigPlay {
    width: 82px !important;
    height: 82px;
    min-height: 82px;
    background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
    border-radius: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.v-bigPlay::before {
    content: "" !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../../assets/images/update-play-btn.svg);
    display: none;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    min-width: 82px;
    height: 82px;
    min-height: 82px;
    margin-left: 4px;
    z-index: 999 !important;
    position: absolute;
}

.v-paused .v-bigPlay:hover {
    background-color: transparent;
    opacity: 0.7;
}

.v-bigPlay::after {
    animation: pulse-animation 1.5s infinite;
    box-shadow: 0 0 0 20px rgba(204, 250, 78, 0);
    content: "";
    display: block;
    height: 82px;
    width: 82px;
    min-width: 82px;
    border-radius: 100px;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(204, 250, 78, 0.3);
    }

    100% {
        box-shadow: 0 0 0 25px rgba(204, 250, 78, 0);
    }
}


@media (max-width: 767px) {
    .video-container .v-bigPlay::before {
        height: 42px !important;
        width: 42px !important;
        min-width: 42px !important;
        min-height: 42px !important;
        background-size: 50% !important;
    }

    .video-container .v-bigPlay {
        height: 42px !important;
        width: 42px !important;
        min-width: 42px !important;
        min-height: 42px !important;
    }

    .video-container .v-bigPlay::after {
        height: 42px !important;
        width: 42px !important;
        min-width: 42px !important;
    }

    // .homepage-middle .banner-section .banner-inner .slick-slider .slick-track {
    // 	width: 388px !important;
    // }
    .author-data-row {
        padding-top: 30px;
    }
}

body .partner-logo-section.section-pad-0 {
    padding: 0 !important;
}

.blog-aspectratio-43 {
    display: flex;
    flex-direction: column;

    .log-aspectratio-image {

        .blog-img {
            img {
                aspect-ratio: auto !important;
                height: 265px;
            }

        }
    }

    .blog-img::after {
        opacity: 1 !important;
    }
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img {
    aspect-ratio: auto !important;
    height: 185px;
    border-radius: 0;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    min-height: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card-div {
    margin-bottom: 26px;
}


// option 2

// .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img, .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
//     aspect-ratio: 4 / 2.7;
//     height: 189px;
// }

// .blog-card .blog-img:after {
//     opacity: 1 !important;
//     width: 1px;
//     left: auto;
//     right: 0;
//     background: linear-gradient(283.13deg,#0086e6 5.56%,#8dd0ff 113.66%);
// }

// .theme-dark .blog-card .blog-img:after {
//     opacity: 1 !important;
//     width: 1px;
//     left: auto;
//     right: 0;
//     background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) !important;
// }



.form-floating .form-control {
    background-color: transparent;
}

.theme-dark .form-floating .form-control {
    background-color: transparent;
    color: #fff;
}

.rfp-modal {

    .global-right-content {
        background-color: #fff !important;

        .range-slider {
            background-color: #fff;
        }

        h2 {
            color: #040405;
            font-size: 28px;
            font-weight: 700;
            line-height: 42px;
            margin-bottom: 12px;
        }

        p {
            color: #686869;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 24px;
        }

        .global-select-upload {
            height: 100%;
            min-height: unset;
        }

        .global-select-upload .form-control {
            border-radius: 12px !important;
            border: 1px solid rgba(0, 134, 230, 0.20) !important;
            min-height: 131px !important;
        }

        .select-budget h3 {
            font-size: 16px;
            line-height: 150%;
            font-weight: 600;
            margin-bottom: 18px;
            color: #040405;
        }

        .select-budget .budget-upload-file .upload-icon {
            height: 56px;
            min-width: 56px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: var(--Troo-Blue-Gradient, linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%));
            margin-right: 14px;
        }

        // aa




        .form-group .form-control {
            padding: 16px !important;
            border-radius: 10px;
            font-size: 16px;
            line-height: 150%;
            font-weight: 600;
            color: #040405;
            border: 1px solid rgba(0, 134, 230, 0.20);
            background-color: #fff;
        }

        .form-floating>label {
            font-size: 14px;
            color: #686869;
        }

        .form-floating>label {
            font-size: 14px;
            color: #686869;
        }

        .form-floating>.form-control-plaintext~label:after,
        .form-floating>.form-control:focus~label:after,
        .form-floating>.form-control:not(:placeholder-shown)~label:after,
        .form-floating>.form-select~label:after {
            background-color: transparent !important;
        }

        .sign-nda {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 48px;

            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0;
                font-size: 12px;
                line-height: 150%;
                font-weight: 500;
                margin-right: 24px;
                text-decoration: none;
                color: rgba(4, 4, 5, 0.56);
                transition: 0.3s;

                img {
                    margin-right: 6px;
                }

                &:hover {
                    color: #84bd00;
                }
            }
        }

        .budget-btn {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 48px;
        }

    }

}

.book-new {
    background-color: #F4FAFE !important;
}

.book-new-main .book-section {
    background-color: #F4FAFE !important;
}

.crm {
    background: #F4FAFE;
}

.crm .partner-logo-section {
    background-color: #EAF5FD;
}

body .crm {
    .section-pad-top-120 {
      padding-top: 120px !important;
    }
  
    .section-pad-bottom-120 {
      padding-bottom: 120px !important;
    }

    .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
  
    section,
    div > section {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  
    .faq-new-section {
      padding-top: 60px !important;
      padding-bottom: 60px !important;
    }
  
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      section,
      div > section {
        padding-top: 40px;
        padding-bottom: 40px;
      }
  
      .faq-new-section {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
      }
  
      .section-pad-top-120 {
        padding-top: 80px !important;
      }
  
      .section-pad-bottom-120 {
        padding-bottom: 80px !important;
      }

      .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
    }
  
    @media (max-width: 992px) {
      section,
      div > section {
        padding-top: 40px;
        padding-bottom: 40px;
      }
  
      .faq-new-section {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
      }
  
      .section-pad-top-120 {
        padding-top: 80px !important;
      }
  
      .section-pad-bottom-120 {
        padding-bottom: 80px !important;
      }

      .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
    }
  
    @media (max-width: 767px) {
      section,
      div > section {
        padding-top: 30px;
        padding-bottom: 30px;
      }
  
      .faq-new-section {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
      }
  
      .section-pad-top-120 {
        padding-top: 60px !important;
      }
  
      .section-pad-bottom-120 {
        padding-bottom: 60px !important;
      }

      .section-pad-top-0 {
        padding-top: 0 !important;
      }
    
      .section-pad-bottom-0 {
        padding-bottom: 0 !important;
      }
    }
  }
  
// banner min height css start
@media only screen and (min-width: 1400px) {
    .about-section {
        min-height: 650px;
    }

    .casestudies-section {
        min-height: 395px;
    }

    .blog-page-section .blog-banner-section {
        min-height: 395px;
    }
}
// banner min height css end


.casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner, .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner{
    padding-right: 120px;
}

@media  (max-width: 1400px) {
    .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner, .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
        padding-right: 15px  !important;
    }   
}