
.filter-dropdown .dropdown-toggle::after {
    display: none !important;

  }

  .filter-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: unset;
    margin-bottom: 10px;
  }

  .filter-dropdown ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    margin-bottom: 8px;
	transition: all 0.3s ease-in-out;
  }

  .filter-dropdown ul li span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #040405;
    padding-right: 10px;
    flex: 1;
  }

  .filter-dropdown ul li:hover span{
    font-weight: 600 !important;
  }

  .filter-dropdown .dropdown-menu {
    border: 2px solid #0086E633;
    border-radius: 12px;
    padding: 12px;
  }

  .filter-dropdown .dropdown-item {
    padding: 0;
    background-color: transparent !important;
  }

  .filter-dropdown ul li input {
    display: none;
  }

  .filter-dropdown ul li label {
    position: relative;
    cursor: pointer;
  }

  .filter-dropdown ul li label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #CCE7FA;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: block;
    padding: 0;
    border-radius: 2px;
  }

  .filter-dropdown ul li input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .filter-offcanvas {
    padding: 24px;
  }

  .filter-accordion .accordion-item {
    border: unset;
  }

  .filter-accordion .accordion-item:last-child {
    border-bottom: 1px solid #D6EEFF;
    border-radius: unset;
  }

  .filter-offcanvas .offcanvas-body {
    padding: 24px 0 0 0;
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
  }

  .filter-offcanvas .offcanvas-title-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

  }

  .filter-offcanvas .offcanvas-title-row a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #040405;
    text-decoration: none;
    padding-left: 5px;

  }

  .filter-offcanvas .offcanvas-title {
    flex: 1;
    padding-right: 12px;
  }

  .filter-offcanvas .offcanvas-title-row h3 {
    margin: 0;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #040405;
  }

  .filter-offcanvas .offcanvas-header {
    padding: 0;

  }

  .filter-offcanvas .offcanvas-header .btn-close {
    border: 2px solid #D6EEFF;
    padding: 13px !important;
    font-size: 10px;
    opacity: 1;
  }

  .filter-accordion .accordion-button {
    background-color: transparent;
    border-top: 1px solid #D6EEFF;
    border-radius: unset !important;
    padding: 12px 0;
    font-weight: 500;
  }

  .filter-accordion .accordion-button:focus {
    box-shadow: unset;

  }

  .filter-accordion .accordion-body {
    padding: 0;
  }

  .filter-accordion .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
    border-radius: unset !important;

  }

  .filter-accordion .accordion-button:not(.collapsed) {
    background-color: unset;
    box-shadow: unset;
  }

  .filter-accordion .accordion-button::after {
    background-image: url("/images/arw-dropdown.svg");
    background-size: 24px;
    width: 24px;
    height: 24px;
  }

  .filter-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("/images/arw-dropdown.svg");

  }
