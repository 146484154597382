/* Text Styles */
.textStyle {
    color: black;

    margin: 0 36px 0 16px
}

.textStyleHeading {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.textStylePara {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #555C60;
    margin: 0 !important;

    div {
        a {
            text-decoration: underline !important;
        }
    }

}

/* Container Style */
.concentContainer {
    display: flex;
    border: 2px solid #D6EEFF;
    height: auto;
    margin: auto;
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    z-index: 1000;
    border-radius: 16px;
    background: white;
    padding: 24px;

}

/* Button Style */
.buttonStyle {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    button:nth-child(1) {
        padding: 16px 24px !important;
        font-size: 14px !important;
    }

    button:nth-child(2) {
        padding: 16px 24px !important;
        font-size: 14px !important;
        color: #555C60 !important;
        border: 2px solid #D6EEFF !important;
    }

}

.svgStyle {
    display: inline;
    margin: auto;
}

@media (max-width: 1024px) {


    .textStylePara {

        font-size: 14px;
        line-height: 19px;


    }

    .textStyleHeading {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }

    .buttonStyle {
        button:nth-child(1) {
            padding: 0px 24px !important;
        }

        button:nth-child(2) {
            padding: 0px 24px !important;
        }
    }
}



@media (max-width: 834px) {


    .textStyle {
        margin: 0 16px 0 16px
    }
}

@media (max-width: 768px) {
    .concentContainer {
        width: auto;
    }

    .textStyle {
        margin: 0 10px 0 16px
    }

    .textStylePara {
        font-size: 12px;
    }

}

@media (max-width: 540px) {
    .concentContainer {
        width: auto;
        height: max-content;
        flex-direction: column;
    }

    .buttonStyle {
        justify-content: end;
        margin-top: 15px;

        button:nth-child(1) {
            font-size: 12px !important;
        }

        button:nth-child(2) {
            font-size: 12px !important;
        }

        button:nth-child(3) {
            position: absolute;
            top: 2%;
            right: 2%;
        }
    }

    .svgStyle {
        margin: 0;
    }

    .textStyle {
        margin: 7px 0 0 0;
    }
}