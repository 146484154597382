// without module css (slider arrow not work)
// 	header css
.btn {
	text-transform: uppercase !important;
}

.header-home .navbar {
	background: transparent !important;
}

// .header.header-home .navbar .collapse1 .navbar-nav a.nav-link {
//     color: #fff !important;
// }

.header.header-home.is-sticky .navbar .collapse1 .navbar-nav a.nav-link {
	color: var(--header-text);
}

.header-home.is-sticky .navbar {
	background: var(--bg-header-light-fixed) !important;
}

// img
.header .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.header .navbar .navbar-brand img:nth-child(2) {
	display: none;
}

.header.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.header.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: none;
}

.theme-light .header.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.theme-light .header.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: none;
}

.theme-dark .header.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.theme-dark .header.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: block;
}


.header-home .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.header-home .navbar .navbar-brand img:nth-child(2) {
	display: block;
}

.header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.header-home.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: block;
}

.theme-light .header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.theme-light .header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.theme-dark .header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.theme-dark .header-home.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: block;
}

// case study detail slider arrow css

.arrow-left,
.arrow-right {
	width: 40px;
	height: 40px;
	border-radius: 8px;
	border: 2px solid var(--primary-border-light);
	background: var(--primary-background-light);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;

}

.arrow-left {
	right: -50px;
	left: unset;
	top: 54%;

	&::after {
		content: "";
		background-image: url(../../public/images/home/icn-arrow.svg);
		background-color: unset;
		width: 15px;
		height: 15px;
		transform: rotate(180deg);
		background-position: center;
	}
}

.arrow-right {
	right: -50px;
	top: 45%;
	left: unset;

	&::after {
		content: "";
		// background-image: url(../../../public/images/home/icn-arrow-dark.svg);
		background-image: url(../../public/images/home/icn-arrow.svg);
		background-color: unset;
		width: 15px;
		height: 15px;
		transform: rotate(0);
		background-position: center;

	}
}

.arrow-left.swiper-button-disabled {
	opacity: 1;

	&::after {
		content: "";
		background-image: url(../../public/images/home/icn-arrow-dark.svg);
		transform: rotate(0);
	}
}

.arrow-right.swiper-button-disabled {
	opacity: 1;

	&::after {
		content: "";
		background-image: url(../../public/images/home/icn-arrow-dark.svg);
		transform: rotate(180deg);

	}
}

// contact page slider
.swiper {

	.swiper-button-prev,
	.swiper-button-next {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		border: 2px solid var(--primary-border-light);
		background: var(--primary-background-light);
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.swiper-button-prev {
		right: 0px;
		left: unset;
		top: 54%;

		&::after {
			content: "";
			background-image: url(../../public/images/home/icn-arrow.svg);
			background-color: unset;
			width: 15px;
			height: 15px;
			transform: rotate(180deg);
			background-position: center;
		}
	}

	.swiper-button-next {
		right: 0px;
		top: 45%;

		&::after {
			content: "";
			// background-image: url(../../../public/images/home/icn-arrow-dark.svg);
			background-image: url(../../public/images/home/icn-arrow.svg);
			background-color: unset;
			width: 15px;
			height: 15px;
			transform: rotate(0);
			background-position: center;

		}
	}

	.swiper-button-next.swiper-button-disabled {
		opacity: 1;

		&::after {
			content: "";
			background-image: url(../../public/images/home/icn-arrow-dark.svg);
			transform: rotate(180deg);
		}
	}

	.swiper-button-prev.swiper-button-disabled {
		opacity: 1;

		&::after {
			content: "";
			background-image: url(../../public/images/home/icn-arrow-dark.svg);
			transform: rotate(0);

		}
	}
}

// swiper pagination
.swiper-pagination {
	display: flex !important;
	align-items: center;
	justify-content: center;
	bottom: 0 !important;
}

.swiper-pagination-bullet {
	width: 6px !important;
	height: 6px !important;
	background-color: #0086E6 !important;
	border-radius: 100px !important;
}

.swiper-pagination-bullet-active {
	width: 8px !important;
	height: 8px !important;
	background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%) !important;
}

// accordion button
.accordion-item .accordion-header .accordion-button {
	background-color: #fff;
	border: none;
	box-shadow: none;
}

.accordion-item .accordion-header .accordion-button:after {
	display: none;
}


// contact ru=ight form
.contact-right-form .form-group {
	margin-bottom: 36px;
}

.contact-right-form .form-group .form-control {
	padding: 13px 16px;
	height: unset;
	min-height: unset;
	font-size: 14px;
	border-radius: 10px;
}

// .contact-right-form .form-floating > label{
// 	padding: 12px;
// }
.contact-right-form .budget-btn {
	margin-top: 0;
}

.contact-right-form .sign-nda {
	margin-top: 16px;
}


#home-swiper .swiper-slide-next {
	max-width: 580px !important;
}

// #home-swiper .swiper-slide-next p{
// 	max-height: 85px;
// }
@media only screen and (max-width: 1400px) and (min-width: 1025px) {
	#home-swiper .swiper-slide-next {
		max-width: 550px !important;
	}
}

@media(max-width: 1400px) and (min-width: 1025px) {
	.swiper .swiper-button-next {
		right: 10px;
		width: 32px;
		height: 32px;
		right: 6px;
	}

	.swiper .swiper-button-prev {
		right: 10px;
		width: 32px;
		height: 32px;
		right: 6px;
	}

}

@media(max-width: 1024px) {

	.arrow-left,
	.arrow-right {
		display: none;
	}
}

@media(max-width: 992px) {
	.swiper .swiper-button-next {
		display: none;
	}

	.swiper .swiper-button-prev {
		display: none;
	}

	.contact-right-form {
		margin-bottom: 20px;
	}

}

@media(max-width: 767px) {

	.explore-logo {
		height: 85%;
	}

	.swiper .swiper-button-next {
		display: flex;
	}

	.swiper .swiper-button-prev {
		display: flex;
	}

	.swiper .swiper-button-next,
	.swiper .swiper-button-prev {
		position: absolute;
		top: auto;
		bottom: 0px !important;
		z-index: 99;
		transform: rotate(90deg);
		height: 35px;
		width: 35px;
	}

	.swiper .swiper-button-prev {
		left: 43%;
	}

	.swiper .swiper-button-next {
		right: 43%;
	}

	#home-swiper {
		min-height: 750px;
		display: flex;
		align-items: center;
	}

	.swiper .swiper-slide {
		margin-bottom: 0;
	}

}

@media(max-width: 600px) {

	.swiper .swiper-button-prev {
		left: 41%;
	}

	.swiper .swiper-button-next {
		right: 41%;
	}

	#home-swiper {
		min-height: 600px;
		display: flex;
		align-items: center;
	}
}

@media(max-width: 500px) {

	.swiper .swiper-button-prev {
		left: 40%;
	}

	.swiper .swiper-button-next {
		right: 40%;
	}

	#home-swiper {
		min-height: 560px;
		display: flex;
		align-items: center;
	}
}

@media(max-width: 480px) {

	.swiper .swiper-button-prev {
		left: 38%;
	}

	.swiper .swiper-button-next {
		right: 38%;
	}

	#home-swiper {
		min-height: 530px;
		display: flex;
		align-items: center;
	}
}

@media(max-width: 370px) {

	.swiper .swiper-button-prev {
		left: 35%;
	}

	.swiper .swiper-button-next {
		right: 35%;
	}

	#home-swiper {
		min-height: 420px;
		display: flex;
		align-items: center;
	}
}




.swiper {

	.swiper-button-prev,
	.swiper-button-next {
		width: 50px;
		height: 50px;
		border-radius: 100px;
		border: 2px solid rgba(0, 134, 230, 0.20);
		background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 7.47px 5.97px 0px rgba(0, 0, 0, 0.0275);
		box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);


	}

	.swiper-button-prev {
		left: 19.3% !important;
		top: 50%;

		&::after {
			content: "";
			background-image: url(../../public/images/home/icn-arrow.svg);
			background-color: unset;
			width: 15px;
			height: 15px;
			transform: rotate(-90deg) !important;
			background-position: center;
			filter: grayscale(100%);
		}
	}

	.swiper-button-prev:hover::after {
		filter: none;
		/* Restores the original color on hover */
	}

	.swiper-button-next {
		right: 19% !important;
		top: 50%;

		&::after {
			content: "";
			// background-image: url(../../../public/images/home/icn-arrow-dark.svg);
			background-image: url(../../public/images/home/icn-arrow.svg);
			background-color: unset;
			width: 15px;
			height: 15px;
			transform: rotate(90deg) !important;
			background-position: center;
			filter: grayscale(100%);
		}
	}

	.swiper-button-next:hover::after {
		filter: none;
		/* Restores the original color on hover */
	}

	.swiper-button-next.swiper-button-disabled {
		opacity: 1;

		&::after {
			content: "";
			background-image: url(../../public/images/home/icn-arrow-dark.svg);
			transform: rotate(180deg);
		}
	}

	.swiper-button-prev.swiper-button-disabled {
		opacity: 1;

		&::after {
			content: "";
			background-image: url(../../public/images/home/icn-arrow-dark.svg);
			transform: rotate(0);

		}
	}
}



@media(max-width: 992px) {

	#home-swiper {
		min-height: unset !important;
	}

	.slider-heading-right {
		img {
			width: 36px !important;
			height: 44px !important;
		}
	}

	.swiper {

		.swiper-wrapper {
			padding-bottom: 50px;
		}

		.swiper-button-prev {
			left: 40% !important;
			top: auto !important;
			bottom: 0px !important;

			&::after {

				// transform: rotate(180deg) !important;
				background-position: center;
			}
		}

		.swiper-button-next {
			right: 40% !important;
			top: auto !important;
			bottom: 0px !important;

			&::after {

				// transform: rotate(0deg) !important;
				background-position: center;

			}
		}
	}

}

@media(max-width: 767px) {
	.swiper {

		.swiper-wrapper {
			padding-bottom: 50px;
		}

		.swiper-button-prev {
			left: 30% !important;
			top: auto !important;
			bottom: 0px !important;

			&::after {

				transform: rotate(180deg) !important;
				background-position: center;
			}
		}

		.swiper-button-next {
			right: 30% !important;
			top: auto !important;
			bottom: 0px !important;

			&::after {

				transform: rotate(0deg) !important;
				background-position: center;

			}
		}
	}
}


.core-service-section .slick-arrow {
	position: absolute;
	top: 50%;
	margin-top: 0px;
	width: 30px;
	height: 30px;
	border-radius: 100px;
	background: var(--section-secondary) !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
  }

  .core-service-section .slick-prev {
	left: -40px;
	background: #0086e6;

  }

  .core-service-section .slick-prev::before {
	content: '';
	background-image: url(/images/small-left-arrow1.svg);
	background-size: 12px;
	background-position: center;
	height: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	margin: 0 auto;
	width: 12px;
  }

  .core-service-section .slick-next {
	right: -40px;
  }

  .core-service-section .slick-next::before {
	content: '';
	background-image: url(/images/small-left-arrow1.svg);
	background-size: 12px;
	background-position: center center;
	height: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	margin: 0 auto;
	width: 12px;
	-webkit-transform: rotate(-180deg);
			transform: rotate(-180deg);
  }

  .theme-dark .core-service-section .slick-next::before {
	-webkit-filter: grayscale(1) brightness(2);
			filter: grayscale(1) brightness(2);
  }

  .theme-dark .core-service-section .slick-prev::before {
	-webkit-filter: grayscale(1) brightness(2);
			filter: grayscale(1) brightness(2);
  }

.core-service-section .slick-slide {
    padding: 5px;
}

@media(max-width: 767px) {
.industries-middel .swiper {

	.swiper-wrapper {
		padding-bottom: 0px;
	}
}

}
@media(max-width: 992px) {

.crm .contact-right-form {
	margin-bottom: 0;
}

.crm {
	.contact-new-section .contact-right-form h3 {
		text-align: center;
	}
}
}
