// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$font-stack: "Inter", sans-serif !important;


body {
  font-family: $font-stack;
}

.middle-sction {
  position: relative;
  z-index: 10;
  min-height: 100vh;
}

.middle-sction.middle-pad {
  padding-top: 80px;
}

a {
  text-decoration: none !important;
  color: #0086e6;
  transition: all 0.4s;
  &:hover{
    color: #8abc00;
  }
}

ul {
  list-style: none;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1140px;
  }
}

@mixin btn-custom {
  padding: 16px 16px;
  border-radius: 8px !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  transition: all 0.8s;
  height: 50px;
  outline: 0 !important;
  transition: all 0.8s !important;

  &:hover {
    transition: all 0.8s;
    transform: translateY(-3px);
  }

  &:focus {
    transition: all 0.8s;
    transform: translateY(0px);
    outline: 0 !important;
  }

  &:active {
    box-shadow: none !important;
  }

  @media (max-width: 767px) {
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 26px;
    height: 40px;
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 40px;
    font-size: 12px !important;
    line-height: 20px !important;
    padding: 10px 16px !important;
  }
}

.btn.btn-primary {
  @include btn-custom();
  background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
  border-radius: 8px;
  color: #fff !important;
  border-color: transparent !important;
  border: 0 !important;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 134, 230, 0.4);
  }

  &:focus {
    outline: 0 !important;
    box-shadow: 0 3px 15px 0 rgba(0, 134, 230, 0.4);
  }
}

.btn.btn-secondary {
  @include btn-custom();
  background: #fff;
  border-radius: 10px;
  color: #00011c !important;
  border: 1px solid rgba(97, 97, 97, 0.2) !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;

  &:hover {
    background-color: #fff;;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: 0 !important;
    background-color: #fff;;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  }
}

.theme-light .btn-toggle {
  background-image: url('../assets/images/icons/theme-dark-icon.svg');
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  margin-left: 10px;
  box-shadow: none !important;
  outline: none;
  background-color: #fff !important;
  border: 0;
}

.theme-dark .btn-toggle {
  background-image: url('../assets/images/icons/theme-light-icon.svg');
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  margin-left: 10px;
  box-shadow: none !important;
  outline: none;
  background-color: #fff !important;
  border: 0;
}

@mixin heading {
  text-align: center;
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500;
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
    color: #040405 !important;
    font-weight: 700;
  }

  @media (max-width: 992px) {
    h3 {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 6px;
    }

    h2 {
      font-size: 36px;
      line-height: 48px;
    }

    h1 {
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

@mixin heading2 {
  h2 {
    line-height: 52px;
  }

  @media (max-width: 992px) {
    h2 {
      font-size: 36px;
      line-height: 48px;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 22px;
      line-height: 32px;
    }
  }
}

@media (max-width: 767px) {
  .middle-sction.middle-pad {
    padding-top: 70px;
  }
}

@media (max-width: 996px) {
  .middle-sction.middle-pad {
    padding-top: 70px;
  }
}

// animation loader
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
  background: #078ae7;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
  background: #84bd00;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
  background: #078ae7;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
  background: #84bd00;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transfom: translate(24px, 0);
  }
}

@media (min-width:1200px) {
  .technologies-middle .design-stage-section .heading .hire-salesforce-developer {
    font-size: 33px !important;
  }
}


@media (max-width: 767px) {
  .btn.btn-h-auto {
      height: auto !important;
  }
}


@media (min-width: 1000px) {
  .common-btn-style {
    .btn.btn-primary {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .common-btn-style {
    .btn.btn-primary {
      font-size: 12px !important;
      padding: 6px 12px !important;
      line-height: 15px !important;
    }
  }
}