.theme-light {
    --text-color: #040405;
    --background-color: #fff;
    --border-color: rgba(0, 145, 218, 0.3);
    --border-color2: rgba(132, 189, 0, .3);
    --section-primary: #fff;
    --form-bg: #fff;
    --form-border: rgba(4, 4, 5, 0.2);
    --form-color: rgba(4, 4, 5, 1);

}

.theme-dark {
    --background-color: #040405;
    --text-color: #fff;
    --border-color: rgba(132, 189, 0, .3);
    --border-color2: rgba(0, 145, 218, 0.3);
    --section-primary: #040405;
    --form-bg: #111111;
    --form-border: rgba(255, 255, 255, 0.2);
    --form-color: #fff !important;
}

// banner css
.case-studies-detail-section {
    padding: 32px 0 40px 0;
    background-color: #fff;

    .case-studies-inner {
        .case-studies-details-btn-wrapper {
            padding-bottom: 16px;

            .case-study-details-btn {
                border: 1px solid #8DD0FF;
                padding: 8px 10px 8px 32px;
                color: #040405;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                border-radius: 8px;
                position: relative;
                transition: all 0.5s;

                &:hover {
                    background-color: transparent !important;
                    transform: translateY(-3px);

                    &::before {
                        left: 10px;
                        opacity: 1;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    width: 15px;
                    height: 15px;
                    background-image: url(../assets/images/update-right-arrow.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    transform: translateY(-50%) rotate(180deg);
                    transition: all 0.5s;
                }
            }
        }

        .case-studies-detials-heading {
            padding-bottom: 23px;

            h1 {
                text-align: left;
                font-size: 32px;
                line-height: 48px;
                font-weight: 700;
                margin: 0;
                color: #040405;
            }
        }

        .case-studies-details-img {
            width: 100%;
            border-radius: 12px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 1200px) {
    .case-studies-detail-section {
        .case-studies-inner {
            .case-studies-detials-heading {
                h1 {
                    font-size: 28px;
                    line-height: 42px;
                }
            }
        }
    }
}

@media(max-width:991px) {
    .case-studies-detail-section {
        padding: 32px 0 23px 0;

        .case-studies-inner {
            .case-studies-detials-heading {
                h1 {
                    font-size: 26px;
                    line-height: 35px;
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .case-studies-detail-section {
        padding: 20px 0;

        .case-studies-inner {
            .case-studies-detials-heading {
                h1 {
                    font-size: 26px;
                    line-height: 35px;
                }
            }
        }
    }
}

@media(max-width: 576px) {
    .case-studies-detail-section {
        padding: 20px 0 0;
    }
}

.ul-unset {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            list-style: none;
        }
    }
}


.case-studies-blog-details-heading {
    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: #040405;
        margin-bottom: 20px;
    }

}

.casestudy-detail-stories {
    .casestudies-stories-inner {
        .casestudies-stories-heading {
            text-align: center;

            &::before {
                display: none;
            }

            h2 {
                &::after {
                    display: none;
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .case-studies-blog-details-heading {
        h3 {
            margin-bottom: 10px;
        }
    }

}

// case study about detials
.case-studies-about-detial-section {
    background-color: #fff;

    .case-studies-about-wrapper {
        padding-bottom: 40px;

        .case-studies-about-heading {
            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                display: flex;
                align-items: center;
                color: #040405;
                margin-bottom: 20px;
            }
        }

        .case-studies-about-content {
            p {
                font-size: 18px;
                line-height: 27px;
                color: #040405;
                margin: 0;

                .text-hide {
                    display: none;
                }

                .text-display {
                    display: inline-block;
                }

                .text-show {
                    cursor: pointer;
                    font-size: inherit;
                    line-height: inherit;
                    text-decoration: underline !important;
                    color: #0091DA;
                    margin-left: 5px;
                }
            }
        }
    }

    .case-studies-about-listing {
        .share-icon {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            height: 100%;

            .icon-list-img {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    margin-right: 16px;

                    &:first-child {
                        margin-right: 24px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 400;
                        margin: 0;
                        color: #616161;
                    }


                    a {
                        span {
                            width: 24px;
                            height: 24px;
                            border-radius: 100px;
                            border: 1px solid #616161;
                            ;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: auto;
                            }
                        }


                    }

                    &:nth-child(2) {
                        a {
                            span {
                                border: none;
                            }
                        }
                    }

                }
            }
        }

        .client-about-list {
            h5 {
                font-size: 22px;
                line-height: 33px;
                font-weight: 600;
                color: #040405;
                margin-bottom: 10px;
            }

            p {
                font-size: 27px;
                line-height: 32px;
                font-weight: 700;
                margin: 0;
                background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                color: transparent;

                span {
                    background: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    color: transparent;
                }

            }
        }
    }
}

@media(max-width:991px) {

    .details {
        padding-bottom: 40px !important;
    }

    .case-studies-about-detial-section {
        .case-studies-about-inner {
            .case-studies-about-listing {
                padding-bottom: 0;

                .share-icon {
                    .icon-list-img {
                        display: block;

                        li {
                            display: inline-block;

                            &:first-child {
                                margin-right: 10px;
                                margin-bottom: 10px;
                                display: block;
                            }
                        }
                    }
                }

                .client-about-list {
                    h5 {
                        font-size: 18px;
                        line-height: 26px;
                    }

                    p {
                        font-size: 22px;
                        line-height: 26px;
                    }
                }
            }

            .case-studies-about-wrapper {
                padding-bottom: 30px;
            }
        }
    }
}

@media(max-width: 767px) {
    .details {
        padding-bottom: 20px !important;
    }

    .case-study-dropdown {
        display: none !important;
    }

    .case-studies-about-detial-section .case-studies-about-listing .client-about-list p {
        margin-bottom: 15px;
    }

    .case-studies-about-detial-section {
        padding-top: 25px;

        .case-studies-about-inner {

            .case-studies-about-heading {

                h3 {
                    font-size: 18px;
                    line-height: 29px;
                }
            }

            .case-studies-about-listing {
                padding-bottom: 0;

                .share-icon {
                    margin-bottom: 15px;

                    .icon-list-img {
                        display: flex;
                        margin-bottom: 15px;

                        li:first-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .client-about-list {
                    h5 {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 22px;
                        word-break: break-all;
                    }
                }
            }

            .case-studies-about-wrapper {
                padding-bottom: 20px;

                .case-studies-about-content {
                    p {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

// case study blog detials
.case-studies-blog-detail-section {
    padding: 48px 0 40px;
    background-color: #fff;

    .case-studies-blog-detial-inner {
        .details-wrapper {
            border-bottom: 1px solid rgba(141, 208, 255, 0.4);
            border-top: 1px solid rgba(141, 208, 255, 0.4);
        }

        .left-blog-details-content {
            .left-casestudy-details {
                padding-top: 48px;
            }

            .details {
                padding-bottom: 60px;
            }

            .case-studies-blog-details-heading {
                h3 {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: #040405;
                }
            }

            p {
                color: #040405;
                font-size: 18px;
                line-height: 27px;

            }

            .major-challange-section {
                li {
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    border: 1px dashed rgba(0, 145, 218, 0.3);
                    box-shadow: 0px 8.95785px 20.9016px rgba(68, 68, 68, 0.06);
                    backdrop-filter: blur(5.6733px);
                    border-radius: 10px;
                    margin-bottom: 20px;

                    span {
                        display: inline-block;
                        padding: 7px 10px;
                        background-color: #F29113;
                        border-radius: 9px;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 700;
                        color: #FFFFFF;
                        margin-right: 12px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 27px;
                        font-weight: 500;
                        color: #040405;
                        margin-bottom: 0;
                    }
                }
            }

            .our-solution-section {
                position: relative;

                .our-solution-section-item {
                    // padding-bottom: 16px;
                    padding-left: 40px;
                    position: relative;
                    margin-bottom: 16px;

                    &.empty {
                        &::after {
                            display: none;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 12px;
                        width: 2px;
                        height: calc(100% - 25px);
                        background-color: #84BD00;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        background-image: url(../assets/images/green-right-icon.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                        z-index: 213;
                    }

                    p {
                        font-size: 16px;
                        line-height: 27px;
                        font-weight: 500;
                        color: #040405;
                        margin-bottom: 16px;
                    }

                    .our-solution-sublist {
                        padding-left: 0;
                        position: relative;

                        .our-solution-sublist-item {
                            list-style: none;
                            padding-left: 10px;
                            position: relative;
                            color: #040405;

                            &:not(:last-child) {
                                padding-bottom: 16px;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: -7px;
                                left: -28px;
                                width: 30px;
                                height: 25px;
                                background-image: url(../assets/images/bottom-shape1.svg);
                                background-repeat: no-repeat;
                                // background: url(../public/curve-arrow-round.svg) no-repeat center center;
                                background-size: contain;
                                z-index: 2;
                            }

                            &:last-child:after {
                                content: "";
                                position: absolute;
                                top: 7px;
                                left: -28px;
                                width: 2px;
                                height: 100%;
                                background-color: #fff;
                                z-index: 1;
                            }
                        }
                    }
                }
            }

            .main-features-wrapper {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }

                .main-features-heading {
                    h2 {
                        font-size: 16px;
                        line-height: 24px;
                        color: #040405;
                        margin-bottom: 16px;
                        font-weight: 600;
                    }
                }
            }

            .main-features-section {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 24px;
                li {
                    background: rgba(0, 145, 218, 0.05);
                    border-radius: 45px;
                    padding: 12px 20px;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 500;
                    color: #040405;
                    display: inline-block;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        border: 2px dashed rgba(0, 145, 218, 0.3);
                        top: -2px;
                        bottom: -2px;
                        left: -2px;
                        right: -2px;
                        border-radius: 45px;
                    }
                }
            }


            .system-functionalities-content {
                position: relative;
                padding: 20px 16px;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    border: 2px dashed rgba(132, 189, 0, .3);
                    top: -2px;
                    bottom: -2px;
                    left: -2px;
                    right: -2px;
                    border-radius: 12px;
                }

                .system-functionalities-content-heading {
                    margin-bottom: 20px;

                    h6 {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                        color: #040405;
                    }
                }

                .system-functionalities-list {
                    padding-left: 32px;

                    .system-functionalities-list-item {
                        list-style: none;
                        position: relative;
                        padding: 20px 16px;
                        background: rgba(132, 189, 0, 0.01);
                        backdrop-filter: blur(9.5px);
                        border-radius: 12px;
                        &:not(:last-child) {
                            margin-bottom: 16px;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            border: 2px dashed rgba(132, 189, 0, .3);
                            top: -2px;
                            bottom: -2px;
                            left: -2px;
                            right: -2px;
                            border-radius: 12px;
                        }

                        .system-functionalities-content-heading {
                            margin-bottom: 20px;

                            h6 {
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 600;
                                color: #040405;

                        &.empty {
                            &::after {
                                display: none;
                            }
                        }

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            left: -24px;
                            width: 16px;
                            height: 16px;
                            background-color: #84BD00;
                            border-radius: 6px;
                            box-shadow: -4px -4px 0px 0px rgba($color: #84BD00, $alpha: 0.20);
                            z-index: 1;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 4px;
                            left: -17px;
                            width: 2px;
                            height: 100%;
                            background-color: #84BD00;
                        }

                        span {
                            font-size: 16px;
                            line-height: 19px;
                            color: #040405;
                            display: block;
                            margin-bottom: 16px;
                        }

                        .system-functionalities-sublist {
                            padding-left: 0;
                            position: relative;

                            .system-functionalities-sublist-item {
                                list-style: none;
                                padding-left: 10px;
                                color: #040405;
                                position: relative;

                                &:not(:last-child) {
                                    padding-bottom: 16px;
                                }

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: -3px;
                                    left: -20px;
                                    width: 29px;
                                    height: 21px;
                                    background: url(../public/curve-arrow.svg) no-repeat center center;
                                    background-size: contain;
                                }

                                &:last-child:after {
                                    content: "";
                                    position: absolute;
                                    top: 4px;
                                    left: -19px;
                                    width: 2px;
                                    height: calc(100% - 25px);
                                    background-color: #84BD00;
                                }

                                span {
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #040405;
                                    display: block;
                                    margin-bottom: 16px;
                                }

                                .system-functionalities-sublist {
                                    padding-left: 0;
                                    position: relative;

                                    .system-functionalities-sublist-item {
                                        list-style: none;
                                        padding-left: 16px;
                                        color: #040405;
                                        position: relative;

                                        &:not(:last-child) {
                                            padding-bottom: 16px;
                                        }

                                        &::before {
                                            content: '';
                                            position: absolute;
                                            top: -7px;
                                            left: -19px;
                                            width: 30px;
                                            height: 25px;
                                            background-image: url(../assets/images/bottom-shape1.svg);
                                            background-repeat: no-repeat;
                                            // background: url(../public/curve-arrow-round.svg) no-repeat center center;
                                            background-size: contain;
                                            z-index: 2;
                                        }

                                        &:last-child:after {
                                            content: "";
                                            position: absolute;
                                            top: 7px;
                                            left: -19px;
                                            width: 2px;
                                            height: 100%;
                                            background-color: #fff;
                                            z-index: 1;
                                        }
                                    }
                                    top: 11px;
                                    left: -17px;
                                    width: 2px;
                                    height: 100%;
                                    background-color: #fff;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }

                        &.even {
                            background: rgba(0, 145, 218, 0.01);
                            &::before {
                                background-color: #0091DA;
                                box-shadow: -4px -4px 0px 0px rgba($color: #0091DA, $alpha: 0.20);
                            }

                            &::after {
                                background-color: #0091DA;
                            }

                            .system-functionalities-sublist {
                                &::before {
                                    background-color: #0091DA;
                                }

                                .system-functionalities-sublist-item {
                                    &::before {
                                        background-color: #0091DA;
                                        box-shadow: -4px -4px 0px 0px rgba($color: #0091DA, $alpha: 0.20);
                                    }

                                    &::after {
                                        background-color: #0091DA;
                                    }

                                    .system-functionalities-sublist {
                                        &::before {
                                            background-color: #0091DA;
                                        }

                                        .system-functionalities-sublist-item {
                                            &::before {
                                                background-image: url(../assets/images/bottom-shape-blue1.svg);
                                                background-repeat: no-repeat;
                                                background-size: contain;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .technology-stack-section {
                ul {
                    li {
                        display: flex;
                        align-items: flex-start;
                        padding: 12px 0;


                        span {
                            font-size: 16px;
                            line-height: 20px;
                            color: #040405;
                            display: inline-block;

                            &.name {
                                max-width: 150px;
                                min-width: 150px;
                                width: 100%;
                                position: relative;

                                &::after {
                                    content: ':';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }

                            &.value {
                                font-weight: 500;
                                padding-left: 24px;
                                max-width: 250px;
                            }
                        }
                    }
                }
            }

            .stack-holders-section {
                ul {
                    li {
                        padding: 10px 0;

                        h5 {
                            font-size: 16px;
                            line-height: 20px;
                            color: #040405;
                            font-weight: 400;

                        }

                        span {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 500;
                            display: block;
                            padding-left: 17px;
                            color: #040405;
                            position: relative;
                            margin-bottom: 12px;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 7px;
                                left: 0;
                                width: 0;
                                height: 0;
                                border-bottom: 8px solid #040405;
                                border-left: 8px solid transparent;
                                transform: rotate(-45deg);
                            }

                        }
                    }
                }
            }
        }

        .right-casestudies {
            padding-top: 48px;
            height: 100%;
            border-left: 1px solid rgba(141, 208, 255, 0.4);

            .right-casestudies-list {
                position: sticky;
                top: 120px;
            }

            ul {
                position: sticky;
                top: 120px;

                li {
                    padding-bottom: 20px;

                    &.active {
                        a {
                            color: #040405;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: -4px;
                                width: 8px;
                                height: 8px;
                                border-radius: 2px;
                                background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
                                transform: translateY(-50%) rotate(45deg);
                            }
                        }
                    }

                    a {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                        display: inline-block;
                        padding-left: 20px;
                        color: #91959B;
                        position: relative;
                    }
                }
            }
        }
    }
}

.theme-dark .case-studies-blog-detail-section .case-studies-blog-detial-inner .left-blog-details-content .system-functionalities-content .system-functionalities-list .system-functionalities-list-item .system-functionalities-sublist .system-functionalities-sublist-item:last-child:after {
    background-color: #000;
}

.theme-dark .case-studies-blog-detail-section .case-studies-blog-detial-inner .left-blog-details-content .our-solution-section .our-solution-section-item .our-solution-sublist .our-solution-sublist-item:last-child::after {
    background-color: #000;
}

.modal-form{
    padding-left: 0 !important;
}

.modal-form .modal-content .btn-close{
    width: 20px;
    height: 20px;
    background-size: auto;
    margin: 0;
}

// logo slider css

.partner-logo-section .partner-logo .splide__track{
    overflow: visible;
}
.partner-logo-section .partner-logo .splide__list:hover .splide__slide img{
    filter: grayscale(100%) blur(1.2px);
}
.partner-logo-section .partner-logo .splide__list .splide__slide img:hover{
    filter: grayscale(0) !important;
}



.technologies-middle{
    .agile-banner-section.agile-full-banner{
        .agile-banner-inner{
            .agile-banner-img{
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    .partner-logo{
        .partner-logo-item{
            .partner-logo-img{
                img{
                    max-width: 100%;
                    max-height: 100%;
                    // height: auto !important;
                }
            }
        }
    }
}
// Technology page
@media(min-width: 991px){
.technologies-middle{
    .technologies-section{
        .technologies-feature-card{
            .technologies-feature-image{
                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
    }
}
// header

.header .navbar .collapse1 .navbar-nav .menu-items a.nav-link {
    display:inline-block;
}
.header .navbar .collapse1 .navbar-nav .menu-items a.nav-link:after {
    display:block;
    content: '';
    transform: scaleX(0);
    transition: transform .2s ease-in-out;
    opacity: 1 !important;
    background-color: #fff;
    bottom: 3px;
  }

  .header.is-sticky .navbar .collapse1 .navbar-nav .menu-items a.nav-link:after {
    background-color: #000;
  }
  .header .navbar .collapse1 .navbar-nav .menu-items a.nav-link:hover:after { transform: scaleX(1); }
}
@media(max-width: 991px){
    .technologies-middle{
        .technologies-section{
            .technologies-feature-card{
                .technologies-feature-image{
                    img{
                        width: 100% !important;
                       height: 300px !important;
                        max-width: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
            }
        }
    }
}
@media(max-width: 767px){
    .technologies-middle{
        .technologies-section{
            .technologies-feature-card{
                .technologies-feature-image{
                    img{
                        height: 200px !important;
                    }
                }
            }
        }
    }
}
@media(max-width:996px) {
    .mobile-menu{
        .submenu-text-link{
            .career-link{
                display: block;
                padding: 16px 20px;
                color: #00011c !important;
                background: #fff !important;
                font-weight: 600;
                box-shadow: none !important;
                font-size: 18px;
                line-height: 21px;
                border-bottom: 1px solid rgba(0, 1, 26, 0.1) !important;
                border-radius: 0;
            }
        }
    }
}
@media(max-width:991px) {
    .case-studies-blog-detail-section {
        padding: 30px 0 20px;

        .case-studies-blog-detial-inner {
            .left-blog-details-content {
                .left-casestudy-details {
                    padding-top: 30px;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                }

                .major-challange-section {
                    li {
                        padding: 10px;
                        margin-bottom: 12px;

                        span {
                            font-size: 12px;
                            line-height: 18px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                .our-solution-section {
                    &::before {
                        left: 7px;
                    }

                    .our-solution-section-item {
                        padding-left: 30px;

                        &::before {
                            width: 18px;
                            height: 18px;
                        }

                        &::after {
                            left: 7px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }

                        .our-solution-sublist {
                            .our-solution-sublist-item {
                                font-size: 14px;
                                line-height: 20px;

                                &::before {
                                    left: -23px;
                                    background-size: contain;
                                }

                                &:last-child {
                                    &::after {
                                        left: -23px;
                                    }
                                }
                            }
                        }
                    }
                }


                        .system-functionalities-content {
                            .system-functionalities-list {
                                .system-functionalities-list-item {
                                    &::after {
                                        left: -19px;
                                    }

                                    span {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }

                                    .system-functionalities-sublist {
                                        .system-functionalities-sublist-item {
                                            font-size: 14px;
                                            line-height: 20px;

                                            &:last-child:after {
                                                left: -19px;
                                            }
                                }
                            }
                        }

                    }
                }


                .main-features-section {
                    gap: 15px;

                    li {
                        padding: 8px 15px;
                        font-size: 14px;
                        line-height: 19px;
                    }
                }

                .technology-stack-section {
                    ul {
                        li {
                            padding: 10px 0;

                            span {
                                font-size: 14px;
                                line-height: 19px;
                            }
                        }
                    }
                }

                .stack-holders-section {
                    ul {
                        li {
                            h5 {
                                font-size: 14px;
                                line-height: 19px;
                            }

                            span {
                                font-size: 14px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }

            .right-casestudies {
                padding-top: 30px;

                ul {
                    li {
                        a {
                            font-size: 14px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .case-studies-blog-detail-section {
        padding-top: 20px;

        .case-studies-blog-detial-inner {
            .left-blog-details-content {
                .left-casestudy-details {
                    padding-top: 30px;

                    .details {
                        padding-bottom: 30px;
                    }

                    .case-studies-blog-details-heading {
                        h3 {
                            font-size: 20px;
                            line-height: 29px;
                        }
                    }
                }
            }

            .right-casestudies {
                display: none;
            }
        }
    }
}

@media(max-width: 575px) {
    .case-studies-blog-detail-section {
        .case-studies-blog-detial-inner {
            .left-blog-details-content {
                .technology-stack-section {
                    ul {
                        li {
                            span {
                                &.name {
                                    min-width: 120px;
                                    max-width: 120px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape */
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .case-studies-detail-section {
        padding: 32px 0 25px 0;

        .case-studies-inner {
            .case-studies-detials-heading {
                h1 {
                    font-size: 26px;
                    line-height: 38px;
                }
            }

            .case-studies-details-img {
                height: 300px;

                img {
                    object-fit: cover;
                }
            }
        }
    }

    .case-studies-about-detial-section {
        .case-studies-about-inner {
            .case-studies-about-wrapper {
                padding-bottom: 20px;

                .case-studies-about-content {
                    p {
                        font-size: 16px;
                        line-height: 22px;
                    }

                }
            }

            .case-studies-about-listing {
                padding-bottom: 0;

                .share-icon {
                    .icon-list-img {
                        display: block;

                        li {
                            display: inline-block;

                            &:first-child {
                                margin-right: 10px;
                                margin-bottom: 10px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

    .case-studies-detail-section {
        padding: 20px 0 30px 0;

        .case-studies-inner {
            .case-studies-details-btn-wrapper {
                .case-study-details-btn {
                    padding: 6px 10px 6px 30px;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .case-studies-detials-heading {
                padding-bottom: 20px;

                h1 {
                    font-size: 30px;
                    line-height: 38px;
                }
            }
        }
    }

    .case-studies-about-detial-section {
        .case-studies-about-wrapper {
            .case-studies-about-content {
                p {
                    font-size: 18px !important;
                    line-height: 27px !important;
                }
            }

            padding-bottom: 15px;

        }

        .case-studies-about-listing {
            .client-about-list {
                h5 {
                    font-size: 18px;
                    line-height: 22px;
                }

                p {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

        }
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

    .case-studies-detail-section {
        padding: 20px 0 30px 0;

        .case-studies-inner {
            .case-studies-details-btn-wrapper {
                .case-study-details-btn {
                    padding: 6px 10px 6px 30px;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .case-studies-detials-heading {
                padding-bottom: 20px;

                h1 {
                    font-size: 30px;
                    line-height: 38px;
                }
            }
        }
    }

    .case-studies-about-detial-section {
        .case-studies-about-wrapper {
            .case-studies-about-content {
                p {
                    font-size: 18px !important;
                    line-height: 27px !important;
                }
            }

            padding-bottom: 15px;

        }

        .case-studies-about-listing {
            .client-about-list {
                h5 {
                    font-size: 18px;
                    line-height: 22px;
                }

                p {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

        }
    }

}

@media only screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .case-studies-about-detial-section .case-studies-about-wrapper .case-studies-about-content p {
        font-size: 15px;
        line-height: 24px;
    }

    .case-studies-about-detial-section .case-studies-about-inner .case-studies-about-listing .client-about-list p {
        font-size: 18px;
        line-height: 28px;
    }
}

// Subscribe model popup

.subscribe-modal {
    .modal-content {
        max-width: 730px;
        margin: 0 auto;
        border-radius: 20px;
        background-color: #fff;

        .modal-header {
            border: 0;
            padding: 30px 30px 20px 30px;

            .btn-close {
                background-image: url(../assets/images/grey-cross.svg);
                width: 13px;
                height: 13px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                padding: 0;
            }
        }

        .modal-body {
            padding: 0 30px 30px 30px;

            .subscribe-modal-content {
                .subscribe-modal-content-top {
                    text-align: center;
                    background: url(../public/images/subscribe-modal-bg.png) no-repeat center;
                    background-size: cover;
                    padding: 24px;
                    border-radius: 20px;
                    margin-bottom: 32px;

                    .subscribe-success-img {
                        width: 130px;
                        margin: 0 auto;
                        margin-bottom: 24px;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }

                    h5 {
                        font-size: 20px;
                        line-height: 30px;
                        font-weight: 600;
                        color: #040405;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        color: #616161;
                        max-width: 435px;
                        margin: 0 auto 24px;

                        &.text-content {
                            margin-bottom: 0;
                        }
                    }

                    .btn-primary {
                        min-width: 165px;
                    }
                }

                .subscribe-modal-content-bottom {
                    display: flex;
                    gap: 30px;
                    align-items: center;

                    li {
                        &:first-child {
                            width: 100%;
                        }

                        .form-control {
                            padding: 18px 16px;
                            font-size: 14px;
                            line-height: 21px;
                            font-weight: 500;
                            border-radius: 10px;
                            background-color: #fff;
                            border: 1px solid rgba(4, 4, 5, 0.2);
                            color: rgba(4, 4, 5, 1);
                        }
                    }

                    .btn-primary {
                        min-width: 165px;
                    }
                }
            }
        }
    }
}

.theme-dark .subscribe-modal .modal-content .modal-header .btn-close {
    opacity: 1;
    filter: invert(1);
}


// Services new page design css
.serives-new-middle{
    .partner-logo-section{
        padding: 0 0 100px;
    }
}
.serives-new-middle{
    .partner-section{
        padding-bottom: 100px;
        .partner-section-inner{
            .heading{
                h2{
                    padding-bottom: 8px;
                }
                h3{
                    margin-bottom: 16px;
                }
            }
        }
    }
}
.theme-dark{
    .company-banner-section{
        .company-banner-bg-img{
            opacity: .9;
            &::before{
                opacity: .8;
            }
        }
    }
}


// Sevices page design changes 11-8-2023
.service-middle{
    .agile-team-section{
        h2{
            font-size: 24px;
            line-height: 29px;
            font-weight: 700;
            margin-bottom: 66px;
            text-align: center;
        }
    }
    .approach-content{
        margin-bottom: 24px;
    }
    .approach-points-wrapper{
        .approach-points{
            margin: 0;
            padding: 0;
            list-style-position: inside;
            .approach-point{
                font-size: 24px;
                line-height: 36px;
                font-weight: 500;
                color: #040405;
                margin-bottom: 31px;
            }
        }
    }
    .approach-section{
        background: #fff !important;
        padding-top: 0;
        .approach-inner{
            padding: 80px 0 160px;
            background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        }
        .cto-pofile{
            position: relative;
            .cto-profile-info{
                content: '';
                position: absolute;
                bottom: -122px;
                left: -62px;
                max-width: 346px;
                padding: 40px 16px 16px 16px;
                border-radius: 24px 24px 0 24px;
                background-color: #fff;
                box-shadow: 0px 4px 20px 0px #2020201A;
                &::before{
                    content: '';
                    position: absolute;
                    top: -28px;
                    left: 16px;
                    width: 56px;
                    height: 56px;
                    background: url(../assets/images/cto-quote.svg);
                    background-size: cover;
                    background-position: center center;
                }
                .description{
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    color: #040405;
                    margin-bottom: 12px;
                }
                .cto-profile-meta{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    h5{
                        font-size: 24px;
                        line-height: 29px;
                        font-weight: 700;
                        margin-bottom: 4px;
                        color: #040405;
                    }
                    span{
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #616161;
                    }
                }
            }
        }
    }
}

// usecase listing page css start 14-8-2023
.usecase-card-div{
    background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
    border-radius: 12px;
    padding: 1px;
    margin-bottom: 32px;
    height: calc(100% - 32px);
    .usecase-card-wrapper{
        padding: 16px;
        background: #fff;
        border-radius: 12px;
        transition: .4s linear;
        height: 100%;
        &:hover{
            background-color: #f2f9fe;
        }
        .usercase-card-img{
            width: 100%;
            max-height: 280px;
            margin-bottom: 16px;
            border-radius: 12px;
            overflow: hidden;
            img{
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .card-description{
            .title{
                font-size: 20px;
                line-height: 30px;
                font-weight: 700;
                color: #040405;
                margin-bottom: 12px;
                max-width: 425px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .usecase-meta{
                span{
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    display: inherit;
                    color: #616161;
                }
            }
        }
    }
}


.theme-dark{
    .usecase-card-div{
            background: linear-gradient(317.72deg,#8abc00 5.24%,#ccfa4e 137.29%)!important;
        .usecase-card-wrapper{
            &:hover{
                background-color: #111;
            }
        }
    }
}
// usecase listing page css end 14-8-2023


// footer css change 16-8-2023
.footer-section{
    .footer-details.footer-bottom{
        .footer-details-inner {
            .footer-col.our-recognitions{
                ul{
                    position: relative;
                    z-index: 4;
                    li{
                        a[href="/#"]{
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}

//Services faq css change 21-8-2023
.service-middle{
    .faq-section{
        .accordion {
            min-height: 250px;
        }
    }
}

// usecase and ghost page image sticky
.count-on-section{
    .count-on-section-inner {
        .content-right{
            position: sticky;
            top: 110px;
        }
    }
}

.alternative-flow-section .alternative-img-container{
    position: sticky;
    top: 110px;
}


@media (min-width: 576px){
    .case-study-section{
        .case-study-inner{
            .case-study-card{
                .img-wrapper{
                    img{
                        min-height: 202px;
                    }
                }
            }
        }
    }
}
@media (min-width: 992px){
    .case-study-section{
        .case-study-inner{
            .case-study-card{
                .img-wrapper{
                    img{
                        min-height: 346px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1200px) and (min-width: 992px){
    .serives-new-middle{
        .partner-section{
            padding-bottom: 70px;
        }
    }
    .services-faq-section{
        &.faq-section{
            .accordion{
                .accordion-item{
                    .accordion-header{
                        .accordion-button{
                            &.collapsed:after {
                                top: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 1366px) {
    .service-middle{
        .approach-section{
            .approach-inner{
                padding-bottom: 100px;
            }
            .cto-pofile{
                .cto-profile-info{
                    max-width: 250px;
                    bottom: -65px;
                    border-radius: 12px 12px 0 12px;
                    &::before{
                        top: -22px;
                        width: 46px;
                        height: 46px;
                    }
                    .description {
                        font-size: 12px;
                        line-height: 20px;
                    }
                    .cto-profile-meta h5 {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom {
        flex-direction: column;
        gap: 20px;
    }
    .serives-new-middle{
        .partner-logo-section{
            padding: 0 0 50px;
        }
    }
    .serives-new-middle{
        .partner-section{
            padding-bottom: 70px;
        }
    }
    .services-faq-section{
        &.faq-section{
            .accordion{
                .accordion-item{
                    .accordion-header{
                        .accordion-button{
                            &::after{
                                top: 26px;
                            }
                            &.collapsed:after {
                                top: 26px;
                            }
                        }
                    }
                }
            }
        }
    }
    .service-middle{

        .approach-content{
            margin-bottom: 0;
        }
        .agile-team-section{
            h2{
                margin-bottom: 45px;
            }
        }
        .approach-points-wrapper{
            .approach-points{
                .approach-point{
                    font-size: 18px;
                    line-height: 30px;
                    margin-bottom: 15px;
                }
            }
        }
    }
    .usecase-card-div{
        margin-bottom: 20px;
        height: calc(100% - 20px);
        .usecase-card-wrapper{
            padding: 10px;
            .usercase-card-img{
                margin-bottom: 10px;
            }
            .card-description{
                .title {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
@media(max-width: 767px) {
    .usecase-card-div{
        .usecase-card-wrapper{
            .usercase-card-img{
                max-height: 170px;
            }
        }
    }
}
@media(max-width: 575px) {
    .service-middle{
        .approach-section{
            .approach-inner{
                padding-bottom: 40px;
            }
        }
    }
}
@media(max-width: 575px) {
    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-top .subscribe-success-img {
        width: 100px;
    }

    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-top h5 {
        font-size: 16px;
        line-height: 24px;
    }

    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-top {
        margin-bottom: 20px;
    }

    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-top p {
        font-size: 12px;
        line-height: 17px;
        margin: 0 auto 14px;
    }

    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom .btn-primary {
        width: 100%;
    }
    .serives-new-middle{
        .partner-logo-section{
            padding: 0 0 30px;
        }
    }
    .serives-new-middle{
        .partner-section{
            padding-bottom: 40px;
        }
    }
    .services-faq-section{
        &.faq-section{
            .accordion{
                .accordion-item{
                    .accordion-header{
                        .accordion-button{
                            padding: 12px 40px 12px 12px;
                            &.collapsed {
                                padding: 12px 40px 12px 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .service-middle{
        .approach-points-wrapper{
            .approach-points{
                .approach-point {
                    font-size: 14px;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}


// Work section
.work-section .our-project .project .project-link a:hover::after{
    right: -24px;
}

// Testimonial section start

.testimonial-slider {
    .swiper-slide {
        &.swiper-slide-active{
            .testimonial-main-box{
                &::before{
                    animation: rating-animation 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
                }
                .star-list{
                    li{
                        img{
                            animation: rating-animation 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
                        }
                    }
                }
            }
        }
    }
}

@keyframes rating-animation {
    0% {
              transform: scale(0);
    }
    100% {
              transform: scale(1);
    }
}

// Footer changes
.tag-line{
    animation: color-animation 2s infinite !important;
    margin-left: 8px;
    margin-top: -3px;
}

.see-more-btn{
    display: inline-block;
    &:after {
        display:block;
        content: '';
        padding-top: 2px;
        border-bottom: solid 1px #0091da;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition: transform 250ms ease-in-out;
      }
      &:hover:after{ transform: scaleX(1); transform-origin:   0% 50%; }
}

.footer-section .footer-details.footer-bottom .footer-details-inner .footer-col.our-recognitions ul li a{
    transition: all .5s linear;
}

.footer-section .footer-details.footer-bottom .footer-details-inner .footer-col.our-recognitions ul li a:hover{
    transform: scale(1.1);
}

// Benifit section
.career-benefits-section .icon-card .card:hover .icon-img img{
    animation: icon-animation 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
}

@keyframes icon-animation{
    0%{
        transform: scale(.8);
    }
    100%{
        transform: scale(1);
    }
}


// new css chnages (18/10/2023)
.blog-digital-section .blog-digital-inner ul.blog-digital-social li a:hover{
    display: inline-block;
    transform: scale(1.05);
}
// new css chnages (19/10/2023)
.bottom-section-submenu .submenu-bottom-link a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
}
.bottom-section-submenu .submenu-bottom-link a .arrow{
    background-image: none !important;
    opacity: 1 !important;
    position: relative;
    transform: translateX(0) !important;
    height: 20px !important;
    width: 20px !important;
    overflow: hidden !important;
    display: inline-block;
    transition: none !important;
    right: 0;
}
.bottom-section-submenu .submenu-bottom-link a .arrow::before{
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .3s linear;
}
.bottom-section-submenu .submenu-bottom-link a .arrow::after{
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: -19px;
    bottom: -19px;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .3s linear;
}
.bottom-section-submenu .submenu-bottom-link a:hover .arrow{
    right: 0;
}
.bottom-section-submenu .submenu-bottom-link a:hover .arrow::before{
    left: 19px;
    top: -19px;

}
.bottom-section-submenu .submenu-bottom-link a:hover .arrow::after{
    left: 0px;
    bottom: 0px;
}
.banner-section .banner-solution-box .solution-btn .arrow{
    background-image: none !important;
    position: relative;
    transform: translateX(0) !important;
    height: 20px !important;
    width: 20px !important;
    overflow: hidden !important;
    transition: none !important;
    opacity: 1 !important;
    transform: rotate(-45deg) translateX(0)
}
.banner-section .banner-solution-box .solution-btn .arrow::before{
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .3s linear;
}
.banner-section .banner-solution-box .solution-btn .arrow::after{
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: -19px;
    bottom: -19px;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .3s linear;
}
.banner-section .banner-solution-box .solution-btn:hover .arrow{
    opacity: 1;
}
.banner-section .banner-solution-box .solution-btn:hover .arrow::before{
    left: 19px;
    top: -19px;
}

.banner-section .banner-solution-box .solution-btn:hover .arrow::after{
    left: 0px;
    bottom: 0px;
}
.career-middle .trootech-job-positions .job-searching-result .job-description-box .job-description-top .job-apply-icon{
    transform: unset !important;
}
.career-middle .trootech-job-positions .job-searching-result .job-description-box .job-description-top .job-apply-icon .btn.btn-secondary span{
    background-image: none !important;
    opacity: 1 !important;
    position: relative;
    transform: translateX(0) !important;
    height: 20px !important;
    width: 20px !important;
    overflow: hidden !important;
    display: inline-block;
}
.career-middle .trootech-job-positions .job-searching-result .job-description-box .job-description-top .job-apply-icon .btn.btn-secondary span::before{
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .3s linear;
}
.career-middle .trootech-job-positions .job-searching-result .job-description-box .job-description-top .job-apply-icon .btn.btn-secondary span::after{
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: -19px;
    bottom: -19px;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .3s linear;
}

.career-middle .trootech-job-positions .job-searching-result .job-description-box:hover .job-description-top .job-apply-icon .btn.btn-secondary span::before{
    left: 19px;
    top: -19px;
}
.career-middle .trootech-job-positions .job-searching-result .job-description-box:hover .job-description-top .job-apply-icon .btn.btn-secondary span::after{
    left: 0px;
    bottom: 0px;
}

.service-section .service-feature:before{
    background-image: url(../assets/images/icons/custom-arrow.svg);
    background-size: 18px !important;
}

.lets-connect-modal .modal-content .modal-header .btn-close {
    width: 20px;
    height: 20px;
    background-size: auto;
    background-image: url(../assets/images/grey-cross.svg);
    box-shadow: none !important;
}
.subscribe-modal .modal-content .modal-header .btn-close{
    width: 20px;
    height: 20px;
    background-size: auto;
    box-shadow: none !important;
}
.modal-form .modal-content .btn-close{
    box-shadow: none !important;
}
// new css chnages (25/10/2023)
.news-letter-section .news-letter-content .news-letter-link a{
    display: inline-block;
}
.news-letter-section .news-letter-content .news-letter-link a span{
    background-image: none !important;
    opacity: 1 !important;
    position: relative;
    transform: translateX(0) !important;
    height: 24px !important;
    width: 24px !important;
    overflow: hidden !important;
}
.news-letter-section .news-letter-content .news-letter-link a span img{
    display: none;
}
.news-letter-section .news-letter-content .news-letter-link a span::before{
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .4s linear;
    margin-top: 3px;
}
.news-letter-section .news-letter-content .news-letter-link a span::after{
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: -20px;
    bottom: -20px;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .4s linear;
        margin-top: 3px;
}

.news-letter-section .news-letter-content .news-letter-link a:hover span::before{
    left: 20px;
    top: -20px;
}

.news-letter-section .news-letter-content .news-letter-link a:hover span::after{
    left: 0px;
    bottom: 0px;
}
.agreement-section .agreement-inner .conect-box .skype-conect a span{
    background-image: none !important;
    opacity: 1 !important;
    position: relative;
    transform: translateX(0) !important;
    height: 24px !important;
    width: 24px !important;
    overflow: hidden !important;
}
.agreement-section .agreement-inner .conect-box .skype-conect a span::before{
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .4s linear;
    margin-top: 3px;
}
.agreement-section .agreement-inner .conect-box .skype-conect a span::after{
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
    background-repeat: no-repeat;
    left: -20px;
    bottom: -20px;
    background-size: contain;
    background-position: center center;
    transform: rotate(-45deg) translateX(0) !important;
    transition: all .4s linear;
    margin-top: 3px;
}
.agreement-section .agreement-inner .conect-box .skype-conect a:hover span::before{
    left: 20px;
    top: -20px;
}
.agreement-section .agreement-inner .conect-box .skype-conect a:hover span::after{
    left: 0px;
    bottom: 0px;
}
.agreement-section .agreement-inner .conect-box .skype-conect a img{
    display: none;
}
.industry-middle .industries-system-section .development-box .development-bottom .development-links li .development-links-btn::after{
    background-image: url("../assets/images/grey-right-icon.svg") !important;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    opacity: 1;
}
.industry-middle .industries-system-section .development-box .development-bottom .development-links li .development-links-btn:hover::after{
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
}
// new css chnages (26/10/2023)
.case-study-section .case-study-inner .case-study-card{
    transition: all 0.4s ease 0s;
    outline: 2px solid transparent;
}
.case-study-section .case-study-inner .case-study-card:hover {
    transform: translateY(-4px);
    outline: 2px solid #0086e6;
}

@media (max-width: 1024px){
    .aboutus-middle .difference-section .deference-feature .feature-box p {
        font-size: 14px;
        line-height: 18px;
    }
}
@media (max-width: 991px){
    .aboutus-middle .difference-section .heading h3{
        text-align: center;
    }
    .aboutus-middle .difference-section .heading h2{
        max-width: unset;
        text-align: center;
    }
    .service-section .service-feature:before{
        background-size: 14px !important;
    }

}
@media (max-width: 767px){
    .career-middle .startups-uber-section .startups-uber-inner .heading h2 {
        text-align: center;
    }
    .startups-uber-section .startups-uber-inner .startups-uber-content p{
        text-align: center;
    }
    .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5{
        font-size: 14px;
        line-height: 21px;
    }
    .technology-feature-top .startups-vacation-inner .heading h3{
        margin-bottom: 3px;
    }
    .banner-section .banner-solution-box .solution-btn .arrow{
        height: 15px !important;
        width: 15px !important;
    }
    .banner-section .banner-solution-box .solution-btn .arrow::before{
        height: 15px !important;
        width: 15px !important;
    }
    .banner-section .banner-solution-box .solution-btn .arrow::after{
        height: 15px !important;
        width: 15px !important;
    }
    .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row .blog-about-img{
        width: 55px;
        height: 55px;
    }
    .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-img h2{
        margin: 0;
        font-size: 24px;
        line-height: 26px;
    }
    .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card{
        margin: 0;
    }
    .blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p{
        padding-bottom: 0;
    }
    .design-stage-section .steps-detail .steps-feature .feature-content h3{
        margin-bottom: 15px;
    }
    .industry-middle .industries-relevant-section .steps-feature .feature-content h3{
        margin-bottom: 15px;
    }
    .industry-middle .industries-relevant-section .steps-feature .feature-content p{
        font-size: 14px;
        line-height: 20px;
    }
    .book-section .book-box .book-text .hire-btn .btn-primary{
        height: auto;
    }
    .news-letter-section .news-letter-content .row{
        flex: 1;
    }
    .news-letter-section .news-letter-content .news-letter-link a:last-child{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .news-letter-section .news-letter-content .news-letter-link a span{
        height: 18px !important;
        width: 18px !important;
    }
    .news-letter-section .news-letter-content .news-letter-link a span::before{
        height: 18px;
        width: 18px;
        margin-top: 0;
    }
    .news-letter-section .news-letter-content .news-letter-link a span::after{
        height: 18px;
        width: 18px;
        left: -15px;
        bottom: -19px;
        margin-top: 0;
    }
    .news-letter-section .news-letter-content .news-letter-link a:hover span::before{
        left: 18px;
        top: -13px;
    }
    .agreement-section .agreement-inner .conect-box .skype-conect a span{
        height: 15px !important;
        width: 15px !important;
        min-width: 15px;
    }
    .agreement-section .agreement-inner .conect-box .skype-conect a span::before{
        height: 15px;
        width: 15px;
        margin-top: 0;
    }
    .agreement-section .agreement-inner .conect-box .skype-conect a span::after{
        height: 15px;
        width: 15px;
        left: -15px;
        bottom: -9px;
        margin-top: 0;
    }
    .agreement-section .agreement-inner .conect-box .skype-conect a:hover span::before{
        left: 18px;
        top: -13px;
    }
    .industry-middle .industries-system-section .development-box .development-bottom .development-links li .development-links-btn span{
        height: 15px !important;
        width: 15px !important;
        min-width: 15px;
    }
    .industry-middle .industries-system-section .development-box .development-bottom .development-links li .development-links-btn span::before{
        height: 15px;
        width: 15px;
    }
    .industry-middle .industries-system-section .development-box .development-bottom .development-links li .development-links-btn span::after{
        height: 15px;
        width: 15px;
        left: -15px;
        bottom: -9px;
    }
}

@media(max-width: 575px){
    .homepage-middle .hiredeveloper-section .developer-circle .developer-circle-inner .content {
        padding: 10px 30px 20px;
    }
    .technologies-middle .framework-section .framework-inner .row .framework-col:first-child .framework-card{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .technologies-middle .framework-section .framework-inner .row .framework-col:last-child .framework-card{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .believe-section .believe-box .believe-image {
        border-radius: 8px;
    }
    .request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog{
        border-radius: 12px;
    }
    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image .location-name h2{
        margin: 0;
    }
    .blog-banner-section .blog-banner-inner .blog-banner-content{
        border-radius: 12px;
    }
    .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row{
        align-items: flex-start;
    }
    .blog-digital-section .blog-digital-inner ul.blog-digital-social li{
        margin-right: 20px;
    }
    .testimonial-section .testimonial-main-box .coordinator{
        margin-bottom: 0;
    }
    .book-section .book-box{
        border-radius: 12px;
    }
    .technologies-middle .onestop-section .onestop-inner .box-card .onestop-box .box-content p{
        font-size: 14px;
        line-height: 20px;
    }
    .technology-stack .casesudies-professional-inner .tech-skills ul li {
        padding: 15px 20px 0 20px;
    }
    .technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge{
        font-size: 14px;
        line-height: 20px;
    }
    .technology-feature .startups-features-inner .startups-features-vacation .startups-features-card p{
        font-size: 14px;
        line-height: 20px;
    }
    .startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card {
        padding: 15px 15px 15px 15px;
    }
    .technologies-middle .digital-service-section .steps-detail .steps-feature .feature-content p {
        font-size: 14px;
        line-height: 20px;
    }
    .technologies-middle .framework-section .framework-inner .framework-card p {
        font-size: 14px;
        line-height: 20px;
    }
    .agile-banner-section .agile-banner-inner .agile-banner-content {
        border-radius: 12px;
    }
    .startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about{
        border-radius: 12px;
    }
    .subscribe-modal .modal-content .modal-body {
        padding: 0 20px 20px 20px;
    }
    .subscribe-modal .modal-content{
        border-radius: 12px;
    }

    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-top{
        border-radius: 12px;
    }
    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom{
        align-items: center !important;
    }
    .hire-developer-modal .modal-banner-left{
        border-radius: 12px;
    }
    .hire-developer-modal.modal-form .modal-content{
        border-radius: 12px;
    }
    .lets-connect-modal .modal-content {
        border-radius: 12px;
    }
    .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card.card-blue .card-img{
        margin: 0;
    }
    .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card.card-blue .card-img,
    .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card.card-green .card-img{
        height: unset;
        padding: 25px 0;
    }
    .request-proposal .agreement-section .agreement-inner .conect-box{
        border-radius: 12px;
    }
    .homepage-middle .service-section .service-feature .service-tools li{
        font-size: 14px;
        line-height: 18px;
    }
    .homepage-middle .work-section .our-project .project .project-link a{
        font-size: 14px;
        line-height: 18px;
    }
    .homepage-middle .faq-section .accordion .accordion-item .accordion-collapse .accordion-body{
        font-size: 14px;
        line-height: 20px;
    }
}
@media (min-width: 340px) and (max-width: 575px){
    .homepage-middle .service-section .service-feature h3 img {
        transform: rotate(19deg);
    }
}
@media(max-width: 479px){
    .career-middle .news-letter-section .news-letter-content .news-letter-link a:first-child{
        border: none !important;
    }
}
@media(max-width: 425px){
    .aboutus-middle .difference-section .deference-feature .feature-box p{
        margin: 0;
        min-height: 54px;
    }
}

// new css


.banner-section .banner-solution-box .solution-btn {
    border-radius: 8px !important;
}

// all slider
.careersabout-slider-inner .careersaboutslider .slick-arrow, .employee-review-section .employee-review-slider .slick-arrow, .meet-management-section .meet-slider .slick-arrow,
.careers-startups-section .careers-partner-section .startups-partner-inner .pratner-box .slick-arrow,
.wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow {
    border-radius: 6px !important;
    border-width: 2px !important;
}
.careersabout-slider-inner .careersaboutslider .slick-arrow::before, .employee-review-section .employee-review-slider .slick-arrow:before, .meet-management-section .meet-slider .slick-arrow:before,
.careers-startups-section .careers-partner-section .startups-partner-inner .pratner-box .slick-arrow::before {
    width: 18px !important;
    height: 12px !important;
}

.wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow::before {
    width: 12px !important;
    height: 18px !important;
}

// our work
.work-section .our-project .project .project-link a:after {
    background-image: url(../assets/images/case-study-arrow.svg);
    height: 14px;
    width: 18px;
}

.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link .arrow {
    background-image: url(../assets/images/icons/custom-arrow.svg) !important;
}

@media  (max-width: 767px) {
    .service-middle .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link .arrow {
        margin-left: 4px;
        width: 16px;
        height: 12px;
    }

    .modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header .accordion-button {
        border-radius: 8px !important;
    }

    .modal-form .modal-content .modal-body .modal-banner-section {
        border-radius: 10px !important;
    }

    .modal-form .form-group .form-control, .modal-form .form-group .form-select {
        height: 50px !important;
        padding: 12px 12px !important;
        border-radius: 8px !important;
    }

    .modal-form .form-group .form-label {
        margin-bottom: 5px !important;
    }

    .hire-developer-modal.modal-form .modal-content .modal-body .form-group .form-control, .modal-form .modal-content .modal-body .form-group .form-select {
        height: 50px !important;
        min-height: 50px !important;
        padding: 12px 12px !important;
        border-radius: 8px !important;
    }

    .hire-developer-modal.modal-form .modal-content .modal-body .form-group label {
        line-height: 15px;
    }
    .error-text-message {
        font-size: 13px !important;
    }

    .modal-form .modal-content .modal-header {
        padding: 23px 18px 0px;
    }
}

.contact-banner-form .contact-form-inner .request-proposal .request-form-section .request-form-inner form{
    box-shadow: none !important;
}
.statastics-card-section{
    padding: 60px 0 0;
    .statastics-card{
        border-radius: 12px;
        border: 1px solid #0086e6;
        background: linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        padding: 20px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        .stats-card-icon{
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 91px;
            img{
                width: 100%;
                height: auto;
            }
        }
        .stats-info{
            max-width: 156px;
            h6{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #91959b;
                margin-bottom: 0;
                min-height: 56px;
            }
            span{
                font-size: 30px;
                line-height: 45px;
                font-weight: 700;
                color: #040405;
            }
        }
        &.statastics-secondary{
            border-color: #8abc00;
            background: linear-gradient(318deg, rgba(138, 188, 0, 0.05) 5.24%, rgba(204, 250, 78, 0.05) 137.29%);
        }
    }
}
@media(max-width:767px){
    .statastics-card-section{
        padding: 40px 0 20px;
    }
}


// 28-11-2023
.hire-developer-form{
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    border: 1px solid rgba(0, 134, 230, 0.2);
}
.hire-dedicated-dev-section .modal-form .modal-content{
    background-color: transparent !important;
}
.hire-dedicated-dev-section::after{
    height: 46% !important;
}
.agile-banner-section.agile-full-banner .agile-banner-inner .agile-banner-content .btn-primary{
    margin-bottom: 20px;
}
@media(max-width:767px){
    .hire-developer-form{
        padding: 20px;
    }
    .hire-dedicated-dev-section::after {
        height: 26% !important;
    }
}
@media(min-width:1400px){
    .work-section .our-project .row div:nth-child(5) .project .project-content h3 {
        position: absolute;
        max-width: 600px;
    }

    .work-section .our-project .row div:nth-child(1) .project .project-content h3 {
        position: absolute;
        max-width: 500px;
    }
}

body .technologies-middle .salesForce {
    padding: 40px !important;
    box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
}

@media(max-width:767px){
    body .technologies-middle .salesForce {
        padding: 40px 20px !important;
    }
}



.subscribe-modal-new {
    // background-color: #fff;

    .modal-header {
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 11;
        border: 2px solid #D6EEFF !important;
        height: 32px !important;
        width: 32px !important;
        min-width: 32px !important;
        background: #FFFFFF;
        border-radius: 100px !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 0 !important;
            margin-left: 1px !important;
            color: #000000 !important;
            opacity: 1;
            height: 12px !important;
            width: 12px !important;
            background-size: cover !important;
            background-position: center !important;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.80752 0.666748C2.20267 0.671155 2.48931 0.826123 2.73931 1.0773C3.76053 2.10259 4.78909 3.1198 5.80372 4.15097C5.96867 4.31842 6.04712 4.30447 6.204 4.14509C7.21277 3.12054 8.23326 2.10847 9.24862 1.09052C9.55873 0.779853 9.91722 0.616071 10.363 0.707142C11.2647 0.890754 11.6268 1.9322 11.0191 2.62626C10.7361 2.94941 10.4179 3.24172 10.1137 3.54652C9.36299 4.29859 8.61668 5.05434 7.85717 5.79613C7.69809 5.9511 7.68123 6.03042 7.84984 6.19641C8.87547 7.20407 9.8857 8.22716 10.9047 9.24069C11.231 9.56532 11.4084 9.93842 11.3035 10.4026C11.1005 11.3008 10.0536 11.635 9.36739 11.0173C9.08221 10.7603 8.82049 10.4768 8.5485 10.205C7.75161 9.40888 6.95251 8.61495 6.16221 7.81293C6.03538 7.6844 5.96941 7.68367 5.84184 7.81293C4.79862 8.8676 3.7488 9.91565 2.69679 10.9615C2.36688 11.2891 1.97174 11.4147 1.51574 11.2803C1.07367 11.1495 0.798754 10.8491 0.698317 10.4041C0.596414 9.95311 0.7577 9.58221 1.07807 9.26273C2.10296 8.24185 3.12053 7.21288 4.15202 6.19788C4.31623 6.0363 4.31037 5.95698 4.14908 5.7976C3.10513 4.76937 2.07144 3.73087 1.03848 2.69236C0.710048 2.36259 0.585418 1.96746 0.721044 1.51136C0.852272 1.06996 1.15505 0.803355 1.59858 0.693922C1.67702 0.674827 1.75987 0.672624 1.80752 0.666748Z' fill='black'/%3E%3C/svg%3E%0A") !important;
        }
    }

    .modal-body {
        padding: 42px 24px !important;
        max-height: 370px !important;
        border: 0 !important;
    }

    .modal-content {
        border: 0 !important;
    }
   .modal-new-bg {
        display: flex;
        align-items: center;
        border: 2px solid #0086E633 !important;
        border-radius: 16px !important;
        overflow: hidden;

        .subscribe-modal-content-top {
            background: #fff !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            text-align: left !important;
            margin-bottom: 0 !important;

            .subscribe-success-img {
                margin-left: 0 !important;
            }

            h5 {
                font-size: 32px !important;
                font-weight: 600 !important;
                line-height: 38.73px !important;
            }

            p {
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 22.4px !important;
                margin-bottom: 24px !important;
            }
        }


        .modal-body.modal-image {
            position: relative;
            width: 255px;
            min-width: 255px;
            max-width: 255px;
            height: 370px;
            padding: 0 !important;
            border-radius: 0px 16px 16px 0px !important;
            background-image: url('/images/image-bg-subscribe.png');
            background-size: cover;
            img {
                height: 100%;
                width: auto;
                object-fit: cover;
				max-width: 225px;
                min-width: 255px;
            }

            &:after {
                content: '';
                display: block;
                background: linear-gradient(122.09deg, rgba(0, 145, 218, 0.5) -11.62%, rgba(0, 145, 218, 0.44) 8.53%, rgba(69, 168, 104, 0) 69.21%, rgba(132, 189, 0, 0.5) 100%);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
            }
        }

        .form-control {
            height: 48px !important;
            border: 1px solid rgba(0, 134, 230, 0.2) !important;
        }
         .btn-primary {
            margin-top: 0px !important;
            height: 48px !important;
            background: linear-gradient(96.18deg, #78C4FB 0%, #0086E6 100%);
            max-width: 111px !important;
            min-width: 111px !important;
			font-size: 14px !important;
            line-height: 16px !important;
			text-transform: uppercase;
        }

        .subscribe-modal-content-bottom {
            gap: 10px !important;
        }
    }


}

@media(max-width:1024px){

    .subscribe-modal-new .modal-content {
        max-width: 560px !important;
        min-width: 560px !important;
    }

    .subscribe-modal-new {
        .modal-header {
            position: absolute;
            right: 12px;
            top: 12px;
            z-index: 11;
            border: 2px solid #D6EEFF !important;
            height: 24px !important;
            width: 24px !important;
            min-width: 24px !important;
            background: #FFFFFF;
            border-radius: 100px !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin: 0 !important;
                margin-left: -1px !important;
                color: #000000 !important;
                opacity: 1;
                height: 12px !important;
                width: 12px !important;
                background-size: cover !important;
                background-position: center !important;
            }
        }

        .modal-body {
            padding: 32px 20px !important;
            max-height: 283px !important;
            border: 0 !important;
        }

    .modal-new-bg {
            display: flex;
            align-items: center;
            border: 2px solid #0086E633 !important;
            border-radius: 16px !important;

            .subscribe-modal-content-top {
                background: #fff !important;
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                text-align: left !important;
                margin-bottom: 0 !important;

                .subscribe-success-img {
                    margin-left: 0 !important;
                    margin-bottom: 16px !important;

                    img {
                        height: 40px !important;
                    }
                }

                h5 {
                    font-size: 24px !important;
                    font-weight: 600 !important;
                    line-height: 29px !important;
                }

                p {
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    line-height: 16px !important;
                    margin-bottom: 24px !important;
                }
            }


            .modal-body.modal-image {
                position: relative;
                width: 195px;
                max-width: 195px;
                min-width: 195px;
                height: 283px;
                padding: 0 !important;
                border-radius: 0px 16px 16px 0px !important;
                overflow: hidden;

                img {
                    height: 100%;
                    width: auto;
                    object-fit: cover;
					max-width: 195px;
                }

                &:after {
                    content: '';
                    display: block;
                    background: linear-gradient(122.09deg, rgba(0, 145, 218, 0.5) -11.62%, rgba(0, 145, 218, 0.44) 8.53%, rgba(69, 168, 104, 0) 69.21%, rgba(132, 189, 0, 0.5) 100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                }
            }

            .form-control {
                height: 40px !important;
                border: 1px solid rgba(0, 134, 230, 0.2) !important;
                font-size: 12px !important;
                line-height: 18px !important;
            }

            .btn-primary {
                margin-top: 0px !important;
                height: 40px !important;
                background: linear-gradient(96.18deg, #78C4FB 0%, #0086E6 100%);
                max-width: 102px !important;
                min-width: 102px !important;
                font-size: 12px !important;
                line-height: 14px !important;
            }

            .subscribe-modal-content-bottom {
                gap: 10px !important;
            }
        }
    }

    .subscribe-modal .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom {
        flex-direction: row;
    }
}

@media(max-width:767px){

    .subscribe-modal-new .modal-content {
        max-width: 560px !important;
        min-width: unset !important;
        border-radius: 16px !important;
        border: 0 !important;
    }

    .subscribe-modal-new .modal-new-bg {
        flex-direction: column-reverse;
        overflow: hidden !important;


        .form-control {
            height: 44px !important;
            border: 1px solid rgba(0, 134, 230, 0.2) !important;
        }
         .btn-primary {
            margin-top: 0px !important;
            height: 44px !important;
            background: linear-gradient(96.18deg, #78C4FB 0%, #0086E6 100%);
            max-width: 111px !important;
            min-width: 111px !important;
        }
    }

    .subscribe-modal-new .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-top  {
        p {
            margin-left: 0 !important;
        }
    }

    .subscribe-modal-new {
        .modal-body {
            padding: auto;
        }
    }

    .subscribe-modal-new .modal-new-bg .modal-body.modal-image {
        width: 100%;
        height: auto;
        border-radius: 0 !important;
        overflow: hidden;
        height: 328px;
        max-width: unset;
        min-width: unset;
        background-size: cover;
        background-position: center;
        img {
            object-fit: cover !important;
            width: 100%;
            height: 328px;
			max-width: unset;
        }
    }

    .subscribe-modal-new .modal-body {
        max-height: unset !important;
    }

    .subscribe-modal-new .modal-content .modal-body .subscribe-modal-content .subscribe-modal-content-bottom {
        align-items: flex-start !important;
    }
}

