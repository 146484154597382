.contact-banner-section {
    padding: 80px 0 60px 0;
    position: relative;
    background-color: #fff;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 48%;
        background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        z-index: 1;
    }

    .contact-banner-content {
        padding-bottom: 40px;
        position: relative;
        z-index: 2;

        .heading {
            text-align: center;

            h3 {
                font-size: 18px;
                line-height: 26px;
                color: rgb(145, 149, 155);
                font-weight: 500;
            }

            h1 {
                font-size: 42px;
                line-height: 60px;
                color: #040405 !important;
                font-weight: 700;
                margin: 0 auto 16px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #040405 !important;
                max-width: 920px;
                margin: 0 auto;
            }
        }
    }
}

.req-proposal-section , .hire-dedicated-dev-section {
    padding: 80px 0 60px 0;
    position: relative;
    background-color: #fff;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 24%;
        background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
        z-index: 1;
    }

    .contact-banner-content {
        padding-bottom: 40px;
        position: relative;
        z-index: 2;

        .heading {
            text-align: center;

            h3 {
                font-size: 18px;
                line-height: 26px;
                color: rgb(145, 149, 155);
                font-weight: 500;
            }

            h1 {
                font-size: 42px;
                line-height: 60px;
                color: #040405 !important;
                font-weight: 700;
                margin: 0 auto 16px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #040405 !important;
                max-width: 920px;
                margin: 0 auto;
            }
        }
    }
}

.contact-banner-form {
    z-index: 2;
    position: relative;

    .contact-form-inner {
        .request-proposal {
            background-color: transparent;

            .request-form-section {
                background-color: transparent;

                .request-form-inner {
                    form {
                        background-color: #fff;
                        padding: 30px;
                        box-shadow: 0px 91.635px 73.308px rgba(0, 0, 0, 0.04);
                        border-radius: 12px;
                        border: 1px solid rgba(0, 134, 230, 0.2);
                    }
                }
            }
        }
    }
}

.contact-case-studies {
    padding: 60px 0 0;
    background-color: #fff;

    .contectus-casestudies-list {
        padding-bottom: 20px;

        .casestudies-allstories-inner {
            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .casestudies-heading-left {
                    h3 {
                        font-size: 18px;
                        line-height: 26px;
                        color: rgb(145, 149, 155);
                        font-weight: 500;
                        margin-bottom: 12px;
                    }

                    h2 {
                        font-size: 42px;
                        line-height: 60px;
                        color: #040405 !important;
                        font-weight: 700;
                        margin: 0 auto 16px;
                    }
                }
            }

            .casestudies-card .casestudies-content {
                background: transparent;
                z-index: 2;
            }

            .casestudies-card-small .casestudies-img {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                }
            }
        }
    }
}

.contact-other-section {
    .findus-section {
        padding-bottom: 120px;

        &::after {
            background: #fff;
        }

        .heading {
            margin-bottom: 0;
            padding-bottom: 60px;
        }

        .our-location {
            .our-location-inner {
                .our-location-row {
                    display: flex;
                    column-gap: 30px;
                    justify-content: center;

                    .our-location-col {
                        .our-location-card {
                            width: 445px;
                            height: auto;

                            border-radius: 1000px;
                            position: relative;
                            transition: all 0.2s;

                            .location-card-box {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                top: 0;
                                left: 0;

                                .location-box {
                                    width: 20%;
                                    height: 100%;
                                }
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                top: 90px;
                                left: 0;
                                max-width: 445px;
                                max-height: 445px;
                                min-height: 445px;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(317.72deg,
                                        rgba(138, 188, 0, 0.05) 5.24%,
                                        rgba(204, 250, 78, 0.05) 137.29%);
                                border-radius: 100%;
                                opacity: 0;
                                transition: all 0.6s linear;
                                transform: scale(0.5);
                                border: 1px solid rgba(138, 188, 0, 0.2);
                                z-index: -1;
                            }

                            &:hover {
                                &::before {
                                    transform: scale(1);
                                    opacity: 1;
                                }

                                .contact-info {
                                    &.contact-info-1 {
                                        h5 {
                                            background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                            background-clip: text;
                                            color: transparent;
                                        }
                                    }

                                    &.contact-info-2 {
                                        h5 {
                                            background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                            background-clip: text;
                                            color: transparent;
                                        }
                                    }

                                }
                            }

                            .location-card-image {
                                width: 100%;
                                max-width: 318px;
                                height: 212px;
                                border-radius: 12px;
                                overflow: hidden;
                                display: block;
                                margin: -5px auto 0;
                                position: relative;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center center;
                                }

                                .location-name {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    border-radius: 12px;
                                    z-index: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    h2 {
                                        font-size: 32px;
                                        line-height: 45px;
                                        font-weight: 600;
                                        color: #fff;
                                    }


                                }
                            }

                            .location-text {
                                text-align: center;
                                padding: 20px;
                                max-width: 350px;
                                margin: 0 auto;

                                h3 {
                                    font-weight: 600;
                                    font-size: 32px;
                                    line-height: 44px;
                                    color: #040405 !important;
                                }

                                p {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #91959b !important;
                                    margin-bottom: 0;
                                }
                            }

                            .contact-info {
                                display: flex;
                                justify-content: center;
                                margin-bottom: 24px;

                                h5 {
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-weight: 600;
                                    margin-bottom: 6px;
                                    color: #91959b !important;
                                    transition: all 0.6s linear;
                                }

                                .contact-link {
                                    display: block;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-weight: 400;
                                    color: #040405;
                                    margin-bottom: 6px;
                                }

                                .contact-info-left {
                                    text-align: right;
                                    padding-right: 16px;
                                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                                }

                                .contact-info-center {
                                    text-align: center;
                                }

                                .contact-info-right {
                                    padding-left: 16px;
                                }
                            }
                        }

                        &.card-0 {
                            .our-location-card {
                                .location-card-image {
                                    position: relative;

                                    .location-name {
                                        background: linear-gradient(0deg, rgba(132, 189, 0, 0.4), rgba(132, 189, 0, 0.4));
                                    }
                                }
                            }
                        }

                        &.card-1 {
                            .our-location-card {
                                .location-card-image {
                                    position: relative;

                                    .location-name {
                                        background: linear-gradient(0deg, rgba(0, 134, 230, 0.4), rgba(0, 134, 230, 0.4));
                                    }


                                }

                                .contact-info {
                                    h5 {
                                        color: #91959b !important;
                                    }
                                }

                                &::before {
                                    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
                                    border: 1px solid rgba(0, 134, 230, 0.2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .media-section {
        padding: 120px 0;
    }
}

.theme-dark .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info .contact-info-left {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.wordwise-section.contact-wordwise-section {
    padding-top: 120px;
    padding-bottom: 120px;
    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);

    .jvectormap-container {
        background-color: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
    }

    .wordwise-inner-heading {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    .contact-map-image {
        flex: 1;
        min-height: 400px;
    }

    .map-image {
        min-height: inherit;
        height: 100%;
    }

    .client-map-content {
        top: 0;
        height: auto;

        .slider-client .slick-slide {
            &.slick-active {
                .item {
                    margin-bottom: 12px;
                    margin-top: 12px;
                    border-color: transparent;
                    box-shadow: none;
                }

                &.slick-center.slick-current {
                    .item {
                        box-shadow: 0px 1.635px 10.308px rgba(0, 0, 0, 0.07),
                            0px 20.2964px 23.35px rgba(0, 0, 0, 0.0425185),
                            0px 7.46655px 5.97324px rgba(0, 0, 0, 0.0274815);
                        border-color: rgba(141, 208, 255, 1);
                    }
                }
            }

            .item {
                display: flex;
                max-width: 253px;
                min-width: auto;
                margin-left: auto;
                margin-right: 20px;
                margin-bottom: 12px;
                margin-top: 12px;
                padding: 16px;
                border-color: transparent;

                .flag-img {
                    margin: 0;

                    img {
                        width: 33px;
                        height: 24px;
                        border-radius: 4px;
                    }
                }

                .flag-dis {
                    flex: 1;
                    padding-left: 24px;
                    text-align: left;

                    h2 {
                        font-weight: 600;
                        font-size: 26px !important;
                        line-height: 31px !important;
                    }
                }
            }
        }

        .slider-client .slick-arrow {
            background: transparent;
            width: 32px;
            height: 32px;
            border: 0.75px solid rgb(4, 4, 5, 0.2);
            border-radius: 10px;
            transition: all 0.4s;

            &::before {
                width: 9px;
                height: 8px;
                transition: all 0.4s;
            }

            &:hover {
                border-color: rgba(141, 208, 255, 1);
                background: transparent;
            }
        }

        .slider-client .slick-arrow.slick-next {
            top: 44%;
            bottom: auto;
            right: -80px;
            transform: rotate(270deg) translateY(-50%);
            left: auto;
        }

        .slider-client .slick-arrow.slick-prev {
            right: -80px;
            top: 51%;
            transform: rotate(270deg) translateY(-50%);
            left: auto;
            bottom: auto;
        }
    }
}

.linkedIn iframe {
    width: 100%;
}

/******responsive*******/
@media (max-width: 1900px) {
    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2 {
        font-size: 40px;
        line-height: 60px;
    }
}

@media (max-width: 1440px) {

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-next,
    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
        right: -50px;
    }
}

@media (max-width: 1400px) {
    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2 {
        font-size: 36px;
        line-height: 56px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text h3 {
        font-size: 28px;
        line-height: 42px;
    }
}

@media (max-width: 1366px) {
    .wordwise-section.contact-wordwise-section .client-map-content .item p {
        font-size: 14px !important;
        line-height: 21px !important;
    }

    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2 {
        font-size: 32px;
        line-height: 43px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text h3 {
        font-size: 26px;
        line-height: 34px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 1200px) {

    .contact-banner-section .contact-banner-content .heading h3,
    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h3 {
        font-size: 16px;
        line-height: 24px;
    }

    .contact-banner-section .contact-banner-content .heading p {
        font-size: 16px;
        line-height: 24px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item {
        padding: 12px;
        margin-right: 40px;
        align-items: center;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item .flag-dis {
        padding-left: 15px;
    }

    .wordwise-section .client-map-content .item .flag-img {
        width: 50px;
        height: 50px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-next,
    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
        right: -22px;
        z-index: 5;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item .flag-dis h2 {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-next {
        top: 43%;
    }

    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2 {
        font-size: 28px;
        line-height: 42px;
    }

    .contect-us-main .request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-content h1 {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text p {
        max-width: 260px;
        margin: 0 auto;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image {
        margin: -45px auto 0;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row {
        padding-top: 30px;
    }
}

@media (max-width: 1024px) {
    .contact-banner-section {
        padding: 80px 0 20px 0;
    }

    .contact-case-studies {
        padding: 20px 0 0;
    }

    .wordwise-section.contact-wordwise-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .contact-other-section .media-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .wordwise-section.contact-wordwise-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image {
        max-width: 250px;
        height: 180px;
    }
}

@media (max-width: 992px) {
    .contact-banner-section .contact-banner-content .heading h3 {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 21px;
    }

    .contact-banner-section {
        padding: 60px 0 20px 0;
    }

    .contact-case-studies {
        padding: 20px 0 0;
    }

    .contact-other-section .findus-section {
        padding-bottom: 80px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card {
        width: 400px;
        height: 400px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card::before {
        min-height: unset;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row {
        flex-direction: column;
        align-items: center;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image .location-name h2 {
        font-size: 24px;
        line-height: 36px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .card-0 {
        padding-bottom: 110px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info h5 {
        font-size: 14px;
        line-height: 20px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info .contact-link {
        font-size: 14px;
        line-height: 20px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card::before {
        top: 30px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text {
        padding: 10px 20px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text p {
        font-size: 14px;
        line-height: 20px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info {
        margin-bottom: 15px;
    }

    .wordwise-section.contact-wordwise-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .contact-other-section .media-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .wordwise-section.contact-wordwise-section {
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2 {
        font-size: 26px;
        line-height: 35px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide.slick-active .item {
        margin: 10px 10px 30px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content {
        padding: 0 40px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-next {
        top: 42%;
        transform: rotate(0deg) translateY(-50%);
        right: -30px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
        top: 42%;
        transform: rotate(0deg) translateY(-50%);
        right: auto;
        left: -30px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text h3 {
        font-size: 22px;
        line-height: 31px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image {
        margin: -45px auto 0;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row {
        padding-top: 30px;
    }
}

@media (max-width: 767px) {
    .contact-banner-section .contact-banner-content .heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .contact-banner-section {
        padding: 50px 0 20px 0;
    }

    .contact-case-studies {
        padding: 20px 0 0;
    }

    .wordwise-section.contact-wordwise-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contact-other-section .media-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .wordwise-section.contact-wordwise-section {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .contact-banner-section .contact-banner-content {
        padding-bottom: 10px;
    }

    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2,
    .wordwise-section .heading h2,
    .media-section .heading h2,
    .contact-banner-section .contact-banner-content .heading h1,
    .findus-section .heading h1.page-heading,
    .wordwise-section .heading h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item {
        flex-flow: column;
        align-items: center;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item .flag-dis {
        padding-left: 0;
        text-align: center;
        padding-top: 5px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .item p {
        margin-bottom: 0;
    }

    .wordwise-section.contact-wordwise-section .contact-map-image {
        min-height: 300px;
        margin-bottom: 20px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item,
    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide.slick-active .item {
        padding: 8px 5px;
        margin: 10px 8px 30px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row {
        flex-flow: column;
        align-items: center;
    }

    .our-location-row .our-location-col {
        padding-bottom: 90px;
    }

    .our-location-row .our-location-col:last-child {
        padding-bottom: 0;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text p {
        font-size: 14px;
        line-height: 21px;
    }

    .contact-banner-section .request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-image {
        width: 240px;
        height: auto;
    }

    .contact-banner-form .contact-form-inner .request-proposal .request-form-section .request-form-inner form {
        padding: 20px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image {
        margin: -30px auto 0;
        transform: none !important;
    }
}

@media (max-width: 600px) {
    .contact-banner-section .contact-banner-content .heading h1 {
        font-size: 26px;
        line-height: 34px;
    }

    .wordwise-section .client-map-content .item .flag-img {
        width: 40px;
        height: 40px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item .flag-img img {
        width: 24px;
        height: 18px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content {
        padding: 0 24px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow {
        width: 26px;
        height: 26px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-next {
        right: -26px;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
        left: -26px;
    }

    .wordwise-section.contact-wordwise-section .contact-map-image {
        min-height: 250px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card::before {
        max-height: 320px;
    }

    .contact-other-section .findus-section {
        padding-bottom: 140px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card {
        height: auto;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .card-0 {
        padding-bottom: 80px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card::before {
        border-radius: 0;
        transform: scale(1);
        opacity: 1;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .card-0 {
        padding-bottom: 140px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card::before {
        top: 90px;
    }
    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info.contact-info-1 h5 {
            background: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
        }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info.contact-info-2 h5 {
            background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
        }
        .wordwise-section.contact-wordwise-section{
            padding-bottom: 40px;
        }
        .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev{
            top: unset;
            bottom: -30px;
            left: 40%;
        }
        .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-arrow.slick-next{
            top: unset;
            bottom: -30px;
            left: 54%;
        }
}

@media (max-width: 416px) {
    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card {
        width: 295px;
        height: 295px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .card-0 {
        padding-bottom: 130px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card::before {
        top: 55px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info .contact-link {
        font-size: 12px;
        line-height: 16px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .contact-info h5 {
        font-size: 12px;
        line-height: 16px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image {
        max-width: 218px;
        height: 150px;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-text p {
        max-width: 222px;
        margin: 0 auto;
    }

    .contact-banner-section .request-proposal-new.request-proposal.request-proposal-form .request-proposal-blog .request-blog-image {
        max-width: 100%;
    }

    .contact-other-section .findus-section .our-location .our-location-inner .our-location-row .our-location-col .our-location-card .location-card-image {
        margin: -45px auto 0;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .item p {
        font-size: 12px !important;
        line-height: 14px !important;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item .flag-dis h2 {
        font-size: 18px !important;
        line-height: 23px !important;
    }

    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide .item,
    .wordwise-section.contact-wordwise-section .client-map-content .slider-client .slick-slide.slick-active .item {
        margin: 10px 6px 30px;
    }
}

@media (max-width: 340px) {
    .contact-banner-section .contact-banner-content .heading h1 {
        font-size: 20px;
        line-height: 27px;
    }

    .contact-case-studies .contectus-casestudies-list .casestudies-allstories-inner .heading .casestudies-heading-left h2,
    .wordwise-section .heading h2,
    .media-section .heading h2,
    .contact-banner-section .contact-banner-content .heading h1,
    .findus-section .heading h1.page-heading,
    .wordwise-section .heading h2 {
        font-size: 24px;
        line-height: 32px;
    }
}

//

.location-card-image {
    transform: translate(0%) rotate(0deg);
}
