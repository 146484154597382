:root {
    .theme-light {
    
      /* Light Theme Colors */
    --primary-bg: #fff;
    --primary-background-light: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
    --secondary-background-light: #F4FAFE;
    --tab-bg-light: var(--Troo-Blue-Gradient, linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%));
    --study-bg-1: #12123C;
    --study-bg-2:#FBBC2E;
    --study-bg-3:linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #365DA2 0%, #274E94 100%);
    --study-bg-4:#25938C;
    --card-green-bg: var(--Troo-Blue-Gradient, linear-gradient(283deg, #8ABC00 5.56%, #F1F8DA 113.66%));
    --light-card-bg: rgba(255, 255, 255, 0.90);
    --step-bg-light: rgba(255, 255, 255, 0.70);
    --developer-bg-light: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
    --table-bg: linear-gradient(283.13deg, rgba(0, 134, 230, 0.8) 5.56%, rgba(141, 208, 255, 0.7) 113.66%),
    linear-gradient(283.13deg, rgba(0, 134, 230, 0.2) 5.56%, rgba(141, 208, 255, 0.2) 113.66%);
    --table-bg1: linear-gradient(283.13deg, rgba(0, 134, 230, 0.9 ) 5.56%, #8DD0FF 113.66%),
    linear-gradient(283.13deg, rgba(0, 134, 230, 0.2) 5.56%, rgba(141, 208, 255, 0.2) 113.66%);
    --box-green-bg: radial-gradient(61.2% 56.03% at 50.82% 52.22%, #8ABC00 34.5%, rgba(138, 188, 0, 0.00) 100%);
    --upload-bg-light: var(--Troo-Blue-Gradient, linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%,rgba(141, 208, 255, 0.05) 113.66%));
    --next-card-bg: #fff;
    --developer-bg: #EAF5FD;
    --solution-bg: rgba(0, 134, 230, 0.15);
    --solution-hover-bg: var(--Troo-Blue-Gradient, linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%));
    --tab-card-light: var(--Troo-Blue-Gradient, linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%));
    --step-bg-light: var(--Troo-Blue-Gradient, linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%));
    --developer-bg-table: #F2F9FE;
    --pattern-bg-light: #fff;
    --form-control-bg: #fff;
    --global-card-bg: linear-gradient(132deg, #FFF 0%, #FFF 100%);
    --case-study-bg: linear-gradient(122deg, rgba(0, 145, 218, 0.50) -11.62%, rgba(0, 145, 218, 0.44) 8.53%, rgba(69, 168, 104, 0.00) 69.21%, rgba(132, 189, 0, 0.50) 100%);
    --tehno-chip-bg: linear-gradient(283.13deg, rgba(0, 134, 230, 15%), rgba(141, 208, 255, 15%));
    --techno-chip-bg-hover: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
    --solution-chip-hover-bg: var(--Troo-Blue-Gradient, linear-gradient(283deg, #ffffff 5.56%, #ffffff 113.66%));
    --resource-card-bg: #EBF6FE;
    --contact-card-bg: #fff;
    --slider-bg: rgba(234, 245, 253, 1);
    --slider-dot: #0086E6;
    --challenge-bg: rgba(242, 145, 19, 1);
    --config-chip-bg: linear-gradient(0deg, rgba(0, 145, 218, 0.03), rgba(0, 145, 218, 0.03)),
    linear-gradient(0deg, rgba(0, 145, 218, 0.05), rgba(0, 145, 218, 0.05));
    --static-green-bg:linear-gradient(0deg, rgba(132, 189, 0, 0.01), rgba(132, 189, 0, 0.01)),
    linear-gradient(0deg, rgba(132, 189, 0, 0.01), rgba(132, 189, 0, 0.01));
    --static-blue-bg: linear-gradient(0deg, rgba(0, 145, 218, 0.01), rgba(0, 145, 218, 0.01)),
    linear-gradient(0deg, rgba(0, 145, 218, 0.01), rgba(0, 145, 218, 0.01));
    --swiper-vt-bg: linear-gradient(0deg, #F4FAFE, #F4FAFE),
    linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
    --next-case-study-bg: #fff;
    
    /* text */
    --heading-text: #040405;
    --primary-text-light: #fff;
    --primary-text-light-active: #fff;
    --primary-text-heading: #19191B;
    --secondary-text-light: #686869;
    --text-blue: #0086E6;
    --study-text: #fff;
    --award-dark-text: #000;
    --listing-text-color: #202020;
    --tab-color-dark: #555C60;
    --share-head-text: #616161;
    --project-link-text: #91959b;
    --step-text-light: rgba(85, 92, 96, 1);

    /* border */
    --primary-light:#fff;
    --primary-border-light: rgba(0, 134, 230, 0.20);
    --secondary-border-light: rgba(0, 134, 230, 0.07);
    --profile-border-light: rgba(138, 188, 0, 0.07);
    --step-border: rgba(255, 255, 255, 0.4);
    --table-border: rgba(0, 134, 230, 0.001);
    --border-blue: #0086E6;
    --primary-green-color: #84bd00;
    --next-card-border: #D6EEFF;
    --step-border-dark:#fff;
    --global-text-card: #00011C;
    --resorce-card-border: rgba(0, 134, 230, 0.6);
    --share-head-border: #616161;
    --challenge-border: rgba(0, 145, 218, 0.3);
    --static-green-border: rgba(132, 189, 0, 0.3);
    --static-blue-border:rgba(0, 145, 218, 0.3);
    --webkit-bg: #f1f1f1;
    --footer-bg: #eaf5fd;

    

     // link color
    --tab-heading-text: #686869;
    --contact-sign-link: rgba(4, 4, 5, 0.56);

    .global-new-section, .feature-new-section {
      --section-primary: #fff;
      --tab-bg-light: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
;
    }
  
    }

   
    /* Dark Theme Colors */
    .theme-dark {
    
      /* Light Theme Colors */
    --primary-bg: rgba(19, 19, 19, 1);
    --primary-background-light:rgba(39, 39, 39, 1);
    --secondary-background-light: rgba(29, 29, 29, 1);
    --tab-bg-light:#0b0e05;
    --study-bg-1: #12123C;
    --study-bg-2:#FBBC2E;
    --study-bg-3:linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #365DA2 0%, #274E94 100%);
    --study-bg-4:#25938C;
    --card-green-bg: var(--Troo-Blue-Gradient, linear-gradient(283deg, #8ABC00 5.56%, #F1F8DA 113.66%));
    --light-card-bg: rgba(255, 255, 255, 0.90);
    --step-bg-light: rgba(255, 255, 255, 0.70);
    --developer-bg-light: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
    --table-bg: linear-gradient(283.13deg, rgba(0, 134, 230, 0.8) 5.56%, rgba(141, 208, 255, 0.7) 113.66%),
    linear-gradient(283.13deg, rgba(0, 134, 230, 0.2) 5.56%, rgba(141, 208, 255, 0.2) 113.66%);
    --table-bg1: linear-gradient(283.13deg, rgba(0, 134, 230, 0.9 ) 5.56%, #8DD0FF 113.66%),
    linear-gradient(283.13deg, rgba(0, 134, 230, 0.2) 5.56%, rgba(141, 208, 255, 0.2) 113.66%);
    --box-green-bg: radial-gradient(61.2% 56.03% at 50.82% 52.22%, #8ABC00 34.5%, rgba(138, 188, 0, 0.00) 100%);
    --upload-bg-light: var(--Troo-Blue-Gradient, linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%,rgba(141, 208, 255, 0.05) 113.66%));
    --next-card-bg: rgba(39, 39, 39, 1);
    --developer-bg: rgba(19, 19, 19, 1);
    --solution-bg: rgba(0, 134, 230, 0.15);
    --solution-hover-bg: var(--Troo-Blue-Gradient, linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%));
    --tab-card-light: var(--Troo-Blue-Gradient, linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%));
    --step-bg-light: rgba(109, 109, 109, 1);
    --developer-bg-table: rgba(242, 249, 254, 0.1);
    --pattern-bg-light: #fff;
    // --form-control-bg: #040405;
    --form-control-bg: rgba(39, 39, 39, 1);
    --global-card-bg: rgba(39, 39, 39, 1);
    --case-study-bg: linear-gradient(122deg, rgba(0, 145, 218, 0.50) -11.62%, rgba(0, 145, 218, 0.44) 8.53%, rgba(69, 168, 104, 0.00) 69.21%, rgba(132, 189, 0, 0.50) 100%);
    --tehno-chip-bg: linear-gradient(283.13deg, rgba(0, 134, 230, 15%), rgba(141, 208, 255, 15%));
    --techno-chip-bg-hover: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
    --solution-chip-hover-bg: var(--Troo-Blue-Gradient, linear-gradient(283deg, #ffffff 5.56%, #ffffff 113.66%));
    --resource-card-bg: #rgba(39, 39, 39, 1);
    --contact-card-bg: rgba(39, 39, 39, 1);
    --slider-bg: #040405;
    --challenge-bg: rgba(242, 145, 19, 1);
    --config-chip-bg: linear-gradient(0deg, rgba(0, 145, 218, 0.03), rgba(0, 145, 218, 0.03)),
    linear-gradient(0deg, rgba(0, 145, 218, 0.05), rgba(0, 145, 218, 0.05));
    --static-green-bg:linear-gradient(0deg, rgba(132, 189, 0, 0.01), rgba(132, 189, 0, 0.01)),
    linear-gradient(0deg, rgba(132, 189, 0, 0.01), rgba(132, 189, 0, 0.01));
    --static-blue-bg: linear-gradient(0deg, rgba(0, 145, 218, 0.01), rgba(0, 145, 218, 0.01)),
    linear-gradient(0deg, rgba(0, 145, 218, 0.01), rgba(0, 145, 218, 0.01));
    --swiper-vt-bg: rgba(39, 39, 39, 1);
    --next-case-study-bg: rgba(19, 19, 19, 1);
    --webkit-bg: rgba(53, 53, 53, 1);
    --footer-bg: rgba(19, 19, 19, 1);
    
    /* text */
    --heading-text: #fff;
    --primary-text-light: #fff;
    --primary-text-heading: #fff;
    --secondary-text-light: #686869;
    --text-blue: #0086E6;
    --study-text: #fff;
    --award-dark-text: #fff;
    --global-text-card: #fff;
    --listing-text-color: #fff;
    --tab-color-dark: #555C60;
    --share-head-text: #616161;
    --project-link-text: #91959b;
    --tab-color-dark: rgba(109, 109, 109, 1);
    --step-text-light: rgba(109, 109, 109, 1);
  
    /* border */
    --primary-light:#fff;
    --primary-border-light: rgba(53, 53, 53, 1);
    // --primary-border-light: rgba(0, 134, 230, 0.20);
    --secondary-border-light: rgba(53, 53, 53, 1);
    --profile-border-light: rgba(53, 53, 53, 1);
    --step-border: rgba(255, 255, 255, 0.4);
    --table-border: rgba(0, 134, 230, 0.001);
    --border-blue: #0086E6;
    --primary-green-color: #84bd00;
    --next-card-border: rgba(214, 238, 255, 0.1);
    --step-border-dark:rgba(53, 53, 53, 1);
    --primary-text-light-active: #fff;
    --resorce-card-border: rgba(53, 53, 53, 1);
    --border-blank: rgba(0, 134, 230, 0.6) ;
    --share-head-border: #616161;
    --static-green-border: rgba(132, 189, 0, 0.3);
    --static-blue-border:rgba(0, 145, 218, 0.3);
    --challenge-border: rgba(0, 145, 218, 0.3);

     // link color
     --tab-heading-text: #686869;
     --contact-sign-link: #686869;
   
    }

    .global-new-section, .feature-new-section {
      --section-primary: rgba(39, 39, 39, 1);
      --tab-bg-light: rgba(39, 39, 39, 1);
    }
  }