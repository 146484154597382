$font-stack: "Inter", sans-serif !important;

/* theme end */
@keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
/***/
.theme-light {
	--bg-header-light: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
	--bg-header-light-fixed: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%);
	--color-primary: #f3f9fe;
	--color-text-primary: #040405;
	--header-text-hover: #040405;
	--color-toggle: #fff;
	--color-submenu-primary: #040405;
	--color-submenu-secondary: rgba(4, 4, 5, 0.5);
	--color-submenu-lightgray: #91959b;
	--header-text: #00011c;
	--header-text-hover: var(--color-text-primary);
	--header-bg: #f3f9fe;
	--bg-navigation: #fff;
	--header-border-gray: rgba(0, 1, 28, 0.01);
	--header-border-gray1: rgba(0, 1, 28, 0.02);
	--section-primary: #fff;
	--heading-priamry: #00011c;
	--line: linear-gradient(283.13deg, rgba(0, 134, 230, 0.3) 5.56%, rgba(141, 208, 255, 0.3) 113.66%);
	--bg-line: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
	--line-active: #0086e6;
	--section-secondary: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
	--heading-secondary: #91959b;
	--sub-heading-primary: #040405;
	--hire-circle: linear-gradient(283.13deg, rgba(0, 134, 230, 0.03) 5.56%, rgba(141, 208, 255, 0.03) 113.66%);
	--marquee-color: #cbd3d8;
	--dot: #c4c4c4;
	--acc-icon-color: #91959b;
	--acc-bg-color: #f6faff;
	--acc-color: #91959b;
	--acc-active-color: #040405;
	--test-border-color: #dfdfdf;
	--test-bg-color: #fff;
	--test-color: #00011c;
	--section-three: rgba(37, 37, 38, 0.12);
	--sectiontree-color: #00011c;
	--footer-title: rgba(0, 1, 28, 1);
	--footer-links: #91959b;
	--footer-links-hover: rgba(0, 1, 28, 1);
	--footer-title-second: #91959b;
	--color-sub-heading: #040405;
	--color-sub-text: #91959b;
	--color-feature: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
	--color-feature-border: #f3f9fe;
	--color-feature2: #fff;
	--color-sub-light: rgba(97, 97, 97, 0.2);
	--border: rgba(0, 1, 26, 0.2);
	--border3: rgba(0, 1, 26, 0.1);
	--border2: rgba(0, 1, 26, 1);
	--new-color-text: #91959b;
	--chip-text-color: #19191b;
	--check-right-text: rgba(4, 4, 5, 0.56);
}
.theme-dark {
	--bg-header-light: #111;
	--bg-header-light-fixed: #111;
	--color-primary: #040405;
	--color-text-primary: #fff;
	--color-toggle: #040405;
	--color-submenu-primary: #fff;
	--color-submenu-secondary: rgba(255, 255, 255, 0.56);
	--color-submenu-lightgray: rgba(255, 255, 255, 0.56);
	--header-text: rgba(255, 255, 255, 0.56);
	--header-text-hover: #fff;
	--header-bg: #040405;
	--bg-navigation: #040405;
	--header-border-gray: rgba(255, 255, 255, 0.02);
	--header-border-gray1: rgba(255, 255, 255, 0.03);
	--section-primary: #040405;
	--heading-priamry: #020202;
	--line: linear-gradient(317.72deg, rgba(138, 188, 0, 0.2) 5.24%, rgba(204, 250, 78, 0.2) 137.29%);
	--line-active: rgba(138, 188, 0, 1);
	--bg-line: linear-gradient(317.72deg, rgba(138, 188, 0, 0.05) 5.24%, rgba(204, 250, 78, 0.05) 137.29%);
	--section-secondary: #111;
	--heading-secondary: rgba(255, 255, 255, 0.56);
	--sub-heading-primary: #fff;
	--hire-circle: #0c0c0c;
	--marquee-color: #3b3b3b;
	--dot: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
	--acc-icon-color: rgba(255, 255, 255, 0.3);
	--acc-bg-color: #111;
	--acc-color: rgba(255, 255, 255, 0.56);
	--acc-active-color: #fff;
	--test-border-color: rgba(255, 255, 255, 0.05);
	--test-bg-color: #111;
	--test-color: #fff;
	--section-three: #252526;
	--sectiontree-color: #fff;
	--footer-title: rgba(255, 255, 255, 1);
	--footer-links: rgba(255, 255, 255, 0.56);
	--footer-links-hover: rgba(255, 255, 255, 1);
	--color-sub-heading: #fff;
	--color-sub-text: rgba(255, 255, 255, 0.56);
	--color-feature: #111;
	--color-feature-border: #111;
	--color-feature2: #040405;
	--color-sub-light: rgba(97, 97, 97, 0.2);
	--border: rgba(255, 255, 255, 0.2);
	--border3: rgba(255, 255, 255, 0.1);
	--border2: rgba(255, 255, 255, 1);
	--new-color-text: rgba(255, 255, 255, 0.56);
	--chip-text-color: #fff;
	--check-right-text: rgba(255, 255, 255, 0.56);
	.menu-overlay {
		background-color: rgba(255, 255, 255, 0.2);
	}
}
body {
	font-family: "Inter", sans-serif;
}

body {
	font-family: $font-stack;
	font-display: swap;
}

.theme-dark {
	body {
		color: #91959b !important;
	}
}

.middle-sction {
	position: relative;
	z-index: 10;
}

.middle-sction.middle-pad {
	padding-top: 80px;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
}

p {
	white-space: pre-wrap;
}

@media (min-width: 1440px) {
	.container {
		max-width: 1280px !important;
	}
}

@media (min-width: 1600px) {
	.container {
		max-width: 1240px !important;
	}
}

@media (min-width: 1900px) {
	.container {
		max-width: 1280px !important;
	}

	.vibe-section .vibe-main-box {
		height: 100%;
	}
}

@mixin btn-custom {
	padding: 16px 16px;
	border-radius: 8px !important;
	font-size: 16px;
	line-height: 19px;
	font-weight: 600;
	transition: all 0.8s;
	height: 50px;
	outline: 0 !important;
	transition: all 0.8s !important;

	&:hover {
		transition: all 0.8s;
		transform: translateY(-3px);
	}

	&:focus {
		transition: all 0.8s;
		transform: translateY(0px);
		outline: 0 !important;
	}

	&:active {
		box-shadow: none !important;
	}

	@media (max-width: 767px) {
		padding: 6px 10px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 26px;
		height: 40px;
	}

	@media (max-width: 992px) and (min-width: 768px) {
		height: 50px;
		line-height: 26px !important;
		padding: 10px 20px !important;
	}
}

.btn-primary {
	@include btn-custom();
	background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%);
	border-radius: 8px;
	color: #fff !important;
	border-color: transparent !important;
	border: 0 !important;

	&:hover {
		box-shadow: 0 3px 15px 0 rgba(0, 134, 230, 0.4);
	}

	&:focus {
		outline: 0 !important;
		box-shadow: 0 3px 15px 0 rgba(0, 134, 230, 0.4);
	}
}

.btn-secondary {
	@include btn-custom();
	background: #ffffff;
	border-radius: 10px;
	color: #000000 !important;
	border: 1px solid rgba(97, 97, 97, 0.2) !important;
	font-size: 16px;
	line-height: 19px;
	font-weight: 600;

	&:hover {
		background-color: #fff;
		box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
	}

	&:focus {
		outline: 0 !important;
		background-color: #fff;
		box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
	}
}

.theme-light .btn-toggle {
	background-image: url("../../assets/images/icons/theme-dark-icon.svg");
	background-repeat: no-repeat;
	background-position: center center;
	margin: auto;
	height: 50px;
	width: 50px;
	border-radius: 8px;
	margin-left: 10px;
	box-shadow: none !important;
	outline: none;
	background-color: #fff !important;
	border: 0;
}

.theme-dark .btn-toggle {
	background-image: url("../../assets/images/icons/theme-light-icon.svg");
	background-repeat: no-repeat;
	background-position: center center;
	margin: auto;
	height: 50px;
	width: 50px;
	border-radius: 8px;
	margin-left: 10px;
	box-shadow: none !important;
	outline: none;
	background-color: #fff !important;
	border: 0;
}

@mixin heading {
	text-align: center;
	margin-bottom: 20px;

	h3 {
		font-size: 20px;
		line-height: 30px;
		color: #91959b !important;
		margin-bottom: 10px;
		font-weight: 500;
	}

	h2 {
		font-size: 40px;
		line-height: 60px;
		color: #040405 !important;
		font-weight: 700;
	}

	@media (max-width: 992px) {
		h3 {
			font-size: 22px;
			line-height: 30px;
			margin-bottom: 6px;
		}

		h2 {
			font-size: 36px;
			line-height: 48px;
		}

		h1 {
			font-size: 40px;
			line-height: 48px;
		}
	}

	@media (max-width: 767px) {
		h3 {
			font-size: 16px;
			line-height: 26px;
			margin-bottom: 3px;
		}

		h2 {
			font-size: 20px;
			line-height: 30px;
		}
	}
}

@mixin heading2 {
	h2 {
		line-height: 60px;
	}

	@media (max-width: 992px) {
		h2 {
			font-size: 36px;
			line-height: 48px;
		}
	}

	@media (max-width: 767px) {
		h2 {
			font-size: 22px;
			line-height: 32px;
		}
	}
}

@media (max-width: 767px) {
	.middle-sction.middle-pad {
		padding-top: 70px;
	}
}

@media (max-width: 996px) {
	.middle-sction.middle-pad {
		padding-top: 70px;
	}
}

.header {
	.navbar.fixed-top {
		background: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%);
		box-shadow: 0 7px 12px rgba(0, 0, 0, 0.02);
		position: fixed !important;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1030;
		height: 80px;
		border-bottom: 1px solid rgba(0, 1, 28, 0.01);
	}
	position: relative;
	font-family: "Inter", sans-serif;
	.sub-menu-inner {
		font-family: "Inter", sans-serif;
	}
	.menu-heading {
		color: #00011c;
		h2 {
			font-family: "Inter", sans-serif;
			font-family: "Inter", sans-serif;
		}
	}
	.sub-menu-content {
		a {
			h3 {
				font-family: "Inter", sans-serif;
			}
		}
	}
	.nav-link {
		font-family: "Inter", sans-serif;
	}
	.navbar {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		padding: 0;
		display: flex;
		height: 80px;
		background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
		z-index: 999;
		.container {
			padding-left: 15px;
			padding-right: 15px;
		}
		.navbar-brand {
			width: 133px;
			height: auto;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			img {
				width: 100%;
			}
		}
		.collapse1 {
			height: 100%;
			.navbar-nav {
				align-items: center;
				justify-content: flex-end;
				&:nth-last-child(1) {
					a {
						&::after {
							opacity: 0 !important;
						}
					}
				}
				a.nav-link {
					color: #00011c;
					font-size: 16px;
					line-height: 24px;
					padding: 8px 12px;
					white-space: nowrap;
					font-weight: 500;
					position: relative;
					&::after {
						content: "";
						height: 2px;
						width: calc(100% - 24px);
						background-color: #00011c;
						position: absolute;
						bottom: 8px;
						left: 12px;
						right: 0;
						opacity: 0;
						transition: all 0.3s;
					}
					&:hover {
						transition: all 0.4s ease-in-out;
						color:  #040405;
						&::after {
							transition: all 0.3s;
							opacity: 1;
						}
					}
				}
				a.active {
					font-weight: 600;
					transition: all 0.4s ease-in-out;
					color: 040405;
					position: relative;
					&::after {
						opacity: 1;
						transition: all 0.3s;
					}
				}
				.btn-primary {
					margin-left: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.nav-btn {
					a {
						font-size: 16px;
						min-height: 30px;
						line-height: 22px;
						border-radius: 4px;
						padding: 8px 20px;
						color: #fff;
					}
				}
				// a.nav-link.nav-mb-link-show {
				// 	background: transparent !important;
				// 	border: 1px solid #0086e6 !important;
				// 	color: #0086e6 !important;
				// 	&:hover {
				// 		background: linear-gradient(283.13deg, #0086e6 5.56%, #8dd0ff 113.66%) !important;
				// 		color: #fff !important;
				// 		border-color: transparent !important;
				// 	}
				// }
			}
		}
		.submenu {
			position: absolute;
			left: 0;
			right: 0;
			top: 50px;
			width: 100%;
			display: none;
			padding-top: 30px;
			.submenu-inner {
				background-color: #fff;
				.menu-heading {
					padding: 16px 0;
					border-bottom: 1px solid rgba(0, 1, 26, 0.1);
					margin-bottom: 20px;
					h2 {
						font-weight: 600;
						font-size: 16px;
						line-height: 24px;
						text-align: left;
						letter-spacing: -0.02em;
						color: #00011c;
						margin-bottom: 0;
					}
					p {
						font-weight: 600;
					}
				}
				.sub-menu-content {
					margin-bottom: 16px;
					a {
						padding: 12px;
						display: block;
						white-space: normal !important;
						position: relative;
						transition: all 0.3s linear;
						border-radius: 12px;
						padding: 12px 12px 12px 12px;
						padding: 10px;
						.sub-menu-title {
							font-weight: 600;
							font-size: 16px;
							line-height: 22px;
							color: #040405 !important;
							margin-top: 0;
							margin-bottom: 0px;
							display: block;
							transition: all 0.3s linear;
							max-width: 202px;
							min-height: unset !important;
						}
						P {
							margin-top: 11px;
							font-weight: 400;
							font-size: 16px;
							line-height: 26px;
							transition: all 0.3s linear;
							color: #91959b;
						}
						&::after {
							content: "";
							background-image: url(../../assets/images/letest-right-icon.svg);
							background-repeat: no-repeat;
							background-position: center;
							position: absolute;
							right: 0;
							top: 0;
							height: 20px;
							width: 30px;
							z-index: 9;
							display: block;
							opacity: 0;
						}
						&:hover {
							transition: all 0.3s linear;
							background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
							&::after {
								opacity: 0;
								width: 0;
							}
							h3 {
								font-weight: 600;
								transition: all 0.3s linear;
							}
							p {
								transition: all 0.3s linear;
								color: #040405;
							}
						}
						p {
							font-size: 14px;
							line-height: 20px;
							margin-top: 10px;
							margin-bottom: 10px;
						}
					}
					a.active {
						background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
						h3 {
							font-weight: 600;
							transition: all 0.3s linear;
						}
						p {
							transition: all 0.3s linear;
							color: #040405;
						}
					}
					.sub-menu-inner {
						font-size: 14px;
						line-height: 20px;
					}
				}
				.menu-marquee {
					background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
					padding: 10px 0;
					ul {
						padding: 0;
						margin: 0;
						li {
							font-weight: 500;
							font-size: 21px;
							line-height: 31px;
							display: inline-flex;
							align-items: center;
							color: rgba(4, 4, 5, 0.5);
							margin-right: 60px;
							position: relative;
							a {
								display: block;
								color: rgba(4, 4, 5, 0.5);
								text-decoration: none;
							}
							&::after {
								content: "";
								height: 11px;
								width: 11px;
								background-image: url(../../assets/images/blue-round-icon.svg);
								background-repeat: no-repeat;
								display: block;
								border-radius: 100px;
								position: absolute;
								right: -40px;
								top: 15px;
							}
						}
					}
				}
				.right-side-submenu {
					margin-bottom: 20px;
					padding: 20px 20px 40px 20px;
					background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
					border-radius: 12px;
					padding: 20px;
					.submenu-icon-image {
						margin-bottom: 15px;
					}
					.submenu-requred {
						p {
							font-size: 20px;
							line-height: 28px;
							font-weight: 700;
							margin: 0;
							color: #040405;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
					.menu-blog-content {
						p {
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
							padding-bottom: 0;
							margin-bottom: 8px;
						}
					}
				}
				.section-main-submenu {
					margin-bottom: 20px;
					border-bottom: 1px solid rgba(0, 1, 26, 0.1);
				}
				.submenu-text-link {
					padding-bottom: 40px;
					a {
						padding: 14px 20px 17px 12px;
						background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
						border-radius: 12px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						position: relative;
						p {
							font-size: 14px;
							line-height: 20px;
							color: #040405 !important;
							font-weight: 400;
							padding: 0;
							margin: 0;
							transition: all 0.3s linear;
						}
						&::after {
							content: "";
							background-image: url(../../assets/images/letest-right-icon.svg);
							background-repeat: no-repeat;
							background-position: center;
							background-size: 13px;
							position: absolute;
							right: 0;
							top: 0;
							height: 100%;
							width: 40px;
							z-index: 9;
							display: flex;
							align-items: center;
							justify-content: center;
							opacity: 0;
							transition: all 0.3s linear;
						}
						&:hover {
							p {
								font-weight: 600;
								transition: all 0.3s linear;
							}
							&::after {
								opacity: 1;
								transition: all 0.3s linear;
							}
						}
					}
				}
			}
		}
	}
	.collapse1 {
		.navbar-toggler {
			.navbar-toggler-icon {
				background-image: url("../../assets/images/close-menu.svg") !important;
				background-size: 80%;
			}
		}
	}
	.navbar-toggler {
		box-shadow: none !important;
	}
}
.sub-menu-inner {
	font-family: "Inter", sans-serif;
}
.menu-heading {
	h2 {
		font-family: "Inter", sans-serif;
		font-family: "Inter", sans-serif;
	}
}
.sub-menu-content {
	a {
		h3 {
			font-family: "Inter", sans-serif;
		}
	}
}
.nav-link {
	font-family: "Inter", sans-serif;
}
.menu-items {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
}
.is-sticky {
	.navbar {
		position: fixed;
		display: flex;
		width: 100%;
		z-index: 999;
		padding-top: 0px;
		padding-bottom: 0px;
		animation-name: slideInDown;
		animation-duration: 1s;
		animation-fill-mode: both;
		background: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%) !important;
	}
	header {
		background-color: #fff !important;
	}
}
.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}
.navItem {
	&:hover {
		.submenu {
			display: block !important;
		}
	}
}
.menu-overlay {
	content: "";
	position: fixed;
	left: 0;
	top: 80px;
	right: 0;
	width: 100%;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99;
	transition: all 0.3s ease-in-out;
}
.career-middle {
	.job-positions-section {
		.job-searching-section {
			form {
				.form-group {
					.form-input {
						input[type="search"].form-control {
							padding-right: 60px;
						}
					}
				}
			}
		}
	}
	.benefits-section {
		&::after {
			z-index: 1;
			background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
		}
		.container {
			position: relative;
			z-index: 2;
		}
	}
}
@media (max-width: 996px) {
	.header {
		.navbar {
			padding: 8px 0;
			z-index: 999;
			height: 70px;
			.navbar-brand {
				width: 120px;
				height: auto;
				img {
					width: 100%;
					height: auto;
				}
			}
			.navbar-collapse {
				padding: 10px 0;
				flex-direction: column;
				.navbar-nav {
					padding-top: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					a {
						font-size: 30px;
						line-height: 30px;
						padding: 18px 12px;
						font-weight: 600;
						white-space: nowrap;
					}
					.btn-primary {
						font-size: 18px !important;
						line-height: 20px !important;
						height: 50px;
						padding: 16px 30px !important;
						margin: 15px 0 15px 0;
					}
				}
			}
			.navbar-toggler {
				padding: 0;
				outline: 0;
				border: 0;
				border: 0;
				display: block;
				z-index: 100;
				padding: 0;
				.navbar-toggler-icon {
					background-color: transparent;
					background-image: url("../../assets/images/menubar.svg");
					background-repeat: no-repeat;
					background-size: contain;
					height: 35px;
					width: 35px;
					border: 0;
					background-image: url(../../assets/images/letest-menu.svg) !important;
					width: 40px !important;
					height: 40px !important;
				}
			}
			.show.navbar-collapse {
				.navbar-nav {
					.theme-div {
						position: absolute;
						top: 10px;
						right: 60px;
						-webkit-box-shadow: 2px 2px 40px -22px rgba(0, 0, 0, 0.68);
						-moz-box-shadow: 2px 2px 40px -22px rgba(0, 0, 0, 0.68);
						box-shadow: 2px 2px 40px -22px rgba(0, 0, 0, 0.68);
						padding: 0 !important;
						.btn-toggle {
							margin-left: 0 !important;
						}
					}
				}
			}
		}
		.collapse1 {
			.navbar-toggler {
				.navbar-toggler-icon {
					background-image: url(../../assets/images/dark-cross.svg) !important;
				}
			}
		}
		.mobile-menu {
			.accordion-button {
				&:not(.collapsed) {
					&::after {
						content: "";
						background-image: url(../../assets/images/down-arrow.svg);
						width: 16px;
						height: 16px;
						background-size: cover;
						background-position: center center;
					}
				}
			}
		}
	}
	#cd-shadow-layer {
		position: fixed;
		min-height: 100vh;
		width: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		cursor: pointer;
		display: none;
		z-index: 99;
	}
	.sidenav-new-menu {
		height: 100%;
		min-height: 100vh;
		width: 300px;
		position: fixed;
		right: -300px;
		top: 0;
		overflow-y: auto;
		overflow-x: hidden;
		transition: all 0.5s;
		z-index: 999999;
		background-color: #fff;
		padding: 40px 0 20px 0;
		flex-basis: auto;
		justify-content: start;
		align-items: start;
		.navbar-toggler {
			position: absolute;
			right: 15px;
			top: 18px;
		}
	}
	.show {
		width: 100%;
		right: 0;
		display: block !important;
		transition: all 0.5s;
	}
	.closebtn {
		display: block !important;
		color: #fff;
		text-decoration: none;
		position: absolute;
		top: 0;
		right: 25px;
		font-size: 36px;
		margin-left: 50px;
		img {
			width: 25px;
			height: 25px;
		}
		&:hover {
			display: block;
			color: #fff;
			text-decoration: none;
		}
	}
	.desktop-menu {
		display: none !important;
	}
	.mobile-menu {
		display: block !important;
		padding-top: 65px;
		position: relative;
		width: 100%;
		.navbar-brand {
			position: absolute;
			top: 0px;
			margin: 10px 20px;
		}
		.accordion-button {
			color: #00011c !important;
			background-color: #fff !important;
			font-weight: 600;
			box-shadow: none !important;
			font-size: 16px;
			line-height: 26px;
			&::after {
				content: "+";
				color: #00011c !important;
				font-weight: 300;
				font-size: 30px;
				background-image: none;
				transform: none !important;
				margin-top: 0px;
				content: "";
				background-image: url(../../assets/images/grey-right-icon.svg);
				width: 16px;
				height: 16px;
				background-size: cover;
				background-position: center center;
			}
			&:not(.collapsed) {
				&::after {
					content: "-";
					font-size: 40px;
				}
			}
		}
		.accordion-item {
			border-radius: 0 !important;
			border: 0 !important;
			border-bottom: 1px solid rgba(0, 1, 26, 0.1) !important;
			box-shadow: none !important;
			background-color: #fff !important;
		}
		.accordion-header {
			margin-bottom: 1px !important;
			border-radius: 0 !important;
			border: 0 !important;
			box-shadow: none !important;
			background-color: #fff !important;
		}
		.accordion-body {
			padding-top: 0 !important;
			border: 0 !important;
			background-color: #fff !important;
			box-shadow: none !important;
		}
		.menu-item {
			padding: 0;
			margin: 0;
			background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
			border-radius: 8px !important;
			li {
				a {
					padding: 15px 15px;
					display: block;
					margin-bottom: 10px;
					.submenu-heading {
						margin-bottom: 0;
						display: block;
						font-size: 16px;
						line-height: 26px;
						font-weight: 600;
						color: #040405 !important;
					}
					&:nth-last-child(1) {
						margin-bottom: 0px !important;
					}
				}
				p {
					font-size: 14px;
					line-height: 24px;
					font-weight: 500;
					color: rgba(4, 4, 5, 0.5);
				}
			}
		}
		.menu-item-2 {
			background: unset !important;
			li {
				background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%) !important;
				margin-bottom: 12px !important;
				border-radius: 8px !important;
				padding: 15px 15px;
				a {
					padding: 0 !important;
				}
				p {
					padding: 6px 0 0 0;
					margin-bottom: 0;
					line-height: 22px;
				}
			}
		}
		.submenu-text-link {
			a {
				padding: 14px 20px 17px 12px;
				background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
				border-radius: 12px;
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #040405 !important;
				position: relative;
				&::after {
					content: "";
					background-image: url(../../assets/images/letest-right-icon.svg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 13px;
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					width: 40px;
					z-index: 9;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					transition: all 0.3s linear;
				}
				&:hover {
					&::after {
						opacity: 1;
						transition: all 0.3s linear;
					}
				}
			}
		}
	}
	.theme-dark {
		.header {
			.navbar {
				.navbar-toggler {
					.navbar-toggler-icon {
						background-image: url(../../assets/images/header-dark-menu.svg) !important;
					}
				}
			}
			.collapse1 {
				.navbar-toggler {
					.navbar-toggler-icon {
						background-image: url(../../assets/images/cross-icon.svg);
					}
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
	.footer-section {
		.stay-in-touch {
			padding-left: 0;
			.subscribe {
				.btn-subscribe {
					max-width: 130px;
				}
			}
		}
		.social-media {
			margin-top: 30px;
		}
	}
}
@media (max-width: 992px) {
	.menu-items {
		display: flex;
		flex-direction: column;
		text-align: center;
	}
}
@media (max-width: 767px) {
	.header {
		.navbar {
			.navbar-collapse {
				.navbar-nav {
					a {
						font-size: 20px;
						line-height: 26px;
						padding: 12px 12px;
						font-weight: 500;
					}
					&:nth-child(3) {
						padding-top: 10px;
					}
					.btn-primary {
						margin: 0 0;
						font-size: 16px !important;
						line-height: 18px !important;
					}
				}
			}
			.submenu {
				z-index: 99;
				top: 25px;
				.sub-menu-content {
					margin-bottom: 0 !important;
				}
				a {
					.sub-menu-title {
						text-align: left;
						font-size: 14px;
						line-height: 22px;
					}
					p {
						text-align: left;
						font-size: 14px;
						line-height: 22px;
						margin-bottom: 5px;
					}
				}
				.submenu-inner {
					.submenu-text-link {
						padding-bottom: 20px;
					}
				}
			}
		}
	}
	.menu-marquee {
		background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%);
		padding-top: 5px;
		ul {
			li {
				font-weight: 600;
				font-size: 14px !important;
				line-height: 12px !important;
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
	.header {
		.navbar {
			.collapse1 {
				.navbar-nav {
					a.nav-link {
						font-size: 14px;
						padding: 10px 8px;
					}
				}
			}
		}
		.btn-toggle {
			width: 25px;
			height: 25px;
		}
	}
}
@media (min-width: 1130px) and (max-width: 1230px) {
	.header {
		.navbar {
			.collapse1 {
				.navbar-nav {
					a.nav-link {
						color: #00011c;
						font-size: 14px;
						line-height: 24px;
						padding: 6px 10px;
						white-space: nowrap;
						font-weight: 500;
					}
				}
			}
		}
	}
}
@media (min-width: 992px) and (max-width: 1129px) {
	.header {
		.navbar {
			.collapse1 {
				.navbar-nav {
					a.nav-link {
						color: #00011c;
						font-size: 14px;
						line-height: 24px;
						padding: 6px 8px;
						white-space: nowrap;
						font-weight: 500;
					}
				}
			}
		}
	}
}
@media (min-width: 1100px) {
	.theme-div {
		padding-right: 0 !important;
		margin-top: 5px;
	}
}
@media (min-width: 992px) {
	.header {
		.navbar {
			.navbar-brand {
				min-width: 133px;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
@media (min-width: 996px) {
	.mobile-menu {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 1366px) {
	.header {
		.navbar {
			height: 80px;
			.navbar-brand {
				min-width: 91px;
				max-width: 91px !important;

				img {
					width: 100%;
					height: auto;
				}
			}
			.collapse1 {
				.navbar-nav {
					.menu-items {
						a.nav-link {
							font-size: 12px !important;
						}
					}
				}
			}
			.submenu {
				.submenu-inner {
					.sub-menu-content {
						margin-bottom: 10px;
						a {
							.sub-menu-title {
								font-size: 14px;
								line-height: 21px;
							}
							&:hover {
								.sub-menu-title {
									font-size: 14px;
									line-height: 21px;
								}
							}
							p {
								font-size: 12px;
								line-height: 18px;
							}
							padding: 12px 12px 12px 12px;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 992px) and (max-width: 1024px) {
	.header {
		.navbar {
			height: 70px;
			.navbar-brand {
				min-width: 91px;
				max-width: 91px !important;

				img {
					width: 100%;
					height: auto;
				}
			}
			.collapse1 {
				.navbar-nav {
					.menu-items {
						a.nav-link {
							font-size: 12px !important;
							line-height: 30px !important;
						}
					}
				}
			}
			.submenu {
				.submenu-inner {
					.sub-menu-content {
						margin-bottom: 10px;
						a {
							.sub-menu-title {
								font-size: 14px;
								line-height: 21px;
							}
							&:hover {
								.sub-menu-title {
									font-size: 14px;
									line-height: 21px;
								}
							}
							p {
								font-size: 12px;
								line-height: 18px;
							}
							padding: 12px 12px 12px 12px;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.header {
		.navbar {
			height: 80px;
			.navbar-brand {
				min-width: 108px;
				max-width: 108px !important;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.mobile-menu {
		.accordion-button {
			font-size: 18px;
			line-height: 21px;
		}
		.menu-item {
			li {
				a {
					.submenu-heading {
						font-size: 18px;
						line-height: 27px;
					}
				}
			}
		}
	}
}
@media (min-width: 250px) and (max-width: 767px) {
	.header {
		.navbar {
			height: 80px;
			.navbar-brand {
				min-width: 108px;
				max-width: 108px !important;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
		.mobile-menu {
			.accordion-button {
				&:not(.collapsed) {
					&::after {
						content: "";
						background-image: url(../../assets/images/down-arrow.svg) !important;
						width: 16px;
						height: 16px;
						background-size: cover;
						background-position: center center;
					}
				}
			}
		}
	}
	.mobile-menu {
		.accordion-button {
			font-size: 18px;
			line-height: 21px;
			&::after {
				content: "";
				background-image: url(../../assets/images/grey-right-icon.svg);
				width: 16px;
				height: 16px;
				background-size: cover;
				background-position: center center;
			}
		}
		.menu-item {
			li {
				a {
					.submenu-heading {
						font-size: 18px;
						line-height: 27px;
						font-weight: 500;
					}
				}
			}
		}
		.menu-item-2 {
			li {
				p {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}
}
@media only screen and (min-width: 834px) and (orientation: portrait) {
	.mobile-menu {
		.menu-item {
			li {
				a {
					.submenu-heading {
						font-size: 16px;
						line-height: 25px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 1400px) and (min-width: 1025px) {
	.header {
		.navbar {
			.navbar-brand {
				min-width: 108px;
				max-width: 108px !important;
			}
			.collapse1 {
				.navbar-nav {
					.menu-items {
						a.nav-link {
							font-size: 16px !important;
							line-height: 33px !important;
						}
					}
					a.nav-link {
						&:after {
							bottom: 0;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1366px) {
	.header {
		.navbar {
			.submenu {
				.submenu-inner {
					.sub-menu-content {
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1440px) and (max-height: 800px) and (min-width: 992px) {
	.header {
		.navbar {
			.submenu {
				.submenu-inner {
					.sub-menu-content {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.technologies-section {
		&:nth-child(odd) {
			.technologies-feature-card {
				.row {
					flex-direction: column-reverse;
				}
			}
		}
		&:nth-child(even) {
			.technologies-feature-card {
				.row {
					flex-direction: column !important;
				}
			}
		}
	}
}
@media (max-width: 575px) {
	.header {
		.navbar {
			.navbar-collapse {
				.navbar-nav {
					.btn-primary {
						height: 42px;
						padding: 12px 30px !important;
					}
				}
			}
		}
	}
}
@media (min-width: 340px) and (max-width: 575px) {
	.service-middle {
		.technologies-section {
			.technologies-inner {
				.technologies-cantent {
					.technologies-name {
						ul.nav-tabs {
							flex-direction: column;
						}
					}
				}
			}
		}
	}
}
@media ((min-width: 992px) and (max-width: 1200px)) {
	.header {
		.navbar {
			.collapse1 {
				.navbar-nav {
					.menu-items {
						a.nav-link {
							font-size: 14px !important;
							line-height: 30px !important;
						}
					}
					a.nav-link {
						padding: 8px 5px;
					}
				}
			}
		}
	}
	.theme-light {
		.btn-toggle {
			margin-left: 0 !important;
		}
	}
	.theme-dark {
		.btn-toggle {
			margin-left: 0 !important;
		}
	}
}
@media ((min-width: 1112px) and (orientation: landscape)) {
	.header {
		.navbar {
			.collapse1 {
				.navbar-nav {
					a.nav-link {
						padding: 8px 8px !important;
					}
				}
			}
		}
	}
}
@media (max-width: 1366px) {
	.header {
		.navbar {
			.collapse1 {
				.navbar-nav {
					.menu-items {
						a.nav-link {
							font-size: 14px !important;
							line-height: 20px !important;
						}
					}
				}
			}
		}
	}
}
@media ((min-width: 992px) and (max-width: 1024px)) {
	.homepage-middle {
		.process-section {
			.process-list {
				ul {
					li {
						&:first-child {
							&::after {
								right: -64px;
							}
						}
					}
				}
			}
		}
	}
}

.header .navbar .submenu .submenu-inner .right-side-submenu .submenu-icon-image img {
	width: 100%;
}

.header-home .navbar {
	background: transparent !important;
}

.header.header-home .navbar .collapse1 .navbar-nav a.nav-link {
	color: #fff;
}

.header.header-home.is-sticky .navbar .collapse1 .navbar-nav a.nav-link {
	color: #00011c;
}

.header-home.is-sticky .navbar {
	background: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%) !important;
}

// img
.header .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.header .navbar .navbar-brand img:nth-child(2) {
	display: none;
}

.header.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.header.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: none;
}

.theme-light .header.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.theme-light .header.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: none;
}

.theme-dark .header.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.theme-dark .header.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: block;
}

.header-home .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.header-home .navbar .navbar-brand img:nth-child(2) {
	display: block;
}

.header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.header-home.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: block;
}

.theme-light .header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.theme-light .header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block;
}

.theme-dark .header-home.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: none;
}

.theme-dark .header-home.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: block;
}



.theme-light .header-case .navbar {
    background: #F4FAFE !important;
}

.theme-dark .header-case .navbar {
    background: #111 !important;
}

.header.header-case .navbar .collapse1 .navbar-nav a.nav-link {
	color: #00011c;
}

.header.header-case.is-sticky .navbar .collapse1 .navbar-nav a.nav-link {
	color: #00011c;
}

.header-case.is-sticky .navbar {
	background: linear-gradient(283.13deg, #f2f9fe, #f8fcff 113.66%) !important;
}

.header-case.is-sticky .navbar .navbar-brand img:nth-child(1) {
	display: block !important;
}

.header-case.is-sticky .navbar .navbar-brand img:nth-child(2) {
	display: none !important;
}

.header-case .navbar .navbar-brand img:nth-child(1) {
	display: block !important;
}

.header-case .navbar .navbar-brand img:nth-child(2) {
	display: none !important;
}

// new design sub menu

@media (min-width: 992px) {
.submenu-new-design {
	// margin-top: 16px;
	// margin-bottom: 16px;
	background: linear-gradient(96.18deg, #78C4FB 0%, #0086E6 100%) !important;
	border-radius: 16px;
	padding: 24px;
	color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// border: 2px solid red;
	.top-menu-title {
		margin-bottom: 20px;
		
		h3, p.h3 {
			font-size: 24px;
			font-weight: 600;
			line-height: 34.56px;
			color: #fff;
			margin-bottom: 12px;
		}

		p {
			font-size: 16px;
			font-weight: 500;
			line-height: 22.4px;
			color: #fff;
		}
	}
}

.right-menu-main {
	// border: 2px solid red;
	// height: calc(100% - 94px);
	height: 100%;
}

.right-menu-root {
	// border: 2px solid red;
	height: calc(100% - 0px);

}

.row-height {
	height: calc(100% - 65px);
}

.header .navbar .submenu .submenu-inner .menu-heading {
	margin-bottom: 0;
}

.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
	line-height: 46px;

}

.header .navbar .submenu .submenu-inner{
	padding-top: 16px;
	padding-bottom: 16px;

}

.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu {
	background-color: #fff;
	margin-bottom: 0;
	padding: 16px;
}

.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-icon-image img {
	min-height: 160px !important;
	border-radius: 12px;
	margin-bottom: 0;
}
.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-icon-image {
	border-radius: 12px;
	margin-bottom: 12px;
}
.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-requred p {
	font-size: 16px;
	line-height: 22px;
	// margin-bottom: 8px;
	color:  rgba(4, 4, 5, 1);
}

.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content p {
	color: rgba(85, 92, 96, 1);
	font-size: 14px;
	line-height: 20px;
	&:hover {
		color: rgba(85, 92, 96, 1) !important;
	}

}
.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content {
	color: rgba(85, 92, 96, 1);
	font-size: 14px;
	line-height: 20px;
	&:hover {
		color: #000000 !important;
	}
}

.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new {
	display: flex;
	align-items: center;
	justify-content: space-between;
	p {
		display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	}

	span, a {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: right;
		color: #555C60;
		white-space: nowrap;
		a {
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: right;
			color: #555C60;
			white-space: nowrap;
		}
	}
}
.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
	color: #040405;
	font-size: 32px;
	font-weight: 600;
	line-height: 46.08px;


}

.header .navbar .submenu .submenu-inner .sub-menu-content span p {
	font-weight: 600;
    font-size: 16px;
    line-height: 26px;
	margin-bottom: 0 !important;
}

.header .navbar .submenu .submenu-inner .sub-menu-content > span  {
	display: block;
	cursor: pointer;
}

.header .navbar .submenu .submenu-inner .sub-menu-content {
	margin-bottom: 1px;
}

.header .navbar .submenu .submenu-inner .sub-menu-content a, .header .navbar .submenu .submenu-inner .sub-menu-content span {
	padding: 16px;
}
.header .navbar .submenu .submenu-inner .sub-menu-content a.active, .header .navbar .submenu .submenu-inner .sub-menu-content a:hover, 
.header .navbar .submenu .submenu-inner .sub-menu-content span.active, .header .navbar .submenu .submenu-inner .sub-menu-content span:hover  {
	background-color: #F4FAFE !important;
	color: #0086E6 !important;
	border-radius: 8px;
	padding: 16px;
	position: relative;
	display: block;
	p {
		padding: 0 !important;
		color: #0086E6 !important;
		margin-bottom: 0;
	}

	&::before {
		content: "";
		position: absolute;
		height: 14px;
		width: 14px;
		background-image: url(../../assets/images/icons/custom-arrow.svg) !important;
		background-repeat: no-repeat;
		right: 15px;
		top: 18px;
		background-size: contain;
		background-position: center center;
		transform: rotate(0deg) translateX(0) !important;
		transition: all 0.3s linear;
	}
}

.card-reviews .sub-menu-new h3 a, .card-reviews .sub-menu-new p.h3 a {
	position: relative;
	text-decoration: underline !important;
	color: #040405;
}

.card-reviews .sub-menu-new h3 a::after, .card-reviews .sub-menu-new p.h3 a::after {
	content: "";
	position: unset;
	height: 14px;
	width: 14px;
	background-image: url(../../assets/images/icons/arrow-menu-black.svg) !important;
	background-repeat: no-repeat;
	right: -18px;
	top: 5px;
	background-size: contain;
	background-position: center center;
	transition: all 0.3s linear;
	opacity: 1;
	display: inline-block;
}

.card-reviews {
	border-radius: 12px;
	background-color: #F4FAFE;
	padding: 24px;
	margin-bottom: 0;
	flex: 1 1 auto;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;

	.sub-menu-new {

	h3, p.h3 {
		font-size: 20px;
		font-weight: 600;
		line-height: 28px;
		color: #040405;
		margin-bottom: 12px;
	}

	p {
		font-size: 14px;
		line-height: 19px;
		color: #555C60;
	}

	.menu-sublink {
		font-size: 16px;
		font-weight: 500;
		line-height: 23.04px;
		padding: 12px 0;
		display: block;
		margin-bottom: 1px;
		color: #040405;

		&:hover {
			color: #0086E6 !important;
		}
	}
 }

}

.card-reviews .testimonial {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 16px;
	margin-top: 15px;
}

.card-reviews .testimonial .stars span {
    color: #a2cf40; /* Star color */
    font-size: 24px;
	margin-bottom: 12px;

	i {
		margin-right: 6px;
		img {
			width: 16px;
			height: 16px;
		}
	}
}

.card-reviews .testimonial h3, .card-reviews .testimonial p.h3  {
    font-size: 16px;
	line-height: 22px;
    color: #333;
    margin: 0 0 8px 0;
    font-weight: 600;
}

.card-reviews .testimonial .description {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
	margin-bottom: 12px;
}

.card-reviews .testimonial .author {
    font-size: 14px;
	line-height: 19px;
    color: #333;
    font-weight: 400;
	text-align: left;
	margin-bottom: 0;

	strong {
		margin-right: 10px;
		font-size: 16px;
		line-height: 22px;
	}
}


.seemore-sublink {
	font-size: 14px;
	font-weight: 600;
	line-height: 20.16px;
	color: #0086E6;
	padding-left: 28px !important;
	display: block;
	margin: 20px 0;

	&:hover {
		color: #acdb37 !important;
	}
}

.menu-heading-new {
	.seemore-sublink {
		font-size: 14px;
		font-weight: 600 !important;
		line-height: 20.16px;
		color: #0086E6 !important;
		padding-left: 28px !important;
		display: block;
		margin: 20px 5px;
	
		&:hover {
			color: #acdb37 !important;
		}
	}
}

.header .navbar .submenu .submenu-inner {
	// padding-bottom: 16px;
}

.header-menu-message {
	border-radius: 12px;
	background-color: #F4FAFE;
	padding: 24px;
	margin-bottom: 0;
	width: 100%;

	 h3, p.h3 {
		color: #040405;
		font-size: 20px;
		font-weight: 600;
		line-height: 28.8px;
		text-align: left;
		margin-bottom: 12px;
	}
	
	p {
		font-size: 14px;
		color: #555C60;
		line-height: 1.6;
		margin-bottom: 12px;
	}
}

.submenu-full-coumnm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	// height: 100%;
	// .bottom-div {
	// 	margin-top: auto;
	// }
}

.header .navbar .submenu .submenu-inner .section-main-submenu {
	margin-bottom: 0 !important;
	// padding-bottom: 20px !important;
	border: 0 !important;
	// height: 100%;

	// border: 2px solid red !important;
}

// .header .menu-heading-new {
// 	max-height: 94px;
// }
}

@media (min-width: 992px) {
.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new {
	max-height: 94px;
}

.top-div {
	width: 100%;
	margin-bottom: 15px;
}

.bottom-div{
	width: 100%;
	height: auto;
}
}

@media (min-width: 992px) {
	
	// .submenu-full-coumnm {
	// 	min-height: 80vh;
	// }

	// .right-menu-main {
	// 	min-height: calc(80vh - 100px);
		
	// }
	
	.right-menu-root {
		min-height: calc(85vh);
		max-height: calc(85vh);
	}
	
	.submenu-full-coumnm {
		min-height: calc(85vh - 85px);
		max-height: calc(85vh - 85px);
	}
	
}

@media (min-width: 1600px) {
	
// .right-menu-main {
// 	min-height: 592px;
// }

// .submenu-full-coumnm {
// 	height: 570px;
// }

// .row-height {
// 	min-height: 70vh;
// }

.right-menu-root {
	min-height: calc(75vh);
	max-height: calc(75vh);
}

.submenu-full-coumnm {
	min-height: calc(75vh - 100px);
	max-height: calc(75vh - 100px);
}


.header .navbar .submenu .submenu-inner .sub-menu-content a .sub-menu-title {
	white-space: nowrap;
}

.header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm a .sub-menu-title {
	white-space: normal;
}

}

.seemore-sublink {
	margin: 0;
}

.seemore-sublink {
	padding-left: 22px !important;
}

.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new > a {
	margin: 0 !important;
}

.card-reviews .sub-menu-new a.active{
    color: #0086E6 !important;
}


@media (min-width: 992px) and (max-width: 1600px){

	.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
		font-size: 24px;
		line-height: 32px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a {
		padding: 12px 12px !important;
	}

	.card-reviews {
		padding: 18px;
	}

	.header-menu-message {
		padding: 18px;
	}

	.card-reviews .sub-menu-new p {
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}

	.header-menu-message h3, .header-menu-message p.h3 {
		font-size: 18px;
	}

	.card-reviews .sub-menu-new h3, .card-reviews .sub-menu-new p.h3 {
		font-size: 18px;
        font-weight: 600;
        line-height: 26px;
	}

	.card-reviews .sub-menu-new .menu-sublink {
		font-size: 14px;
		line-height: 21px;
		padding: 10px 0;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a::before {
		top: 16px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a .sub-menu-title {
		white-space: nowrap;
		margin: 0 !important;
	}



	.header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm a .sub-menu-title {
		white-space: normal;
	}


	.header .navbar .submenu .submenu-inner .sub-menu-content span  {
		padding: 12px 12px !important;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content span p {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 0 !important;
	}
	
}


@media (min-width: 992px) and (max-width: 1600px) {

	.right-menu-root {
		min-height: calc(80vh);
		max-height: calc(80vh);
	}
	
	.submenu-full-coumnm {
		min-height: calc(80vh - 80px);
		max-height: calc(80vh - 80px);
	}

    .header .navbar .submenu .submenu-inner .sub-menu-content a {
        padding: 12px 10px !important;
		font-size: 14px;
		line-height: 24px;
		font-weight: 500;
    }

	.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
		font-size: 22px;
		line-height: 26px;
	 }

	.submenu-new-design .top-menu-title h3, .submenu-new-design .top-menu-title p.h3 {
		font-size: 20px;
		line-height: 28px;
	}

	.submenu-new-design .top-menu-title p {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}
}


@media 
  (max-device-width: 1280px) 
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {
	
	.right-menu-root {
		min-height: calc(75vh);
		max-height: calc(75vh);
	}
	
	.submenu-full-coumnm {
		min-height: calc(75vh - 100px);
		max-height: calc(75vh - 100px);
	}

	.header .navbar .collapse1 .navbar-nav a.nav-link {
		padding: 6px 10px !important;
		font-size: 14px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a {
        padding: 9px 10px !important;
    }

	.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
		font-size: 24px;
		line-height: 26px;
	}

	.submenu-new-design {
		padding: 16px;
	}

	.card-reviews {
		padding: 16px;
		height: 100%;
	}

	.submenu-new-design .top-menu-title h3, .submenu-new-design .top-menu-title p.h3 {
		font-size: 20px;
		line-height: 28px;
	}

	.submenu-new-design .top-menu-title p {
		font-size: 12px;
		font-weight: 500;
		line-height: 16.8px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content p {
		font-size: 12px;
		font-weight: 400;
		line-height: 16.8px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a::before {
		top: 12px !important;
	}

	.seemore-sublink {
		padding-left: 22px !important;
	}

	.card-reviews .sub-menu-new h3 a, .card-reviews .sub-menu-new p.h3 a {
		display: inline-block !important;
	}
}

@media (min-width: 1920px) {

	.right-menu-root {
		min-height: calc(700px);
		max-height: calc(700px);
	}
	
	.submenu-full-coumnm {
		min-height: calc(700px - 100px);
		max-height: calc(700px - 100px);
	}

	.row-height {
		height: calc(100% - 80px);
	}

	.seemore-sublink {
		padding-left: 28px !important;
	}
}
@media (max-width: 992px) {
	.menu-overlay {
		display: none !important;
	}
}

.card-reviews .sub-menu-new a.menu-sublink {
	position: relative;
	text-decoration: underline !important;
}
.card-reviews .sub-menu-new > a.menu-sublink:after {
	content: "";
	position: unset;
	height: 14px;
	width: 14px;
	background-image: url(../../assets/images/icons/arrow-menu-black.svg) !important;
	background-repeat: no-repeat;
	right: 0;
	display: inline-block;
	top: 5px;
	background-size: contain;
	background-position: center center;
	transition: all 0.3s linear;
	opacity: 1;
	margin-left: 5px;
}


.card-reviews .sub-menu-new > a.menu-sublink:hover {
	color: #040405 !important;
	text-decoration: underline !important;
}

.card-reviews .sub-menu-new p a {
	color: #040405 !important;
	text-decoration: none !important;
}

.card-reviews .sub-menu-new > a.menu-sublink:hover {
	color: #0086E6  !important;
	text-decoration: underline !important;
}

.card-reviews .sub-menu-new > a.menu-sublink:hover::after, .card-reviews .sub-menu-new > a.active::after {
	background-image: url(../../assets/images/icons/blue-arrow.svg) !important;
}

.card-reviews .sub-menu-new h3 a:hover {
	color: #0086E6  !important;
	text-decoration: underline !important;
}

.card-reviews .sub-menu-new h3 a:hover::after {
	background-image: url(../../assets/images/icons/blue-arrow.svg) !important;
}

.card-reviews .sub-menu-new p.h3 a:hover {
	color: #0086E6  !important;
	text-decoration: underline !important;
}

.card-reviews .sub-menu-new p.h3 a:hover::after {
	background-image: url(../../assets/images/icons/blue-arrow.svg) !important;
}

.header .navbar .submenu .submenu-inner .sub-menu-content  .header-menu-message h3 a,
 .header .navbar .submenu .submenu-inner .sub-menu-content  .header-menu-message p.h3 a  {
	position: relative;
	cursor: pointer;
	text-decoration: underline !important;
}

.header-menu-message h3 a:hover, .header-menu-message p.h3 a:hover {
	color: #0086E6  !important;
	text-decoration: underline !important;
}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p a {
	color: #555C60 !important;
	text-decoration: none !important;

}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p a,
 .header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message h3 a,
 .header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p.h3 a {
	padding: 0 !important;
	background-color: transparent !important;
	background: transparent !important;

	&::before {
		display: none !important;
	}
}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message h3 a,
 .header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p.h3 a {
	color: #040405 !important;
	font-size: 20px;
	font-weight: 600;
	line-height: 28.8px;
	text-align: left;
	margin-bottom: 12px;
}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message h3 a:hover,
 .header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p.h3 a:hover {
	color: #0086E6  !important;
}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p a {
	color: #555C60 !important;
}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message h3 a::after,
 .header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p.h3 a::after {
		content: "";
		position: unset !important;
		height: 14px;
		width: 14px;
		background-image: url(../../assets/images/icons/arrow-menu-black.svg) !important;
		background-repeat: no-repeat;
		right: -18px;
		top: 5px;
		display: inline-block !important;
		margin-left: 5px;
		background-size: contain;
		background-position: center center;
		transition: all 0.3s linear;
		opacity: 1;
}

.header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message h3 a:hover::after,
 .header .navbar .submenu .submenu-inner .sub-menu-content .header-menu-message p.h3 a:hover::after  {
	background-image: url(../../assets/images/icons/blue-arrow.svg) !important;
}

@media (min-width: 992px) and (max-width: 1600px) {
    .header-menu-message h3 a, .header-menu-message p.h3 a {
        font-size: 18px !important;
    }
}

.header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm a {
	padding-right: 30px !important;
}

.header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm .top-div a::before {
	top: 0 !important;
	height: 100% !important;
	display: flex;
    align-items: center;
	top: 50% !important;
	transform: translateY(-50%) !important;
	// opacity: 1;
}

@media (max-width: 1366px) and (min-width: 768px) {
	.right-menu-root {
		min-height: calc(75vh);
		max-height: calc(75vh);
	}
	
	.submenu-full-coumnm {
		min-height: calc(75vh - 100px);
		max-height: calc(75vh - 100px);
	}

	.row-height {
		height: calc(100% - 80px);
	}

	// fonts
	

	.header .navbar .collapse1 .navbar-nav a.nav-link {
		padding: 8px 10px !important;
		font-size: 14px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a {
        padding: 9px 10px !important;
    }

	.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
		font-size: 20px;
		line-height: 24px;
	}

	.submenu-new-design {
		padding: 12px;
	}

	.card-reviews {
		padding: 14px;
		height: 100%;
	}

	.submenu-new-design .top-menu-title h3, .submenu-new-design .top-menu-title p.h3 {
		font-size: 18px;
		line-height: 24px;
	}

	.submenu-new-design .top-menu-title p {
		font-size: 12px;
		font-weight: 500;
		line-height: 15.5px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content p {
		font-size: 12px;
		font-weight: 400;
		line-height: 15.5px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a::before {
		top: 12px !important;
	}

	.seemore-sublink {
		padding-left: 22px !important;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm a .sub-menu-title {
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-requred p {
        font-size: 14px;
        line-height: 18px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content {
        font-size: 12px;
        line-height: 18px;
    }

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-icon-image img {
		min-height: 110px !important;
	}

	.submenu-new-design .top-menu-title {
		margin-bottom: 0;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu {
		padding: 12px;
	}

	.card-reviews .sub-menu-new .menu-sublink {
		padding: 6px 0 !important;
	}

	.card-reviews .sub-menu-new {
		overflow-y: auto !important;
		height: calc(75vh - 100px);
		padding-right: 10px;

		&::-webkit-scrollbar {
			width: 5px;
			border-radius: 6px;
			margin-left: -10px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 6px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}

	.header-menu-message {
		padding: 14px !important;

		p.h3 {
			margin-bottom: 5px !important;
			line-height: 22px !important;

			a {
				margin-bottom: 0 !important;
				line-height: 22px !important;
			}
		}
	}

	.header-menu-message .description {
		overflow-y: auto !important;
		height: 70px;
		padding-right: 10px;
		margin-bottom: 5px;
		font-size: 14px;
        line-height: 1.35;

		 a {
			font-size: 13px;
			color: #555C60;
			line-height: 1.35;
			margin-bottom: 0;
		}

		&::-webkit-scrollbar {
			width: 5px;
			border-radius: 6px;
			margin-left: -10px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 6px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}

@media (max-width: 1280px) and (min-width: 768px) {
	.right-menu-root {
		min-height: calc(75vh);
		max-height: calc(75vh);
	}
	
	.submenu-full-coumnm {
		min-height: calc(75vh - 100px);
		max-height: calc(75vh - 100px);
	}

	.row-height {
		height: calc(100% - 80px);
	}

	// fonts
	

	.header .navbar .collapse1 .navbar-nav a.nav-link {
		padding: 8px 10px !important;
		font-size: 14px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a {
        padding: 9px 10px !important;
    }

	.header .navbar .submenu .submenu-inner .menu-heading.menu-heading-new p {
		font-size: 20px;
		line-height: 24px;
	}

	.submenu-new-design {
		padding: 14px;
	}

	.card-reviews {
		padding: 14px;
		height: 100%;
	}

	.submenu-new-design .top-menu-title h3, .submenu-new-design .top-menu-title p.h3 {
		font-size: 18px;
		line-height: 26px;
	}

	.submenu-new-design .top-menu-title p {
		font-size: 12px;
		font-weight: 500;
		line-height: 16.8px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content p {
		font-size: 12px;
		font-weight: 400;
		line-height: 16.8px;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content a::before {
		top: 12px !important;
	}

	.seemore-sublink {
		padding-left: 22px !important;
	}

	.header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm a .sub-menu-title {
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-requred p {
        font-size: 14px;
        line-height: 19px;
	}

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .menu-blog-content {
        font-size: 12px;
        line-height: 20px;
    }

	.header .navbar .submenu .submenu-inner .submenu-new-design .right-side-submenu .submenu-icon-image img {
		min-height: 120px !important;
	}
	
	.card-reviews .sub-menu-new .menu-sublink {
		padding: 6px 0 !important;
	}

	.card-reviews .sub-menu-new {
		overflow-y: auto !important;
		height: calc(75vh - 90px);
		padding-right: 10px;

		&::-webkit-scrollbar {
			width: 5px;
			border-radius: 6px;
			margin-left: -10px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 6px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}

	.header-menu-message {
		padding: 14px !important;

		p.h3 {
			margin-bottom: 5px !important;
			line-height: 22px !important;

			a {
				margin-bottom: 0 !important;
				line-height: 22px !important;
			}
		}
	}

	.header-menu-message .description {
		overflow-y: auto !important;
		height: 70px;
		padding-right: 10px;
		margin-bottom: 5px;
		font-size: 14px;
        line-height: 1.35;

		 a {
			font-size: 13px;
			color: #555C60;
			line-height: 1.35;
			margin-bottom: 0;
		}

		&::-webkit-scrollbar {
			width: 5px;
			border-radius: 6px;
			margin-left: -10px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 6px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}


@media (min-width: 1500px) {
	.card-reviews .sub-menu-new .menu-sublink {
		padding: 12px 0;
	}
}

@media (min-width: 992px) {
	.card-reviews .sub-menu-new .menu-sublink {
		padding: 8px 0;
		line-height: 18px;
	}
}

// 28-11

.header-menu-message .description a {
	font-weight: 400 !important;
}

@media (min-width: 992px) and (max-width: 1600px) {
	header .navbar .submenu .submenu-inner .submenu-full-coumnm .top-div {
		a, p {
			font-size: 14px !important;
			line-height: 22px !important;
			font-weight: 500 !important;
		}

		a {
			// background-color: green;
			padding: 12px 12px !important;

			&:hover::before {
				height: 12px !important;
				width: 12px !important;
				background-size: 100% !important;
			}
		}
	}

	.submenu-new-design {
		height: calc(100vh - 130px);
	}

	header .navbar .submenu .submenu-inner .submenu-full-coumnm {
		height: 100%;
		min-height: calc(100vh - 205px);
		max-height: calc(100vh - 205px);
	}

	.card-reviews .sub-menu-new {
		overflow-y: auto !important;
		height: 100% !important;
	}

}
@media (max-width: 1280px) and (min-width: 720px) {
	.submenu-new-design {
		height: calc(100vh - 120px);
	}
}

@media (max-width: 1280px) and (min-width: 768px) {
	
	.submenu-new-design {
		height: calc(100vh - 120px);
	}

	.submenu-new-design .top-menu-title h3, .submenu-new-design .top-menu-title p.h3 {
        line-height: 22px;
		margin-bottom: 5px;
		font-size: 16px !important;
    }

	header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm {
		height: auto;
		min-height: calc(100vh - 205px);
		max-height: calc(100vh - 205px);

		.top-div{
			height: auto;	
			a, p {
				font-size: 14px !important;
				line-height: 16px !important;
				font-weight: 400 !important;
			}
		
			a {
				padding: 9.5px 12px !important;
				font-weight: 400 !important;
			}
		} 
	}

	.header-menu-message {
		padding: 12px !important;
		padding-bottom: 8px !important;
	}

	.header-menu-message .description  {
		height: 46px;
		margin-bottom: 5px;

		a {
			font-size: 12px !important;
			line-height: 16px !important;
		}
	}
}


@media (max-width: 1366px) and (min-width: 768px) {
	.submenu-new-design {
		height: calc(100vh - 120px);
	}
}



@media screen and (max-width: 1366px) {

	header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm {
		height: auto;

		.top-div{
			height: auto;	
			a, p {
				font-size: 13.5px !important;
				line-height: 16px !important;
				font-weight: 400 !important;
			}
		
			a {
				padding: 8.5px 12px !important;
				font-weight: 400 !important;
			}
		} 
	}


	.header-menu-message .description a {
		line-height: 1.2 !important;
		font-size: 14px;
	}

	.submenu-new-design {
		height: calc(100vh - 120px);

		//  .top-menu-title {
		// 	height: calc(180px - 0px);
		// 	// background-color: red;
		// 	padding-right: 10px;
		// 	overflow-y: auto !important;
	
		// 	&::-webkit-scrollbar {
		// 		width: 5px;
		// 		border-radius: 6px;
		// 		margin-left: -10px;
		// 	}
	
		// 	&::-webkit-scrollbar-track {
		// 		background: transparent;
		// 		border-radius: 6px;
		// 	}
	
		// 	&::-webkit-scrollbar-thumb {
		// 		background: rgba(255, 255, 255,0.5);
		// 		border-radius: 6px;
		// 	}
			
		// 	&::-webkit-scrollbar-thumb:hover {
		// 		background: #555;
		// 	}
		//  }
	}
}

@media (max-width: 1366px) and (min-width: 768px) {
    header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm {
		height: auto;

		.top-div{
			height: auto;	
			a, p {
				font-size: 14px !important;
				line-height: 16px !important;
				font-weight: 400 !important;
			}
		
			a {
				padding: 9px 12px !important;
				font-weight: 400 !important;
			}
		} 
	}

	.submenu-new-design .top-menu-title p {
		font-size: 12.5px;
		line-height: 1.2;
	}
}

@media screen and (max-height: 720px)  {
	.submenu-new-design {
		height: calc(100vh - 100px);
	}

	header .navbar .submenu .submenu-inner .sub-menu-content.submenu-full-coumnm {
		height: auto;

		.top-div{
			height: auto;	
			a, p {
				font-size: 13px !important;
				line-height: 16px !important;
				font-weight: 400 !important;
			}
		
			a {
				padding: 8.5px 12px !important;
				font-weight: 400 !important;
			}
		} 
	}

		.header-menu-message .description a {
		line-height: 1.3 !important;
		font-size: 14px;
	}
}