@media (max-width:768px) {
  .container {
    max-width: 100% !important
  }

  .believe-section .believe-box .believe-content>p {
    min-height: 90px
  }

  .aboutus-middle .vibe-section .vibe-main-box .vibe-text p {
    font-size: 14px;
    line-height: 24px;
    max-width: initial
  }
}

@media (max-width:767.99px) {
  .process-section .process-inner .m-none {
    display: none
  }

  .process-section .process-inner.mobile-process-inner {
    display: block
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li {
    padding-bottom: 60px;
    position: relative;
    text-align: center
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li::after {
    content: "";
    top: auto !important;
    bottom: 5px;
    background-image: url(../../assets/images/grey-vertical-line.svg);
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 8px;
    position: absolute
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li:last-child {
    padding-bottom: 0
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li:last-child::after {
    display: none
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li.list-active .process-list-title,
  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li:hover .process-list-title {
    color: #040405;
    font-weight: 700
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li.list-active p,
  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li:hover p {
    color: #040405 !important
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li.list-active::after,
  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li:hover::after {
    background-image: url(../../assets/images/blue-vertical-image.svg) !important
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li.list-active .process-text,
  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li:hover .process-text {
    max-height: 1000px;
    overflow: hidden;
    opacity: 1
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li .process-list-title {
    font-size: 28px !important;
    line-height: 42px;
    margin-bottom: 12px;
    color: #91959b
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li .process-text {
    max-height: 0;
    overflow: hidden;
    opacity: 0
  }

  .process-section .process-inner.mobile-process-inner .mobile-process-list ul li p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #91959b !important
  }

  .process-section .process-inner.desktop-process-inner {
    display: none
  }

  .homepage-middle .process-section .process-inner.mobile-process-inner .mobile-process-list ul li .process-list-title {
    font-weight: 600
  }

  .testimonial-section .testimonial-text-box .swiper-slide {
    margin: auto !important
  }

  .testimonial-section .testimonial-text-box .swiper-slide .testimonial-main-box {
    transform: inherit
  }

  .testimonial-slider .testimonial-text-box .swiper-slide {
    padding: 0 12px
  }

  .lr-padding {
    padding: 0 0 !important
  }

  .testimonial-section .swiper-watch-progress .swiper-slide {
    padding: 0 12px;
    margin: auto !important
  }
}

@media (max-width:767px) {
  .about-section {
    padding: 40px 0 20px 0
  }

  .about-section .heading {
    margin-bottom: 40px
  }

  .about-section .heading p {
    font-size: 14px;
    line-height: 18px
  }

  .order-sm-0 {
    order: 1 !important;
    margin-bottom: 10px
  }

  .order-sm-1 {
    order: 0 !important;
    margin-bottom: 25px
  }

  .order-sm-2 {
    order: 2 !important;
    margin-bottom: 20px
  }

  .about-section .work-image {
    border-radius: 10px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-heading {
    margin-bottom: 10px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-heading h2 {
    font-size: 18px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name {
    margin-bottom: 20px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name ul {
    padding: 0;
    margin: 0
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li a {
    font-size: 12px;
    line-height: 22px;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 2px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group {
    margin-top: 10px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .form-control {
    padding: 10px 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    max-width: 100%
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .input-group-text {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card-div {
    margin-bottom: 15px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-img {
    overflow: hidden;
    max-height: 218px;
    border-top-right-radius: 12px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-img img {
    height: 100%
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    min-height: 30px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 {
    min-height: 20px
  }

  .blog-allstories-section .blog-allstories-inner nav {
    margin-top: 15px
  }

  .blog-allstories-section .blog-allstories-inner nav .pagination .page-item .page-link {
    font-size: 14px;
    padding: 5px 10px
  }

  .blog-banner-section {
    padding: 20px 0 0 0
  }

  .blog-banner-section .blog-banner-inner .blog-banner-content .blog-heading h2 {
    line-height: 35px;
    font-size: 25px
  }

  .blog-banner-section .blog-banner-inner .blog-banner-content .btn-primary {
    padding: 9px 10px
  }

  .blog-topstories-section {
    padding: 20px 0 0 0
  }

  .blog-topstories-section .blog-topstories-inner .blog-topstories-heading {
    margin-bottom: 10px
  }

  .blog-topstories-section .blog-topstories-inner .blog-topstories-heading h2 {
    font-size: 18px
  }

  .blog-topstories-section .blog-topstories-inner .blog-card-div {
    margin-bottom: 15px
  }

  .blog-topstories-section .blog-topstories-inner .blog-card .blog-img {
    max-height: 218px
  }

  .blog-topstories-section .blog-topstories-inner .blog-card .blog-content {
    padding: 15px 15px 15px 15px
  }

  .blog-topstories-section .blog-topstories-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    min-height: unset
  }

  .blog-articles-section .blog-articles-inner .blog-articles-heading {
    margin-bottom: 20px
  }

  .blog-articles-section .blog-articles-inner .blog-articles-heading h2 {
    font-size: 18px;
    line-height: 20px
  }

  .blog-articles-section .blog-articles-inner .blog-card-div {
    margin-bottom: 15px
  }

  .blog-articles-section .blog-articles-inner .blog-card .blog-img {
    max-height: 218px
  }

  .blog-articles-section .blog-articles-inner .blog-card .blog-content {
    padding: 15px 15px 15px 15px
  }

  .blog-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    min-height: unset
  }

  .blog-articles-section .blog-articles-inner nav {
    margin-top: 15px
  }

  .blog-articles-section .blog-articles-inner nav .pagination .page-item .page-link {
    font-size: 14px;
    padding: 5px 10px
  }

  .blog-articles-section .blog-articles-inner .blog-card-div {
    margin-bottom: 15px
  }

  .blog-articles-section .blog-articles-inner .blog-card .blog-img {
    max-height: 218px
  }

  .blog-articles-section .blog-articles-inner .blog-card .blog-content {
    padding: 15px 15px 15px 15px
  }

  .blog-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 10px
  }

  .blog-digital-section {
    padding-top: 20px
  }

  .blog-digital-section .blog-digital-inner .blog-digital-heading h2 {
    font-size: 25px;
    line-height: 35px
  }

  .blog-digital-section .blog-digital-inner ul.blog-digital-date {
    margin-bottom: 10px
  }

  .blog-digital-section .blog-digital-inner ul.blog-digital-social {
    text-align: left;
    margin-bottom: 20px
  }

  .blog-digital-section .blog-digital-inner ul.blog-digital-social li {
    margin-left: 0;
    margin-right: 10px
  }

  .blog-migration-section {
    padding: 20px 0 20px 0
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name>div {
    position: unset;
    top: 100px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name ul.nav-tabs li .nav-link {
    padding: 6px 0;
    font-size: 14px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail {
    padding: 20px 0 20px 0
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h2 {
    font-size: 20px;
    line-height: 30px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h3 {
    font-size: 18px;
    line-height: 20px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail img {
    margin: 10px 0 20px 0
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table th,
  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail table td {
    font-size: 14px;
    line-height: 22px
  }

  .blog-migration-section .blog-migration-inner .blog-about-box {
    padding: 15px 0
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading {
    max-width: 100%;
    justify-content: center;
    margin-bottom: 15px
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading h2 {
    font-size: 18px;
    line-height: 20px
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about {
    padding: 0 0
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about:after {
    border-left: 0
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img {
    margin-bottom: 15px;
    padding-left: 0
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img img {
    width: 75px
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content {
    text-align: center
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content h3 {
    font-size: 18px;
    line-height: 20px
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content p {
    font-size: 14px;
    line-height: 22px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading {
    margin-bottom: 10px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-heading h2 {
    font-size: 16px;
    line-height: 26px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name {
    margin-bottom: 20px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li a {
    font-size: 10px;
    line-height: 20px;
    padding: 3px 8px;
    margin-bottom: 8px;
    margin-right: 2px;
    border-radius: 4px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group {
    margin-top: 10px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group .form-control {
    padding: 10px 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    max-width: 100%
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name .input-group .input-group-text {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-div {
    margin-bottom: 15px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-img {
    overflow: hidden;
    max-height: 218px;
    border-top-right-radius: 12px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-img img {
    height: 100%
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content {
    padding: 12px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content .tag {
    margin-bottom: 5px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content h3 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-content img.csimage {
    height: 15px
  }

  .casestudies-allstories-section .casestudies-allstories-inner nav {
    margin-top: 15px
  }

  .casestudies-allstories-section .casestudies-allstories-inner nav .pagination .page-item .page-link {
    font-size: 14px;
    padding: 5px 10px
  }

  .casestudies-section .casestudies-inner .casestudies-content {
    padding: 40px 10px
  }

  .casestudies-section .casestudies-inner .casestudies-content .blog-heading {
    padding: 20px 15px
  }

  .casestudies-section .casestudies-inner .casestudies-content .blog-heading h2 {
    line-height: 35px;
    font-size: 25px
  }

  .casestudies-section .casestudies-inner .casestudies-content .btn-primary {
    padding: 9px 10px
  }

  .casestudies-stories-section {
    padding: 20px 0 0 0
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading {
    margin-bottom: 10px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2 {
    font-size: 16px;
    line-height: 26px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card-div {
    margin-bottom: 15px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img {
    max-height: 218px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content {
    padding: 12px;
    margin: 8px;
    width: calc(100% - 16px)
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .tag {
    margin-bottom: 5px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content h3 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content img.csimage {
    height: 15px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-heading {
    margin-bottom: 10px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-heading h2 {
    font-size: 18px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name {
    margin-bottom: 20px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name ul {
    padding: 0;
    margin: 0
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name ul li a {
    font-size: 12px;
    line-height: 22px;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 2px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group {
    margin-top: 10px
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .form-control {
    padding: 10px 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    max-width: 100%
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name .input-group .input-group-text {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card-div {
    margin-bottom: 15px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-img {
    overflow: hidden;
    max-height: 218px;
    border-top-right-radius: 12px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-img img {
    height: 100%
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    min-height: 30px
  }

  .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h5 {
    min-height: 20px
  }

  .blog-allstories-section .blog-allstories-inner nav {
    margin-top: 15px
  }

  .blog-allstories-section .blog-allstories-inner nav .pagination .page-item .page-link {
    font-size: 14px;
    padding: 5px 10px
  }

  .demand-education-section {
    padding: 40px 0
  }

  .demand-education-section .demand-inner .heading {
    margin-bottom: 20px
  }

  .demand-education-section .demand-inner .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .demand-education-section .demand-inner .e-demand-box {
    margin-bottom: 20px;
    padding: 0 15px
  }

  .demand-education-section .demand-inner .e-demand-box .e-demand-inner h3 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 10px
  }

  .demand-education-section .demand-inner .e-demand-box .e-demand-inner p {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%
  }

  .services-education-section .heading {
    max-width: 736px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 30px
  }

  .services-education-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500
  }

  .services-education-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700
  }
}

@media (max-width:767px) and (max-width:992px) {
  .services-education-section .heading h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 6px
  }

  .services-education-section .heading h2 {
    font-size: 36px;
    line-height: 48px
  }

  .services-education-section .heading h1 {
    font-size: 40px;
    line-height: 48px
  }
}

@media (max-width:767px) and (max-width:767px) {
  .services-education-section .heading h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 3px
  }

  .services-education-section .heading h2 {
    font-size: 20px;
    line-height: 30px
  }
}

@media (max-width:767px) {
  .industries-tabs-section {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 9999
  }

  .industries-tabs-section .industries-feature-heading {
    margin: 0;
    padding: 20px 0 0 0
  }

  .industries-tabs-section .industries-feature-heading li {
    text-align: center;
    margin-right: 15px
  }

  .industries-tabs-section .industries-feature-heading li a {
    font-size: 10px;
    line-height: 12px;
    padding: 10px 0
  }

  .industries-system-section {
    padding: 20px 0 10px 0
  }

  .industries-system-section .industries-card {
    padding-top: 20px;
    margin-bottom: 20px
  }

  .industries-system-section .industries-card h2 {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 20px
  }

  .industries-system-section .industries-card p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500
  }

  .industries-system-section .development-box {
    margin-bottom: 20px
  }

  .industries-system-section .development-box .development-top {
    flex-direction: column;
    padding: 20px
  }

  .industries-system-section .development-box .development-top .development-img {
    height: 60px;
    width: 60px;
    min-width: 60px
  }

  .industries-system-section .development-box .development-top h3 {
    font-size: 16px;
    line-height: 24px
  }

  .industries-system-section .development-box .development-top p {
    font-size: 16px;
    line-height: 26px
  }

  .industries-system-section .development-box .title {
    margin-left: 0 !important;
    width: 100%
  }

  .industries-system-section .development-box .title h3 {
    font-size: 16px !important;
    line-height: 26px !important
  }

  .industries-system-section .development-box .development-links {
    flex-direction: column;
    width: 100%
  }

  .industries-system-section .development-box .development-bottom {
    flex-direction: column;
    justify-content: flex-start
  }

  .industries-system-section .development-box .development-bottom .development-links li {
    width: 100%
  }

  .industries-system-section .development-box .development-bottom .development-links li a {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    padding: 5px 20px
  }

  .industries-system-section .development-box .development-bottom .development-links li a::after {
    top: 11px
  }

  .industries-relevant-section {
    background-color: #fff
  }

  .industries-relevant-section .heading {
    text-align: center;
    margin-bottom: 20px
  }

  .industries-relevant-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500
  }

  .industries-relevant-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700
  }
}

@media (max-width:767px) and (max-width:992px) {
  .industries-relevant-section .heading h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 6px
  }

  .industries-relevant-section .heading h2 {
    font-size: 36px;
    line-height: 48px
  }

  .industries-relevant-section .heading h1 {
    font-size: 40px;
    line-height: 48px
  }
}

@media (max-width:767px) and (max-width:767px) {
  .industries-relevant-section .heading h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 3px
  }

  .industries-relevant-section .heading h2 {
    font-size: 20px;
    line-height: 30px
  }
}

@media (max-width:767px) {
  .industries-relevant-section .heading h3 {
    text-align: left
  }

  .industries-relevant-section .heading h2 {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 20px
  }

  .industries-relevant-section .step-feature-text h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #040405
  }

  .industries-relevant-section .steps-feature {
    padding: 15px 15px;
    border-radius: 4px;
    margin-bottom: 20px
  }

  .industries-relevant-section .steps-feature .feature-img {
    margin-right: 15px;
    height: 40px;
    min-width: 40px;
    border-radius: 8px;
    width: 40px
  }

  .industries-relevant-section .steps-feature .feature-img img {
    object-fit: contain
  }

  .industries-relevant-section .steps-feature .feature-content h3 {
    font-size: 16px;
    line-height: 20px
  }

  .industries-relevant-section .steps-feature .feature-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0
  }

  .industries-relevant-section .steps-feature:hover .feature-content h3 {
    font-weight: 600
  }

  .industries-relevant-section .feature-green {
    border: 1px solid;
    border-image: linear-gradient(317.72deg, #8abc00 5.24%, #ccfa4e 137.29%) 1
  }

  .industries-relevant-section .feature-green .feature-img {
    background: linear-gradient(283.13deg, rgba(138, 188, 0, .05) 5.56%, rgba(204, 250, 78, .05) 113.66%) !important
  }

  .technologies-feature-latest {
    margin-bottom: 10px
  }

  .technologies-feature-latest .letest-technologies-content h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px
  }

  .technologies-feature-latest .letest-technologies-box {
    padding: 8px;
    margin-bottom: 12px
  }

  .technologies-feature-latest .letest-technologies-box .letest-technologies-img {
    max-width: 120px;
    min-width: 120px;
    height: 90px;
    border-radius: 12px;
    overflow: hidden
  }

  .technologies-feature-latest .letest-technologies-box .letest-technologies-img img {
    width: 100%;
    object-fit: cover;
    height: 100%
  }

  .technologies-feature-latest .letest-technologies-box .letest-technologies-text {
    padding-left: 12px
  }

  .technologies-feature-latest .letest-technologies-box .letest-technologies-text h3 {
    font-size: 14px;
    line-height: 20px;
    min-height: 62px
  }

  .technologies-feature-latest .letest-technologies-box .letest-technologies-text .letest-technologies-date span {
    font-size: 12px;
    line-height: 15px
  }

  .approach-section {
    padding: 40px 0
  }

  .approach-section .approach-inner .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .approach-section .approach-inner .approach-content {
    padding-top: 20px
  }

  .approach-section .approach-inner .approach-content p {
    margin-bottom: 25px;
    line-height: 24px;
    font-size: 16px
  }

  .approach-section .approach-inner .approach-content p:nth-last-child(1) {
    margin-bottom: 0
  }

  .approach-section .approach-inner .cto-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse
  }

  .approach-section .approach-inner .cto-div .cto-pofile-info {
    margin-top: 20px;
    margin-right: 30px;
    text-align: center
  }

  .approach-section .approach-inner .cto-div .cto-pofile-info h4 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 5px
  }

  .approach-section .approach-inner .cto-div .cto-pofile-info span {
    font-size: 10px;
    margin-bottom: 12px
  }

  .approach-section .approach-inner .cto-div .cto-pofile-info ul {
    margin: 0;
    padding: 0
  }

  .approach-section .approach-inner .cto-div .cto-pofile-info ul li {
    display: inline-block
  }

  .approach-section .approach-inner .cto-div .cto-pofile-info ul li a {
    font-size: 10px;
    margin-left: 7px;
    display: inline-block;
    margin-top: 14px
  }

  .approach-section .approach-inner .cto-div .cto-pofile {
    margin-top: 30px
  }

  .approach-section .approach-inner .cto-div .cto-pofile img {
    max-width: 300px;
    width: 100%;
    margin: 0 auto
  }

  .book-section {
    padding: 40px 0
  }

  .book-section .book-box {
    padding: 20px;
    background-size: 100% 100%
  }

  .book-section .book-box .book-text {
    text-align: center
  }

  .book-section .book-box .book-text h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px
  }

  .book-section .book-box .book-text h2 span {
    display: block
  }

  .book-section .book-box .book-text p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px
  }

  .challenges-section {
    padding: 40px 0 40px 0
  }

  .challenges-section .challenges-inner .heading {
    margin-bottom: 30px
  }

  .challenges-section .challenges-inner .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .challenges-section .challenges-inner .challenges-content p {
    font-size: 16px;
    line-height: 24px;
    color: #616161
  }

  .challenges-section .challenges-inner .challenges-img {
    text-align: right
  }

  .challenges-section .challenges-inner .challenges-img img {
    width: 100%;
    max-width: 558px
  }

  .middle-section {
    padding-top: 60px
  }

  .agile-banner-section {
    padding: 20px 0 20px 0
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content {
    border-radius: 16px;
    padding: 40px 20px
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    margin: 0 auto 30px auto
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content .heading h3 {
    margin-bottom: 15px
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content .heading P {
    font-size: 16px;
    line-height: 24px
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content .btn-primary {
    padding: 9px 10px
  }

  .agile-team-section {
    padding: 40px 0
  }

  .agile-team-section .agile-team-inner .team-card {
    padding: 40px 12px;
    border: 1px solid #8dd0ff !important
  }

  .agile-team-section .agile-team-inner .team-card span {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    top: -22px
  }

  .agile-team-section .agile-team-inner .team-card h5 {
    font-size: 16px;
    line-height: 24px
  }

  .agile-team-section .agile-team-inner .team-card p {
    font-size: 14px;
    line-height: 22px
  }

  .agile-team-section .col-lg-4.lg2 .team-card {
    margin-top: -1px !important
  }

  .agile-team-section .col-lg-4.lg4 .team-card {
    margin-top: -2px !important
  }

  .technologies-section {
    padding: 40px 0
  }

  .technologies-section .technologies-inner .heading {
    margin-bottom: 30px
  }

  .technologies-section .technologies-inner .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .technologies-section .technologies-inner .technologies-cantent {
    border-top: 1px solid #8dd0ff
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name {
    border-right: 0 solid #8dd0ff;
    border-bottom: 1px solid #8dd0ff
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs {
    display: inline-block
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li {
    max-width: 49%;
    display: inline-block
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    padding: 10px 0;
    font-size: 14px;
    line-height: 24px
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link .arrow {
    margin-left: 10px;
    background-size: 70%
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content {
    padding: 15px 15px
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top {
    margin-bottom: 40px
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #040405
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom hr {
    padding: 0;
    margin: 10px 0;
    border-top: 1px solid rgb(97, 97, 97)
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons {
    padding: 10px 0;
    margin-bottom: 30px
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-bottom .team-get-buttons a {
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    margin: 0 6px 7px 0;
    display: inline-block
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name {
    border-right: 0 solid #8dd0ff;
    border-bottom: 1px solid #8dd0ff
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li {
    max-width: 49%;
    display: inline-block
  }

  .startups-hireteam-section {
    padding-top: 40px
  }

  .startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card {
    padding: 20px
  }

  .startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card .startups-hireteam-content h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px
  }

  .startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card {
    padding: 20px
  }

  .startups-hireteam-section .startups-hireteam-inner .startups-airbnb-card .startups-airbnb-content h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px
  }

  .middle-section {
    padding-top: 60px
  }

  .startups-business-section {
    padding: 20px 0
  }

  .startups-business-section .startups-business-inner .startups-heading {
    margin-bottom: 30px
  }

  .startups-tabs-section .startups-tab-feature-heading li {
    margin-right: 10px
  }

  .startups-tabs-section .startups-tab-feature-heading li a {
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 5px
  }

  .middle-section {
    padding-top: 60px
  }

  .startups-business-section {
    padding: 20px 0
  }

  .startups-business-section .startups-business-inner .startups-heading {
    margin-bottom: 30px
  }

  .startups-tabs-section .startups-tab-feature-heading li {
    margin-right: 10px
  }

  .startups-tabs-section .startups-tab-feature-heading li a {
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 5px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name {
    border-right: 0 solid #8dd0ff;
    border-bottom: 1px solid #8dd0ff
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li {
    max-width: 49%;
    display: inline-block
  }

  .startups-looking-section {
    padding: 40px 0 40px 0
  }

  .startups-looking-section .startups-looking-inner .startups-looking-content h2 {
    font-size: 20px;
    line-height: 30px
  }

  .startups-looking-section .startups-looking-inner .startups-looking-content p {
    font-size: 16px;
    line-height: 24px
  }

  .startups-looking-section .startups-looking-inner .startups-looking-img {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .startups-looking-section .startups-looking-inner .startups-looking-img img {
    max-width: 400px
  }

  .startups-idea-section .startups-idea-card .startups-idea-img img {
    height: 60px;
    width: 60px;
    margin-bottom: 10px
  }

  .startups-idea-section .startups-idea-card .startups-idea-content h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600
  }

  .startups-idea-section .startups-idea-card {
    margin-bottom: 15px;
    padding-bottom: 10px !important
  }

  .startups-demandtaxi-section {
    padding: 20px 0 20px 0
  }

  .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee {
    padding: 6px
  }

  .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee ul li {
    font-size: 16px;
    line-height: 19px
  }

  .startups-hire-marquee-section .startups-hire-marquee-inner .startups-hire-marquee ul li::after {
    top: 8px
  }

  .startups-feature-section {
    padding: 20px 0 20px 0
  }

  .startups-feature-section .startups-feature-inner .startups-features-card {
    margin-bottom: 10px
  }

  .startups-feature-section .startups-feature-inner .startups-features-card h2 {
    font-size: 25px;
    line-height: 40px
  }

  .startups-uber-section {
    padding: 30px 0 130px 0
  }

  .startups-uber-section .startups-uber-inner .startups-uber-heading h2 {
    font-size: 25px;
    line-height: 35px
  }

  .startups-partner-section {
    padding: 40px 0
  }

  .startups-partner-section .startups-partner-inner .startups-partner-card {
    padding: 15px
  }

  .startups-uber-section {
    padding: 30px 0 20px 0
  }

  .startups-partner-section .startups-partner-inner .pratner-box {
    position: unset
  }

  .startups-planning-section {
    padding: 20px 0
  }

  .startups-planning-section .startups-planning-inner .startups-planning-heading {
    margin-bottom: 20px
  }

  .startups-planning-section .startups-planning-inner .startups-planning-heading h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 20px
  }

  .startups-planning-section .startups-planning-inner .startups-planning-heading .btn-primary {
    font-size: 14px;
    padding: 9px 10px
  }

  .startups-tab-section .startups-tab-inner .startups-tab-heading {
    padding: 6px 0 0 0
  }

  .startups-tab-section .startups-tab-inner .startups-tab-heading li a {
    padding-bottom: 6px;
    line-height: 16px
  }

  .middle-section {
    padding-top: 60px
  }

  .startups-building-section {
    padding: 20px 0
  }

  .startups-building-section .startups-building-inner .startups-building-heading h2 {
    font-size: 25px;
    line-height: 35px
  }

  .startups-app-section .startups-app-inner .startups-app-card .startups-app-tittle {
    margin-bottom: 20px
  }

  .startups-app-section .startups-app-inner .startups-app-card .startups-app-tittle span {
    font-size: 16px
  }

  .startups-app-section .startups-app-inner .startups-app-card .startups-app .startups-app-img img {
    width: 90px;
    height: 80px
  }

  .startups-app-section .startups-app-inner .startups-app-card .startups-app .startups-app-img p {
    margin-top: 10px;
    margin-bottom: 0
  }

  .startups-app-section .startups-app-inner .active .startups-app-tittle span::before {
    width: 68px;
    right: -75px;
    background-size: contain
  }

  .startups-app-section .startups-app-inner .active .startups-app-tittle span::after {
    width: 68px;
    left: -75px;
    background-size: contain
  }

  .startups-separate-section {
    padding: 20px 0 20px 0
  }

  .startups-separate-section .startups-separate-inner .startups-separate-content h2 {
    font-size: 25px;
    line-height: 35px
  }

  .startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-content h2 {
    font-size: 25px;
    line-height: 35px
  }

  .startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-content .btn-primary {
    padding: 9px 10px;
    font-size: 14px
  }

  .framework-section {
    background-color: #fff;
    padding: 50px 0 30px 0;
    position: relative
  }

  .framework-section .heading {
    margin-bottom: 40px
  }

  .framework-section .framework-inner .framework-card {
    padding: 20px 10px 10px 10px;
    margin-bottom: 40px
  }

  .framework-section .framework-inner .framework-card span {
    height: 40px;
    min-width: 152px;
    max-width: 152px;
    border-radius: 12px;
    line-height: 21px;
    font-size: 12px;
    padding: 14px 5px
  }

  .framework-section .framework-inner .framework-card h5 {
    font-size: 20px;
    line-height: 24px;
    color: #040405;
    font-weight: 600
  }

  .framework-section .framework-inner .framework-card p {
    font-size: 12px;
    line-height: 20px;
    margin: 14px 0;
    min-height: 100px;
    color: #616161
  }

  .onestop-section {
    padding: 50px 0;
    background-color: #fff
  }

  .onestop-section .onestop-inner .heading {
    margin-bottom: 30px
  }

  .onestop-section .onestop-inner .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .onestop-section .onestop-inner .box-card {
    margin-bottom: 20px
  }

  .onestop-section .onestop-inner .onestop-box {
    margin-bottom: 0
  }

  .onestop-section .onestop-inner .onestop-box .box-counter {
    padding: 30px 20px;
    height: 100%;
    min-width: 80px;
    max-width: 80px
  }

  .onestop-section .onestop-inner .onestop-box .box-counter h3 {
    font-size: 20px;
    line-height: 30px
  }

  .onestop-section .onestop-inner .onestop-box .box-content {
    padding: 15px 10px
  }

  .onestop-section .onestop-inner .onestop-box .box-content h3 {
    font-size: 14px;
    line-height: 20px
  }

  .onestop-section .onestop-inner .onestop-box .box-content p {
    font-size: 14px;
    line-height: 21px
  }

  .technologies-section {
    padding: 10px 0
  }

  .technologies-section .technologies-feature-card {
    padding: 30px 0 20px 0
  }

  .technologies-section .technologies-feature-card .technologies-feature-content h6 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px
  }

  .technologies-section .technologies-feature-card .technologies-feature-content h2 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15px
  }

  .technologies-section .technologies-feature-card .technologies-feature-content h3 {
    font-size: 16px;
    line-height: 24px
  }

  .technologies-section .technologies-feature-card .technologies-feature-content p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px
  }

  .technologies-section .technologies-feature-card .technologies-feature-image {
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 15px
  }

  .technologies-section .technologies-feature-card .technologies-feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 200px
  }

  .technologies-section .technologies-feature-latest {
    margin-bottom: 10px
  }

  .technologies-section .technologies-feature-latest .letest-technologies-content h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px
  }

  .technologies-section .technologies-feature-latest .letest-technologies-box {
    padding: 8px;
    margin-bottom: 12px
  }

  .technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-img {
    max-width: 120px;
    min-width: 120px;
    height: 90px;
    border-radius: 12px;
    overflow: hidden
  }

  .technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-img img {
    width: 100%;
    object-fit: cover;
    height: 100%
  }

  .technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text {
    padding-left: 12px
  }

  .technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text h3 {
    font-size: 14px;
    line-height: 20px;
    min-height: 62px
  }

  .technologies-section .technologies-feature-latest .letest-technologies-box .letest-technologies-text .letest-technologies-date span {
    font-size: 12px;
    line-height: 15px
  }

  .milestone-section {
    padding: 50px 0 0 0;
    background-color: #fff
  }

  .milestone-section .heading {
    max-width: 736px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 30px
  }

  .milestone-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500
  }

  .milestone-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700
  }
}

@media (max-width:767px) and (max-width:992px) {
  .milestone-section .heading h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 6px
  }

  .milestone-section .heading h2 {
    font-size: 36px;
    line-height: 48px
  }

  .milestone-section .heading h1 {
    font-size: 40px;
    line-height: 48px
  }
}

@media (max-width:767px) and (max-width:767px) {
  .milestone-section .heading h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 3px
  }

  .milestone-section .heading h2 {
    font-size: 20px;
    line-height: 30px
  }
}

@media (max-width:767px) {
  .modal-form .modal-content .modal-body .form-group {
    margin-bottom: 20px
  }

  .modal-form .modal-content .modal-body .form-group .form-control,
  .modal-form .modal-content .modal-body .form-group .form-select {
    height: 50px
  }

  .modal-form .modal-lg {
    max-width: 575px
  }

  .modal-form .modal-content .modal-body .modal-banner-section:after,
  .modal-form .modal-content .modal-body .modal-banner-section::before {
    display: none
  }

  .modal-form .modal-content .modal-body .modal-banner-section {
    padding: 20px 20px
  }

  .modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content {
    max-width: 350px
  }

  .modal-form .modal-content .modal-body .modal-banner-section {
    min-height: 160px
  }

  .modal-form .modal-content .modal-body .form-group .modal-upload-file {
    min-height: auto;
    padding: 15px
  }

  .modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    width: 55px;
    height: 55px;
    margin: 0 auto 12px
  }

  .modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img img {
    width: 35px;
    height: 35px
  }

  .modal-form .modal-content .modal-body .form-group .modal-number-group .number-plus .btn,
  .modal-form .modal-content .modal-body .form-group .modal-number-group .number-minus .btn {
    height: 30px;
    width: 30px;
    padding: 0 !important
  }

  .modal-form .modal-content .modal-body .form-group .modal-number-group .number-input input {
    height: 52px
  }

  .modal-form .modal-content .modal-body .form-group .modal-number-group {
    width: 156px
  }

  .modal-form .modal-content .modal-body .form-group .form-control,
  .modal-form .modal-content .modal-body .form-group .form-select {
    height: 50px
  }

  .modal-form.request-quote-modal .modal-content .modal-body .form-group .modal-upload-file {
    min-height: auto;
    padding: 20px 30px
  }

  .modal-form.careers-forms-modal .modal-content .modal-body .form-group .modal-upload-file {
    min-height: auto;
    padding: 20px 30px
  }

  .startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card {
    padding: 60px 20px;
    min-height: 320px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    background-position: top left, 102% -13%, -54px 100%, 100% 109%
  }

  .industries-system-section .development-box .development-bottom .development-links li a::after,
  .industries-system-section .development-box .development-bottom .development-links li .development-links-btn:hover::after {
    top: 15px !important
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img {
    max-height: 266px
  }

  .vibe-section {
    padding: 40px 0
  }

  .vibe-section .heading {
    margin-bottom: 40px
  }

  .vibe-section .vibe-main-box {
    height: calc(100% - 15px);
    margin-bottom: 15px
  }

  .vibe-section .vibe-main-box .vibe-img {
    height: auto
  }

  .vibe-section .vibe-main-box .vibe-img img {
    height: auto
  }

  .vibe-section .vibe-main-box .vibe-text p {
    font-size: 18px;
    line-height: 27px
  }

  .difference-section {
    padding: 40px 0
  }

  .difference-section .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .difference-section .make-text h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px
  }

  .difference-section .make-text p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px
  }

  .difference-section .deference-feature .feature-box p {
    font-size: 12px;
    line-height: 24px
  }

  .difference-section .deference-feature .feature-box h2 {
    font-size: 30px;
    line-height: 46px;
    text-align: center
  }

  .difference-section .help-text {
    margin-bottom: 15px
  }

  .difference-section .deference-feature>div>div {
    padding: 5px
  }

  .difference-section .deference-feature>div>div:nth-child(1) {
    order: 1
  }

  .difference-section .deference-feature>div>div:nth-child(2) {
    order: 2
  }

  .difference-section .deference-feature>div>div:nth-child(3) {
    order: 4
  }

  .difference-section .deference-feature>div>div:nth-child(4) {
    order: 3
  }

  .difference-section .deference-feature .feature-box {
    margin: 0px !important;
    height: auto
  }

  .meet-management-section {
    padding: 40px 0
  }

  .meet-management-section .meet-management-inner .meet-slider-div {
    justify-content: center
  }

  .meet-management-section .meet-slider-div {
    display: flex;
    height: 100%
  }

  .meet-management-section .meet-slider {
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 50px
  }

  .meet-management-section .meet-slider .slick-arrow {
    position: absolute;
    top: calc(50% - 2px);
    height: 25px;
    width: 25px;
    border-radius: 9px;
    border: 1px solid #0086e6;
    background-color: transparent;
    color: #0086e6
  }

  .meet-management-section .meet-slider .slick-next {
    right: -30px
  }

  .meet-management-section .meet-slider .slick-prev {
    left: -30px
  }

  .meet-management-section .meet-slider .meet-thumb {
    width: 50px;
    height: 60px
  }

  .meet-management-section .meet-slider .meet-thumb img {
    width: 100%;
    object-fit: cover
  }

  .meet-management-section .profile-info {
    padding: 20px 30px 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start
  }

  .meet-management-section .profile-info ul li {
    margin-right: 15px;
    margin-left: 0
  }

  .meet-management-section .meet-profile {
    width: 300px;
    min-height: 400px;
    position: relative;
    padding: 0 5px;
    margin: 0 auto
  }

  .believe-section {
    padding: 40px 0 20px 0
  }

  .believe-section .believe-box {
    margin-bottom: 20px
  }

  .believe-section .believe-box .believe-content p {
    font-size: 16px;
    line-height: 24px
  }

  .believe-section .believe-box .believe-content h3 {
    font-size: 20px;
    line-height: 26px
  }

  .believe-section .believe-image img {
    width: 100%;
    object-fit: cover;
    height: 220px
  }

  .careers-banner-section {
    padding: 60px 0 60px 0;
    min-height: auto
  }

  .careers-banner-section::before {
    height: 80%
  }

  .careers-banner-section:after {
    height: 80%;
    background-size: cover
  }

  .careers-banner-section .careers-banner-inner .heading {
    margin-bottom: 30px
  }

  .careers-banner-section .careers-banner-inner .heading h2 {
    line-height: 30px
  }

  .careers-banner-section .careers-banner-inner .heading .btn {
    margin-top: 22px;
    font-weight: 500;
    line-height: 26px
  }

  .careers-banner-section .careers-banner-inner .top-baner-images {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px
  }

  .careers-banner-section .careers-banner-inner .top-baner-images .img-box img {
    width: 60px;
    border-radius: 8px;
    object-fit: cover
  }

  .careers-banner-section .careers-banner-inner .top-baner-images .img-box:nth-child(2) {
    text-align: right
  }

  .careers-banner-section .careers-banner-inner .bottom-baner-images {
    position: relative;
    bottom: -20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px
  }

  .careers-banner-section .careers-banner-inner .bottom-baner-images .img-box img {
    width: 80px;
    border-radius: 8px
  }

  .careers-banner-section .careers-banner-inner .bottom-baner-images .img-box:nth-child(2) {
    text-align: right
  }

  .careers-banner-section .careers-banner-inner .bottom-baner-images .image-four {
    margin-top: 0;
    margin-right: 0
  }

  .careers-contributer-section .secton-titel h4 {
    font-size: 18px
  }

  .careers-contributer-section .secton-titel h2 {
    font-size: 30px
  }

  .careers-contributer-section .contributer-list>div {
    position: relative;
    margin: 3px;
    padding: 8px 8px 8px 8px
  }

  .careers-contributer-section .contributer-list>div::after {
    left: 8px
  }

  .news-letter-section {
    padding: 40px 0
  }

  .news-letter-section .news-letter-content {
    padding: 20px
  }

  .news-letter-section .news-letter-content .news-letter-link a {
    font-size: 16px;
    padding: 0 10px
  }

  .job-positions-section .job-searching-section {
    padding: 30px 0 30px;
    margin-top: 0
  }

  .job-positions-section .job-searching-section form .form-group .form-input .search-icon {
    right: 12px
  }

  .job-positions-section .job-searching-section form .job-searching-result {
    margin-top: 30px;
    margin-top: 0
  }

  .job-positions-section .job-searching-section form .job-searching-result ul li {
    padding: 10px 0
  }

  .job-positions-section .job-searching-section form .job-searching-result ul li .job-description {
    margin-bottom: 10px
  }

  .job-positions-section .job-searching-section form .job-searching-result ul li .job-description h3 {
    margin-bottom: 0
  }

  .job-positions-section .job-searching-section form .job-searching-result ul li .job-description p {
    margin-bottom: 0
  }

  .job-positions-section .job-searching-section form .job-searching-result ul li .job-description.job-apply-btn {
    text-align: left;
    margin-bottom: 0
  }

  .our-culture-section .our-culture-card {
    padding: 50px 20px
  }

  .our-culture-section .our-culture-card .card-images div {
    width: 70px;
    height: 70px
  }

  .our-culture-section .our-culture-card .card-images div img {
    width: 100%
  }

  .our-culture-section .our-culture-card .card-tite h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px
  }

  .our-culture-section .our-culture-card .card-images div.img-top-left {
    top: -7%;
    left: 0%
  }

  .our-culture-section .our-culture-card .card-images div.img-top-right {
    top: -6%;
    right: 4%
  }

  .gallery-slider-section .gallery-slider .slick-slide img {
    width: 100%;
    height: auto;
    min-width: unset;
    max-width: unset;
    height: 250px;
    max-height: 250px
  }

  .gallery-slider-section .gallery-slider .slick-slide {
    margin: 10px
  }

  .benefits-section {
    padding: 40px 0
  }

  .testimonial-section {
    padding: 50px 0
  }

  .testimonial-section .heading {
    margin-bottom: 30px
  }

  .testimonial-section .container-fluid {
    padding: 0
  }

  .testimonial-section .testimonial-main-box {
    padding: 12px;
    box-shadow: none !important
  }

  .testimonial-section .testimonial-main-box::before {
    background-size: 40% !important;
    right: 0 !important;
    width: 30px
  }

  .testimonial-section .testimonial-main-box .star-list li {
    margin-right: 3px
  }

  .testimonial-section .testimonial-main-box .star-list li img {
    width: 12px
  }

  .testimonial-section .testimonial-main-box:before {
    background-size: 70%;
    background-repeat: no-repeat;
    right: 10px;
    top: 20px
  }

  .testimonial-section .testimonial-main-box .testimonial-text p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400
  }

  .testimonial-section .testimonial-main-box .coordinator span {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300
  }

  .testimonial-section .testimonial-main-box .coordinator span strong {
    display: block
  }

  .testimonial-section .swiper-slide {
    opacity: 1;
    margin: auto 0 auto 15px !important
  }

  .testimonial-section .swiper-slide .testimonial-main-box {
    transform: scale(1)
  }

  .testimonial-section .gallery-thumbs .swiper-slide {
    height: 25px;
    width: 25px;
    border-radius: 5px
  }

  .testimonial-section .gallery-thumbs .swiper-slide img {
    width: 100%;
    height: 100%
  }

  .testimonial-section .gallery-thumbs .swiper-slide-thumb-active {
    height: 32px !important;
    width: 32px !important
  }

  .testimonial-section .gallery-thumbs .swiper-slide-thumb-active img {
    width: 100%;
    height: 100%
  }

  .testimonial-section .gallery-thumbs {
    padding: 5px 0 0 0
  }

  .case-studies-blog-section {
    padding-top: 40px
  }

  .case-studies-blog-section .case-studies-blog-inner .case-studies-heading h3 {
    font-size: 20px;
    line-height: 29px
  }

  .case-studies-blog-section .case-studies-blog-inner .right-blog-img img {
    width: 100%
  }

  .case-studies-about-section {
    padding-top: 25px
  }

  .case-studies-about-section .case-studies-about-inner .case-studies-heading h3 {
    font-size: 18px;
    line-height: 29px
  }

  .case-studies-about-section .case-studies-about-inner .case-studies-listing {
    padding-bottom: 20px
  }

  .case-studies-about-section .case-studies-about-inner .case-studies-listing .client-list h5 {
    font-size: 14px;
    line-height: 20px
  }

  .case-studies-about-section .case-studies-about-inner .case-studies-listing .client-list p {
    font-size: 14px;
    line-height: 20px
  }

  .case-studies-about-section .case-studies-about-inner .case-studies-wrapper {
    padding-bottom: 20px
  }

  .case-studies-about-section .case-studies-about-inner .case-studies-wrapper .case-studies-content p {
    font-size: 14px;
    line-height: 20px
  }

  .case-studies-section {
    padding: 20px 0 0 0
  }

  .case-studies-section .case-studies-inner .case-studies-heading {
    padding-bottom: 10px
  }

  .case-studies-section .case-studies-inner .case-studies-heading h3 {
    font-size: 18px;
    line-height: 26px
  }

  .case-studies-section .case-studies-inner .case-studies-heading h2 {
    font-size: 20px;
    line-height: 29px
  }

  .case-studies-overview-section {
    padding-top: 40px
  }

  .case-studies-overview-section .case-studies-overview-inner .left-content-overview .overview-heading {
    margin-bottom: 20px
  }

  .case-studies-overview-section .case-studies-overview-inner .left-content-overview .overview-heading h3 {
    font-size: 20px;
    line-height: 29px
  }

  .case-studies-overview-section .case-studies-overview-inner .right-content-overview p {
    font-size: 14px;
    line-height: 24px
  }

  .case-studies-process-section {
    padding-top: 40px
  }

  .case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper {
    padding-bottom: 20px
  }

  .case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-wrapper {
    padding-bottom: 20px
  }

  .case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading h3 {
    font-size: 20px;
    line-height: 29px
  }

  .case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading p {
    margin-bottom: 8px
  }

  .case-studies-country-section {
    padding-top: 40px
  }

  .case-studies-country-section .case-studies-country-inner .case-studies-heading {
    padding-bottom: 15px
  }

  .case-studies-country-section .case-studies-country-inner .case-studies-heading h3 {
    font-size: 20px;
    line-height: 30px
  }

  .case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-heading h2 {
    font-size: 18px;
    line-height: 26px
  }

  .case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul li {
    font-size: 12px;
    line-height: 16px;
    padding-right: 12px
  }

  .case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-listing ul li h6 {
    font-size: 12px;
    line-height: 16px
  }

  .case-studies-country-section .case-studies-country-inner .cloud-content .developer-content .developer-text p {
    font-size: 14px;
    line-height: 20px
  }

  .case-studies-team-section {
    padding-top: 40px
  }

  .case-studies-team-section .case-studies-team-inner .startup-main-content {
    padding-bottom: 10px
  }

  .case-studies-team-section .case-studies-team-inner .case-studies-heading {
    padding-bottom: 20px
  }

  .case-studies-team-section .case-studies-team-inner .startups-app-card {
    margin-bottom: 20px
  }

  .case-studies-team-section .case-studies-team-inner .startups-app-card .startups-app-tittle {
    margin-bottom: 15px
  }

  .findus-section {
    padding: 40px 0
  }

  .findus-section .heading {
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 40px
  }

  .findus-section .heading h3 {
    font-size: 20px;
    line-height: 30px;
    color: #91959b !important;
    margin-bottom: 10px;
    font-weight: 500
  }

  .findus-section .heading h2 {
    font-size: 40px;
    line-height: 60px;
    color: #040405 !important;
    font-weight: 700
  }
}

@media (max-width:767px) and (max-width:992px) {
  .findus-section .heading h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 6px
  }

  .findus-section .heading h2 {
    font-size: 36px;
    line-height: 48px
  }

  .findus-section .heading h1 {
    font-size: 40px;
    line-height: 48px
  }
}

@media (max-width:767px) and (max-width:767px) {
  .findus-section .heading h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 3px
  }

  .findus-section .heading h2 {
    font-size: 20px;
    line-height: 30px
  }
}

@media (max-width:767px) {
  .findus-section .heading p {
    font-size: 16px;
    line-height: 26px
  }

  .findus-section .find-card {
    height: auto !important;
    margin-bottom: 20px
  }

  .findus-section .find-card .find-image {
    position: relative;
    height: auto;
    max-height: unset
  }

  .findus-section .find-card .find-image .find-content p {
    margin-bottom: 30px
  }

  .findus-section .find-card .find-image .find-content span {
    opacity: .4
  }

  .findus-section .find-card .find-image img {
    width: 100%
  }

  .findus-section .find-card .find-text {
    padding: 20px;
    min-height: auto
  }

  .findus-section .find-card .find-text p {
    font-size: 16px;
    line-height: 26px
  }

  .wordwise-section {
    padding: 40px 0
  }

  .wordwise-section .map-image {
    height: 400px
  }

  .wordwise-section .heading {
    margin-bottom: 30px
  }

  .wordwise-section .client-map-content {
    position: unset;
    height: auto
  }

  .wordwise-section .client-map-content .item {
    padding: 20px 15px;
    border: 1px solid rgba(0, 1, 26, 0.2);
    margin-bottom: 0
  }

  .wordwise-section .client-map-content .item .flag-img {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, .05) 5.56%, rgba(141, 208, 255, .05) 113.66%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 100px;
    margin: auto;
    margin-bottom: 8px
  }

  .wordwise-section .client-map-content .item .flag-img img {
    width: auto
  }

  .wordwise-section .client-map-content .item p {
    font-size: 16px;
    line-height: 20px;
    color: #91959b;
    margin-bottom: 5px
  }

  .wordwise-section .client-map-content .item h2 {
    font-size: 20px;
    line-height: 30px;
    color: #040405;
    font-weight: 500;
    margin-bottom: 0
  }

  .media-section {
    padding: 40px 0
  }

  .media-section .heading h2 {
    font-size: 30px;
    line-height: 40px
  }

  .media-section .nav-tabs .nav-item {
    padding-right: 20px
  }

  .media-section .nav-tabs .nav-item .nav-link {
    font-size: 16px;
    line-height: 24px
  }

  .banner-section {
    padding: 0 0 40px 0;
    background-color: #fff
  }

  .banner-section .banner-heading {
    padding: 20px 17px 20px 17px
  }

  .banner-section .banner-heading h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 30px
  }

  .banner-section .banner-heading h1 u::after {
    content: "";
    height: 5px;
    bottom: 0
  }

  .banner-section .banner-heading .btn {
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    padding: 6px 24px
  }

  .banner-section .solution-box {
    margin-bottom: 20px
  }

  .banner-section .banner-img img {
    width: 100%;
    max-width: 450px;
    margin: 0 auto
  }

  .banner-section .banner-img .img-banner-image {
    width: 100%;
    display: block
  }

  .banner-section .mobile-banner {
    display: flex;
    align-items: center;
    flex-direction: column-reverse
  }

  .banner-section .solution-box {
    position: relative;
    padding-right: 30px
  }

  .banner-section .solution-box .arrow {
    position: absolute;
    right: 5px
  }

  .banner-thumb {
    display: flex;
    align-items: center
  }

  .banner-thumb .slick-slide .solution-box {
    padding: 8px 10px;
    margin: 6px 6px !important
  }

  .banner-thumb .slick-slide .solution-box .text p {
    font-size: 12px;
    line-height: 17px;
    min-height: 36px;
    display: flex;
    align-items: center
  }

  .banner-thumb .slick-slide:nth-last-child(1) .solution-box {
    margin-right: 6px !important
  }

  .banner-thumb .slick-slide:nth-child(1) .solution-box {
    margin-left: 6px !important
  }

  @keyframes slide-in {
    from {
      transform: translateY(1.5vh);
      opacity: 0
    }
  }

  .faq-section {
    padding: 40px 0
  }

  .faq-section .heading {
    margin-bottom: 30px
  }

  .faq-section .accordion {
    min-height: unset
  }

  .faq-section .accordion .accordion-item {
    margin-bottom: 10px
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    font-size: 15px;
    line-height: 22px;
    padding: 12px 40px 12px 12px;
    font-weight: 500
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
    top: 20px;
    right: 5px;
    background-size: 50%
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 15px;
    line-height: 22px;
    padding: 12px 40px 12px 15px;
    font-weight: 600
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
    top: 5px;
    right: 10px;
    background-size: 50%
  }

  .faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 20px 15px;
    font-size: 14px;
    line-height: 22px
  }

  .hiredeveloper-section {
    padding: 30px 0
  }

  .hiredeveloper-section .developer-circle {
    width: 330px;
    height: 330px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner {
    width: 280px;
    height: 280px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner marquee p {
    font-size: 12px;
    line-height: 24px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner marquee p span {
    margin: 0 10px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner marquee p span::after {
    top: 10px;
    height: 5px;
    width: 5px;
    right: -15px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner .content {
    padding: 15px 20px;
    margin: 0
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner .content h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner .marquee-top {
    margin-top: 5px
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner .hire-developer {
    margin-bottom: 0;
    position: relative;
    z-index: 10
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner .hire-developer a,
  .hiredeveloper-section .developer-circle .developer-circle-inner .hire-developer span {
    font-size: 14px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #cbd3d8;
    padding: 0 5px;
    z-index: 99
  }

  .hiredeveloper-section .developer-circle .developer-circle-inner .btn {
    font-weight: 500;
    height: 38px
  }

  .process-section {
    padding: 60px 0 50px 0
  }

  .process-section .heading {
    margin-bottom: 15px
  }

  .process-section .process-list {
    padding: 20px 0 30px 0
  }

  .process-section .process-list ul {
    padding: 0
  }

  .process-section .process-list ul li {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px
  }

  .process-section .process-list ul li:first-child {
    width: 100%;
    text-align: center;
    padding-right: 20px
  }

  .process-section .process-list ul li:first-child::after {
    right: 0;
    background-image: url(../../assets/images/responsive-first-line.svg) !important;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 76px;
    height: 54px;
    top: 18px
  }

  .process-section .process-list ul li:nth-child(2) {
    text-align: center;
    padding: 0 !important
  }

  .process-section .process-list ul li:nth-child(2)::before {
    left: 0;
    background-image: url(../../assets/images/responsive-second-line.svg) !important;
    background-repeat: no-repeat;
    width: 85px;
    height: 54px;
    top: 18px
  }

  .process-section .process-list ul li:nth-child(3) {
    padding: 0 !important
  }

  .process-section .process-list ul li:nth-child(3)::before {
    display: none
  }

  .process-section .process-list ul li:nth-child(3)::after {
    content: "";
    right: 0;
    background-image: url(../../assets/images/responsive-third-line.svg) !important;
    background-repeat: no-repeat;
    width: 85px;
    height: 54px;
    position: absolute;
    top: 18px
  }

  .process-section .process-list ul li:nth-child(4) {
    text-align: center;
    padding: 0 !important
  }

  .process-section .process-list ul li:nth-child(4)::before {
    content: "";
    position: absolute;
    background: url(../../assets/images/responsive-fourth-line.svg) no-repeat;
    width: 80px;
    height: 54px;
    left: 0;
    top: 18px
  }

  .process-section .process-list ul li:nth-child(5) {
    padding: 0 !important
  }

  .process-section .process-list ul li:nth-child(5)::before {
    display: none
  }

  .process-section .process-list ul li:nth-child(5)::after {
    content: "";
    position: absolute;
    background: url(../../assets/images/responsive-last-line.svg) no-repeat;
    width: 80px;
    height: 54px;
    right: 0;
    top: 18px
  }

  .process-section .process-list ul li:last-child {
    width: 100%;
    text-align: center;
    padding-right: 20px
  }

  .process-section .process-text p {
    font-size: 14px;
    line-height: 22px;
    max-width: 90%
  }

  .process-section .process-list ul .reveal-none.reveal-0 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important
  }

  .process-section .process-list ul .reveal-none.reveal-0::after {
    background-image: url(../../assets/images/list-line-mob-blue1.svg)
  }

  .process-section .process-list ul li.reveal-none.reveal-1 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important
  }

  .process-section .process-list ul li.reveal-none.reveal-1::before {
    background-image: url(../../assets/images/list-line-mob-blue2.svg)
  }

  .process-section .process-list ul .reveal-none.reveal-2 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important
  }

  .process-section .process-list ul .reveal-none.reveal-3 {
    opacity: 1;
    transform: translateY(0);
    font-weight: 600 !important;
    color: #040405 !important
  }

  .process-section .process-list ul .reveal-none.reveal-4 {
    opacity: 1;
    transform: translateY(0);
    color: #040405 !important;
    font-weight: 600 !important
  }

  .process-section .process-list ul .reveal-none.reveal-5 {
    opacity: 1;
    color: #040405 !important;
    font-weight: 600 !important
  }

  .service-section {
    padding: 40px 0
  }

  .service-section .heading {
    margin-bottom: 20px
  }

  .service-section .service-feature {
    margin-bottom: 15px;
    min-height: unset;
    padding-bottom: 10px
  }

  .service-section .service-feature h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px
  }

  .service-section .service-feature p {
    font-size: 16px;
    line-height: 24px
  }

  .service-section .service-bg-image {
    padding-top: 5px;
    background-image: none !important
  }

  .service-section .service-bg-image .service-feature {
    opacity: 1 !important
  }

  .service-section .list-fetaure div:nth-child(2n) .service-feature {
    padding-left: 15px
  }

  .list-fetaure div .service-feature .service-tools {
    display: block
  }

  .list-fetaure div .service-feature .service-tools li {
    padding: 5px 6px;
    height: 30px;
    border-radius: 6px;
    margin-right: 5px;
    font-size: 10px;
    line-height: 18px;
    border: 2px solid 1px transparent
  }

  .list-fetaure div .service-feature .service-tools li::before {
    margin: -2px
  }

  .list-fetaure div .service-feature .service-tools li:nth-last-child(1) {
    margin-right: 0
  }

  .service-section .service-feature:before {
    right: 25px;
    top: 22px
  }

  .block-img::before {
    opacity: 0
  }

  .service-section .list-fetaure div .service-feature:hover:after {
    display: none !important
  }

  .service-section .list-fetaure div .service-feature:hover:before {
    opacity: 0
  }

  .m-none {
    display: none !important
  }

  .client-section {
    padding: 40px 0
  }

  .client-section .client-section-video .play-btn {
    height: 50px;
    width: 50px
  }

  .client-section .client-section-video .play-btn img {
    width: 40%
  }

  .client-section .solution-listing .solution-box .icon {
    height: 40px;
    width: 40px;
    border-radius: 6px
  }

  .client-section .solution-listing .solution-box {
    margin-bottom: 15px;
    padding: 8px 12px;
    border-radius: 6px
  }

  .client-section .solution-listing .solution-box .text p {
    font-size: 16px;
    line-height: 18px
  }

  .client-section .solution-listing .solution-box span {
    margin-left: auto;
    font-size: 18px;
    line-height: 20px
  }

  .client-section .video-container .vjs-big-play-button {
    height: 60px !important;
    width: 60px !important;
    min-height: 60px !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto
  }

  .client-section .video-container .vjs-big-play-button .vjs-icon-placeholder::before {
    min-height: 60px;
    background-size: 40%
  }

  .vjs-big-play-button::after {
    height: 60px;
    width: 60px
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 rgba(204, 250, 78, .3)
    }

    100% {
      box-shadow: 0 0 0 15px rgba(204, 250, 78, 0)
    }
  }

  .work-section {
    padding: 50px 0
  }

  .work-section .heading {
    margin-bottom: 30px
  }

  .work-section .our-project .project {
    min-height: unset
  }

  .work-section .our-project .project .project-content h3 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px
  }

  .work-section .our-project .project .project-user .retention p {
    font-size: 14px;
    line-height: 16px
  }

  .work-section .our-project .project .project-user .retention span {
    font-size: 16px;
    line-height: 28px
  }

  .work-section .our-project .project .project-img img {
    width: auto;
    object-fit: contain
  }

  .work-section .our-project .project .project-link {
    position: unset
  }

  .work-section .our-project .project .project-link a {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    opacity: 1
  }

  .work-section .our-project .project .project-link a img {
    opacity: 1
  }

  .work-section .our-project .project:hover .project-link a img {
    opacity: 1 !important;
    transition: all 0.3s
  }

  .work-section .View-all {
    margin-top: 30px;
    text-align: center
  }

  .pagenot-section .heading h2 {
    margin-bottom: 30px;
    line-height: 40px;
    font-size: 30px
  }

  .digital-banner-section {
    padding: 60px 0 60px 0;
    min-height: auto
  }

  .digital-banner-section::before {
    height: 80%
  }

  .digital-banner-section:after {
    height: 80%;
    background-size: cover
  }

  .digital-banner-section .digital-banner-inner .heading {
    margin-bottom: 30px
  }

  .digital-banner-section .digital-banner-inner .heading h2 {
    line-height: 30px
  }

  .digital-banner-section .digital-banner-inner .heading .btn {
    margin-top: 22px;
    font-weight: 500;
    line-height: 26px
  }

  .digital-banner-section .digital-banner-inner .top-baner-images {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px
  }

  .digital-banner-section .digital-banner-inner .top-baner-images .img-box img {
    width: 60%;
    object-fit: cover
  }

  .digital-banner-section .digital-banner-inner .top-baner-images .img-box:nth-child(2) {
    text-align: right
  }

  .digital-banner-section .digital-banner-inner .bottom-baner-images {
    position: relative;
    bottom: -20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px
  }

  .digital-banner-section .digital-banner-inner .bottom-baner-images .img-box img {
    width: 60%
  }

  .digital-banner-section .digital-banner-inner .bottom-baner-images .img-box:nth-child(2) {
    text-align: right
  }

  .digital-banner-section .digital-banner-inner .bottom-baner-images .image-four {
    margin-top: 0;
    margin-right: 0
  }

  .sticky-tab {
    position: sticky;
    top: 70px;
    z-index: 99
  }

  .design-stage-section {
    padding: 40px 0
  }

  .design-stage-section .design-steps {
    padding: 10px 0;
    margin-bottom: 20px
  }

  .design-stage-section .design-steps ul {
    flex-direction: column;
    width: 100%;
    display: block
  }

  .design-stage-section .design-steps ul li {
    display: inline-block !important;
    justify-content: flex-start !important;
    width: 49% !important;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left !important
  }

  .design-stage-section .design-steps ul li a {
    display: flex;
    width: 100%;
    font-size: 14px
  }

  .design-stage-section .design-steps ul li:nth-last-child(1) {
    width: 100% !important
  }

  .design-stage-section .design-steps ul .active {
    width: 100%
  }

  .design-stage-section .design-steps ul .active::after {
    background-size: 60%;
    right: 10px;
    top: 5px !important
  }

  .design-stage-section .steps-detail .step-row {
    margin-bottom: 30px
  }

  .design-stage-section .steps-detail .heading-steps h2 {
    font-size: 40px;
    line-height: 50px
  }

  .design-stage-section .steps-detail .heading-steps h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px
  }

  .design-stage-section .steps-detail .steps-content p {
    font-size: 14px;
    line-height: 24px
  }

  .design-stage-section .steps-detail .step-feature-text h3 {
    font-size: 16px;
    line-height: 24px
  }

  .design-stage-section .steps-detail .steps-feature {
    padding: 12px;
    margin-bottom: 15px
  }

  .design-stage-section .steps-detail .steps-feature .feature-img {
    margin-right: 10px
  }

  .design-stage-section .steps-detail .steps-feature .feature-img img {
    height: 40px;
    width: 40px;
    border-radius: 4px
  }

  .design-stage-section .steps-detail .steps-feature .feature-content h3 {
    font-size: 14px;
    line-height: 16px
  }

  .design-stage-section .steps-detail .steps-feature .feature-content p {
    font-size: 14px;
    line-height: 22px
  }

  .design-stage-section .design-steps ul {
    white-space: normal
  }

  .hire-section {
    padding: 40px 0
  }

  .hire-section .hite-content {
    text-align: center
  }

  .hire-section .hite-content span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500
  }

  .hire-section .hite-content h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 20px
  }

  .service-hero-section {
    padding: 40px 0
  }

  .service-hero-section .heading {
    margin-bottom: 30px
  }

  .service-hero-section .heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .service-hero-section .heading p {
    font-size: 16px;
    line-height: 24px
  }

  .service-feature-heading {
    width: 100%;
    overflow-y: scroll;
    padding: 0 20px
  }

  .book-section {
    padding: 40px 0
  }

  .book-section .book-box {
    padding: 20px
  }

  .book-section .book-box .book-text {
    text-align: center
  }

  .book-section .book-box .book-text h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px
  }

  .book-section .book-box .book-text p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px
  }

  .book-section .book-box .book-text .hire-btn .btn-primary {
    padding: 9px 10px;
    margin: 2px
  }

  .book-section .book-box .book-text .hire-btn .btn-outline-secondary {
    padding: 9px 10px;
    border-radius: 4px;
    margin: 2px
  }

  .compeny-section {
    padding: 40px 0
  }

  .compeny-section .compeny-main-content {
    padding: 25px;
    border-radius: 24px
  }

  .compeny-section .compeny-main-content .compeny-img {
    margin-bottom: 20px
  }

  .compeny-section .compeny-main-content .compeny-img img {
    max-width: unset;
    max-height: unset
  }

  .compeny-section .compeny-main-content .compeny-text h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px
  }

  .compeny-section .compeny-main-content .compeny-text p {
    font-size: 16px;
    line-height: 26px
  }

  .compeny-section .compeny-main-content .compeny-text .compeny-logo h4 {
    font-size: 16px;
    line-height: 24px
  }

  .compeny-section .compeny-main-content .compeny-text .compeny-logo ul li {
    padding-right: 8px
  }

  .compeny-section .compeny-main-content .compeny-text .compeny-logo ul li img {
    width: 50px;
    height: 50px
  }

  .compeny-section .compeny-box {
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 12px
  }

  .compeny-section .compeny-box p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px
  }

  .compeny-section .compeny-box h2 {
    font-size: 20px;
    line-height: 24px
  }

  .service-feature-section {
    background-color: #fff
  }

  .service-feature-section .service-feature-card {
    padding: 30px 0 20px 0
  }

  .service-feature-section .service-feature-card .service-feature-content h6 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px
  }

  .service-feature-section .service-feature-card .service-feature-content h2 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15px
  }

  .service-feature-section .service-feature-card .service-feature-content p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px
  }

  .service-feature-section .service-feature-card .service-feature-image {
    border-radius: 12px;
    overflow: hidden
  }

  .service-feature-section .service-feature-card .service-feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 200px;
    border-radius: 12px
  }

  .service-feature-section .service-feature-latest {
    margin-bottom: 10px
  }

  .service-feature-section .service-feature-latest .letest-service-content h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px
  }

  .service-feature-section .service-feature-latest .letest-service-box {
    padding: 8px;
    margin-bottom: 12px
  }

  .service-feature-section .service-feature-latest .letest-service-box .letest-service-img {
    max-width: 120px;
    min-width: 120px;
    height: 90px;
    border-radius: 12px;
    overflow: hidden
  }

  .service-feature-section .service-feature-latest .letest-service-box .letest-service-img img {
    width: 100%;
    object-fit: cover;
    height: 100%
  }

  .service-feature-section .service-feature-latest .letest-service-box .letest-service-text {
    padding-left: 12px
  }

  .service-feature-section .service-feature-latest .letest-service-box .letest-service-text h3 {
    font-size: 14px;
    line-height: 20px;
    min-height: 62px
  }

  .service-feature-section .service-feature-latest .letest-service-box .letest-service-text .letest-service-date span {
    font-size: 12px;
    line-height: 15px
  }

  .service-feature-section .service-follow .service-follow-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start
  }

  .service-feature-section .service-follow .service-follow-content h5 {
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px
  }

  .service-feature-section .service-follow .service-follow-content ul {
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap
  }

  .service-feature-section .service-follow .service-follow-content ul li {
    padding-right: 10px;
    font-size: 12px
  }

  .service-feature-section .service-follow .service-follow-content ul li h6 {
    font-size: 12px;
    line-height: 20px
  }

  .service-feature2 .service-feature-card .row {
    display: flex;
    flex-direction: column-reverse
  }

  .project-section {
    padding: 40px 0
  }

  .project-section .project-box .project-logos {
    width: 150px;
    height: 70px;
    margin-right: 15px
  }

  .project-section .project-box .project-logos img {
    width: auto;
    height: 30px
  }

  .service-tabs-section .service-feature-heading li {
    margin-right: 10px
  }

  .service-tabs-section .service-feature-heading li a {
    font-size: 10px;
    line-height: 12px;
    padding: 10px 0
  }

  .service-tabs-section {
    position: unset
  }

  .service-feature-section .service-feature-card {
    padding-top: 0px !important
  }

  .startups-vacation-section .startups-vacation-inner .startups-vacation-content p {
    font-size: 14px;
    line-height: 24px
  }

  .startups-vacation-section .startups-vacation-inner .heading h2 {
    margin-bottom: 30px
  }

  .startups-features-section .startups-features-inner .startups-heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs {
    display: inline-block
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li {
    display: inline-block;
    max-width: 49%
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li button {
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 12px 0 !important
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li button .arrow {
    background-size: 13px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-img {
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-right: 15px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card {
    padding: 20px 15px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle h4 {
    font-size: 16px;
    line-height: 26px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .tabs-content .tabs-card .tabs-card-tittle p {
    font-size: 14px;
    line-height: 24px
  }

  .startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card {
    padding: 20px 20px 15px 20px
  }

  .startups-features-section .startups-features-inner .startups-features-vacation .startups-features-card h4 {
    font-size: 16px;
    line-height: 26px
  }

  .startups-hire-marquee-section .hire-marquee ul li {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-right: 30px
  }

  .startups-hire-marquee-section .hire-marquee ul li::after {
    right: -18px;
    top: 14px;
    height: 5px;
    width: 5px
  }

  .startups-hire-marquee-section .hire-marquee div span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px
  }

  .startups-hire-marquee-section .hire-marquee div a {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 40px
  }

  .startups-hire-marquee-section .hire-marquee div a:hover {
    font-weight: 500;
    color: rgba(0, 1, 28, 1)
  }

  .startups-hire-marquee-section .hire-marquee div a:after {
    top: 10px;
    height: 5px;
    width: 5px;
    left: -20px
  }

  .modal-form .form-group {
    margin-bottom: 15px
  }

  .modal-form .form-group .form-label {
    font-size: 14px;
    line-height: 20px
  }

  .modal-form .form-group .form-label-check {
    padding-bottom: 0
  }

  .modal-form .form-group .form-group-checkbox {
    display: block
  }

  .modal-form .form-group .form-group-checkbox .form-check {
    align-items: center;
    padding-bottom: 10px
  }

  .modal-form .form-group .form-group-checkbox .form-check .form-check-label {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding-bottom: 0
  }

  .modal-form .form-group .form-control {
    height: 45px;
    font-size: 14px;
    line-height: 20px
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button,
  .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    font-size: 16px
  }

  .about-section .about-inner .row .order-sm-0 {
    order: 2 !important
  }

  .believe-section .believe-box .believe-content h3 {
    font-size: 20px;
    line-height: 24px
  }

  .vibe-section .vibe-main-box .vibe-text p {
    min-height: 54px
  }

  .meet-management-section .profile-info {
    align-items: center
  }

  .findus-section .find-card {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto
  }

  .media-section {
    overflow: hidden
  }

  .media-section .twitter-timeline iframe {
    max-width: 540px;
    margin: 0 auto
  }

  .startups-partner-section .startups-partner-inner .startups-partner-card .startups-partner-content h3 {
    font-size: 16px
  }

  .careers-contributer-section .contributer-list>div {
    padding: 8px 8px 8px 26px
  }

  .benefits-section .section-titel h2 {
    font-size: 22px
  }

  .benefits-section .icon-card .card .card-content h3 {
    font-size: 18px
  }

  .service-tabs-section {
    margin-bottom: 20px
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name {
    height: auto
  }

  .technologies-section .technologies-inner .technologies-cantent .tabs-content {
    padding: 15px 0
  }

  .agile-team-section .col-lg-4.lg0 .team-card {
    border-top-left-radius: 12px
  }

  .agile-team-section .col-lg-4.lg1 .team-card {
    border-top-right-radius: 12px
  }

  .agile-team-section .col-lg-4.lg2 .team-card {
    border-top-right-radius: 0
  }

  .agile-team-section .col-lg-4.lg3 .team-card {
    border-bottom-left-radius: 0
  }

  .agile-team-section .col-lg-4.lg4 .team-card {
    border-bottom-left-radius: 12px
  }

  .agile-team-section .col-lg-4.lg5 .team-card {
    border-bottom-right-radius: 12px
  }

  .onestop-section .onestop-inner .onestop-box .box-inner {
    justify-content: flex-start
  }

  .framework-section .framework-inner .row.g-0 {
    padding: 0 15px
  }

  .industries-system-section .development-box .development-bottom .development-links li a:hover::after {
    top: 9px
  }

  .modal-form .form-group .form-control::file-selector-button,
  .modal-form .form-group:hover:not(:disabled):not([readonly])::file-selector-button {
    top: -2px
  }

  section .steps-feature .inner-step {
    padding: 15px 15px
  }

  .casesudies-professional-section {
    padding: 50px 0
  }

  .casesudies-professional-section .casesudies-professional-inner .heading {
    margin-bottom: 40px
  }

  .casesudies-professional-section .casesudies-professional-inner .heading h3 {
    font-size: 20px;
    line-height: 26px
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills::before {
    bottom: -40px
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills ul {
    background: linear-gradient(283.13deg, rgba(0, 134, 230, .05) 5.56%, rgba(141, 208, 255, .05) 113.66%);
    overflow: hidden;
    border: 1px solid #ABD8F9;
    border-bottom: 0
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills ul:nth-child(1) {
    border-radius: 20px 20px 0 0
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills ul li {
    padding: 20px 20px 0 20px
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills ul li:nth-child(1) {
    padding-bottom: 0;
    border-right: 0
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills ul li h3 {
    font-size: 16px
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills .tech-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills .tech-list .tech-item {
    height: 40px;
    width: 40px;
    min-height: 55px;
    margin-right: 40px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .casesudies-professional-section .casesudies-professional-inner .tech-skills .tech-list .tech-item svg {
    width: 100%;
    height: auto
  }

  .blog-page-section .blog-banner-section {
    padding: 24px 0 10px 0
  }

  .blog-page-section .blog-banner-section .blog-banner-inner .blog-banner-content {
    padding: 48px 24px 28px 24px
  }

  .blog-page-section .blog-banner-section .blog-banner-inner .blog-banner-content .heading h2 {
    margin-bottom: 12px;
    font-size: 20px !important;
    line-height: 28px !important
  }

  .blog-page-section .blog-topstories-section {
    padding: 38px 0 0 0
  }

  .blog-topstories-section .blog-topstories-inner .blog-topstories-heading:before {
    top: 11px
  }

  .blog-topstories-section .blog-topstories-section .blog-topstories-inner .blog-topstories-heading h2:after {
    top: 9px
  }

  .blog-page-section .blog-topstories-section .blog-topstories-inner .blog-card .blog-img {
    height: 239px
  }

  .blog-page-section .blog-topstories-section .blog-topstories-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 24px
  }

  .blog-page-section .blog-topstories-section .blog-topstories-inner .blog-card .blog-content {
    padding: 12px 12px 16px 12px
  }

  .blog-topstories-section .blog-topstories-inner .blog-card-div {
    margin-bottom: 24px
  }

  .blog-page-section .blog-topstories-section .blog-topstories-inner .blog-card .blog-content ul li span {
    font-size: 14px;
    line-height: 21px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner nav {
    margin-top: 8px
  }

  .blog-page-section .book-section .book-box .book-text h2 {
    font-size: 24px;
    line-height: 36px
  }

  .book-section .book-box {
    padding: 54px 24px 54px 24px
  }

  .blog-page-section .blog-topstories-section .blog-topstories-inner .blog-card-small .blog-img img {
    height: 239px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-size: 12px;
    line-height: 18px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    height: 155px
  }

  .blog-page-section .book-section .book-box {
    background-image: url(../../public/images/bg-book-responsive.png);
    padding-top: 54px;
    padding-bottom: 36px
  }

  .blog-page-section .book-section {
    padding: 80px 0 80px 0
  }

  .blog-detail-development .blog-digital-section {
    padding-top: 24px
  }

  .blog-detail-development .blog-digital-section .business-development-btn {
    padding-bottom: 16px
  }

  .blog-detail-development .blog-digital-section .business-development-btn a {
    font-size: 12px;
    line-height: 18px
  }

  .blog-detail-development .blog-digital-section .blog-digital-inner .blog-digital-heading h2 {
    margin-bottom: 8px
  }

  .blog-detail-development .blog-digital-section .blog-digital-inner .blog-digital-heading p {
    font-size: 14px;
    line-height: 21px
  }

  .blog-detail-development .blog-digital-section .blog-digital-inner ul.blog-digital-date li a {
    font-size: 12px;
    line-height: 18px
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-migration-cantent .blog-migration-name {
    display: none
  }

  .blog-detail-development .blog-migration-section {
    padding: 0 0 20px 0
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading {
    max-width: 100%;
    justify-content: flex-start
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content {
    text-align: left
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-img img {
    width: 65px
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .blog-about-content p {
    font-size: 14px;
    line-height: 22px;
    margin: 0
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading h2 {
    margin-bottom: 15px
  }

  .blog-detail-development .blog-migration-section .blog-migration-inner .blog-about-box .blog-about-heading {
    border-bottom: 1px solid #8dd0ff
  }

  .blog-detail-development .blog-articles-section {
    padding-top: 64px
  }

  .blog-detail-development .blog-articles-section .blog-articles-inner .blog-card .blog-img {
    height: 144px
  }

  .blog-detail-development .blog-articles-section .blog-articles-inner .blog-card .blog-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
  }

  .blog-detail-development .blog-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-size: 12px;
    line-height: 18px
  }

  .blog-detail-development .blog-articles-section .blog-articles-inner .blog-card .blog-content h5 {
    font-size: 12px;
    line-height: 18px
  }

  .blog-detail-development .book-section .book-box .book-text h2 {
    font-size: 24px;
    line-height: 36px
  }

  .blog-detail-development .book-section .book-box {
    background-image: url(../../public/images/bg-book-responsive.png);
    padding-top: 54px;
    padding-bottom: 36px
  }

  .blog-detail-development .book-section {
    padding: 40px 0 80px 0
  }

  .request-proposal .request-banner-section {
    padding: 24px 0 20px 0
  }

  .request-proposal .request-banner-section .request-banner-inner .request-banner-content {
    padding: 48px 12px 38px 12px
  }

  .request-proposal .request-banner-section .request-banner-inner .request-banner-content h2 {
    font-size: 24px !important;
    line-height: 38px !important
  }

  .request-proposal .request-banner-section .request-banner-inner .request-banner-content .heading h3 {
    font-size: 14px;
    line-height: 21px
  }

  .request-proposal .request-form-section .request-form-inner .request-form-heading {
    margin-bottom: 24px
  }

  .request-proposal .request-form-section .request-form-inner .request-form-heading h6 {
    font-size: 12px;
    line-height: 18px
  }

  .request-proposal .request-form-section .request-form-inner .request-form .form-group .form-label {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding-bottom: 16px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .form-group .form-group-checkbox .form-check {
    align-items: flex-start
  }

  .request-proposal .request-form-section .request-form-inner .request-form .form-group .form-control {
    height: 48px;
    font-size: 14px;
    line-height: 21px
  }

  .request-proposal .request-form-section .request-form-inner .request-form .form-group {
    margin-bottom: 24px
  }

  .request-proposal .request-form-section .request-form-inner .request-form .form-group .form-group-checkbox .form-check .form-check-label {
    font-size: 14px;
    line-height: 21px
  }

  .request-proposal .form-check .form-check-input {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 12px !important
  }

  .request-proposal .form-check-input[type=checkbox]:checked:after {
    width: 6px;
    height: 11px;
    margin-left: 5px;
    margin-top: 1px
  }

  .request-proposal .form-check-input[type=checkbox]:checked {
    border: unset !important
  }

  .request-proposal .request-form-section {
    padding: 20px 0 0 0
  }

  .request-proposal .about-project-section {
    padding-top: 30px
  }

  .request-proposal .about-project-section .about-project-inner .about-project-heading {
    margin-bottom: 30px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .form-group {
    margin-bottom: 8px !important
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .form-group .form-label {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding-bottom: 12px
  }

  .request-proposal .about-project-section .about-project-inner .about-project-heading h6 {
    font-size: 12px;
    line-height: 18px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .form-group .form-group-checkbox .form-check .form-check-label {
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 16px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .text-area {
    padding: 14px;
    font-size: 14px;
    line-height: 21px;
    min-height: 163px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    padding: 25px 20px 28px 20px;
    min-height: 163px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .upload-file .upload-file-img {
    width: 50px;
    height: 50px;
    margin: auto;
    margin-bottom: 27px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .upload-file .upload-file-img img {
    width: 30px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .upload-file .upload-text span {
    font-size: 9px;
    line-height: 12px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .budget-content {
    margin-top: 0
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider {
    padding: 10px 10px 10px 14px;
    border: 1px solid rgba(4, 4, 5, 0.2);
    border-radius: 10px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider {
    padding: 10px 12px 0 12px
  }

  .request-proposal .agreement-section .agreement-inner .agreement-list .agreement-box .form-group .form-group-checkbox .form-check .form-check-label {
    font-size: 14px;
    line-height: 22px
  }

  .request-proposal .agreement-section .agreement-inner .agreement-list {
    padding: 15px 0 12px 12px;
    margin-bottom: 60px
  }

  .request-proposal .agreement-section .agreement-inner .conect-box {
    padding: 46px 26px
  }

  .request-proposal .agreement-section .agreement-inner .conect-box .conect-box-content h3 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 28px
  }

  .request-proposal .agreement-section .agreement-inner .conect-box .skype-conect a p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    padding-right: 10px
  }

  .request-proposal .agreement-section {
    padding-bottom: 48px;
    padding-top: 48px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .slider-labels .caption p span {
    font-size: 14px;
    line-height: 21px
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .budget-content .range-slider .MuiBox-root {
    margin: 0;
    margin-top: 8px
  }

  .request-proposal .agreement-section .agreement-inner .conect-box {
    background-image: url(../../public/images/agreement-responsive.png);
    background-size: 100% 100%
  }

  .request-proposal .agreement-section .agreement-inner .conect-box .skype-conect a img {
    width: 14px
  }

  .modal-form .form-group {
    margin-bottom: 24px
  }

  .modal-form .modal-content .modal-title {
    font-size: 16px;
    line-height: 22px
  }

  .modal-form .form-group .form-control,
  .modal-form .form-group .form-select {
    font-size: 14px;
    line-height: 20px
  }

  .case-studies-main-section .casestudies-section {
    padding: 24px 0 10px 0
  }

  .case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content {
    padding: 48px 24px 28px 24px
  }

  .case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content .heading h2 {
    margin-bottom: 12px;
    font-size: 20px !important;
    line-height: 28px !important
  }

  .book-section .book-box .book-text h2 {
    font-size: 24px !important;
    line-height: 36px !important
  }

  .book-section .book-box {
    padding: 54px 24px 54px 24px !important
  }

  .book-section .book-box {
    background-image: url(../../public/images/bg-book-responsive.png) !important;
    padding-top: 54px !important;
    padding-bottom: 36px !important
  }

  .book-section {
    padding: 80px 0 80px 0 !important
  }

  .technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content {
    padding: 48px 12px
  }

  .technologies-middle .technologies-tabs-section {
    display: none
  }

  .technologies-middle .onestop-section {
    padding: 18px 0
  }

  .technologies-middle .onestop-section .onestop-inner .heading {
    margin-bottom: 32px
  }

  .technologies-middle .onestop-section .onestop-inner .box-card {
    margin-bottom: 25px
  }

  .technologies-middle .onestop-section .onestop-inner .box-card .onestop-box .box-counter {
    padding: 26px 10px
  }

  .technologies-middle .milestone-section {
    padding: 36px 0 0 0
  }

  .technologies-middle .technologies-section {
    padding: 40px 0 5px
  }

  .technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-card {
    padding: 0 0 5px 0
  }

  .technologies-middle .technologies-section .technologies-feature-inner .technologies-feature-card .technologies-feature-image {
    margin-top: 16px
  }

  .technologies-middle .design-stage-section {
    padding: 63px 0 40px
  }

  .technologies-middle .digital-service-section .heading h2 {
    margin-bottom: 32px
  }

  .technologies-middle .framework-section {
    padding: 24px 0 0
  }

  .technologies-middle .framework-section .framework-inner .heading {
    margin-bottom: 58px
  }

  .technologies-middle .faq-section {
    padding: 80px 0 40px
  }

  .aboutus-middle .about-section {
    padding: 32px 0 20px
  }

  .aboutus-middle .about-work-slider {
    display: block
  }

  .aboutus-middle .about-work-slider .slick-slide img {
    display: block;
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 12px
  }

  .aboutus-middle .about-work-slider .work-image-item {
    padding: 0 12px
  }

  .aboutus-middle .meet-management-section .meet-profile::after {
    background-size: contain
  }

  .aboutus-middle .meet-management-section .meet-slider {
    max-width: 280px
  }

  .aboutus-middle .meet-management-section .meet-slider .slick-next {
    right: -30px
  }

  .aboutus-middle .meet-management-section .meet-slider {
    max-width: 310px;
    padding: 0 40px
  }

  .aboutus-middle .difference-section .deference-feature .feature-box h2 {
    text-align: right;
    font-size: 28px;
    line-height: 42px
  }

  .aboutus-middle .difference-section .deference-feature .feature-box {
    padding: 13px 24px 22px 13px;
    border: 1px solid #f3f9fe
  }

  .aboutus-middle .difference-section .deference-feature>div>div {
    padding: 0
  }

  .aboutus-middle .aboutus-middle .believe-section .believe-inner .believe-box {
    margin-bottom: 32px
  }

  .aboutus-middle .aboutus-middle .vibe-section {
    padding: 40px 0 80px
  }

  .aboutus-middle .meet-management-section .heading {
    text-align: center
  }

  .industry-middle .heading h3 {
    margin-bottom: 8px !important
  }

  .industry-middle .agile-banner-section {
    padding: 20px 0 10px 0
  }

  .industry-middle .agile-banner-section .agile-banner-inner .agile-banner-content {
    min-height: inherit
  }

  .industry-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading {
    max-width: 500px
  }

  .industry-middle .demand-education-section .demand-inner .e-demand-box {
    margin-bottom: 32px
  }

  .industry-middle .demand-education-section {
    padding: 40px 0 10px 0
  }

  .industry-middle .industries-system-section {
    padding: 10px 0 80px 0
  }

  .industry-middle .industries-system-section .industries-card h2 {
    font-size: 24px;
    line-height: 36px;
    max-width: 100%
  }

  .industry-middle .industries-system-section .development-box .development-top {
    flex-direction: column;
    padding: 12px
  }

  .industry-middle .industries-system-section .development-box .development-bottom .development-links li a::after {
    top: 17px
  }

  .industry-middle .industries-system-section .development-box .development-bottom .development-links li a:hover::after {
    top: 15px
  }

  .industry-middle .industries-system-section .development-box .development-bottom .development-links li a {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 29px
  }

  .industry-middle .industries-system-section {
    padding: 10px 0 10px 0
  }

  .industry-middle .industries-relevant-section {
    padding: 50px 0 0 0
  }

  .industry-middle section .steps-feature:last-child {
    margin-bottom: 0
  }

  .industries-middel .industries-relevant-section .heading h2 {
    font-size: 28px;
    line-height: 42px
  }

  .industries-middel .industries-relevant-section .rel-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px
  }

  .industries-middel .design-stage-section .steps-detail .steps-feature .feature-content p {
    font-size: 12px;
    line-height: 18px
  }

  .industries-middel .design-stage-section .steps-detail .steps-feature .feature-content h3 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 7px
  }

  .industries-middel .design-stage-section .steps-detail .steps-feature .feature-img img {
    height: 40px;
    width: 40px;
    border-radius: 9px;
    margin-right: 17px
  }

  .industries-middel .industries-tabs-section {
    display: none
  }

  .digital-middle .design-stage-section .steps-detail .step-row {
    padding-bottom: 50px
  }

  .startups-middle .heading .btn.btn-primary {
    line-height: 18px
  }

  .blog-detail-development .blog-digital-section {
    padding-bottom: 50px
  }

  .react-select-3-listbox {
    background-color: #fff;
    margin: 0 !important
  }

  .startups-idea-section .startups-idea-inner .heading {
    max-width: 555px
  }

  .startups-middle .agile-banner-section {
    padding: 20px 0 40px 0
  }

  .startups-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading h3 {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px
  }

  .startups-middle .agile-banner-section .agile-banner-inner .agile-banner-content .heading h2 {
    font-size: 28px;
    line-height: 42px
  }

  .startups-middle .agile-banner-section .agile-banner-inner .agile-banner-content .btn-primary {
    padding: 6px 10px
  }

  .startups-middle .startups-looking-section .startups-looking-inner .startups-looking-content h2 {
    font-size: 24px;
    line-height: 36px;
    text-align: center
  }

  .startups-middle .startups-looking-section .startups-looking-content p {
    font-size: 16px;
    line-height: 24px;
    text-align: center
  }

  .startups-middle .milestone-section .heading h3 {
    font-size: 14px !important;
    line-height: 21px !important
  }

  .startups-middle .startups-middle section .heading h2 {
    font-size: 24px !important;
    line-height: 26px !important
  }

  .startups-middle .startups-features-section .startups-features-inner .startups-heading h2 {
    font-size: 20px;
    line-height: 30px
  }

  .startups-middle .startups-hireteam-section .startups-hireteam-inner .startups-hireteam-card .startups-hireteam-content h2 {
    font-size: 24px;
    line-height: 36px
  }

  .startups-middle .m-none {
    display: none
  }

  .startups-middle .mobile-process-inner {
    display: block
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li {
    padding-bottom: 40px;
    position: relative;
    text-align: center
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li::after {
    content: "";
    top: auto !important;
    bottom: 5px;
    background-image: url(../../assets/images/grey-vertical-line.svg);
    height: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 8px;
    position: absolute
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li:last-child {
    padding-bottom: 0
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li:last-child::after {
    display: none
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li.list-active .process-list-title,
  .startups-middle .mobile-process-inner .mobile-process-list ul li:hover .process-list-title {
    color: #040405;
    font-weight: 700
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li.list-active p,
  .startups-middle .mobile-process-inner .mobile-process-list ul li:hover p {
    color: #040405 !important
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li.list-active::after,
  .startups-middle .mobile-process-inner .mobile-process-list ul li:hover::after {
    background-image: url(../../assets/images/blue-vertical-image.svg) !important
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li.list-active .process-text,
  .startups-middle .mobile-process-inner .mobile-process-list ul li:hover .process-text {
    max-height: 1000px;
    overflow: hidden;
    opacity: 1
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li .process-list-title {
    font-size: 28px !important;
    line-height: 42px;
    margin-bottom: 12px;
    color: #91959b
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li .process-text {
    max-height: 0;
    overflow: hidden;
    opacity: 0
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #91959b !important
  }

  .startups-middle.desktop-process-inner {
    display: none
  }

  .startups-middle .mobile-process-inner .mobile-process-list ul li .process-list-title {
    font-size: 24px !important;
    line-height: 36px
  }

  .dx-none {
    display: inline-block !important
  }

  .mb-none {
    display: none !important
  }

  .blog-digital-inner .btn.btn-primary {
    margin-bottom: 10px;
    height: 32px;
    padding: 9px 12px;
    font-size: 12px;
    line-height: 14px
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul li a {
    font-size: 12px;
    line-height: 22px;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 2px
  }

  .technologies-middle .milestone-section .heading {
    margin-bottom: 20px
  }

  .service-middle .technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top a.btn {
    padding: 9px 10px;
    line-height: 23px !important;
    height: 40px
  }

  .agile-banner-section .agile-banner-inner .agile-banner-content .btn-primary {
    line-height: 23px
  }

  .book-section .book-box .book-text .hire-btn .btn-primary {
    line-height: 23px
  }

  .technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .btn.btn-primary {
    line-height: 23px
  }

  .homepage-middle .sticky-bottom a {
    line-height: 19px;
    padding: 12px
  }

  .startups-middle .agile-banner-section .agile-banner-inner .agile-banner-content .btn-primary {
    padding: 9px 10px
  }

  .startUp-detail-middle .startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-about .startups-separate-about-content .btn-primary {
    line-height: 23px
  }

  .startUp-detail-middle .startups-planning-section .startups-planning-inner .startups-planning-heading .btn-primary {
    line-height: 23px
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button,
  .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    padding: 12px 35px 12px 12px
  }

  .faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 20px 12px
  }

  .startups-middle .startups-vacation-inner .btn.btn-primary {
    line-height: 23px;
    padding: 9px 10px !important
  }

  .startups-middle .technologies-tabs-section {
    display: none
  }

  .startups-separate-section .startups-separate-inner .startups-separate-imgbox.startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-img {
    max-width: 210px;
    margin: 0
  }

  .startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-img img {
    max-width: 100%
  }

  .difference-section .deference-feature>div>div {
    order: unset !important
  }

  .digital-middle .stage-select-dropdown {
    padding-bottom: 25px
  }

  .agile-recommend-section {
    padding: 30px 0 30px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-img {
    text-align: center;
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-img img {
    width: auto
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .heading h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div {
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user .recommend-user-img {
    margin-right: 10px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-user-div .recommend-user .recommend-user-name h3 {
    font-size: 14px;
    line-height: 20px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu {
    padding: 15px 0;
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .logistics-card {
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .logistics-card h3 {
    font-size: 16px;
    line-height: 20px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .recommend-indu .logistics-card h2 {
    font-size: 18px;
    line-height: 22px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div {
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-heading {
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-heading h3 {
    font-size: 18px;
    line-height: 22px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card {
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .Goals-div .Goals-content .goals-card h2 {
    font-size: 24px;
    line-height: 30px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-heading {
    margin-bottom: 15px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-heading h3 {
    font-size: 18px;
    line-height: 22px
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul li {
    margin-bottom: 20px
  }

  .maintanence-banner .mantainence-banner-content h4 {
    font-size: 18px;
    line-height: 28px
  }

  .compeny-section .compeny-section-inner .agile-box {
    padding: 15px
  }

  .compeny-section .compeny-section-inner .agile-box h2 {
    font-size: 26px;
    line-height: 42px
  }

  .partner-section .partner-section-inner .heading h3 {
    margin-bottom: 0
  }

  .partner-section .partner-section-inner .heading h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0
  }

  .partner-section .partner-section-inner p {
    line-height: 20px;
    font-size: 14px
  }

  .request-proposal .agreement-section .agreement-inner .conect-box {
    background-position: 0% 2%, 108% -4%, -6% 100%, 105% 100% !important;
    padding: 55px 26px
  }

  .request-proposal .agreement-section .agreement-inner .agreement-link {
    max-width: 400px;
    margin: 0 auto;
    position: relative
  }

  .request-proposal .agreement-section .agreement-inner .agreement-link:after {
    content: "";
    width: 1px;
    height: 100%;
    left: 50%;
    top: 0;
    position: absolute;
    background-color: #000
  }

  .agreement-section .agreement-inner .conect-box .skype-conect a {
    border-right: none !important;
    justify-content: center !important
  }

  .agreement-section .agreement-inner .conect-box .skype-conect {
    text-align: center
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name>.row {
    flex-flow: column-reverse
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name {
    margin-bottom: 0
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-allstories-name ul {
    margin-top: 20px
  }

  .casestudy-details-middle .casestudies-stories-section .casestudies-stories-inner .casestudies-stories-heading h2 {
    font-size: 20px;
    line-height: 26px
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 15px 50px 15px 15px
  }

  .casestudy-details-middle .casesudies-professional-section .casesudies-professional-inner .row.justify-content-center {
    justify-content: flex-start !important
  }

  .casestudy-details-middle .casesudies-professional-section .casesudies-professional-inner .heading h3 {
    text-align: left
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name>.row {
    flex-flow: column-reverse
  }

  .blog-allstories-section .blog-allstories-inner .blog-allstories-name ul {
    margin-top: 30px
  }

  .blog-detail-development .blog-articles-section .blog-articles-heading h2 {
    font-size: 20px;
    line-height: 26px
  }

  .technology-feature-top {
    padding-top: 30px
  }

  .technology-feature-top .startups-vacation-inner .heading-wrapper {
    padding-bottom: 0;
    border: 0
  }

  .technology-feature-top .startups-vacation-inner .heading-wrapper a {
    display: inline !important
  }

  .technology-feature-top .startups-vacation-inner .startups-vacation-content {
    margin-top: 20px
  }

  .technology-feature-top .startups-vacation-inner .startups-vacation-content p {
    padding-right: 0
  }

  .technology-stack .casesudies-professional-inner .heading {
    margin-bottom: 30px
  }

  .technology-stack .casesudies-professional-inner .heading h3 {
    margin-bottom: 0
  }

  .technology-stack .casesudies-professional-inner .tech-skills ul li {
    padding: 15px 25px 0 25px
  }

  .technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge {
    margin-bottom: 6px
  }

  .technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge:before {
    border-radius: 6px
  }

  .technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge:after {
    border-radius: 8px
  }

  .technology-stack .casesudies-professional-inner .tech-skills .tech-list .tech-list-badge:not(:last-child) {
    margin-right: 8px
  }

  .technology-feature {
    padding-bottom: 0
  }

  .technology-feature .slick-prev {
    top: -36px
  }

  .technology-feature .slick-next {
    top: -36px
  }

  .technology-feature .startups-features-inner .startups-heading h2 {
    font-size: 18px;
    line-height: 26px
  }

  .technology-feature .startups-features-inner .startups-features-vacation .startups-features-card {
    padding-top: 20px
  }

  .technology-feature .startups-features-inner .startups-features-vacation .startups-features-card p {
    font-size: 12px;
    line-height: 18px
  }

  .technologies-middle .technologies-section {
    padding-top: 0
  }

  .technologies-middle .technologies-section .technologies-feature-inner {
    padding-top: 30px
  }

  .technologies-middle .anchor-before[id]::before {
    height: 120px;
    margin: -100px 0 0
  }

  .news-letter-section .news-letter-content {
    height: 204px;
    display: flex;
    align-items: center
  }

  .book-section .book-box {
    background-image: none !important
  }

  .news-letter-section .news-letter-content:after,
  .book-section .book-box:after {
    background-image: url(../../assets/images/desired-position-mb-1.svg), url(../../assets/images/desired-position-mb-2.svg), url(../../assets/images/desired-position-mb-3.svg), url(../../assets/images/desired-position-mb-4.svg);
    background-position: top left, bottom left, top right, bottom right;
    background-size: auto;
    background-repeat: no-repeat;
    opacity: 1
  }

  .book-section .book-box:after {
    background-image: url(../../assets/images/book-box-bg-mb-1.svg), url(../../assets/images/book-box-bg-mb-2.svg), url(../../assets/images/book-box-bg-mb-3.svg), url(../../assets/images/book-box-bg-mb-4.svg)
  }

  .news-letter-section .news-letter-content .news-letter-link a {
    padding: 0 20px
  }

  .banner-section {
    overflow-x: hidden
  }

  .case-studies-v2 .anchor-top {
    position: absolute;
    top: -130px
  }

  .career-middle .careers-banner-section .careers-banner-inner h1.page-heading {
    font-size: 28px;
    line-height: 40px;
    max-width: 345px
  }

  .industries-middel .industries-system-section .development-box .development-bottom .development-links li .development-links-btn {
    padding-left: 29px;
    padding-top: 12px
  }

  .industries-middel .industries-system-section .development-box .development-bottom .development-links li .development-links-btn::after {
    top: 18px
  }

  .request-proposal-form .about-project-section {
    padding-bottom: 0
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .job-description-inner {
    display: block;
    margin-bottom: 0
  }

  .hire-developer-modal.modal-form .modal-content .modal-footer .modal-recaptcha {
    margin-bottom: 30px !important;
    display: flex;
    justify-content: center
  }

  .hire-developer-modal.modal-form .modal-content .modal-footer .modal-footer-btn {
    text-align: center
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    padding: 0
  }

  .modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img img {
    width: auto;
    height: auto
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    margin: 0;
    padding: 8px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group textarea {
    min-height: 80px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .job-description-inner .form-separator {
    padding-right: 0
  }

  .hire-developer-modal.modal-form .modal-content .modal-footer .modal-footer-btn {
    padding-top: 0
  }

  .request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    padding: 15px 20px 15px 20px
  }

  .request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file {
    min-height: auto
  }

  .request-proposal-form .agreement-section .agreement-inner .agreement-list .agreement-box {
    display: flex
  }

  .request-proposal-form .agreement-section .agreement-inner .agreement-list .agreement-box .agreement-btn {
    padding-top: 0
  }

  .request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    text-align: left
  }

  .request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data .upload-text {
    padding-left: 20px
  }

  .request-proposal-form .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    padding: 10px 10px 10px 10px
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row .blog-about-img {
    width: 65px;
    height: 65px;
    margin-bottom: 0
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about {
    padding-left: 0;
    padding-bottom: 0
  }

  .blog-migration-section .blog-migration-inner .blog-about-box .blog-about .author-data-row .blog-about-img img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%
  }

  .sticky-bottom.footerBtn {
    position: fixed;
    right: 0;
    bottom: 0
  }

  .footer-section .footer-copy-right.footer-bottom {
    z-index: 80;
    position: relative;
    background: #f6faff
  }

  .theme-dark .footer-section .footer-copy-right.footer-bottom {
    background: #111
  }

  .case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading ul li {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px
  }

  .request-proposal-form.request-proposal .about-project-section .about-project-inner .select-type-content .text-area {
    min-height: 150px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h2,
  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail h1 {
    font-size: 20px;
    line-height: 30px
  }

  .compeny-section .compeny-box-border,
  .compeny-section .compeny-box-border-2 {
    height: auto !important
  }

  .default-page {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 40px 0
  }

  .default-page h1 {
    font-size: 22px;
    line-height: 34px
  }

  .default-page h2 {
    font-size: 20px;
    line-height: 30px
  }

  .default-page h3 {
    font-size: 18px;
    line-height: 26px
  }

  .default-page p,
  .default-page a,
  .default-page li {
    font-size: 16px;
    line-height: 24px
  }

  .error-page-title h1 {
    font-size: 28px;
    line-height: 38px
  }

  .error-page-img {
    max-width: 250px;
    margin: 0 auto 40px
  }

  .technologies-middle .agile-banner-section .agile-banner-inner .agile-banner-content .btn.btn-primary {
    font-size: 14px
  }

  .careers-forms-modal.modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content {
    padding: 0 0
  }

  .modal-form .modal-body .request-form .accordion-body {
    font-size: 14px;
    line-height: 24px
  }

  .sticky-bottom.footerBtn {
    position: fixed;
    right: 10px;
    bottom: 10px
  }

  .service-feature-section .service-feature-card .service-feature-content h2 {
    margin-bottom: 15px
  }

  .thank-you-middle .thank-you-content {
    padding-top: 60px;
    padding-bottom: 40px
  }

  .thank-you-middle .thank-you-content .thank-you-img {
    max-width: 150px
  }

  .thank-you-middle .thank-you-content .thank-you-title {
    padding-bottom: 12px
  }

  .thank-you-middle .thank-you-content .thank-you-title h1 {
    font-size: 28px;
    line-height: 52px
  }

  .thank-you-middle .thank-you-content .thank-you-title h1 span {
    font-size: 26px;
    line-height: 32px
  }

  .thank-you-middle .thank-you-content .thank-page-content p {
    font-size: 16px;
    line-height: 24px
  }

  .wordwise-section {
    padding-bottom: 40px
  }

  .startUp-detail-middle .startups-demandtaxi-section {
    padding: 20px 0 0
  }

  .case-studies-process-section .case-studies-process-inner .case-studies-process-wrapper .business-content .process-content .process-text .case-studies-heading ol li {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px
  }

  .blog-allstories-section .blog-allstories-inner nav .pagination {
    flex-wrap: wrap
  }

  .blog-allstories-section .blog-allstories-inner nav .pagination li {
    margin-top: 5px;
    margin-bottom: 5px
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link:hover .arrow {
    opacity: 0
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link:hover .arrow {
    opacity: 0
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link.active .arrow {
    opacity: 1 !important
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link.active .arrow {
    opacity: 1 !important
  }

  .startups-features-section .slick-prev,
  .startups-features-section .slick-next {
    width: 35px;
    height: 35px
  }

  .startups-features-section .slick-next::before,
  .startups-features-section .slick-prev::before {
    width: 16px;
    height: 16px;
    background-size: contain
  }

  .request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .form-group .form-label {
    padding-bottom: 0
  }

  .request-proposal-new.request-proposal.request-proposal-form .agreement-section {
    padding-top: 24px
  }

  .modal-banner-left p {
    font-size: 14px;
    line-height: 21px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    align-items: flex-start
  }

  .col-pr-0 {
    padding-right: 12px !important
  }

  .col-pl-0 {
    padding-left: 12px !important
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group.form-group-left {
    margin-bottom: 0
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .form-control {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group {
    margin-bottom: 24px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group.modal-recaptcha {
    margin-bottom: 24px !important
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .form-control {
    border-left: 1px solid rgba(4, 4, 5, 0.2) !important
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .file-upload-data {
    padding: 20px 13px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group.form-group-left {
    position: relative
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group.form-group-left:after {
    content: "or";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 100%;
    border: 1px solid rgba(4, 4, 5, 0.2) !important;
    position: absolute;
    bottom: -12px;
    width: 24px;
    text-align: center;
    height: 24px;
    left: calc(50% - 12px);
    background-color: #fff;
    color: #91959b !important
  }

  .hire-developer-modal .modal-banner-left {
    height: auto;
    margin: 100px 20px 0;
    display: block;
    text-align: center;
    border-radius: 20px
  }

  .hire-developer-modal .modal-left-img {
    margin: -100px auto 16px;
    height: 206px;
    width: 275px;
    max-width: 100%
  }

  .modal-form-request-quote.modal-form .col-pr-0 .form-group {
    margin-bottom: 0 !important
  }

  .modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .modul-document-link .form-control {
    border-top: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 1px solid rgba(4, 4, 5, 0.2) !important
  }

  .modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .modul-document-link:after {
    top: -12px;
    left: 50%;
    transform: translate(-50%, 0)
  }

  .hire-developer-modal.modal-form.modal-form-request-quote .modal-content .modal-body .form-group .modal-upload-file {
    border-top-right-radius: 10px
  }

  .modal-form-request-quote.hire-developer-modal.modal-form .modal-content .modal-body .col-pl-0 .form-group .modul-document-link .form-control {
    padding-left: 16px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .form-control,
  .modal-form .modal-content .modal-body .form-group .form-select {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }

  .aboutus-middle .meet-management-section .meet-slider .slick-next {
    right: -30px
  }

  .lets-connect-middle {
    padding-bottom: 40px
  }

  .lets-connect-middle.hire-developer-modal .modal-banner-left {
    margin-top: 100px
  }

  .lets-connect-modal .modal-content .modal-body .card {
    margin-bottom: 20px
  }

  .lets-connect-modal .modal-content .modal-body .card .card-img {
    height: 150px
  }

  .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-img {
    height: 150px
  }

  .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-img h2 {
    font-size: 32px;
    line-height: 60px
  }

  .lets-connect-modal.lets-connect-newmodal .modal-dialog {
    max-width: 600px
  }

  .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card {
    height: auto
  }

  .lets-connect-modal.lets-connect-newmodal .modal-content .modal-body .card .card-body {
    padding: 0 24px 24px
  }

  .partner-logo-title p {
    font-size: 16px
  }

  .partner-logo-title p {
    padding-bottom: 20px
  }

  .partner-logo-img img {
    max-height: 35px
  }

  .lets-connect-middle {
    padding-top: 30px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    height: 205px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content h3,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-content h3 {
    font-size: 16px;
    line-height: 24px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-content ul li span,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-content ul li span {
    font-size: 14px;
    line-height: 21px
  }

  .topStories-aspectratio-43 .topStories-aspectratio-43-col1 .blog-card .blog-img,
  .topStories-aspectratio-43 .topStories-aspectratio-43-col2 .blog-card-small .blog-img {
    max-height: unset !important
  }

  .topStories-aspectratio-43 .topStories-aspectratio-43-col1 .blog-card .blog-img img,
  .topStories-aspectratio-43 .topStories-aspectratio-43-col2 .blog-card-small .blog-img img {
    aspect-ratio: 4/3
  }

  .partner-logo-img img {
    max-width: 120px
  }

  .request-proposal-page.request-proposal-new.request-proposal.request-proposal-form .blocks-section {
    padding-top: 40px
  }

  .bd-book-section.book-section .book-box .book-text h2 {
    font-size: 16px !important;
    line-height: 24px !important
  }

  .contact-us-page .partner-logo-section {
    padding: 40px 0
  }

  .contact-us-page .request-proposal-new.request-proposal.request-proposal-form .request-form-section {
    padding-top: 30px
  }

  .blog-detail-development .blog-digital-section {
    padding-bottom: 0
  }

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 15px 50px 15px 15px
  }

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner h3 {
    font-size: 14px !important;
    line-height: 24px !important
  }

  .submenu-bottom-content {
    padding-top: 30px
  }

  .service-feature-section .service-follow .service-follow-content ul li::after {
    top: 6px
  }

  .btn-primary {
    padding: 9px 10px
  }

  .company-banner-section .company-banner-inner .img-wrapper {
    width: 220px
  }

  .count-on-section {
    padding-bottom: 50px
  }

  .count-on-section .count-on-section-inner .count-on-content {
    max-width: unset;
    margin-bottom: 20px
  }

  .count-on-section .count-on-section-inner .count-on-content .title {
    max-width: 460px;
    font-size: 24px;
    line-height: 32px
  }

  .count-on-section .count-on-section-inner .img-wrapper {
    max-width: 360px
  }

  .hiring-banner-wrapper {
    padding-bottom: 50px
  }

  .hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-banner-box-inner {
    flex-direction: column
  }

  .hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-box-left {
    max-width: unset
  }

  .hiring-banner-wrapper .hiring-banner-inner .hiring-banner-box .hiring-box-left .title {
    text-align: center;
    font-size: 24px;
    line-height: 32px
  }

  .services-technology-section.technologies-section {
    padding-bottom: 40px
  }

  .services-technology-section.technologies-section .technologies-inner .heading {
    max-width: 435px
  }

  .services-technology-section.technologies-section .technologies-inner .heading h2 {
    font-size: 24px;
    line-height: 32px
  }

  .services-technology-section.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start
  }

  .services-technology-section.technologies-section .technologies-inner .technologies-cantent .tabs-content {
    padding: 15px 0
  }

  .services-technology-section.technologies-section .technologies-inner .technologies-cantent .tabs-content .content-top {
    margin-bottom: 0
  }

  .latest-technology-wrapper .latest-technology-inner .heading-wrapper .title {
    font-size: 24px;
    line-height: 32px
  }

  .latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card {
    padding: 15px
  }

  .latest-technology-wrapper .latest-technology-inner .latest-technology-content .latest-technology-card .card-text p {
    font-size: 12px;
    line-height: 18px
  }

  .serives-new-middle .testimonial-section .testimonial-inner .gallery-thumbs {
    padding-bottom: 0
  }

  .serives-new-middle .testimonial-section .testimonial-inner .heading {
    margin-bottom: 30px
  }

  .serives-new-middle .testimonial-section .testimonial-inner .heading h2 {
    font-size: 24px;
    line-height: 32px
  }

  .transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper .title {
    font-size: 24px;
    line-height: 32px
  }

  .transfroming-industries-wrapper .transfroming-industries-inner .transfroming-industries-content .industry-badge .title {
    font-size: 14px;
    line-height: 18px
  }

  .approach-section-wrapper .approach-section-inner .heading-wrapper .title {
    font-size: 24px;
    line-height: 32px
  }

  .approach-section-wrapper .approach-section-inner .approach-section-content .row {
    flex-direction: column-reverse
  }

  .approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item {
    max-width: unset
  }

  .approach-section-wrapper .approach-section-inner .approach-section-content .img-wrapper {
    max-width: 360px;
    margin: 0 auto;
    margin-bottom: 15px
  }

  .why-trusted-section .why-trusted-inner .heading-wrapper .title {
    font-size: 24px;
    line-height: 32px
  }

  .case-study-section .case-study-inner .case-study-top .heading-wrapper .title {
    font-size: 24px;
    line-height: 32px
  }

  .case-study-section .case-study-inner .case-study-top .btn-wrapper .btn-secondary {
    padding: 9px 10px;
    line-height: 20px
  }

  .case-study-section .case-study-inner .case-study-card .card-content .title {
    font-size: 14px;
    line-height: 22px
  }

  .services-faq-section.faq-section .faq-inner .heading {
    margin-bottom: 30px
  }

  .services-faq-section.faq-section .faq-inner .heading h2 {
    font-size: 24px;
    line-height: 32px
  }

  .latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content {
    border-radius: 0 0 12px 12px
  }

  .latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-title {
    font-size: 14px;
    line-height: 22px
  }

  .latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-meta span {
    font-size: 12px;
    line-height: 18px
  }

  .latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-meta span:last-child {
    padding-left: 20px
  }

  .latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-meta span:last-child::before {
    width: 4px;
    height: 4px
  }

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner,
  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 10px 12px
  }

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner h3,
  .case-studies-main-section .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content h3 {
    font-size: 14px !important;
    line-height: 19px !important;
    font-weight: 500
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card .casestudies-img {
    max-height: unset
  }

  .marquee-container-new {
    padding: 5px
  }

  .developer-slider-list li {
    font-size: 18px;
    line-height: 28px;
    padding: 0 5px
  }

  .meet-management-section .meet-slider .meet-thumb {
    margin: 5px auto 0
  }

  .footer-section .footer-bottom .footer-copyright .copyright {
    justify-content: center
  }

  .meet-management-section .meet-profile {
    width: 250px;
    min-height: 375px
  }
}

@media (max-width:600px) {

  h1.page-heading,
  .request-banner-section .request-banner-inner .request-banner-content .heading h1.page-heading,
  .about-section .heading h1,
  .findus-section .heading h1.page-heading,
  .findus-section .heading h2.page-heading,
  .about-section .heading h1,
  .service-middle .service-hero-section .heading h1.page-heading,
  .digital-banner-section .digital-banner-inner .heading h1.page-heading,
  .agile-banner-section .agile-banner-inner .agile-banner-content .heading h1.page-heading,
  .blog-banner-section .blog-banner-inner .blog-banner-content .heading h1,
  .blog-digital-section .blog-digital-inner .blog-digital-heading h1,
  .case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content .heading h1,
  .case-studies-section .case-studies-inner .case-studies-heading h1 {
    font-size: 26px;
    line-height: 34px
  }

  .lets-connect-modal.lets-connect-newmodal .modal-dialog {
    max-width: calc(100% - 40px)
  }

  .case-studies-main-section .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img img {
    object-position: unset !important
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-img {
    height: auto;
    width: 100%
  }

  .casestudies-stories-section .casestudies-stories-inner .casestudies-card-small .casestudies-img {
    height: auto;
    width: 100%
  }

  .div-grid-row .div-grid-col:nth-of-type(1) .casestudies-card-small .casestudies-img img,
  .div-grid-row .div-grid-col:nth-of-type(8n) .casestudies-card-small .casestudies-img img {
    object-position: unset !important
  }

  .casestudies-allstories-section .casestudies-allstories-inner .casestudies-card-small .casestudies-img {
    height: auto !important;
    width: 100%
  }

  .div-grid-row .div-grid-col:nth-of-type(1) .casestudies-card-small .casestudies-img,
  .div-grid-row .div-grid-col:nth-of-type(8n) .casestudies-card-small .casestudies-img {
    height: auto !important;
    width: 100%
  }

  .div-grid-row .div-grid-col:nth-of-type(1) .casestudies-card-small .casestudies-img img,
  .div-grid-row .div-grid-col:nth-of-type(8n) .casestudies-card-small .casestudies-img img {
    height: auto;
    width: 100%
  }
}

@media (max-width:576px) {
  .modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content h2 {
    font-size: 16px;
    line-height: 23px
  }

  .modal-form.careers-forms-modal .modal-content .modal-banner-section .modal-banner-content p {
    font-size: 12px;
    line-height: 19px
  }

  .modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-header .accordion-button {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 19px
  }

  .modal-form.careers-forms-modal .modal-content .request-form .accordion .accordion-item .accordion-body {
    padding: 15px 0
  }

  .modal-form .form-group .form-label {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 5px
  }

  .modal-form .form-group .form-control,
  .modal-form .form-group .form-select {
    padding: 8px 15px
  }

  .modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-file-img {
    width: 45px;
    height: 45px
  }

  .modal-form.careers-forms-modal .modal-content .modal-body .form-group .modal-upload-file {
    padding: 15px 20px
  }
}

@media (max-width:575px) {
  .careers-about-section {
    padding: 30px 0 30px
  }

  .careers-about-section .careers-about-left h2 {
    font-size: 18px;
    margin-bottom: 15px
  }

  .careers-about-section .careers-about-right {
    max-width: unset
  }

  .careers-about-section .careers-about-right img {
    width: 100%
  }

  .careers-contributer-section {
    padding: 30px 0 30px
  }

  .news-letter-section {
    padding: 0 0 30px
  }

  .news-letter-section .news-letter-content h2 {
    font-size: 22px
  }

  .news-letter-section .news-letter-content .news-letter-link a span img {
    height: 12px;
    width: 12px;
    margin-top: -5px
  }

  .job-positions-section {
    padding: 40px 0 20px
  }

  .job-positions-section .section-titel h2 {
    line-height: 35px;
    font-size: 22px
  }

  .job-positions-section .section-titel p {
    font-size: 16px;
    line-height: 26px
  }

  .contact-us-page .media-section {
    overflow: hidden
  }

  .contact-us-page .media-section .twitter-timeline iframe {
    max-width: 300px;
    margin: 0 auto
  }

  .job-positions-section .job-searching-section form .job-searching-result {
    margin-bottom: 30px
  }

  .technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li {
    max-width: 100%
  }

  .faq-section {
    padding: 40px 0
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    font-size: 14px;
    line-height: 21px
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 14px;
    line-height: 21px;
    padding: 12px 35px 12px 12px
  }

  .faq-section .accordion .accordion-item .accordion-collapse .accordion-body {
    font-size: 12px;
    line-height: 20px
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button:after {
    width: 22px;
    height: 22px;
    right: 14px
  }

  .faq-section .accordion .accordion-item .accordion-header .accordion-button span,
  .faq-section .accordion .accordion-item .accordion-collapse .accordion-body span {
    max-width: 100%
  }

  .footer-section .footer-inner .hire-marquee {
    padding-top: 12px;
    padding-bottom: 10px
  }

  .footer-section .footer-inner .hire-marquee div a {
    font-size: 22px;
    line-height: 33px
  }

  .footer-section .footer-inner .hire-marquee div a::after {
    left: -26px;
    top: 14px
  }

  .footer-section .footer-inner .hire-marquee div span {
    font-size: 22px;
    line-height: 33px
  }

  .footer-section .footer-bottom {
    padding: 24px 0 32px 0 !important
  }

  .footer-section .content-company .content {
    margin-bottom: 37px
  }

  .footer-section .content-company h3 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px
  }

  .footer-section .content-company ul li::after {
    top: 7px
  }

  .footer-section .content-company ul li a {
    font-size: 16px;
    line-height: 19px
  }

  .footer-section .content-company .badges ul li img {
    width: 47px;
    height: 47px
  }

  .footer-section .footer-content .footerLinks h3 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px
  }

  .footer-section .footer-content .footerLinks ul li {
    margin-bottom: 11px
  }

  .footer-section .footer-content .footerLinks ul li a {
    font-size: 12px;
    line-height: 15px
  }

  .footer-section .footer-copyright .copyright ul li {
    font-size: 8px;
    line-height: 10px;
    margin-right: 14px
  }

  .footer-section .footer-copyright .copyright ul li:first-child {
    width: 100%
  }

  .footer-section .footer-copyright .copyright a {
    font-size: 8px;
    line-height: 10px
  }

  .footer-section .footer-copyright .trootech-text {
    font-size: 40px !important;
    line-height: 50px !important;
    margin-bottom: 5px !important
  }

  .footer-section .footer-bottom {
    padding: 60px 0 0 0
  }

  .modal-form .form-control,
  .modal-form .form-select {
    height: 50px
  }

  .modal-form .modal-lg {
    padding: 15px 0
  }

  .modal-form .modal-content .modal-footer .modal-footer-inner .btn {
    margin: 5px 0;
    min-width: 100px
  }

  .modal-form .modal-content .modal-body .form-group textarea {
    min-height: 100px
  }

  .modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label {
    padding: 11px 13px 11px 40px;
    min-height: auto
  }

  .modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label::after {
    left: 10px
  }

  .modal-form .modal-content .modal-body {
    padding: 18px 15px 30px
  }

  .modal-form .modal-content .modal-footer {
    padding: 0 15px
  }

  .modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
    margin-right: 20px
  }

  .modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content h2 {
    font-size: 18px;
    line-height: 25px
  }

  .modal-form .modal-content .modal-body .form-group .form-control,
  .modal-form .modal-content .modal-body .form-group .form-select {
    height: 50px
  }

  .technologies-middle .framework-section .framework-inner .row .framework-col {
    width: 100%
  }

  .technologies-middle .framework-section .framework-inner .row .framework-col:first-child .framework-card {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
  }

  .technologies-middle .framework-section .framework-inner .row .framework-col:last-child .framework-card {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
  }

  .technologies-middle .framework-section .framework-inner .framework-card {
    margin-top: -1px;
    margin-bottom: 0;
    height: auto
  }

  .aboutus-middle .vibe-section .vibe-main-box .vibe-text p {
    font-size: 14px;
    line-height: 24px;
    max-width: 100px
  }

  .modal-form .form-control,
  .modal-form .form-select {
    height: 50px
  }

  .modal-form .modal-lg {
    padding: 15px 0
  }

  .modal-form .modal-content .modal-footer .modal-footer-inner .btn {
    margin: 5px 0;
    min-width: 100px
  }

  .modal-form .modal-content .modal-body .form-group textarea {
    min-height: 100px
  }

  .modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label {
    padding: 11px 13px 11px 40px;
    min-height: auto
  }

  .modal-form .modal-content .modal-body .form-group.modal-radio-group .form-radio label::after {
    left: 10px
  }

  .modal-form .modal-content .modal-body {
    padding: 18px 15px 20px
  }

  .modal-form .modal-content .modal-footer {
    padding: 0 15px
  }

  .modal-form .modal-content .modal-footer .modal-footer-inner .btn.btn-secondary {
    margin-right: 20px
  }

  .modal-form .modal-content .modal-body .modal-banner-section .modal-banner-content h2 {
    font-size: 18px;
    line-height: 25px
  }

  .modal-form .modal-content .modal-body .form-group .form-control,
  .modal-form .modal-content .modal-body .form-group .form-select {
    height: 50px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail.blog-banner {
    padding: 18px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .blog-banner2-text {
    padding: 15px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .col-bg {
    padding-top: 15px
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .blog-banner2-text h2 {
    max-width: 100%
  }

  .blog-migration-section .blog-migration-inner .blog-migration-cantent .blogcontent-detail .blog-banner2 .blog-banner2-text {
    text-align: center
  }

  .news-letter-section .news-letter-content h2 {
    width: 320px;
    margin: 0 auto
  }

  .news-letter-section .news-letter-content {
    background-image: url(../../assets/images/news-letter-bg.png);
    text-align: center;
    background-size: 100% 110%;
    background-position: 50% 100%
  }

  .our-culture-section .our-culture-card {
    background-color: rgba(243, 250, 254, .7607843137)
  }

  .our-culture-section .our-culture-card .card-tite p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #616161;
    opacity: .56
  }

  .theme-dark .our-culture-section .our-culture-card {
    background-color: rgba(28, 29, 30, .76)
  }

  .theme-dark .our-culture-section .our-culture-card .card-tite p {
    color: #fff
  }

  .startups-separate-section .startups-separate-inner .startups-separate-imgbox .startups-separate-img img {
    margin: 0 auto;
    width: 100%;
    display: block
  }

  .maintanence-banner .mantainence-banner-content .row {
    flex-direction: column-reverse
  }

  .maintanence-banner .mantainence-banner-content h4 {
    margin: 0 auto;
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    line-height: 22px
  }

  .maintanence-banner .mantainence-banner-content .img-wrapper {
    max-width: 200px;
    margin: 0 auto
  }

  .partner-section .partner-section-inner p {
    max-width: 100%
  }

  .agile-recommend-section .agile-recommend-inner .recommend-content .solutions-div .solutions-list ul {
    padding-left: 0
  }

  .startups-middle .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li .nav-link {
    font-size: 18px;
    line-height: 27px
  }

  .our-culture-section .our-culture-card {
    background-image: linear-gradient(283.13deg, rgba(0, 134, 230, 0.05) 5.56%, rgba(141, 208, 255, 0.05) 113.66%), url(../../assets/images/our-culture-bg-xs.png);
    background-size: contain
  }

  .service-feature-section .anchor-top {
    position: absolute;
    top: -140px
  }

  .blog-migration-section .anchor-top {
    position: absolute;
    top: -130px
  }

  .casestudies-allstories-section .anchor-top {
    top: -90px
  }

  .news-letter-section .news-letter-content h2 {
    max-width: 100%;
    width: 100%
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    height: 100%;
    min-height: 125px
  }

  .sticky-bottom.footerBtn {
    position: fixed;
    right: 0;
    bottom: 0
  }

  .footer-section .footer-copy-right.footer-bottom {
    z-index: 80;
    position: relative;
    background: #f6faff
  }

  .theme-dark .footer-section .footer-copy-right.footer-bottom {
    background: #111
  }

  .theme-dark .footer-section .footer-second-row.footer-bottom .footer-review-section ul ligti {
    border-right: none !important
  }

  .sticky-bottom.footerBtn {
    position: fixed;
    right: 20px;
    bottom: 20px
  }

  .aboutus-middle .believe-section .believe-inner .believe-box {
    height: auto
  }

  .thank-you-middle .thank-you-content {
    padding-top: 60px;
    padding-bottom: 40px
  }

  .thank-you-middle .thank-you-content .thank-you-title {
    padding-bottom: 6px
  }

  .thank-you-middle .thank-you-content .thank-you-title h1 {
    font-size: 26px;
    line-height: 49px
  }

  .thank-you-middle .thank-you-content .thank-you-title h1 span {
    font-size: 24px;
    line-height: 34px
  }

  .thank-you-middle .thank-you-content .thank-page-content p {
    font-size: 14px;
    line-height: 21px
  }

  .page-blog-list.blog-articles-section .blog-articles-inner .blog-card .blog-img {
    height: 100%;
    min-height: 125px
  }

  .page-blog-list.blog-articles-section .blog-articles-inner .blog-card .blog-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
  }

  .startups-technology-section .startups-technology-inner .startups-technology-cantent .startups-technology-name ul.nav-tabs li {
    max-width: 100%;
    display: inline-block
  }

  .wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
    left: 43%
  }

  .request-proposal-new.request-proposal.request-proposal-form .agreement-section .agreement-inner .agreement-list .agreement-box {
    flex-flow: column
  }

  .request-proposal-new.request-proposal.request-proposal-form .about-project-section .about-project-inner .select-type-content .form-group {
    padding-top: 0;
    margin-bottom: 20px !important
  }

  .lets-connect-modal .modal-content .modal-header h4 {
    font-size: 14px;
    line-height: 21px
  }

  .lets-connect-modal .modal-content .modal-body .card .card-body h4 {
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    margin-bottom: 10px
  }

  .lets-connect-modal .modal-content .modal-body .card {
    padding: 10px
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    width: 100%;
    height: unset;
    border-radius: 0;
    max-height: unset
  }

  .blog-aspectratio-43 {
    display: flex !important;
    flex-direction: column
  }

  .blog-page-section .blog-allstories-section .blog-allstories-inner .blog-card .blog-img,
  .blog-detail-development .blog-articles-section.blog-details-articles-section .blog-articles-inner .blog-card .blog-img {
    width: 100%;
    height: unset;
    max-height: unset
  }

  .nav-bottom-btn {
    display: flex;
    flex-flow: column
  }

  .submenu-bottom-content.d-none-desktop {
    display: block;
    text-align: center
  }

  .submenu-bottom-content.d-none-desktop p {
    margin-bottom: 15px;
    padding-right: 0;
    text-align: center
  }

  .submenu-bottom-content.d-none-desktop .btn-primary {
    max-width: 255px;
    margin: 0 auto !important
  }

  .company-banner-section {
    padding: 30px 0
  }

  .company-banner-section .company-banner-inner .row {
    flex-direction: column-reverse
  }

  .logo-slider-wrapper {
    padding-bottom: 30px
  }

  .count-on-section {
    padding-bottom: 30px
  }

  .hiring-banner-wrapper {
    padding-bottom: 30px
  }

  .services-technology-section.technologies-section .technologies-inner {
    padding: 30px 0
  }

  .services-technology-section.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li .nav-link {
    padding-right: 0
  }

  .services-technology-section.technologies-section .technologies-inner .heading h2 {
    font-size: 24px;
    line-height: 36px
  }

  .services-technology-section.technologies-section .technologies-inner .heading h3 {
    font-size: 14px;
    line-height: 21px
  }

  .services-technology-section.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs {
    flex-direction: column
  }

  .services-technology-section.technologies-section .technologies-inner .technologies-cantent .technologies-name ul.nav-tabs li {
    max-width: unset
  }

  .latest-technology-wrapper {
    padding-bottom: 40px
  }

  .latest-technology-wrapper .latest-technology-inner .heading-wrapper {
    margin-bottom: 30px
  }

  .latest-technology-wrapper .latest-technology-inner .heading-wrapper .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px
  }

  .latest-technology-wrapper .latest-technology-inner .heading-wrapper .subtext {
    font-size: 14px;
    line-height: 21px
  }

  .serives-new-middle .testimonial-section {
    padding: 0 0 50px
  }

  .serives-new-middle .testimonial-section .testimonial-inner {
    padding: 30px 0 60px
  }

  .serives-new-middle .testimonial-section .heading {
    margin-bottom: 30px
  }

  .transfroming-industries-wrapper {
    padding-bottom: 40px
  }

  .transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper {
    margin-bottom: 30px
  }

  .transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px
  }

  .transfroming-industries-wrapper .transfroming-industries-inner .heading-wrapper .subtext {
    font-size: 14px;
    line-height: 21px
  }

  .approach-section-wrapper {
    padding-bottom: 40px
  }

  .approach-section-wrapper .approach-section-inner {
    padding: 30px 0
  }

  .approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item {
    padding: 12px
  }

  .approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item .approach-list-content .count {
    font-size: 12px;
    line-height: 18px
  }

  .approach-section-wrapper .approach-section-inner .approach-section-content .approach-list .approach-list-item .approach-list-content p {
    font-size: 12px;
    line-height: 18px
  }

  .why-trusted-section {
    padding-bottom: 40px
  }

  .why-trusted-section .why-trusted-inner .heading-wrapper {
    margin: 0 auto 30px
  }

  .why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform {
    width: 98%
  }

  .why-trusted-section .why-trusted-inner .why-trusted-section-content .promote-slider .plateform .count {
    margin-bottom: 8px
  }

  .case-study-section {
    padding-bottom: 40px
  }

  .case-study-section .case-study-inner {
    padding: 30px 0
  }

  .case-study-section .case-study-inner .case-study-card {
    max-height: 240px;
    overflow: hidden
  }

  .services-aprtner-section.partner-section .partner-section-inner .heading {
    font-size: 24px;
    line-height: 32px
  }

  .services-faq-section.faq-section {
    padding-bottom: 40px
  }

  .services-faq-section.faq-section .faq-inner {
    padding: 30px 0
  }

  .latest-blogs-section .latest-blogs-inner .heading-wrapper {
    margin-bottom: 0
  }

  .latest-blogs-section .latest-blogs-inner .latest-blog-card-list .latest-blog-card .card-content .blog-title {
    min-height: unset
  }

  .industries-system-section .development-box .development-bottom .development-links li a::after,
  .industries-system-section .development-box .development-bottom .development-links li .development-links-btn:hover::after {
    top: 20px !important
  }

  .service-feature-section .service-feature-card {
    padding: 0 0 16px !important
  }

  .service-feature-section .service-feature-card {
    padding: 0 0 16px !important
  }
}

@media (max-width:570px) {
  .marquee-container-new {
    padding: 2px
  }

  .developer-slider-list li {
    font-size: 14px;
    line-height: 19px;
    padding: 0 5px
  }
}

@media (max-width:480px) {
  .agile-recommend-section .agile-recommend-inner .recommend-img img {
    width: 100%
  }

  .startups-features-section .slick-prev,
  .startups-features-section .slick-next {
    top: -44px
  }
}

@media (max-width:479px) {
  .career-middle .news-letter-section .news-letter-content .news-letter-link a:first-child {
    border-right: 1px solid #000000 !important
  }

  .theme-dark .career-middle .news-letter-section .news-letter-content .news-letter-link a:first-child {
    border-right: 1px solid #ffffff !important
  }

  .career-middle .news-letter-section .news-letter-content .news-letter-link a {
    padding: 0 12px
  }

  .career-middle .careers-contributer-section {
    padding: 60px 0 0
  }

  .career-middle .careers-contributer-section::after {
    background-size: 180% 100% !important
  }
}

@media (max-width:475px) {

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner,
  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 5px 12px
  }

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner h3,
  .case-studies-main-section .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content h3 {
    font-size: 13px !important;
    line-height: 17px !important
  }
}

@media (max-width:420px) {
  .case-studies-section .case-studies-inner .case-studies-heading h2 {
    font-size: 20px;
    line-height: 35px
  }

  .agreement-section .agreement-inner .agreement-list .agreement-box {
    display: block
  }

  .agreement-section .agreement-inner .agreement-list .agreement-box .agreement-btn {
    padding-top: 25px
  }

  .request-proposal-new.request-proposal.request-proposal-form .agreement-section .agreement-inner .agreement-list .agreement-box .agreement-btn {
    padding-top: 0px !important;
    text-align: right
  }
}

@media (max-width:416px) {
  .casestudies-stories-section .casestudies-stories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 15px 30px 15px 15px
  }

  .hire-developer-modal .modal-left-img {
    height: 160px;
    width: 200px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span {
    padding-left: 8px
  }

  .hire-developer-modal.modal-form .modal-content .modal-body .form-group .modal-upload-file .upload-text span:first-child {
    padding-left: 8px
  }

  .casestudies-allstories-section.allcase-studies-section .casestudies-allstories-inner .casestudies-card .casestudies-content .casestudies-content-inner {
    padding: 15px 30px 20px 15px
  }
}

@media (max-width:375px) {
  .wordwise-section .client-map-content .slider-client .slick-arrow.slick-prev {
    left: 40%
  }
}

@media (max-width:340px) {

  h1.page-heading,
  .request-banner-section .request-banner-inner .request-banner-content .heading h1.page-heading,
  .about-section .heading h1,
  .findus-section .heading h1.page-heading,
  .findus-section .heading h2.page-heading,
  .about-section .heading h1,
  .service-middle .service-hero-section .heading h1.page-heading,
  .digital-banner-section .digital-banner-inner .heading h1.page-heading,
  .agile-banner-section .agile-banner-inner .agile-banner-content .heading h1.page-heading,
  .blog-banner-section .blog-banner-inner .blog-banner-content .heading h1,
  .blog-digital-section .blog-digital-inner .blog-digital-heading h1,
  .case-studies-main-section .casestudies-section .casestudies-inner .casestudies-content .heading h1,
  .case-studies-section .case-studies-inner .case-studies-heading h1 {
    font-size: 20px;
    line-height: 27px
  }
}

@media screen and (max-width:339px) {

  .page-blog-list .blog-allstories-heading-ty,
  .page-stories-list .casestudies-allstories-heading {
    display: block
  }

  .page-blog-list .blog-allstories-heading-ty:before,
  .page-stories-list .casestudies-allstories-heading:before {
    top: 20% !important;
    transform: translateY(-50%) !important
  }

  .page-blog-list .view-all-btn,
  .page-stories-list .view-all-btn {
    padding-top: 18px;
    padding-bottom: 10px
  }

  .view-all-btn {
    text-align: right
  }
}

@media (max-width:320px) {
  .vibe-section .vibe-main-box .vibe-img {
    height: 200px
  }

  .vibe-section .vibe-main-box .vibe-text p {
    font-size: 14px;
    line-height: 20px
  }

  .vibe-section .vibe-main-box {
    height: 302px
  }
}


@media (max-width: 992px) {
  .faq-section .heading h2 {
    font-size: 36px;
    line-height: 48px;
  }

  .testimonial-section .heading h2 {
    font-size: 36px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .faq-section .heading h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .testimonial-section .heading h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .upload-file .file-upload-data {
    min-height: auto !important;
  }

  .request-proposal .about-project-section .about-project-inner .select-type-content .upload-file .upload-file-img {
    margin-bottom: 0 !important;
  }

}

@media (max-width: 767px) and (min-width: 575px) {
  .startups-separate-section .startups-separate-inner .startups-separate-imgbox {
    display: flex;
  }
}