.contact-new-section {
  padding: 60px 0 116px 0;
  // background: var(--secondary-background-light);
  background: #F4FAFE;
  min-height: 650px;

  .contact-left-content {
    .contact-logo {
      padding-bottom: 30px;
      display: block;

      img {
        max-width: 255px;
        width: 100%;
        height: auto;

      }
    }

    h2 {
      font-size: 40px;
      line-height: 150%;
      font-weight: 700;
      color: #040405;
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    p {
      font-size: 18px;
      line-height: 150%;
      font-weight: 500;
      color: #686869;
      margin-bottom: 20px;
      max-width: 430px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        padding-bottom: 24px;
        list-style: none;

        a {
          font-size: 24px;
          line-height: 150%;
          font-weight: 500;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          padding-right: 42px;
          position: relative;
          color: #040405;

          .call-icon {
            border-radius: 8px;
            border: 1px solid rgba(0, 134, 230, 0.20);
            background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
          }

          &:before {
            content: "";
            background-image: url("../../../public/images/home/skype-right-arrow.svg");
            width: 28px;
            height: 28px;
            background-size: contain;
            position: absolute;
            right: 14px;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: 0.3s;
            opacity: 0;
          }

          &:hover {
            background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
              opacity: 1;
              right: 0;
            }
          }
        }
      }
    }
  }

  .contact-right-form {
    padding: 30px;
    border-radius: 16px;
    // border: 2px solid rgba(0, 134, 230, 0.20);
    border: 2px solid rgba(0, 134, 230, 0.20);
    // background: var(--contact-card-bg);
    background: #fff;
    box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);

    h3 {
      font-size: 24px;
      line-height: 150%;
      font-weight: 600;
      margin-bottom: 34px;
      // color: var(--heading-text);
      color: #040405;
      
    }

    .form-group {
      margin-bottom: 24px;

      .form-control {
        padding: 16px !important;
        border-radius: 10px;
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        // color: var(--heading-text);
         color: #040405;
        // border: 1px solid var(--primary-border-light);
        border: 1px solid rgba(0, 134, 230, 0.20);
        // background-color: var(--form-control-bg);
        background-color: #fff;

        &:focus {
          // border: 1px solid var(--border-blue) !important;
          border: 1px solid #0086e6 !important;

        }

      }

      textarea.form-control {
        height: 122px;
        resize: none;
      }

    }

    .form-floating>label {
      font-size: 14px;
      // color: var(--secondary-text-light);
      color: #686869;
    }

    .form-floating>.form-control:focus~label {
      // color: var(--text-blue);
      color: #0086E6;
      font-size: 14px;
      // background-color: var(--contact-card-bg) !important;
      background-color:#fff !important;
      
    }

    .select-budget {
      height: 100%;
      margin: 0;
      min-height: 135px;

      .form-control {
        height: 100%;
      }

      h3 {
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        margin-bottom: 13px;
        // color: var(--heading-text);
        color: #040405;
      }

      .file-input-budget {
        opacity: 0;
        overflow: hidden;
        z-index: -1;
        display: none;
      }

      .budget-upload-file {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .upload-icon {
          width: 64px;
          height: 64px;
          border-radius: 12px;
          // background: var(--upload-bg-light);
          background: var(--Troo-Blue-Gradient, linear-gradient(283deg, rgba(0, 134, 230, 0.05) 5.56%,rgba(141, 208, 255, 0.05) 113.66%));
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;
        }

        span {
          font-size: 14px;
          line-height: 150%;
          font-weight: 500;
          // color: var(--secondary-text-light);
          color:#686869;
          margin: 0;
        }
      }
    }

    .bt-bugget-content {
      .slider-labels {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .caption {
          margin-bottom: 12px;

          p {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;

            em {
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              font-weight: 500;
              // color: var(--tab-color-dark);
              color: rgba(109, 109, 109, 1);
            }

            span {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
              // color: var(--heading-text);
              color: #fff;
              margin-left: 8px;
            }
          }
        }
      }
    }

    .update-bugget-select {
      .budget-select .budget-select__control {
        padding: 13px 16px 13px 2px;
        color: #040405;
        border: 1px solid rgba(0, 134, 230, 0.20) !important;
        background-color: #fff;
      }
    }

    .sign-nda {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 48px;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        font-size: 12px;
        line-height: 150%;
        font-weight: 500;
        margin-right: 24px;
        text-decoration: none;
        // color: var(--contact-sign-link);
        color: rgba(4, 4, 5, 0.56);
        transition: 0.3s;

        img {
          margin-right: 6px;
        }

        &:hover {
          // color: var(--primary-green-color);
          color: #84bd00;
        }
      }
    }

    .budget-btn {
      margin-top: 32px;
      text-align: right;

      .btn-primary {
        min-width: max-content;
      }
    }

  }
}

// technology contact section
.technology-cobntact-section {
  padding-top: 0;
  min-height: 620px;

  .contact-left-content {
    h2 {
      margin-bottom: 24px;
      font-size: 32px;
      max-width: 550px;
    }
  }

  .contact-right-form {
    .budget-btn {
      margin-top: 10px;
    }

    .sign-nda {
      margin-top: 24px;
    }
  }
}

.contact-right-form {
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #040405;
    margin-bottom: 30px;
  }
}

.contact-right-form {
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #040405;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
  .contact-new-section {
    padding: 0px 0 80px 0;
  }

  .contact-new-section .container {
    max-width: 1000px !important;
  }

  .contact-new-section .contact-left-content .contact-logo {
    max-width: 200px;
    padding-bottom: 24px;
  }

  .contact-new-section .contact-right-form h2 {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 24px;
  }

  .contact-new-section .contact-left-content .contact-logo img {
    width: 100%;
    max-width: 255px;
  }

  .contact-new-section .contact-left-content h2 {
    font-size: 32px;
  }

  .contact-new-section .contact-left-content p {
    font-size: 14px;
  }

  .contact-new-section .contact-left-content ul li a .call-icon img {
    width: 24px;
    height: 24px;
  }

  .contact-new-section .contact-left-content ul li a {
    font-size: 18px;
  }

  .contact-new-section .contact-left-content ul li {
    padding-bottom: 12px;
  }

  .contact-new-section .contact-right-form .sign-nda a img {
    width: 15px;
  }

  .contact-new-section .contact-right-form .sign-nda a {
    font-size: 11px;
    margin-right: 16px;
  }

  .contact-new-section .contact-right-form {
    padding: 24px;
  }

  .contact-new-section .contact-right-form .form-group .form-control {
    padding: 10px 12px !important;
    height: unset;
    min-height: unset;
    border-radius: 8px;
    font-size: 14px;
  }

  .contact-new-section .contact-right-form .form-floating>label {
    padding: 11px;
  }

  .contact-new-section .contact-right-form .update-bugget-select .budget-select .budget-select__control {
    padding: 7px 16px 7px 2px;
    font-size: 14px;
    border-radius: 8px;
  }

  .contact-new-section .contact-right-form .select-budget h3 {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .contact-new-section .contact-right-form .bt-bugget-content .slider-labels .caption {
    margin-bottom: 6px;
  }

  .contact-new-section .contact-right-form .bt-bugget-content .slider-labels .caption p span {
    font-size: 14px;
    margin-left: 6px;
  }

  .contact-new-section .contact-right-form .select-budget .form-control {
    height: 100%;
  }

  .contact-new-section .contact-right-form h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .contact-new-section .contact-right-form .form-group .form-control {
    padding: 12px 12px !important;
  }

  .contact-new-section .contact-right-form .sign-nda {
    margin-top: 10px;
  }

  .contact-new-section .contact-right-form .budget-btn .btn-primary {
    min-width: unset;
  }

  .contact-new-section .contact-right-form .budget-btn {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .contact-new-section {
    padding: 30px 0 60px 0;
    min-height: auto;
  }

  .contact-new-section .contact-left-content .contact-logo {
    max-width: 200px;
    padding-bottom: 24px;
  }

  .contact-new-section .contact-left-content .contact-logo img {
    width: 100%;
    max-width: 255px;
  }

  .contact-new-section .contact-left-content h2 {
    font-size: 32px;
  }

  .contact-new-section .contact-left-content p {
    font-size: 14px;
  }

  .contact-new-section .contact-left-content ul li a .call-icon img {
    width: 24px;
    height: 24px;
  }

  .contact-new-section .contact-left-content ul li a {
    font-size: 18px;
  }

  .contact-new-section .contact-left-content ul li {
    padding-bottom: 12px;
  }

  .contact-new-section .contact-right-form .sign-nda a img {
    width: 15px;
  }

  .contact-new-section .contact-right-form .sign-nda a {
    font-size: 11px;
    margin-right: 16px;
  }

  .contact-new-section .contact-right-form {
    padding: 24px;
  }

  .contact-new-section .contact-right-form .form-group .form-control {
    padding: 10px 12px !important;
    height: unset;
    min-height: unset;
    border-radius: 8px;
    font-size: 14px;
  }

  .contact-new-section .contact-right-form .form-floating>label {
    padding: 11px;
  }

  .contact-new-section .contact-right-form .update-bugget-select .budget-select .budget-select__control {
    padding: 7px 16px 7px 2px;
    font-size: 14px;
    border-radius: 8px;
  }

  .contact-new-section .contact-right-form .select-budget h3 {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .contact-new-section .contact-right-form .bt-bugget-content .slider-labels .caption {
    margin-bottom: 6px;
  }

  .contact-new-section .contact-right-form .bt-bugget-content .slider-labels .caption p span {
    font-size: 14px;
    margin-left: 6px;
  }

  .contact-new-section .contact-right-form .select-budget .form-control {
    height: 100%;
  }
}

@media (max-width: 992px) {
  .contact-new-section .contact-left-content .contact-logo {
    max-width: 140px;
    padding-bottom: 16px;
  }

  .contact-new-section .contact-left-content .contact-logo img {
    width: 100%;
  }

  .contact-new-section .contact-left-content h2 {
    font-size: 24px;
  }

  .contact-new-section .contact-left-content p {
    font-size: 16px;
  }

  .contact-new-section .contact-left-content ul li a .call-icon {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }

  .contact-new-section .contact-left-content ul li a .call-icon img {
    width: 18px;
    height: 18px;
  }

  .contact-new-section .contact-left-content ul li a {
    font-size: 16px;
    padding-right: 30px;
  }

  .contact-new-section .contact-left-content ul li {
    padding-bottom: 16px;
  }

  .contact-new-section .contact-left-content ul li a:before {
    width: 18px;
    height: 18px;
  }

  .contact-new-section .contact-right-form .select-budget {
    height: auto;
    min-height: unset;
    margin-bottom: 24px;
  }

  .contact-new-section .contact-right-form {
    padding: 16px;
  }

  .contact-new-section .contact-right-form h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .contact-new-section .contact-right-form .form-group .form-control {
    padding: 10px 12px !important;
    height: unset;
    min-height: unset;
    border-radius: 6px;
  }

  .contact-new-section .contact-right-form .form-floating>label {
    padding: 10px;
  }

  .contact-new-section .contact-right-form .form-group {
    margin-bottom: 14px;
  }

  .contact-new-section .contact-right-form .sign-nda {
    margin-top: 10px;
  }

  .contact-new-section .contact-right-form .budget-btn {
    margin-top: 20px;
    text-align: center;
  }

  .contact-new-section {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .contact-new-section {
    padding: 0 0 60px 0;
  }

  .contact-new-section .contact-left-content .contact-logo {
    margin: auto;
  }

  .contact-new-section .contact-left-content h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 26.4px;
  }

  .contact-new-section .contact-left-content p {
    text-align: center;
  }

  .contact-new-section .contact-left-content ul li a {
    display: flex;
  }

  .contact-new-section .contact-left-content ul li a:before {
    opacity: 1;
    right: 0;
  }

  .contact-new-section .contact-right-form .select-budget .budget-upload-file .upload-icon {
    width: 44px;
    height: 44px;
    border-radius: 6px;
  }

  .contact-new-section .contact-right-form .select-budget .budget-upload-file .upload-icon img {
    width: 26px;
  }

  // .contact-new-section .contact-right-form{
  //   margin-bottom: 32px;
  // }
  .contact-new-section .contact-right-form .update-bugget-select .budget-select .budget-select__control {
    padding-right: 10px;
  }
}


.budget-btn .btn-send {
  padding: 13px 16px !important;
  min-width: unset !important;
}

@media (max-width: 991px) {
  .schedule-form .btn.btn-primary {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}



////////////
///
///

.form-group {
	margin-bottom: 24px;

	.form-control {
		padding: 16px !important;
		border-radius: 10px;
		font-size: 16px;
		line-height: 150%;
		font-weight: 600;
		color: #040405;
		border: 1px solid rgba(0, 134, 230, 0.20);
		background-color: #fff;

		&:focus {
			border: 1px solid #0086E6 !important;
		}
	}

	textarea.form-control {
		height: 122px;
		resize: none;
	}

	.budget-select .budget-select__control {
		padding: 10px 12px 10px 4px;
		background-color: #fff;
		box-shadow: none;
		border-radius: 12px;
		border: 1px solid rgba(4, 4, 5, 0.2) !important;
	}

	.budget-select .budget-select__control.budget-select__control--menu-is-open {
		border: 1px solid #0086E6 !important;
	}

	.budget-select .budget-select__single-value {
		color: #fff;
		font-weight: 600;
	}

	.budget-select .budget-select__indicator-separator {
		display: none;
	}

	.budget-select .budget-select__control--menu-is-open .budget-select__indicator {
		background-image: url(../../../assets/images/bg-up-arrow.svg);
		background-repeat: no-repeat;
	}

	.budget-select .budget-select__indicator svg {
		display: none;
	}

	.budget-select .budget-select__indicator {
		height: 30px;
		width: 30px;
		background-image: url(../../../assets/images/light-down-arrow.svg);
		background-size: cover;
		background-position: center center;
	}

	.budget-select .budget-select__menu {
		border: 1px solid #8dd0ff !important;
		border-top: 1px solid #8dd0ff !important;
		border-radius: 12px;
		overflow: hidden;
		z-index: 9;
		padding: 0;
	}

	.budget-select .budget-select__menu-list {
		padding: 0;
	}

	.budget-select .budget-select__menu .budget-select__option:last-child {
		border-bottom: none !important;
	}

	.budget-select .budget-select__multi-value__remove:hover {
		background-color: transparent;
	}
}
.range-slider {
	padding: 15px 20px 25px 20px;
	border: 1px solid rgba(4, 4, 5, 0.2);
	border-radius: 10px;

	.slider-labels {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.caption {
			p {
				margin: 0;

				em {
					font-size: 12px;
					line-height: 18px;
					font-weight: 500;
					color: #91959b;
					opacity: 0.56;
					margin-bottom: 5px;
					display: block;
					font-style: normal;
				}

				span {
					font-size: 18px;
					line-height: 27px;
					font-weight: 600;
					color: #040405 !important;
				}

			}
		}
	}
}
.pricing-list {
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap:6px;
	margin-bottom: 2px;
  }

    .pricing-list li {
	margin-bottom: 0 !important;
	list-style: none;
  }

    .pricing-list .pricing-radio {
	display: none; /* Hide the radio button */
  }

    .pricing-list .pricing-label {
	font-size: 12px !important;
	font-weight: 400 !important;
	line-height: 15px;
	text-align: center;
	border: 1px solid #DCECF8;
	border-radius: 8px;
	height: 32px;
	padding: 6px 7px;
	color: #000;
	text-decoration: none;
	display: block;
	transition: background-color 0.2s ease;
	white-space: nowrap;
	cursor: pointer;
	margin-bottom: 0 !important;
  }

    .pricing-list .pricing-radio:checked + .pricing-label, .pricing-list .pricing-radio.selected + .pricing-label p, .selected-label{
	background: linear-gradient(96.18deg, #78C4FB 0%, #0086E6 100%);
	color: #fff !important;
	font-weight: 600 !important;
  }

    .pricing-list .pricing-label:hover {
	background-color: #F0F8FF;
  }
