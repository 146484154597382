/*****************/
.nav-link {
	padding: 0;
}
.footer-section {
	padding: 0px 0 0px 0;
	background: #eaf5fd;
	overflow: hidden;

	.hire-marquee {
		background-color: rgba(37, 37, 38, 0.12);
		padding-top: 10px;
		padding-bottom: 15px;
		div {
			span {
				font-weight: 700;
				font-size: 40px;
				line-height: 60px;
				display: inline-flex;
				align-items: center;
				color: #00011c;
				margin-right: 60px;
			}
			a {
				font-weight: 700;
				font-size: 40px;
				line-height: 60px;
				display: inline-flex;
				align-items: center;
				color: #00011c;
				margin-right: 60px;
				position: relative;
				&:hover {
					display: block;
					color: #00011c;
					text-decoration: none;
				}
				&::after {
					content: "";
					height: 11px;
					width: 11px;
					background-color: #00011c;
					display: block;
					border-radius: 100px;
					position: absolute;
					left: -40px;
					top: 28px;
				}
			}
		}
		ul {
			li {
				font-weight: 700;
				font-size: 40px;
				line-height: 60px;
				display: inline-flex;
				align-items: center;
				color: #00011c;
				margin-right: 60px;
				position: relative;
				a {
					display: block;
					color: #00011c;
					text-decoration: none;
				}
				&::after {
					content: "";
					height: 11px;
					width: 11px;
					background-color: #00011c;
					display: block;
					border-radius: 100px;
					position: absolute;
					right: -40px;
					top: 28px;
				}
			}
		}
	}
	.footer-bottom {
		padding-top: 80px;
		padding-left: 50px;
		padding-right: 50px;
		max-width: 1920px;
		margin: 0 auto;
	}
	.content-company {
		.content {
			margin-bottom: 50px;
		}
		h3 {
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			display: flex;
			align-items: center;
			color: rgba(0, 1, 28, 1);
			margin-bottom: 12px;
		}
		ul {
			max-width: 500px;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				margin: 0 30px 16px 0;
				position: relative;
				&::after {
					content: "";
					height: 5px;
					width: 5px;
					background: #91959b;
					display: block;
					border-radius: 100px;
					position: absolute;
					right: -18px;
					top: 15px;
				}
				&:first-child {
					margin-left: 0 !important;
				}
				&:last-child {
					&::after {
						display: none;
					}
				}
				a {
					font-weight: 500;
					font-size: 24px;
					line-height: 30px;
					display: flex;
					text-transform: capitalize;
					align-items: center;
					color: #91959b;
					text-decoration: none;
					&:hover {
						font-weight: 500;
						color: rgba(0, 1, 28, 1);
					}
				}
			}
		}
		.badges {
			ul {
				li {
					margin-right: 11px;
					&::after {
						display: none;
					}
					img {
						mix-blend-mode: luminosity;
						height: 66px;
						width: 66px;
						object-fit: contain;
					}
					&:hover {
						img {
							mix-blend-mode: normal;
						}
					}
				}
			}
		}
	}
	.footer-content {
		.footerLinks {
			margin-bottom: 20px;
			h3 {
				font-weight: 700;
				font-size: 20px;
				line-height: 24px;
				text-transform: capitalize;
				color: rgba(0, 1, 28, 1);
				margin-bottom: 20px;
			}
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				margin-bottom: 40px;
				li {
					margin-bottom: 18px;
					a {
						font-weight: 500;
						font-size: 16px;
						line-height: 19px;
						text-transform: capitalize;
						color: #91959b;
						text-decoration: none;
						&:hover {
							font-weight: 500;
							color: rgba(0, 1, 28, 1);
						}
					}
				}
			}
		}
	}
	.footer-copyright {
		position: relative;
		overflow: hidden;
		.trootech-text {
			font-weight: 800;
			font-size: 134.644px;
			line-height: 100px;
			display: flex;
			align-items: flex-end;
			color: rgba(0, 1, 28, 1);
			margin-bottom: 0;
			a {
				color: rgba(0, 1, 28, 1);
			}
		}
		.copyright {
			text-align: right;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			ul {
				padding: 0;
				margin: 0;
				li {
					display: inline-block;
					color: #91959b;
					font-size: 12px;
					line-height: 15px;
					margin-right: 16px;
					text-transform: uppercase;
				}
			}
			a {
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				color: #91959b;
				text-decoration: none;
				text-transform: capitalize;
				&:hover {
					color: rgba(0, 1, 28, 1);
				}
			}
		}
	}
	.footer-details.footer-bottom {
		padding: 80px 20px 40px;
		.footer-logo {
			padding-bottom: 40px;
			img {
				width: 166px;
				height: auto;
				max-width: 100%;
			}
		}
		.footer-details-inner {
			.footer-col {
				.footer-col-title {
					padding-bottom: 24px;
					p {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
						text-transform: capitalize;
						color: rgba(0, 1, 28, 1);
						margin-bottom: 0;
					}
				}
				.footer-location {
					max-width: 296px;
					padding-bottom: 16px;
					.location-name {
						font-weight: 600;
						font-size: 16px;
						line-height: 19px;
						text-transform: capitalize;
						color: rgba(0, 1, 28, 1);
						padding-bottom: 12px;
						margin-bottom: 0;
					}
					p {
						font-weight: 400;
						font-size: 14px;
						line-height: 150%;
						color: #91959b;
						margin-bottom: 0;
						transition: all 0.5s;
					}
					a {
						font-weight: 400;
						font-size: 14px;
						line-height: 150%;
						color: #91959b;
						margin-bottom: 0;
						transition: all 0.5s;
						&:hover {
							color: rgba(0, 1, 28, 1);
						}
					}
				}
				.footer-location.footer-company-info {
					padding-top: 34px;
					p {
						span {
							display: block;
						}
					}
				}
				.footer-col-link {
					ul {
						padding: 0;
						li {
							padding-bottom: 20px;
							&:last-child {
								padding-bottom: 0;
							}
							a {
								font-weight: 400;
								font-size: 14px;
								line-height: 17px;
								text-transform: capitalize;
								color: #111;
								opacity: 0.56;
								transition: all 0.5s;
								&:hover {
									font-weight: 400;
									color: rgba(0, 1, 28, 1);
									opacity: 1;
								}
							}
							a.list-see-more {
								color: #0091da;
								&:hover {
									font-weight: 400;
									color: rgba(0, 1, 28, 1);
								}
							}
						}
					}
				}
			}
			.footer-col.our-recognitions {
				padding-top: 36px;
				ul {
					padding: 0;
					margin: 0;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					li {
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 32px 20px 0;
						&:first-child {
							margin-left: 0;
							img {
								width: 75px;
								height: auto;
							}
						}
						&:nth-child(2) {
							img {
								// width: 120px;
								width: 100px;
							}
						}

						&:nth-child(3) {
							img {
								width: 160px;
							}
						}

						&:nth-child(4) {
							img {
								width: 130px;
							}
						}

						&:nth-child(5) {
							img {
								width: 125px;						
							}
						}

						&:nth-child(6) {
							img {
								width: 120px;
							}
						}

						&:nth-last-child(3) {
							img {
								width: 130px;
							}
						}
						&:nth-last-child(2) {
							img {
								width: 100px;
							}
						}

						&:nth-last-child(1) {
							img {
								width: 160px;
							}
						}

						img {
							width: 115px;
							max-width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
	.footer-second-row.footer-bottom {
		padding: 32px 20px;
		border-top: 1px solid #d1e9fb;
		border-bottom: 1px solid #d1e9fb;
		.footer-review-section {
			ul {
				padding: 0;
				display: flex;
				margin: 0;
				li {
					padding: 10px 30px;
					position: relative;
					border-right: 1px solid #d1e9fb;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
					.footer-review-box {
						.review-count {
							display: flex;
							align-items: center;
							padding-bottom: 11px;
							span {
								font-weight: 600;
								font-size: 16px;
								line-height: 19px;
								text-transform: capitalize;
								color: #111;
								padding-left: 8px;
							}
						}
						.review-logo {
							img {
								max-width: 100%;
								width: auto;
								max-height: 35px;
							}
						}
					}
				}
			}
		}
		.footer-social-link {
			padding: 22px 0;
			ul {
				display: flex;
				padding: 0;
				margin: 0;
				align-items: center;
				justify-content: flex-end;
				li {
					list-style: none;
					margin-left: 10px;
					a {
						width: 36px;
						height: 36px;
						padding: 5px;
						border-radius: 36px;
						background-color: #76787a;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						transition: all 0.5s;
						&:hover {
							background-color: #111;
						}
						img {
							width: auto;
							max-width: 16px;
							height: auto;
						}
					}
				}
			}
		}
	}
	.footer-copy-right.footer-bottom {
		padding: 22px 20px;
		p {
			font-weight: 400;
			font-size: 12px;
			line-height: 23px;
			text-transform: capitalize;
			color: #91959b;
			margin-bottom: 0;
		}
		ul {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;
			li {
				padding: 0 16px;
				&:last-child {
					padding-right: 0;
				}
				a {
					font-weight: 400;
					font-size: 12px;
					line-height: 15px;
					text-transform: capitalize;
					color: #91959b;
					&:hover {
						color: rgba(0, 1, 28, 1);
					}
				}
			}
		}
	}
}
.theme-dark {
	.footer-section {
		background: #111;
		.footer-details.footer-bottom {
			.footer-details-inner {
				.footer-col {
					.footer-col-link {
						ul {
							li {
								a {
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
		.footer-second-row.footer-bottom {
			.footer-social-link {
				ul {
					li {
						a {
							&:hover {
								background-color: rgba(97, 97, 97, 0.2);
							}
						}
					}
				}
			}
			padding: 32px 20px;
			border-top: 1px solid rgba(97, 97, 97, 0.2);
			border-bottom: 1px solid rgba(97, 97, 97, 0.2);
			.footer-review-section {
				ul {
					li {
						border-right: 1px solid rgba(97, 97, 97, 0.2);
					}
				}
			}
		}
	}
	.footer-second-row.footer-bottom {
		.footer-review-section {
			ul {
				li {
					.footer-review-box {
						.review-count {
							span {
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}
.tr-pl-20 {
	padding-left: 20px;
}
@media (max-width: 1366px) {
	.footer-section {
		.content-company {
			ul {
				li {
					a {
						font-size: 20px;
						line-height: 28px;
					}
				}
			}
		}
	}
}
@media (max-width: 1200px) {
	.footer-section {
		.content-company {
			ul {
				li {
					a {
						font-size: 20px;
						line-height: 28px;
					}
				}
			}
		}
		.footer-bottom {
			padding: 50px 0;
		}
		.footer-copyright {
			.trootech-text {
				font-weight: 800;
				font-size: 94.644px;
				line-height: normal;
			}
		}
	}
}
@media (max-width: 992px) {
	.footer-section {
		.hire-marquee {
			div {
				a {
					font-size: 30px;
					line-height: 36px;
					&:after {
						top: 15px;
					}
				}
				span {
					font-size: 30px;
					line-height: 36px;
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.footer-section {
		.hire-marquee {
			ul {
				li {
					font-weight: 700;
					font-size: 20px;
					line-height: 30px;
					margin-right: 30px;
					&::after {
						right: -18px;
						top: 14px;
						height: 5px;
						width: 5px;
					}
				}
			}
			div {
				span {
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
				}
				a {
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					margin-right: 40px;
					&:hover {
						font-weight: 500;
						color: rgba(0, 1, 28, 1);
					}
					&:after {
						top: 10px;
						height: 5px;
						width: 5px;
						left: -20px;
					}
				}
			}
		}
		.footer-bottom {
			padding: 20px 10px !important;
			.content-company {
				.content {
					margin-bottom: 20px;
				}
				h3 {
					font-size: 18px;
					line-height: 30px;
					ul {
						margin-bottom: 20px;
						li {
							a {
								font-size: 16px;
								line-height: 24px;
							}
						}
					}
				}
			}
			.footer-copyright {
				.trootech-text {
					font-size: 50px;
				}
				.copyright {
					justify-content: center;
				}
			}
		}
		.content-company {
			ul {
				li {
					margin-bottom: 5px;
					a {
						font-size: 18px;
					}
				}
			}
		}
		.footer-copyright {
			.copyright {
				text-align: center;
			}
			.trootech-text {
				justify-content: center;
				align-items: center;
			}
		}
		.footer-second-row.footer-bottom {
			.footer-social-link {
				padding-bottom: 0;
				padding-top: 30px;
				ul {
					justify-content: center;
					li {
						margin: 0 5px;
					}
				}
			}
			.footer-review-section {
				ul {
					justify-content: center;
					li {
						&:first-child {
							padding-left: 20px;
						}
						padding: 10px 20px;
					}
				}
			}
		}
		.footer-copy-right.footer-bottom {
			p {
				text-align: center;
			}
			ul {
				justify-content: center;
				li {
					padding: 0 6px !important;
				}
			}
		}
		.footer-details.footer-bottom {
			.footer-details-inner {
				.footer-col {
					.footer-col-link {
						ul {
							li {
								padding-bottom: 15px;
							}
						}
					}
				}
				.footer-col.our-recognitions {
					ul {
						li {
							margin: 0 15px 22px 0;
							&:first-child {
								img {
									width: 52px;
								}
							}
							&:nth-child(2) {
								img {
									width: 76px;
								}
							}
							&:nth-child(3) {
								img {
									width: 125px;
								}
							}

							&:nth-child(4) {
								img {
									width: 90px !important;
								}
							}

							&:nth-child(5) {
								img {
									width: 90px !important;
								}
							}

							&:nth-child(6) {
								img {
									width: 80px !important;
								}
							}

							&:nth-child(7) {
								img {
									width: 80px !important;
								}
							}



							&:nth-last-child(3) {
								img {
									width: 100px;
								}
							}

							&:nth-last-child(2) {
								img {
									width: 75px;
								}
							}

							&:nth-last-child(1) {
								img {
									width: 100px;
								}
							}

							img {
								width: 70px;
							}
						}
					}
					padding-bottom: 22px;
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
	.footer-section {
		.footer-copyright {
			.trootech-text {
				justify-content: center;
			}
			.copyright {
				justify-content: center;
			}
		}
	}
}
@media only screen and (max-width: 1400px) and (min-width: 1025px) {
	.footer-section {
		.footer-bottom {
			padding: 50px 20px 0 20px;
		}
	}
}
@media (max-width: 991px) {
	.footer-section {
		.footer-copyright {
			.copyright {
				justify-content: center;
			}
		}
		.footer-details.footer-bottom {
			.footer-details-inner {
				.footer-col {
					padding-bottom: 30px;
				}
				.footer-col.our-recognitions {
					padding-top: 0px;
					padding-bottom: 42px;
					ul {
						li {
							margin-bottom: 10px;
							img {
								width: 85px;
								height: auto;
							}
							&:nth-child(2) {
								img {
									width: 75px;
								}
							}

							&:nth-child(3) {
								img {
									width: 140px;
								}
							}

							&:nth-child(4) {
								img {
									width: 110px;
								}
							}

							&:nth-child(5) {
								img {
									width: 100px;
								}
							}

							&:nth-child(6) {
								img {
									width: 100px;
								}
							}

							&:nth-child(7) {
								img {
									width: 100px;
								}
							}
							&:nth-last-child(4) {
								img {
									width: 100px;
								}
							}

							&:nth-last-child(3) {
								img {
									width: 110px;
								}
							}

							&:nth-last-child(2) {
								img {
									width: 75px;
								}
							}

							&:nth-last-child(1) {
								img {
									width: 130px;
								}
							}

							&:first-child {
								img {
									width: 60px;
								}
							}
							margin: 0 15px 15px 0;
						}
					}
				}
			}
			padding: 80px 20px 10px;
		}
		.footer-second-row.footer-bottom {
			padding: 20px 20px;
		}
	}
	.tr-pl-20 {
		padding-left: 0px;
	}
}
@media (min-width: 1600px) {
	.footer-details-inner {
		> .row {
			> div {
				&:nth-child(3) {
					flex: 0 0 auto;
					width: 25%;
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.footer-details-inner {
		> .row {
			> div {
				&:nth-child(3) {
					flex: 0 0 auto;
					width: 22%;
				}
				&:nth-child(2) {
					flex: 0 0 auto;
					width: 19.66666667%;
					.footer-col {
						padding-left: 20px;
					}
				}
			}
		}
	}
}
@media (max-width: 575px) {
	.footer-section {
		.footer-second-row.footer-bottom {
			.footer-review-section {
				ul {
					flex-wrap: wrap;
					li {
						width: 50%;
						text-align: center;
						padding: 15px 20px;
						border-right: none;
						.footer-review-box {
							.review-count {
								justify-content: center;
							}
						}
					}
				}
			}
			padding: 20px 0px 30px !important;
		}
		.footer-details.footer-bottom {
			.footer-details-inner {
				.footer-col {
					.footer-location {
						max-width: 100%;
					}
					.footer-col-link {
						ul {
							li {
								padding-bottom: 10px;
							}
						}
					}
					.footer-col-title {
						padding-bottom: 15px;
					}
					padding-bottom: 15px;
					.footer-location.footer-company-info {
						padding-top: 20px;
					}
				}
				.footer-col.our-recognitions {
					ul {
						li {
							margin: 0 20px 15px 0;
							width: 25%;
							img {
								width: 65px !important;
								max-width: inherit;
							}

							&:nth-child(1) {
								img {
									width: 55px !important;
								}
							}

							&:nth-child(2) {
								img {
									width: 65px !important;
								}
							}

							&:nth-child(3) {
								img {
									width: 83px !important;
								}
							}

							&:nth-child(4) {
								img {
									width: 75px !important;
								}
							}

							&:nth-child(5) {
								img {
									width: 75px !important;
								}
							}

							
							&:nth-child(6) {
								img {
									width: 70px !important;
								}
							}

							&:nth-child(7) {
								img {
									width: 70px !important;
								}
							}


							&:nth-child(3) {
								img {
									width: 90px !important;
								}
							}

							&:nth-last-child(3) {
								img {
									width: 70px !important;
								}
							}

							&:nth-last-child(2) {
								img {
									width: 65px;
								}
							}


							&:nth-last-child(1) {
								img {
									width: 80px;
								}
							}
							&:first-child {
								img {
									width: 50px;
								}
							}
						}
						max-width: unset;
						justify-content: center;
					}
				}
			}
		}
	}
}
